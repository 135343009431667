import { baseApi } from './base';
import type { IFieldVisibility } from 'src/interfaces/visiblity';

interface StaffMetrics {
  totalStaff: number;
  activeStaff: number;
  utilization: number;
  specializations: Array<{
    name: string;
    count: number;
    utilization: number;
  }>;
}

interface ShiftMetrics {
  total: number;
  filled: number;
  pending: number;
  completed: number;
  fillRate: number;
  completionRate: number;
  byShiftType: Record<
    string,
    {
      total: number;
      filled: number;
      rate: number;
    }
  >;
}

interface RevenueMetrics {
  currentMonth: number;
  previousMonth: number;
  growth: number;
  byShiftType: Record<string, number>;
}

interface CareHomeMetrics {
  total: number;
  active: number;
  newThisMonth: number;
  shiftDistribution: Record<string, number>;
}

interface DashboardMetrics {
  staffMetrics: StaffMetrics;
  shiftMetrics: ShiftMetrics;
  revenueMetrics: RevenueMetrics;
  careHomeMetrics: CareHomeMetrics;
}

interface StaffingTrend {
  date: string;
  totalDemand: number;
  filled: number;
}

interface UpcomingRequirement {
  shiftType: string;
  required: number;
  filled: number;
  urgent: boolean;
}

// Inject endpoints into base API
export const analyticsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // Get full dashboard metrics
    getDashboardMetrics: build.query<DashboardMetrics, void>({
      query: () => ({
        url: '/analytics/dashboard',
        method: 'GET'
      }),
      providesTags: ['Analytics']
    }),

    // Get staff metrics
    getStaffMetrics: build.query<StaffMetrics, void>({
      query: () => ({
        url: '/analytics/staff',
        method: 'GET'
      }),
      providesTags: ['StaffAnalytics']
    }),

    // Get shift metrics
    getShiftMetrics: build.query<ShiftMetrics, void>({
      query: () => ({
        url: '/analytics/shifts',
        method: 'GET'
      }),
      providesTags: ['ShiftAnalytics']
    }),

    // Get revenue metrics
    getRevenueMetrics: build.query<RevenueMetrics, void>({
      query: () => ({
        url: '/analytics/revenue',
        method: 'GET'
      }),
      providesTags: ['RevenueAnalytics']
    }),

    // Get care home metrics
    getCareHomeMetrics: build.query<CareHomeMetrics, void>({
      query: () => ({
        url: '/analytics/care-homes',
        method: 'GET'
      }),
      providesTags: ['CareHomeAnalytics']
    }),

    // Get staffing trends with period parameter
    getStaffingTrends: build.query<
      StaffingTrend[],
      { period: 'week' | 'month' }
    >({
      query: ({ period }) => ({
        url: '/analytics/staffing-trends',
        method: 'GET',
        params: { period }
      }),
      providesTags: ['StaffingTrends']
    }),

    // Get upcoming requirements
    getUpcomingRequirements: build.query<UpcomingRequirement[], void>({
      query: () => ({
        url: '/analytics/upcoming-requirements',
        method: 'GET'
      }),
      providesTags: ['UpcomingRequirements']
    })
  }),
  overrideExisting: false
});

// Export hooks for usage in components
export const {
  useGetDashboardMetricsQuery,
  useGetStaffMetricsQuery,
  useGetShiftMetricsQuery,
  useGetRevenueMetricsQuery,
  useGetCareHomeMetricsQuery,
  useGetStaffingTrendsQuery,
  useGetUpcomingRequirementsQuery
} = analyticsApi;

// Export API slice for store configuration
export default analyticsApi;
