import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  Paper,
  IconButton,
  Chip,
  Stack,
  alpha,
  styled,
  Grid,
  Tooltip
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  AccessTime as TimeIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import type { IShiftPattern, IShiftType } from 'src/interfaces/shift-pattern';
import ShiftTypeDialog from './AddShiftTypeDialog';
import {
  useCreateYourShiftTypeMutation,
  useDeleteYourShiftTypeMutation,
  useLazyGetYourShiftPatternsQuery,
  useUpdateYourShiftTypeMutation
} from 'src/redux/@api/shift';

const StyledSection = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  overflow: 'hidden'
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: alpha(theme.palette.primary.main, 0.03)
}));

const SectionContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3)
}));

const StyledChip = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  backgroundColor: theme.colors.secondary.main,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04)
  }
}));

const Preferences: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedShiftType, setSelectedShiftType] =
    useState<IShiftPattern | null>(null);
  const theme = useTheme();
  const dispatch = useDispatch();

  const userState = useAppSelector((state) => state.user.user);
  const userId = userState._id;
  const [shiftTypes, setShiftTypes] = useState<IShiftType[]>([]);
  const [getYourShiftTypes] = useLazyGetYourShiftPatternsQuery();
  const [createYourShiftType] = useCreateYourShiftTypeMutation();
  const [deleteYourShiftType] = useDeleteYourShiftTypeMutation();
  const [updateYourShiftType] = useUpdateYourShiftTypeMutation();

  async function fetchShiftTypes() {
    try {
      const response = await getYourShiftTypes(userId).unwrap();
      setShiftTypes(response);
    } catch (error) {
      console.error(error);
      dispatch(
        showSnack({ message: 'Error fetching shift types', color: 'error' })
      );
    }
  }

  useEffect(() => {
    fetchShiftTypes();
  }, []);

  const handleOpenDialog = (shiftType?: IShiftType) => {
    setSelectedShiftType(shiftType || null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedShiftType(null);
  };

  const handleSaveShiftType = async (shiftType: IShiftType) => {
    try {
      if (shiftType._id) {
        await updateYourShiftType(shiftType).unwrap();
        dispatch(
          showSnack({
            message: 'Shift type updated successfully',
            color: 'success'
          })
        );
      } else {
        await createYourShiftType(shiftType).unwrap();
        dispatch(
          showSnack({
            message: 'Shift type created successfully',
            color: 'success'
          })
        );
      }
      await fetchShiftTypes();
      handleCloseDialog();
    } catch (error) {
      console.error(error);
      dispatch(
        showSnack({ message: 'Error saving shift type', color: 'error' })
      );
    }
  };

  const handleDeleteShiftType = async (shiftTypeId: string) => {
    try {
      await deleteYourShiftType(shiftTypeId).unwrap();
      await fetchShiftTypes();
      dispatch(
        showSnack({
          message: 'Shift type deleted successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        showSnack({ message: 'Error deleting shift type', color: 'error' })
      );
    }
  };

  return (
    <Box sx={{ mx: 2 }}>
      <StyledSection elevation={1}>
        <SectionHeader>
          <TimeIcon color="primary" />
          <Box>
            <Typography variant="h6">Shift Types</Typography>
            <Typography variant="body2" color="text.secondary">
              Manage your available shift types and their settings
            </Typography>
          </Box>
        </SectionHeader>
        <SectionContent>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            {shiftTypes.map((shiftType) => (
              <Grid item xs={6} sm={6} md={3} key={shiftType._id}>
                <StyledChip elevation={0}>
                  <Typography variant="subtitle2">{shiftType.name}</Typography>
                  <Box>
                    <Tooltip title="Edit">
                      <IconButton
                        size="small"
                        onClick={() => handleOpenDialog(shiftType)}
                        sx={{ color: theme.palette.primary.main }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteShiftType(shiftType._id!)}
                        sx={{ color: theme.palette.error.main }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </StyledChip>
              </Grid>
            ))}
          </Grid>
          <Button
            variant="contained"
            onClick={() => handleOpenDialog()}
            startIcon={<AddIcon />}
            size="medium"
          >
            Add Shift Type
          </Button>
        </SectionContent>
      </StyledSection>

      <StyledSection elevation={1}>
        <SectionHeader>
          <SettingsIcon color="primary" />
          <Box>
            <Typography variant="h6">Shift Preferences</Typography>
            <Typography variant="body2" color="text.secondary">
              Set your default preferences for shift scheduling
            </Typography>
          </Box>
        </SectionHeader>
        <SectionContent>
          <Typography color="text.secondary">
            Coming soon - You'll be able to set your preferred shift patterns
            and availability here.
          </Typography>
        </SectionContent>
      </StyledSection>

      <StyledSection elevation={1}>
        <SectionHeader>
          <NotificationsIcon color="primary" />
          <Box>
            <Typography variant="h6">Shift Notifications</Typography>
            <Typography variant="body2" color="text.secondary">
              Customize your notification preferences
            </Typography>
          </Box>
        </SectionHeader>
        <SectionContent>
          <Typography color="text.secondary">
            Coming soon - Configure how and when you receive notifications about
            shifts.
          </Typography>
        </SectionContent>
      </StyledSection>

      <ShiftTypeDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSave={handleSaveShiftType}
        selectedShiftType={selectedShiftType}
      />
    </Box>
  );
};

export default Preferences;
