import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Chip,
  Divider,
  Alert,
  styled,
  LinearProgress,
  Tooltip
} from '@mui/material';
import {
  FitnessCenter,
  Warning,
  Timer,
  AccessibilityNew,
  Favorite,
  SentimentSatisfied,
  Info,
  CheckCircle,
  RecordVoiceOver
} from '@mui/icons-material';
import { ITask } from 'src/interfaces/new/tasks';

interface ExerciseData {
  // Exercise Details
  exerciseType: string[];
  duration: number;
  intensity: 'light' | 'moderate' | 'vigorous';

  // Engagement
  participation: 'full' | 'partial' | 'refused';
  completedExercises: string[];
  repetitions?: number;
  sets?: number;

  // Physical Response
  breathlessness: 'none' | 'mild' | 'moderate' | 'severe';
  tirednessLevel: number;
  painReported: boolean;
  painLocation?: string;
  painLevel?: number;

  // Monitoring
  pulseBeforeExercise?: number;
  pulseAfterExercise?: number;
  restRequired: boolean;
  restDuration?: number;

  // Support
  assistanceLevel: 'independent' | 'minimal' | 'moderate' | 'full';
  equipmentUsed: string[];

  // Outcome
  completed: boolean;
  concerns: boolean;
  notes: string;
  timestamp: string;
}

interface ExerciseReportProps {
  data: ExerciseData | any;
  task?: Partial<ITask>;
}

const ReportSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& + &': {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const IntensityProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[200],
  '& .MuiLinearProgress-bar': {
    borderRadius: 5
  }
}));

const ExerciseReport: React.FC<ExerciseReportProps> = ({ data, task }) => {
  const getIntensityValue = (intensity: string) => {
    switch (intensity) {
      case 'light':
        return 33;
      case 'moderate':
        return 66;
      case 'vigorous':
        return 100;
      default:
        return 0;
    }
  };

  const getIntensityColor = (intensity: string) => {
    switch (intensity) {
      case 'light':
        return 'success';
      case 'moderate':
        return 'warning';
      case 'vigorous':
        return 'error';
      default:
        return 'primary';
    }
  };

  const formatDuration = (minutes: number) => {
    if (minutes < 60) return `${minutes} minutes`;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      {/* Header */}
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <FitnessCenter color="primary" fontSize="large" />
        <Box>
          <Typography variant="h6">Exercise Record</Typography>
          <Typography variant="caption" color="textSecondary">
            {new Date(task.completedDate).toLocaleString()}
          </Typography>
          {/* completed by */}
          <Box display="flex" alignItems="center" gap={1} mt={1}>
            <RecordVoiceOver />
            <Typography variant="body2">
              Completed by: {task.completedBy?.firstName}{' '}
              {task.completedBy?.lastName}
            </Typography>

            {/* role */}
            <Tooltip title={task.completedBy?.role}>
              <Chip
                label={task.completedBy?.role}
                size="small"
                color="primary"
              />
            </Tooltip>
          </Box>
        </Box>
        <Box flexGrow={1} />
        {data.concerns && (
          <Chip
            icon={<Warning />}
            label="Requires Review"
            color="error"
            size="small"
          />
        )}
      </Box>

      <Divider />

      {/* Exercise Details */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Exercise Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
              {data.exerciseType.map((type, index) => (
                <Chip key={index} label={type} color="primary" size="small" />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              <Typography variant="subtitle2" gutterBottom>
                Intensity
              </Typography>
              <IntensityProgress
                variant="determinate"
                value={getIntensityValue(data.intensity)}
                color={getIntensityColor(data.intensity)}
              />
              <Typography variant="caption" color="textSecondary">
                {data.intensity.charAt(0).toUpperCase() +
                  data.intensity.slice(1)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <Timer color="action" />
              <Typography>Duration: {formatDuration(data.duration)}</Typography>
            </Box>
          </Grid>
        </Grid>
      </ReportSection>

      {/* Completed Exercises */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Exercises Completed
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {data.completedExercises.map((exercise, index) => (
                <Chip
                  key={index}
                  label={exercise}
                  icon={<CheckCircle />}
                  color="success"
                  size="small"
                  variant="outlined"
                />
              ))}
            </Box>
          </Grid>

          {(data.repetitions || data.sets) && (
            <Grid item xs={12}>
              <Box display="flex" gap={2}>
                {data.repetitions && (
                  <Chip
                    label={`${data.repetitions} Repetitions`}
                    size="small"
                  />
                )}
                {data.sets && <Chip label={`${data.sets} Sets`} size="small" />}
              </Box>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Physical Response */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Physical Response
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" gap={2}>
              {data.pulseBeforeExercise && data.pulseAfterExercise && (
                <Tooltip title="Pulse Before → After">
                  <Box display="flex" alignItems="center" gap={1}>
                    <Favorite color="error" />
                    <Typography>
                      {data.pulseBeforeExercise} → {data.pulseAfterExercise} bpm
                    </Typography>
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Chip
              label={`Breathlessness: ${data.breathlessness}`}
              color={
                data.breathlessness === 'severe'
                  ? 'error'
                  : data.breathlessness === 'moderate'
                  ? 'warning'
                  : 'default'
              }
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Tiredness Level
            </Typography>
            <Box display="flex" alignItems="center" gap={1}>
              <LinearProgress
                variant="determinate"
                value={(data.tirednessLevel / 10) * 100}
                sx={{
                  flexGrow: 1,
                  height: 10,
                  borderRadius: 5
                }}
              />
              <Typography variant="body2">{data.tirednessLevel}/10</Typography>
            </Box>
          </Grid>

          {data.painReported && (
            <Grid item xs={12}>
              <Alert severity="warning">
                <Box>
                  <Typography variant="subtitle2">Pain Reported</Typography>
                  <Typography variant="body2">
                    Location: {data.painLocation}
                  </Typography>
                  {data.painLevel && (
                    <Typography variant="body2">
                      Level: {data.painLevel}/10
                    </Typography>
                  )}
                </Box>
              </Alert>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Support & Equipment */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Support & Equipment
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Chip
              icon={<AccessibilityNew />}
              label={`Assistance: ${data.assistanceLevel}`}
              color={
                data.assistanceLevel === 'independent' ? 'success' : 'primary'
              }
              size="small"
            />
          </Grid>

          {data.equipmentUsed.length > 0 && (
            <Grid item xs={12}>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {data.equipmentUsed.map((equipment, index) => (
                  <Chip
                    key={index}
                    label={equipment}
                    size="small"
                    variant="outlined"
                  />
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Rest & Recovery */}
      {data.restRequired && (
        <ReportSection>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Rest & Recovery
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                Rest Required
                {data.restDuration && (
                  <Typography variant="body2">
                    Duration: {formatDuration(data.restDuration)}
                  </Typography>
                )}
              </Alert>
            </Grid>
          </Grid>
        </ReportSection>
      )}

      {/* Notes & Outcome */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Outcome & Notes
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" gap={1} mb={2}>
              <Chip
                icon={data.completed ? <CheckCircle /> : <Warning />}
                label={data.completed ? 'Completed' : 'Not Completed'}
                color={data.completed ? 'success' : 'warning'}
              />
              <Chip
                icon={<SentimentSatisfied />}
                label={`Participation: ${data.participation}`}
                color={
                  data.participation === 'full'
                    ? 'success'
                    : data.participation === 'partial'
                    ? 'warning'
                    : 'error'
                }
              />
            </Box>
          </Grid>

          {data.notes && (
            <Grid item xs={12}>
              <Typography variant="body2">{data.notes}</Typography>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Timestamp */}
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Typography variant="caption" color="textSecondary">
          Recorded: {new Date(data.timestamp).toLocaleString()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default ExerciseReport;
