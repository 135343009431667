import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  alpha,
  Paper,
  Fade,
  Tooltip,
  useTheme
} from '@mui/material';
import {
  Save,
  Check,
  AccessTime,
  CalendarMonth,
  WatchLater,
  Schedule,
  Assignment
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation
} from 'src/redux/@api/carer-application';

// Styled Components
const StyledCard = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  overflow: 'visible',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0, 0, 0, 0.08)'
  }
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  '& svg': {
    fontSize: 28,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: 0,
    width: 40,
    height: 3,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius
  }
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: '0 8px'
  },
  '& .MuiTableCell-root': {
    border: 'none'
  },
  '& .MuiTableRow-root': {
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.paper, 0.95),
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)'
    }
  },
  '& .MuiTableHead-root .MuiTableRow-root': {
    backgroundColor: 'transparent',
    '&:hover': {
      transform: 'none',
      boxShadow: 'none'
    }
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  '&.MuiTableCell-head': {
    fontWeight: 600,
    color: theme.palette.text.secondary,
    borderBottom: `2px solid ${alpha(theme.palette.primary.main, 0.1)}`
  }
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.primary.main
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1)
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.02)
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
    }
  }
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.02)
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
    }
  }
}));

// Types and interfaces
interface AvailabilityProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

interface AvailabilityDetails {
  [key: string]: {
    available: boolean;
    morning: boolean;
    afternoon: boolean;
    evening: boolean;
    night: boolean;
  };
}

const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
] as const;

const timeSlots = ['morning', 'afternoon', 'evening', 'night'] as const;

type Day = typeof daysOfWeek[number];
type TimeSlot = typeof timeSlots[number];

const Availability: React.FC<AvailabilityProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();

  const [saving, setSaving] = useState(false);

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {
        preferredWorkPattern: '',
        availabilityDetails: Object.fromEntries(
          daysOfWeek.map((day) => [
            day,
            {
              available: false,
              morning: false,
              afternoon: false,
              evening: false,
              night: false
            }
          ])
        ),
        maxHoursPerWeek: '',
        minHoursPerWeek: ''
      }
    : initialData || {};

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const handleSave = async (data: any) => {
    if (!editable) return;
    setSaving(true);
    try {
      await updateApplicationSection({
        section: 'availability',
        data
      }).unwrap();

      await refetch();

      dispatch(
        showSnack({
          message: 'Availability information saved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: 'Failed to save availability information',
          color: 'error'
        })
      );
    } finally {
      setSaving(false);
    }
  };

  const getTimeSlotIcon = (slot: TimeSlot) => {
    switch (slot) {
      case 'morning':
        return (
          <WatchLater
            sx={{ fontSize: 20, color: theme.palette.primary.light }}
          />
        );
      case 'afternoon':
        return (
          <Schedule sx={{ fontSize: 20, color: theme.palette.primary.main }} />
        );
      case 'evening':
        return (
          <AccessTime
            sx={{ fontSize: 20, color: theme.palette.primary.dark }}
          />
        );
      case 'night':
        return (
          <Schedule sx={{ fontSize: 20, color: theme.palette.grey[700] }} />
        );
    }
  };

  const renderAvailabilityTable = () => {
    const availabilityDetails = watch(
      'availabilityDetails'
    ) as AvailabilityDetails;

    return (
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Day</StyledTableCell>
              <StyledTableCell align="center">
                <Tooltip title="Available">
                  <CalendarMonth sx={{ color: theme.palette.primary.main }} />
                </Tooltip>
              </StyledTableCell>
              {timeSlots.map((slot) => (
                <StyledTableCell key={slot} align="center">
                  <Tooltip title={slot.charAt(0).toUpperCase() + slot.slice(1)}>
                    {getTimeSlotIcon(slot)}
                  </Tooltip>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {daysOfWeek.map((day) => (
              <TableRow key={day}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    color: theme.palette.text.primary
                  }}
                >
                  {day}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Controller
                    name={`availabilityDetails.${day}.available`}
                    control={control}
                    render={({ field }) =>
                      editable ? (
                        <StyledCheckbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                            if (!e.target.checked) {
                              timeSlots.forEach((slot) => {
                                setValue(
                                  `availabilityDetails.${day}.${slot}`,
                                  false
                                );
                              });
                            }
                          }}
                        />
                      ) : field.value ? (
                        <Check sx={{ color: theme.palette.success.main }} />
                      ) : null
                    }
                  />
                </StyledTableCell>
                {timeSlots.map((slot) => (
                  <StyledTableCell key={slot} align="center">
                    <Controller
                      name={`availabilityDetails.${day}.${slot}`}
                      control={control}
                      render={({ field }) =>
                        editable ? (
                          <StyledCheckbox
                            {...field}
                            checked={field.value}
                            disabled={!availabilityDetails[day].available}
                          />
                        ) : field.value ? (
                          <Check sx={{ color: theme.palette.success.main }} />
                        ) : null
                      }
                    />
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    );
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        p: { xs: 2, sm: 3, md: 4 },
        borderRadius: 4,
        boxShadow: (theme) =>
          `0 0 40px ${alpha(theme.palette.primary.main, 0.08)}`
      }}
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <SectionHeader>
          <Assignment />
          <SectionTitle>Availability Information</SectionTitle>
        </SectionHeader>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledCard elevation={0} sx={{ p: 3 }}>
              <Controller
                name="preferredWorkPattern"
                control={control}
                rules={{ required: 'Preferred work pattern is required' }}
                render={({ field, fieldState: { error } }) =>
                  editable ? (
                    <StyledFormControl size="small" fullWidth error={!!error}>
                      <InputLabel>Preferred Work Pattern</InputLabel>
                      <Select
                        {...field}
                        label="Preferred Work Pattern"
                        size="small"
                      >
                        <MenuItem value="Full-time">Full-time</MenuItem>
                        <MenuItem value="Part-time">Part-time</MenuItem>
                        <MenuItem value="Flexible">Flexible</MenuItem>
                      </Select>
                      {error && (
                        <Typography
                          color="error"
                          variant="caption"
                          sx={{ mt: 1 }}
                        >
                          {error.message}
                        </Typography>
                      )}
                    </StyledFormControl>
                  ) : (
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        display="block"
                      >
                        Preferred Work Pattern
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {field.value || 'N/A'}
                      </Typography>
                    </Box>
                  )
                }
              />
            </StyledCard>
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyledCard elevation={0} sx={{ p: 3 }}>
              <Controller
                name="maxHoursPerWeek"
                control={control}
                rules={{
                  required: 'Maximum hours per week is required',
                  min: { value: 1, message: 'Must be at least 1 hour' },
                  max: {
                    value: 168,
                    message: 'Cannot exceed 168 hours (7 days)'
                  }
                }}
                render={({ field, fieldState: { error } }) =>
                  editable ? (
                    <StyledTextField
                      {...field}
                      label="Maximum Hours per Week"
                      type="number"
                      fullWidth
                      size="small"
                      error={!!error}
                      helperText={error?.message}
                    />
                  ) : (
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        display="block"
                      >
                        Maximum Hours per Week
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {field.value || 'N/A'}
                      </Typography>
                    </Box>
                  )
                }
              />
            </StyledCard>
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyledCard elevation={0} sx={{ p: 3 }}>
              <Controller
                name="minHoursPerWeek"
                control={control}
                rules={{
                  required: 'Minimum hours per week is required',
                  min: { value: 0, message: 'Cannot be negative' },
                  max: {
                    value: 168,
                    message: 'Cannot exceed 168 hours (7 days)'
                  }
                }}
                render={({ field, fieldState: { error } }) =>
                  editable ? (
                    <StyledTextField
                      {...field}
                      label="Minimum Hours per Week"
                      type="number"
                      fullWidth
                      size="small"
                      error={!!error}
                      helperText={error?.message}
                    />
                  ) : (
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        display="block"
                      >
                        Minimum Hours per Week
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {field.value || 'N/A'}
                      </Typography>
                    </Box>
                  )
                }
              />
            </StyledCard>
          </Grid>

          <Grid item xs={12}>
            <StyledCard elevation={0} sx={{ p: 3 }}>
              <SectionHeader>
                <AccessTime />
                <SectionTitle>Weekly Availability</SectionTitle>
              </SectionHeader>
              {renderAvailabilityTable()}
            </StyledCard>
          </Grid>
        </Grid>

        {editable && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button
              type="submit"
              variant="contained"
              startIcon={saving ? <CircularProgress size={20} /> : <Save />}
              disabled={saving}
              sx={{
                borderRadius: 2,
                px: 4,
                py: 1,
                background: (theme) =>
                  `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  background: (theme) =>
                    `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                  boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)'
                }
              }}
            >
              {saving ? 'Saving...' : 'Save Availability'}
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default Availability;
