import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Chip,
  IconButton,
  Stack,
  Alert,
  Grid,
  Tooltip,
  styled
} from '@mui/material';
import {
  Close as CloseIcon,
  Groups,
  Warning,
  SentimentSatisfied,
  SentimentVeryDissatisfied
} from '@mui/icons-material';

interface SocialActivityData {
  activityType: string[];
  duration: number;
  location: string;
  groupActivity: boolean;
  participantCount?: number;
  participation: 'active' | 'passive' | 'refused';
  mood: 'happy' | 'content' | 'neutral' | 'anxious' | 'distressed';
  engagement: number;
  enjoyment: number;
  communication: 'verbal' | 'non-verbal' | 'both' | 'none';
  interactionQuality: 'good' | 'fair' | 'poor';
  behaviorsObserved: string[];
  concerns: boolean;
  notes: string;
}

const activityTypes = [
  'Group Discussion',
  'Games',
  'Music Session',
  'Arts & Crafts',
  'Reading',
  'Movie/TV',
  'Exercise Group',
  'Religious Activity',
  'Outdoor Activity',
  'Special Event',
  'Family Visit',
  'Meal Time Social',
  'One-to-One Chat'
];

const behaviors = [
  'Sociable',
  'Withdrawn',
  'Agitated',
  'Calm',
  'Enthusiastic',
  'Distracted',
  'Sleeping',
  'Anxious',
  'Aggressive',
  'Happy'
];

const locations = [
  'Common Room',
  'Dining Room',
  'Activity Room',
  'Garden',
  'Resident Room',
  'Chapel',
  'Reception Area'
];

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2
  }
}));

interface SocialActivityDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: SocialActivityData) => void;
  task: any;
  initialData?: Partial<SocialActivityData>;
}

const SocialActivityDialog: React.FC<SocialActivityDialogProps> = ({
  open,
  onClose,
  onResolve,
  task,
  initialData
}) => {
  const [formData, setFormData] = useState<SocialActivityData>({
    activityType: [],
    duration: 0,
    location: '',
    groupActivity: false,
    participation: 'passive',
    mood: 'neutral',
    engagement: 5,
    enjoyment: 5,
    communication: 'verbal',
    interactionQuality: 'fair',
    behaviorsObserved: [],
    concerns: false,
    notes: ''
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setFormData((prev) => ({ ...prev, ...initialData }));
    }
  }, [initialData]);

  const handleChange = (field: keyof SocialActivityData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.activityType.length) {
      setError('Please select at least one activity type');
      return false;
    }

    if (!formData.location) {
      setError('Please select activity location');
      return false;
    }

    if (formData.groupActivity && !formData.participantCount) {
      setError('Please specify number of participants for group activity');
      return false;
    }

    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onResolve(formData);
      onClose();
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <Groups color="primary" />
            <Typography variant="h6">Social Activity Record</Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Activity Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {activityTypes.map((type) => (
                    <Chip
                      key={type}
                      label={type}
                      onClick={() => {
                        const newTypes = formData.activityType.includes(type)
                          ? formData.activityType.filter((t) => t !== type)
                          : [...formData.activityType, type];
                        handleChange('activityType', newTypes);
                      }}
                      color={
                        formData.activityType.includes(type)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.activityType.includes(type)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Location</InputLabel>
                  <Select
                    value={formData.location}
                    onChange={(e) => handleChange('location', e.target.value)}
                  >
                    {locations.map((loc) => (
                      <MenuItem key={loc} value={loc}>
                        {loc}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Duration (minutes)"
                  value={formData.duration}
                  onChange={(e) =>
                    handleChange('duration', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Participation
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.groupActivity}
                      onChange={(e) =>
                        handleChange('groupActivity', e.target.checked)
                      }
                    />
                  }
                  label="Group Activity"
                />
              </Grid>

              {formData.groupActivity && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Number of Participants"
                    value={formData.participantCount || ''}
                    onChange={(e) =>
                      handleChange('participantCount', Number(e.target.value))
                    }
                    InputProps={{ inputProps: { min: 2 } }}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Participation Level</InputLabel>
                  <Select
                    value={formData.participation}
                    onChange={(e) =>
                      handleChange('participation', e.target.value)
                    }
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="passive">Passive</MenuItem>
                    <MenuItem value="refused">Refused</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Communication</InputLabel>
                  <Select
                    value={formData.communication}
                    onChange={(e) =>
                      handleChange('communication', e.target.value)
                    }
                  >
                    <MenuItem value="verbal">Verbal</MenuItem>
                    <MenuItem value="non-verbal">Non-verbal</MenuItem>
                    <MenuItem value="both">Both</MenuItem>
                    <MenuItem value="none">None</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Mood & Engagement
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Mood</InputLabel>
                  <Select
                    value={formData.mood}
                    onChange={(e) => handleChange('mood', e.target.value)}
                  >
                    <MenuItem value="happy">Happy</MenuItem>
                    <MenuItem value="content">Content</MenuItem>
                    <MenuItem value="neutral">Neutral</MenuItem>
                    <MenuItem value="anxious">Anxious</MenuItem>
                    <MenuItem value="distressed">Distressed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Behaviors Observed
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {behaviors.map((behavior) => (
                    <Chip
                      key={behavior}
                      label={behavior}
                      onClick={() => {
                        const newBehaviors =
                          formData.behaviorsObserved.includes(behavior)
                            ? formData.behaviorsObserved.filter(
                                (b) => b !== behavior
                              )
                            : [...formData.behaviorsObserved, behavior];
                        handleChange('behaviorsObserved', newBehaviors);
                      }}
                      color={
                        formData.behaviorsObserved.includes(behavior)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.behaviorsObserved.includes(behavior)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Engagement Level (1-10)"
                  value={formData.engagement}
                  onChange={(e) =>
                    handleChange('engagement', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 1, max: 10 } }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Enjoyment Level (1-10)"
                  value={formData.enjoyment}
                  onChange={(e) =>
                    handleChange('enjoyment', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 1, max: 10 } }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Additional Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Notes"
                  value={formData.notes}
                  onChange={(e) => handleChange('notes', e.target.value)}
                  placeholder="Enter observations, interactions, or concerns..."
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.concerns}
                      onChange={(e) =>
                        handleChange('concerns', e.target.checked)
                      }
                      color="error"
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography>Requires Follow-up</Typography>
                      {formData.concerns && (
                        <Warning color="error" fontSize="small" />
                      )}
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color={formData.concerns ? 'error' : 'primary'}
        >
          {formData.concerns ? 'Save & Flag' : 'Save Record'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default SocialActivityDialog;
