import React from 'react';
import {
  Typography,
  Autocomplete,
  TextField,
  Button,
  Box,
  Container,
  CircularProgress,
  Grid
} from '@mui/material';
import { motion } from 'framer-motion';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { apiHostname } from 'src/api/api';
import { pageTransition, pageVariants } from '../animation';

import { useAppSelector } from 'src/redux/hook';
import type { IUser } from 'src/interfaces/heirarchy';
import { th } from 'date-fns/locale';
import { LoadingButton } from '@mui/lab';
import { useGetOrganizationListingQuery } from 'src/redux/@api/organisation';
import { useSendJoinRequestMutation } from 'src/redux/@api/join-request';
import { useNavigate } from 'react-router';

const AgencySelection = ({ onSubmit, user }) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      selectedAgency: null
    }
  });

  const [sendJoinRequest] = useSendJoinRequestMutation();

  const {
    data: organizations,
    isLoading,
    isError,
    error
  } = useGetOrganizationListingQuery(undefined);
  const navigate = useNavigate();

  const userState = useAppSelector((state) => state.userState.user);

  const onFormSubmit = async (data) => {
    if (!data.selectedAgency) return;

    try {
      if (!userState?._id) {
        throw new Error('User ID not found');
      }
      const response = await sendJoinRequest({
        user: userState?._id,
        organization: data.selectedAgency._id,
        message: `${userState?.firstName} ${userState?.lastName} would like to join your organization.`,
        role: userState?.role
      }).unwrap();

      console.log('Join request sent:', response);

      onSubmit();
    } catch (error) {
      console.error('Failed to join agency:', error);
      // Handle error (show message to user)
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <Typography color="error">Error loading agencies</Typography>;
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            textAlign: 'center'
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            sx={{ fontWeight: 700, mb: 4 }}
          >
            Choose Your Organization
          </Typography>
          <form onSubmit={handleSubmit(onFormSubmit)} style={{ width: '100%' }}>
            <Controller
              name="selectedAgency"
              control={control}
              rules={{ required: 'Please select an organisation' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={organizations?.organizations || []}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organisation"
                      error={!!errors.selectedAgency}
                      helperText={errors.selectedAgency?.message}
                      sx={{
                        mb: 3,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'primary.main'
                          },
                          '&:hover fieldset': {
                            borderColor: 'primary.dark'
                          }
                        }
                      }}
                    />
                  )}
                  onChange={(_, data) => field.onChange(data)}
                />
              )}
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  sx={{
                    mt: 2,
                    py: 1.5,
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    textTransform: 'none',
                    borderRadius: 2,
                    boxShadow:
                      '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
                    '&:hover': {
                      boxShadow:
                        '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)'
                    }
                  }}
                >
                  Join Organization
                </LoadingButton>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    localStorage.removeItem('token');
                    navigate('/login');
                  }}
                  variant="outlined"
                >
                  <Typography variant="body2" color="textSecondary">
                    Go back
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </motion.div>
  );
};

export default AgencySelection;
