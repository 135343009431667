import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Chip,
  Divider,
  Alert,
  styled,
  Tooltip
} from '@mui/material';
import {
  MedicationOutlined,
  CheckCircle,
  Cancel,
  Warning,
  AccessTime,
  RecordVoiceOver
} from '@mui/icons-material';
import { ITask } from 'src/interfaces/new/tasks';

interface MedicationReportData {
  // Basic Info
  medicationType: string;
  medicationName: string;
  dosage: string;
  route: string;
  scheduled_time: string;
  actual_time: string;

  // Status & Verification
  status: 'administered' | 'refused' | 'unavailable' | 'held' | 'missed';
  verified: boolean;
  doubleChecked: boolean;
  doubleCheckMethod: 'in-person' | 'video' | 'phone';

  // Administration Details
  givenFrom: 'blister' | 'mds' | 'bottle' | 'other';
  water_offered: boolean;
  position: string;
  crushRequired: boolean;
  thickenedFluid: boolean;

  // PRN Specific
  isPRN: boolean;
  prnReason?: string;
  lastPRNTime?: string;
  effectiveness?: string;

  // Monitoring
  sideEffectsObserved: string[];
  swallowingDifficulties: boolean;

  // Refusal Details
  refusalReason?: string;
  alternativeOffered?: string;

  // Additional Info
  notes: string;
  followUpRequired: boolean;
  timestamp: string;
}

interface MedicationReportProps {
  data: MedicationReportData | any;
  task: Partial<ITask>;
}

const ReportSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& + &': {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const StatusChip = styled(Chip)<{ status: string }>(({ theme, status }) => ({
  backgroundColor:
    status === 'administered'
      ? theme.palette.success.main
      : status === 'refused'
      ? theme.palette.error.main
      : status === 'held'
      ? theme.palette.warning.main
      : status === 'missed'
      ? theme.palette.error.dark
      : theme.palette.grey[500],
  color: theme.palette.common.white
}));

const MedicationReport: React.FC<MedicationReportProps> = ({ data, task }) => {
  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      {/* Header */}
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <MedicationOutlined color="primary" fontSize="large" />
        <Box>
          <Typography variant="h6">{data.medicationName}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {data.medicationType} - {data.dosage}
          </Typography>
        </Box>
        <Box flexGrow={1} />
        <StatusChip
          status={data.status}
          label={data.status.toUpperCase()}
          icon={data.status === 'administered' ? <CheckCircle /> : <Cancel />}
        />
        <Typography variant="caption" color="textSecondary">
          {new Date(task.completedDate).toLocaleString()}
        </Typography>
        {/* completed by */}
        <Box display="flex" alignItems="center" gap={1} mt={1}>
          <RecordVoiceOver />
          <Typography variant="body2">
            Completed by: {task.completedBy?.firstName}{' '}
            {task.completedBy?.lastName}
          </Typography>

          {/* role */}
          <Tooltip title={task.completedBy?.role}>
            <Chip label={task.completedBy?.role} size="small" color="primary" />
          </Tooltip>
        </Box>
      </Box>

      <Divider />

      {/* Timing Information */}
      <ReportSection>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <AccessTime color="action" />
              <Typography variant="body2">
                Scheduled: {data.scheduled_time}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <AccessTime color="action" />
              <Typography variant="body2">
                Given: {data.actual_time || 'Not Given'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </ReportSection>

      {/* Administration Details */}
      <ReportSection>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          Administration Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Route: {data.route}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              Given From: {data.givenFrom}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Position: {data.position}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
              {data.water_offered && (
                <Chip size="small" label="Water Offered" color="info" />
              )}
              {data.crushRequired && (
                <Chip size="small" label="Crushing Required" color="warning" />
              )}
              {data.thickenedFluid && (
                <Chip size="small" label="Thickened Fluid" color="warning" />
              )}
            </Box>
          </Grid>
        </Grid>
      </ReportSection>

      {/* Verification Status */}
      <ReportSection>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          Verification Status
        </Typography>
        <Box display="flex" gap={2}>
          <Chip
            size="small"
            label="Verified"
            color={data.verified ? 'success' : 'default'}
            icon={<CheckCircle />}
          />
          {data.doubleChecked && (
            <Chip
              size="small"
              label={`Double Checked (${data.doubleCheckMethod})`}
              color="success"
              icon={<CheckCircle />}
            />
          )}
        </Box>
      </ReportSection>

      {/* PRN Details if applicable */}
      {data.isPRN && (
        <ReportSection>
          <Typography variant="subtitle2" color="primary" gutterBottom>
            PRN Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">Reason: {data.prnReason}</Typography>
            </Grid>
            {data.lastPRNTime && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  Last Given: {new Date(data.lastPRNTime).toLocaleString()}
                </Typography>
              </Grid>
            )}
            {data.effectiveness && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  Effectiveness: {data.effectiveness}
                </Typography>
              </Grid>
            )}
          </Grid>
        </ReportSection>
      )}

      {/* Monitoring */}
      <ReportSection>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          Monitoring
        </Typography>
        {data.sideEffectsObserved.length > 0 && (
          <Box mb={2}>
            <Typography variant="body2" color="error" gutterBottom>
              Side Effects Observed:
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {data.sideEffectsObserved.map((effect, index) => (
                <Chip key={index} size="small" label={effect} color="error" />
              ))}
            </Box>
          </Box>
        )}
        {data.swallowingDifficulties && (
          <Alert severity="warning" sx={{ mt: 1 }}>
            Swallowing difficulties observed
          </Alert>
        )}
      </ReportSection>

      {/* Refusal Details if applicable */}
      {data.status === 'refused' && (
        <ReportSection>
          <Typography variant="subtitle2" color="error" gutterBottom>
            Refusal Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">
                Reason: {data.refusalReason}
              </Typography>
            </Grid>
            {data.alternativeOffered && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  Alternative Offered: {data.alternativeOffered}
                </Typography>
              </Grid>
            )}
          </Grid>
        </ReportSection>
      )}

      {/* Additional Information */}
      <ReportSection>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          Additional Information
        </Typography>
        {data.notes && (
          <Typography variant="body2" paragraph>
            {data.notes}
          </Typography>
        )}
        {data.followUpRequired && (
          <Alert severity="warning">Requires follow-up</Alert>
        )}
      </ReportSection>

      {/* Timestamp */}
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Typography variant="caption" color="textSecondary">
          Recorded: {new Date(data.timestamp).toLocaleString()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default MedicationReport;
