import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Chip,
  IconButton,
  Stack,
  Divider,
  Alert,
  Grid,
  Tooltip,
  styled
} from '@mui/material';
import {
  Close as CloseIcon,
  Info as InfoIcon,
  MonitorHeart,
  Favorite,
  Thermostat,
  Air,
  BloodtypeOutlined
} from '@mui/icons-material';

interface VitalSignsData {
  // Blood Pressure
  systolic: number | null;
  diastolic: number | null;
  position: 'sitting' | 'lying' | 'standing';

  // Heart Rate
  pulse: number | null;
  pulseRhythm: 'regular' | 'irregular';

  // Temperature
  temperature: number | null;
  tempRoute: 'oral' | 'tympanic' | 'axillary';

  // Oxygen
  oxygenSaturation: number | null;
  supplementalOxygen: boolean;
  oxygenFlow?: number;

  // Respiration
  respirationRate: number | null;
  respirationType: 'regular' | 'irregular' | 'labored';

  // Additional Info
  beforeAfterMeal: 'before' | 'after' | 'not_relevant';
  consciousness: 'alert' | 'drowsy' | 'confused' | 'unresponsive';
  notes: string;
  concern: boolean;
  followUp: boolean;
  timestamp: string;
}

interface VitalSignsDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: VitalSignsData) => void;
  task: any;
  initialData?: Partial<VitalSignsData>;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2)
  }
}));

const MetricBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper
}));

const WarningValue = styled(Typography)<{ isAbnormal: boolean }>(
  ({ theme, isAbnormal }) => ({
    color: isAbnormal ? theme.palette.error.main : theme.palette.text.primary,
    fontWeight: isAbnormal ? 600 : 400
  })
);

const VitalSignsDialog: React.FC<VitalSignsDialogProps> = ({
  open,
  onClose,
  onResolve,
  task,
  initialData
}) => {
  const [formData, setFormData] = useState<VitalSignsData>({
    systolic: null,
    diastolic: null,
    position: 'sitting',
    pulse: null,
    pulseRhythm: 'regular',
    temperature: null,
    tempRoute: 'oral',
    oxygenSaturation: null,
    supplementalOxygen: false,
    respirationRate: null,
    respirationType: 'regular',
    beforeAfterMeal: 'not_relevant',
    consciousness: 'alert',
    notes: '',
    concern: false,
    followUp: false,
    timestamp: new Date().toISOString()
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setFormData((prev) => ({ ...prev, ...initialData }));
    }
  }, [initialData]);

  const checkAbnormalValue = (
    metric: string,
    value: number | null
  ): boolean => {
    if (value === null) return false;

    const ranges = {
      systolic: { min: 90, max: 160 },
      diastolic: { min: 60, max: 100 },
      pulse: { min: 60, max: 100 },
      temperature: { min: 36, max: 37.8 },
      oxygenSaturation: { min: 95, max: 100 },
      respirationRate: { min: 12, max: 20 }
    };

    const range = ranges[metric as keyof typeof ranges];
    return value < range.min || value > range.max;
  };

  const validateForm = (): boolean => {
    const requiredFields = [
      'systolic',
      'diastolic',
      'pulse',
      'temperature',
      'oxygenSaturation',
      'respirationRate'
    ];

    const missingFields = requiredFields.filter(
      (field) => formData[field as keyof VitalSignsData] === null
    );

    if (missingFields.length > 0) {
      setError(
        `Please fill in all required vital signs: ${missingFields.join(', ')}`
      );
      return false;
    }

    // Check for impossible combinations
    if (formData.systolic! <= formData.diastolic!) {
      setError('Systolic pressure must be higher than diastolic pressure');
      return false;
    }

    setError(null);
    return true;
  };

  const handleChange = (field: keyof VitalSignsData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }));

    // Auto-set concern flag for abnormal values
    if (
      [
        'systolic',
        'diastolic',
        'pulse',
        'temperature',
        'oxygenSaturation',
        'respirationRate'
      ].includes(field)
    ) {
      const hasAbnormalValue = checkAbnormalValue(field, value);
      if (hasAbnormalValue) {
        setFormData((prev) => ({
          ...prev,
          concern: true
        }));
      }
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onResolve(formData);
      onClose();
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <MonitorHeart color="primary" />
            <Typography variant="h6">Vital Signs Record</Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          {/* Blood Pressure Section */}
          <MetricBox>
            <Stack spacing={2}>
              <Box display="flex" alignItems="center" gap={1}>
                <BloodtypeOutlined color="primary" />
                <Typography variant="subtitle1">Blood Pressure</Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Systolic"
                    type="number"
                    size="small"
                    value={formData.systolic || ''}
                    onChange={(e) =>
                      handleChange('systolic', Number(e.target.value))
                    }
                    error={checkAbnormalValue('systolic', formData.systolic)}
                    helperText={
                      checkAbnormalValue('systolic', formData.systolic)
                        ? 'Abnormal value'
                        : ''
                    }
                    InputProps={{
                      endAdornment: <Typography>mmHg</Typography>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Diastolic"
                    type="number"
                    size="small"
                    value={formData.diastolic || ''}
                    onChange={(e) =>
                      handleChange('diastolic', Number(e.target.value))
                    }
                    error={checkAbnormalValue('diastolic', formData.diastolic)}
                    helperText={
                      checkAbnormalValue('diastolic', formData.diastolic)
                        ? 'Abnormal value'
                        : ''
                    }
                    InputProps={{
                      endAdornment: <Typography>mmHg</Typography>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Position</InputLabel>
                    <Select
                      value={formData.position}
                      onChange={(e) => handleChange('position', e.target.value)}
                    >
                      <MenuItem value="sitting">Sitting</MenuItem>
                      <MenuItem value="lying">Lying</MenuItem>
                      <MenuItem value="standing">Standing</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Stack>
          </MetricBox>

          {/* Heart Rate & Temperature Section */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MetricBox>
                <Stack spacing={2}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Favorite color="primary" />
                    <Typography variant="subtitle1">Heart Rate</Typography>
                  </Box>

                  <TextField
                    fullWidth
                    label="Pulse Rate"
                    type="number"
                    size="small"
                    value={formData.pulse || ''}
                    onChange={(e) =>
                      handleChange('pulse', Number(e.target.value))
                    }
                    error={checkAbnormalValue('pulse', formData.pulse)}
                    helperText={
                      checkAbnormalValue('pulse', formData.pulse)
                        ? 'Abnormal value'
                        : ''
                    }
                    InputProps={{
                      endAdornment: <Typography>bpm</Typography>
                    }}
                  />

                  <FormControl fullWidth size="small">
                    <InputLabel>Rhythm</InputLabel>
                    <Select
                      value={formData.pulseRhythm}
                      onChange={(e) =>
                        handleChange('pulseRhythm', e.target.value)
                      }
                    >
                      <MenuItem value="regular">Regular</MenuItem>
                      <MenuItem value="irregular">Irregular</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </MetricBox>
            </Grid>

            <Grid item xs={12} md={6}>
              <MetricBox>
                <Stack spacing={2}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Thermostat color="primary" />
                    <Typography variant="subtitle1">Temperature</Typography>
                  </Box>

                  <TextField
                    fullWidth
                    label="Temperature"
                    type="number"
                    size="small"
                    value={formData.temperature || ''}
                    onChange={(e) =>
                      handleChange('temperature', Number(e.target.value))
                    }
                    error={checkAbnormalValue(
                      'temperature',
                      formData.temperature
                    )}
                    helperText={
                      checkAbnormalValue('temperature', formData.temperature)
                        ? 'Abnormal value'
                        : ''
                    }
                    InputProps={{
                      endAdornment: <Typography>°C</Typography>
                    }}
                  />

                  <FormControl fullWidth size="small">
                    <InputLabel>Route</InputLabel>
                    <Select
                      value={formData.tempRoute}
                      onChange={(e) =>
                        handleChange('tempRoute', e.target.value)
                      }
                    >
                      <MenuItem value="oral">Oral</MenuItem>
                      <MenuItem value="tympanic">Tympanic</MenuItem>
                      <MenuItem value="axillary">Axillary</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </MetricBox>
            </Grid>
          </Grid>

          {/* Oxygen & Respiration Section */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MetricBox>
                <Stack spacing={2}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Air color="primary" />
                    <Typography variant="subtitle1">
                      Oxygen Saturation
                    </Typography>
                  </Box>

                  <TextField
                    fullWidth
                    label="SpO2"
                    type="number"
                    size="small"
                    value={formData.oxygenSaturation || ''}
                    onChange={(e) =>
                      handleChange('oxygenSaturation', Number(e.target.value))
                    }
                    error={checkAbnormalValue(
                      'oxygenSaturation',
                      formData.oxygenSaturation
                    )}
                    helperText={
                      checkAbnormalValue(
                        'oxygenSaturation',
                        formData.oxygenSaturation
                      )
                        ? 'Abnormal value'
                        : ''
                    }
                    InputProps={{
                      endAdornment: <Typography>%</Typography>
                    }}
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.supplementalOxygen}
                        onChange={(e) =>
                          handleChange('supplementalOxygen', e.target.checked)
                        }
                      />
                    }
                    label="On Supplemental Oxygen"
                  />

                  {formData.supplementalOxygen && (
                    <TextField
                      fullWidth
                      label="Oxygen Flow Rate"
                      type="number"
                      size="small"
                      value={formData.oxygenFlow || ''}
                      onChange={(e) =>
                        handleChange('oxygenFlow', Number(e.target.value))
                      }
                      InputProps={{
                        endAdornment: <Typography>L/min</Typography>
                      }}
                    />
                  )}
                </Stack>
              </MetricBox>
            </Grid>

            <Grid item xs={12} md={6}>
              <MetricBox>
                <Stack spacing={2}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Air color="primary" />
                    <Typography variant="subtitle1">Respiration</Typography>
                  </Box>

                  <TextField
                    fullWidth
                    label="Respiration Rate"
                    type="number"
                    size="small"
                    value={formData.respirationRate || ''}
                    onChange={(e) =>
                      handleChange('respirationRate', Number(e.target.value))
                    }
                    error={checkAbnormalValue(
                      'respirationRate',
                      formData.respirationRate
                    )}
                    helperText={
                      checkAbnormalValue(
                        'respirationRate',
                        formData.respirationRate
                      )
                        ? 'Abnormal value'
                        : ''
                    }
                    InputProps={{
                      endAdornment: <Typography>breaths/min</Typography>
                    }}
                  />

                  <FormControl fullWidth size="small">
                    <InputLabel>Type</InputLabel>
                    <Select
                      value={formData.respirationType}
                      onChange={(e) =>
                        handleChange('respirationType', e.target.value)
                      }
                    >
                      <MenuItem value="regular">Regular</MenuItem>
                      <MenuItem value="irregular">Irregular</MenuItem>
                      <MenuItem value="labored">Labored</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </MetricBox>
            </Grid>
          </Grid>

          {/* Additional Information Section */}
          <MetricBox>
            <Stack spacing={2}>
              <Typography variant="subtitle1" color="primary">
                Additional Information
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Meal Timing</InputLabel>
                    <Select
                      value={formData.beforeAfterMeal}
                      onChange={(e) =>
                        handleChange('beforeAfterMeal', e.target.value)
                      }
                    >
                      <MenuItem value="before">Before Meal</MenuItem>
                      <MenuItem value="after">After Meal</MenuItem>
                      <MenuItem value="not_relevant">Not Relevant</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Consciousness Level</InputLabel>
                    <Select
                      value={formData.consciousness}
                      onChange={(e) =>
                        handleChange('consciousness', e.target.value)
                      }
                    >
                      <MenuItem value="alert">Alert</MenuItem>
                      <MenuItem value="drowsy">Drowsy</MenuItem>
                      <MenuItem value="confused">Confused</MenuItem>
                      <MenuItem value="unresponsive">Unresponsive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Notes"
                    size="small"
                    value={formData.notes}
                    onChange={(e) => handleChange('notes', e.target.value)}
                    placeholder="Enter any additional observations or concerns..."
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.concern}
                        onChange={(e) =>
                          handleChange('concern', e.target.checked)
                        }
                        color="error"
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography
                          color={formData.concern ? 'error' : 'inherit'}
                        >
                          Requires Immediate Attention
                        </Typography>
                        {formData.concern && (
                          <Tooltip title="This will alert the nursing team">
                            <InfoIcon color="error" fontSize="small" />
                          </Tooltip>
                        )}
                      </Box>
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.followUp}
                        onChange={(e) =>
                          handleChange('followUp', e.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography>Requires Follow-up</Typography>
                        {formData.followUp && (
                          <Tooltip title="Will be added to follow-up tasks">
                            <InfoIcon color="primary" fontSize="small" />
                          </Tooltip>
                        )}
                      </Box>
                    }
                  />
                </Grid>
              </Grid>
            </Stack>
          </MetricBox>

          {/* Summary Section - Shows if any abnormal values */}
          {(checkAbnormalValue('systolic', formData.systolic) ||
            checkAbnormalValue('diastolic', formData.diastolic) ||
            checkAbnormalValue('pulse', formData.pulse) ||
            checkAbnormalValue('temperature', formData.temperature) ||
            checkAbnormalValue('oxygenSaturation', formData.oxygenSaturation) ||
            checkAbnormalValue(
              'respirationRate',
              formData.respirationRate
            )) && (
            <Alert severity="warning">
              <Typography variant="subtitle2" gutterBottom>
                Abnormal Values Detected:
              </Typography>
              <Stack spacing={1}>
                {checkAbnormalValue('systolic', formData.systolic) && (
                  <Typography variant="body2">
                    • Blood Pressure (Systolic): {formData.systolic} mmHg
                  </Typography>
                )}
                {checkAbnormalValue('diastolic', formData.diastolic) && (
                  <Typography variant="body2">
                    • Blood Pressure (Diastolic): {formData.diastolic} mmHg
                  </Typography>
                )}
                {checkAbnormalValue('pulse', formData.pulse) && (
                  <Typography variant="body2">
                    • Pulse Rate: {formData.pulse} bpm
                  </Typography>
                )}
                {checkAbnormalValue('temperature', formData.temperature) && (
                  <Typography variant="body2">
                    • Temperature: {formData.temperature}°C
                  </Typography>
                )}
                {checkAbnormalValue(
                  'oxygenSaturation',
                  formData.oxygenSaturation
                ) && (
                  <Typography variant="body2">
                    • Oxygen Saturation: {formData.oxygenSaturation}%
                  </Typography>
                )}
                {checkAbnormalValue(
                  'respirationRate',
                  formData.respirationRate
                ) && (
                  <Typography variant="body2">
                    • Respiration Rate: {formData.respirationRate} breaths/min
                  </Typography>
                )}
              </Stack>
            </Alert>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color={formData.concern ? 'error' : 'primary'}
          startIcon={formData.concern && <InfoIcon />}
        >
          {formData.concern ? 'Save & Alert Nursing Team' : 'Save Record'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default VitalSignsDialog;
