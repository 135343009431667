import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Slider,
  Box,
  Chip,
  Alert,
  Stack,
  Divider,
  FormControlLabel,
  Switch,
  useTheme,
  styled
} from '@mui/material';
import {
  Restaurant as RestaurantIcon,
  LocalCafe as LocalCafeIcon,
  Fastfood as FastfoodIcon,
  Person as PersonIcon,
  AccessTime as TimeIcon
} from '@mui/icons-material';

interface MealData {
  // Meal Type Information
  mealType: 'breakfast' | 'lunch' | 'dinner' | 'snack';
  mealDetails: {
    // Breakfast Options
    breakfastType?: 'continental' | 'cooked' | 'cereal' | 'light';
    breakfastItems?: string[];

    // Lunch/Dinner Options
    starter?: string;
    mainCourse?: string;
    sideDish?: string[];
    dessert?: string;

    // Snack Options
    snackType?: string;
    snackItems?: string[];
  };

  // Consumption Details
  portionSize: 'small' | 'medium' | 'large';
  amountConsumed: number;
  fluidIntake: number;
  fluidType: {
    hotDrinks: string[]; // Tea, Coffee, etc.
    coldDrinks: string[]; // Water, Juice, etc.
    thickenedFluids: string[]; // If required
  };

  // Assistance Details
  assistanceLevel: 'independent' | 'minimal' | 'moderate' | 'full';
  assistanceNotes: string;
  feedingPosition: 'upright' | 'semi-reclined' | 'bed';
  timeSpent: number;

  // Dietary Information
  dietaryRequirements: string[];

  // Monitoring
  swallowingDifficulties: boolean;
  chokingIncident: boolean;
  foodRefusal: boolean;
  refusalReason?: string;

  // Notes
  comments: string;
}

const mealOptions = {
  breakfast: {
    continental: ['Toast', 'Croissant', 'Cereal', 'Yogurt', 'Fruit'],
    cooked: ['Full English', 'Scrambled Eggs', 'Porridge', 'Pancakes'],
    cereal: ['Cornflakes', 'Weetabix', 'Porridge', 'Muesli'],
    light: ['Fruit', 'Toast', 'Yogurt']
  },
  mainMeal: {
    starters: ['Soup', 'Salad', 'Fruit Juice', 'Appetizer'],
    mains: ['Chicken', 'Fish', 'Beef', 'Vegetarian', 'Pasta'],
    sides: ['Potatoes', 'Rice', 'Vegetables', 'Pasta'],
    desserts: ['Fruit', 'Ice Cream', 'Pudding', 'Yogurt']
  },
  snacks: {
    types: ['Morning', 'Afternoon', 'Evening'],
    items: ['Fruit', 'Biscuits', 'Yogurt', 'Sandwich', 'Cake', 'Crackers']
  }
};
const fluidOptions = {
  hotDrinks: ['Tea', 'Coffee', 'Hot Chocolate', 'Soup', 'Other Hot Drink'],
  coldDrinks: ['Water', 'Juice', 'Squash', 'Milk', 'Other Cold Drink'],
  thickenedFluids: [
    'Stage 1 - Slightly Thick',
    'Stage 2 - Mildly Thick',
    'Stage 3 - Moderately Thick',
    'Stage 4 - Extremely Thick'
  ]
};

const dietaryRequirements = [
  'Regular',
  'Soft',
  'Pureed',
  'Thickened Fluids',
  'Diabetic',
  'Gluten Free',
  'Dairy Free'
];

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: theme.spacing(1)
  }
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  '&.MuiChip-filled': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

interface MealDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: MealData) => void;
  task?: Partial<MealData>;
}

const MealDialog: React.FC<MealDialogProps> = ({
  open,
  onClose,
  onResolve,
  task
}) => {
  const [formData, setFormData] = useState<MealData>({
    mealType: 'breakfast',
    mealDetails: {},
    portionSize: 'medium',
    amountConsumed: 0,
    fluidIntake: 0,
    fluidType: {
      hotDrinks: [],
      coldDrinks: [],
      thickenedFluids: []
    },
    assistanceLevel: 'independent',
    assistanceNotes: '',
    feedingPosition: 'upright',
    timeSpent: 0,
    dietaryRequirements: [],
    swallowingDifficulties: false,
    chokingIncident: false,
    foodRefusal: false,
    comments: ''
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (task) {
      setFormData((prev) => ({ ...prev, ...task }));
    }
  }, [task]);

  const handleChange = (field: keyof MealData, value: any) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    onResolve(formData);
    onClose();
  };

  const validateForm = (): boolean => {
    if (formData.foodRefusal && !formData.refusalReason) {
      setError('Please provide reason for food refusal');
      return false;
    }

    setError(null);
    return true;
  };

  const renderMealDetails = () => {
    switch (formData.mealType) {
      case 'breakfast':
        return (
          <Grid container>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Breakfast Type</InputLabel>
                <Select
                  value={formData.mealDetails.breakfastType || ''}
                  onChange={(e) =>
                    handleChange('mealDetails', {
                      ...formData.mealDetails,
                      breakfastType: e.target.value
                    })
                  }
                >
                  <MenuItem value="continental">Continental</MenuItem>
                  <MenuItem value="cooked">Cooked Breakfast</MenuItem>
                  <MenuItem value="cereal">Cereal</MenuItem>
                  <MenuItem value="light">Light Breakfast</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formData.mealDetails.breakfastType && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Select Items
                </Typography>
                <Box display="flex" flexWrap="wrap">
                  {mealOptions.breakfast[
                    formData.mealDetails.breakfastType
                  ].map((item) => (
                    <StyledChip
                      key={item}
                      label={item}
                      onClick={() => {
                        const currentItems =
                          formData.mealDetails.breakfastItems || [];
                        const newItems = currentItems.includes(item)
                          ? currentItems.filter((i) => i !== item)
                          : [...currentItems, item];
                        handleChange('mealDetails', {
                          ...formData.mealDetails,
                          breakfastItems: newItems
                        });
                      }}
                      color={
                        formData.mealDetails.breakfastItems?.includes(item)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.mealDetails.breakfastItems?.includes(item)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>
        );

      case 'lunch':
      case 'dinner':
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Starter</InputLabel>
                <Select
                  value={formData.mealDetails.starter || ''}
                  onChange={(e) =>
                    handleChange('mealDetails', {
                      ...formData.mealDetails,
                      starter: e.target.value
                    })
                  }
                >
                  {mealOptions.mainMeal.starters.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Main Course</InputLabel>
                <Select
                  value={formData.mealDetails.mainCourse || ''}
                  onChange={(e) =>
                    handleChange('mealDetails', {
                      ...formData.mealDetails,
                      mainCourse: e.target.value
                    })
                  }
                >
                  {mealOptions.mainMeal.mains.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Side Dishes
              </Typography>
              <Box display="flex" flexWrap="wrap">
                {mealOptions.mainMeal.sides.map((item) => (
                  <StyledChip
                    key={item}
                    label={item}
                    onClick={() => {
                      const currentSides = formData.mealDetails.sideDish || [];
                      const newSides = currentSides.includes(item)
                        ? currentSides.filter((i) => i !== item)
                        : [...currentSides, item];
                      handleChange('mealDetails', {
                        ...formData.mealDetails,
                        sideDish: newSides
                      });
                    }}
                    color={
                      formData.mealDetails.sideDish?.includes(item)
                        ? 'primary'
                        : 'default'
                    }
                    variant={
                      formData.mealDetails.sideDish?.includes(item)
                        ? 'filled'
                        : 'outlined'
                    }
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Dessert</InputLabel>
                <Select
                  value={formData.mealDetails.dessert || ''}
                  onChange={(e) =>
                    handleChange('mealDetails', {
                      ...formData.mealDetails,
                      dessert: e.target.value
                    })
                  }
                >
                  {mealOptions.mainMeal.desserts.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );

      case 'snack':
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Snack Time</InputLabel>
                <Select
                  value={formData.mealDetails.snackType || ''}
                  onChange={(e) =>
                    handleChange('mealDetails', {
                      ...formData.mealDetails,
                      snackType: e.target.value
                    })
                  }
                >
                  {mealOptions.snacks.types.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Snack Items
              </Typography>
              <Box display="flex" flexWrap="wrap">
                {mealOptions.snacks.items.map((item) => (
                  <StyledChip
                    key={item}
                    label={item}
                    onClick={() => {
                      const currentItems =
                        formData.mealDetails.snackItems || [];
                      const newItems = currentItems.includes(item)
                        ? currentItems.filter((i) => i !== item)
                        : [...currentItems, item];
                      handleChange('mealDetails', {
                        ...formData.mealDetails,
                        snackItems: newItems
                      });
                    }}
                    color={
                      formData.mealDetails.snackItems?.includes(item)
                        ? 'primary'
                        : 'default'
                    }
                    variant={
                      formData.mealDetails.snackItems?.includes(item)
                        ? 'filled'
                        : 'outlined'
                    }
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        );

      default:
        return null;
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          <RestaurantIcon />
          <Typography variant="h6">Meal Record</Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          {/* Meal Type Selection */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Meal Type
            </Typography>
            <Grid container spacing={1}>
              {['breakfast', 'lunch', 'dinner', 'snack'].map((type) => (
                <StyledChip
                  key={type}
                  label={type.charAt(0).toUpperCase() + type.slice(1)}
                  onClick={() =>
                    handleChange('mealType', type as MealData['mealType'])
                  }
                  color={formData.mealType === type ? 'primary' : 'default'}
                  variant={formData.mealType === type ? 'filled' : 'outlined'}
                />
              ))}
            </Grid>
          </Box>

          <Divider
            sx={{
              my: 1
            }}
          />

          {/* Meal Details */}
          {renderMealDetails()}

          <Divider
            sx={{
              my: 1
            }}
          />

          {/* Consumption Details */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Consumption Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="portion-size">Portion Size</InputLabel>
                  <Select
                    value={formData.portionSize}
                    onChange={(e) =>
                      handleChange('portionSize', e.target.value)
                    }
                    id="portion-size"
                  >
                    <MenuItem value="small">Small</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="large">Large</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography gutterBottom>Amount Consumed</Typography>
                <Box display="flex" alignItems="center" gap={2}>
                  <FastfoodIcon />
                  <Slider
                    value={formData.amountConsumed}
                    onChange={(_, value) =>
                      handleChange('amountConsumed', value)
                    }
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={0}
                    max={100}
                  />
                  <Typography>{formData.amountConsumed}%</Typography>
                </Box>
              </Grid>

              {/* Fluid Type Selection */}
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Hot Drinks
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {fluidOptions.hotDrinks.map((drink) => (
                    <StyledChip
                      key={drink}
                      label={drink}
                      onClick={() => {
                        const current = formData.fluidType.hotDrinks || [];
                        const updated = current.includes(drink)
                          ? current.filter((d) => d !== drink)
                          : [...current, drink];
                        handleChange('fluidType', {
                          ...formData.fluidType,
                          hotDrinks: updated
                        });
                      }}
                      color={
                        formData.fluidType.hotDrinks?.includes(drink)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.fluidType.hotDrinks?.includes(drink)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Cold Drinks
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {fluidOptions.coldDrinks.map((drink) => (
                    <StyledChip
                      key={drink}
                      label={drink}
                      onClick={() => {
                        const current = formData.fluidType.coldDrinks || [];
                        const updated = current.includes(drink)
                          ? current.filter((d) => d !== drink)
                          : [...current, drink];
                        handleChange('fluidType', {
                          ...formData.fluidType,
                          coldDrinks: updated
                        });
                      }}
                      color={
                        formData.fluidType.coldDrinks?.includes(drink)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.fluidType.coldDrinks?.includes(drink)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              {formData.dietaryRequirements.includes('Thickened Fluids') && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>
                    Thickened Fluids
                  </Typography>
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    {fluidOptions.thickenedFluids.map((drink) => (
                      <StyledChip
                        key={drink}
                        label={drink}
                        onClick={() => {
                          const current =
                            formData.fluidType.thickenedFluids || [];
                          const updated = current.includes(drink)
                            ? current.filter((d) => d !== drink)
                            : [...current, drink];
                          handleChange('fluidType', {
                            ...formData.fluidType,
                            thickenedFluids: updated
                          });
                        }}
                        color={
                          formData.fluidType.thickenedFluids?.includes(drink)
                            ? 'primary'
                            : 'default'
                        }
                        variant={
                          formData.fluidType.thickenedFluids?.includes(drink)
                            ? 'filled'
                            : 'outlined'
                        }
                      />
                    ))}
                  </Box>
                </Grid>
              )}

              {/* amount consumed */}

              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" gap={2}>
                  <LocalCafeIcon />
                  <TextField
                    fullWidth
                    label="Fluid Intake (ml)"
                    type="number"
                    value={formData.fluidIntake}
                    onChange={(e) =>
                      handleChange('fluidIntake', parseInt(e.target.value))
                    }
                    InputProps={{ inputProps: { min: 0, max: 1000 } }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Divider
            sx={{
              my: 1
            }}
          />

          {/* Assistance Details */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Assistance Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Assistance Level</InputLabel>
                  <Select
                    value={formData.assistanceLevel}
                    onChange={(e) =>
                      handleChange('assistanceLevel', e.target.value)
                    }
                  >
                    <MenuItem value="independent">Independent</MenuItem>
                    <MenuItem value="minimal">Minimal Assistance</MenuItem>
                    <MenuItem value="moderate">Moderate Assistance</MenuItem>
                    <MenuItem value="full">Full Assistance</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Feeding Position</InputLabel>
                  <Select
                    value={formData.feedingPosition}
                    onChange={(e) =>
                      handleChange('feedingPosition', e.target.value)
                    }
                  >
                    <MenuItem value="upright">Upright</MenuItem>
                    <MenuItem value="semi-reclined">Semi-reclined</MenuItem>
                    <MenuItem value="bed">In Bed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" gap={2}>
                  <TimeIcon />
                  <TextField
                    fullWidth
                    label="Time Spent (minutes)"
                    type="number"
                    value={formData.timeSpent}
                    onChange={(e) =>
                      handleChange('timeSpent', parseInt(e.target.value))
                    }
                    InputProps={{ inputProps: { min: 0, max: 120 } }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label="Assistance Notes"
                  value={formData.assistanceNotes}
                  onChange={(e) =>
                    handleChange('assistanceNotes', e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Box>

          <Divider
            sx={{
              my: 1
            }}
          />

          {/* Dietary Requirements */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Dietary Requirements
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {dietaryRequirements.map((req) => (
                <StyledChip
                  key={req}
                  label={req}
                  onClick={() => {
                    const current = formData.dietaryRequirements;
                    const updated = current.includes(req)
                      ? current.filter((r) => r !== req)
                      : [...current, req];
                    handleChange('dietaryRequirements', updated);
                  }}
                  color={
                    formData.dietaryRequirements.includes(req)
                      ? 'primary'
                      : 'default'
                  }
                  variant={
                    formData.dietaryRequirements.includes(req)
                      ? 'filled'
                      : 'outlined'
                  }
                />
              ))}
            </Box>
          </Box>

          <Divider
            sx={{
              my: 1
            }}
          />

          {/* Monitoring */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Monitoring
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.swallowingDifficulties}
                      onChange={(e) =>
                        handleChange('swallowingDifficulties', e.target.checked)
                      }
                    />
                  }
                  label="Swallowing Difficulties"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.chokingIncident}
                      onChange={(e) =>
                        handleChange('chokingIncident', e.target.checked)
                      }
                    />
                  }
                  label="Choking Incident"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.foodRefusal}
                      onChange={(e) =>
                        handleChange('foodRefusal', e.target.checked)
                      }
                    />
                  }
                  label="Food Refusal"
                />
              </Grid>
              {formData.foodRefusal && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Refusal Reason"
                    value={formData.refusalReason || ''}
                    onChange={(e) =>
                      handleChange('refusalReason', e.target.value)
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Box>

          <Divider
            sx={{
              my: 1
            }}
          />

          {/* Comments and Signature */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Additional Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Comments"
                  value={formData.comments}
                  onChange={(e) => handleChange('comments', e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          Save Record
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default MealDialog;
