import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import CheckoutForm from './CheckoutForm';
import {
  useGetPlansQuery,
  useGetSubscriptionDetailsQuery,
  useCancelSubscriptionMutation,
  useGetPaymentHistoryQuery,
  useUpdatePaymentMethodMutation,
  useRestartSubscriptionMutation,
  useChangePlanMutation,
  useGetCardDetailsQuery
} from 'src/redux/@api/stripe';
import { useAppSelector } from 'src/redux/hook';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  Paper,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
  Container,
  Divider,
  useTheme,
  useMediaQuery,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  alpha,
  Fade,
  Avatar,
  Chip
} from '@mui/material';
import {
  Star as StarIcon,
  ArrowForward,
  CreditScore,
  History,
  DeleteOutline,
  SwapHoriz,
  Info,
  CheckCircle,
  Warning
} from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { apiHostname } from 'src/api/api';
import axios from 'axios';
import UpdatePaymentForm from './Updatepayment';
import { format, isValid } from 'date-fns';

import CreditCardIcon from '@mui/icons-material/CreditCard';

const stripePromise = loadStripe(
  'pk_test_51Pid1H2M2oCccQjmdDbnK7EEm1aSwZ6Zy76aCgaDKvwUoLw5fjnd20Cq7PUqSEwbOmtoKGFB2ZxZHBaQgIlT0aDd00J4qRyAmm'
);
interface StyledCardProps {
  selected?: boolean;
  theme?: any;
}

interface StatusBadgeProps {
  status?: string;
  theme?: any;
}

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'selected'
})<StyledCardProps>(({ theme, selected = false }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease',
  position: 'relative',
  borderRadius: theme.spacing(2),
  background: selected
    ? theme.palette.primary.lighter || '#f5f5ff'
    : theme.palette.background.paper,
  border: `1px solid ${
    selected ? theme.palette.primary.main : theme.palette.divider
  }`,
  boxShadow: selected ? theme.shadows[4] : theme.shadows[1],
  overflow: 'visible',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[8]
  }
}));

const StatusBadge = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'status'
})<StatusBadgeProps>(({ theme, status }) => {
  const getStatusStyles = () => {
    switch (status?.toLowerCase()) {
      case 'active':
        return {
          backgroundColor: theme.palette.success.lighter || '#edf7ed',
          color: theme.palette.success.main,
          borderColor: theme.palette.success.light
        };
      case 'trialing':
        return {
          backgroundColor: theme.palette.info.lighter || '#e5f6fd',
          color: theme.palette.info.main,
          borderColor: theme.palette.info.light
        };
      case 'past_due':
        return {
          backgroundColor: theme.palette.warning.lighter || '#fff7cd',
          color: theme.palette.warning.main,
          borderColor: theme.palette.warning.light
        };
      case 'canceled':
        return {
          backgroundColor: theme.palette.error.lighter || '#ffe7e6',
          color: theme.palette.error.main,
          borderColor: theme.palette.error.light
        };
      default:
        return {
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.grey[700],
          borderColor: theme.palette.grey[300]
        };
    }
  };

  const statusStyles = getStatusStyles();

  return {
    backgroundColor: statusStyles.backgroundColor,
    color: statusStyles.color,
    fontWeight: 600,
    border: `1px solid ${statusStyles.borderColor}`,
    '.MuiChip-icon': {
      color: 'inherit'
    }
  };
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  padding: theme.spacing(3),
  boxShadow: `0 4px 20px ${alpha(theme.palette.grey[500], 0.1)}`,
  border: `1px solid ${alpha(theme.palette.grey[300], 0.3)}`
}));

const PlanPrice = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  '& .period': {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    fontWeight: 400
  }
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& .MuiTabs-indicator': {
    height: 3,
    borderRadius: '3px 3px 0 0'
  },
  '& .MuiTab-root': {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1rem',
    minWidth: 120
  }
}));

const PaymentMethodCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  backgroundColor: alpha(theme.palette.primary.main, 0.05),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    fontSize: 28
  }
}));

const FeatureList = styled(List)(({ theme }) => ({
  '& .MuiListItem-root': {
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiListItemIcon-root': {
      minWidth: 32,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.success.main
      }
    },
    '& .MuiListItemText-primary': {
      fontSize: '0.9rem',
      color: theme.palette.text.secondary
    }
  }
}));
// Add this to your styled components
const FeaturesDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

const FeatureItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: theme.palette.primary.light || '#f5f5ff',
    borderColor: theme.palette.primary.light
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  padding: theme.spacing(2),
  '&.MuiTableCell-head': {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    color: theme.palette.text.secondary,
    fontWeight: 600,
    borderBottom: `2px solid ${alpha(theme.palette.primary.main, 0.1)}`
  }
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

const ActionButton = styled(Button)(({ theme, color = 'primary' }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1.2, 3),
  textTransform: 'none',
  fontWeight: 600,
  boxShadow: 'none',
  '&.MuiButton-contained': {
    background: `linear-gradient(45deg, ${theme.palette[color].main}, ${theme.palette[color].dark})`,
    '&:hover': {
      background: `linear-gradient(45deg, ${theme.palette[color].dark}, ${theme.palette[color].main})`,
      boxShadow: `0 4px 12px ${alpha(theme.palette[color].main, 0.2)}`
    }
  }
}));
const planFeatures = {
  basic: [
    'Feature 1 of Basic Plan',
    'Feature 2 of Basic Plan',
    'Feature 3 of Basic Plan'
  ],
  standard: [
    'Feature 1 of Standard Plan',
    'Feature 2 of Standard Plan',
    'Feature 3 of Standard Plan',
    'Feature 4 of Standard Plan'
  ],
  premium: [
    'Feature 1 of Premium Plan',
    'Feature 2 of Premium Plan',
    'Feature 3 of Premium Plan',
    'Feature 4 of Premium Plan',
    'Feature 5 of Premium Plan'
  ]
};

const SubscriptionComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openChangePlanDialog, setOpenChangePlanDialog] = useState(false);
  const [newPlanId, setNewPlanId] = useState('');

  const organizationType = useAppSelector(
    (state) => state.userState.currentOrganization?.type
  );

  const [changePlan] = useChangePlanMutation();
  const { data: cardDetails } = useGetCardDetailsQuery(undefined);
  const {
    data: subscriptionDetails,
    isLoading: subscriptionLoading,
    refetch: subscriptionDetailsRefetch
  } = useGetSubscriptionDetailsQuery(undefined);

  const { data: plansData, isLoading: plansLoading } =
    useGetPlansQuery(undefined);

  const { data: paymentHistory, isLoading: paymentHistoryLoading } =
    useGetPaymentHistoryQuery(undefined);

  const [cancelSubscription] = useCancelSubscriptionMutation();
  const [restartSubscription] = useRestartSubscriptionMutation();
  const [updatePaymentMethod] = useUpdatePaymentMethodMutation();

  // Add this to your component state
  const [openFeaturesDialog, setOpenFeaturesDialog] = useState(false);
  const [selectedPlanFeatures, setSelectedPlanFeatures] = useState({
    name: '',
    features: []
  });

  useEffect(() => {
    if (plansData?.length > 0) {
      const firstPlan = plansData[0];
      setSelectedPlan(firstPlan.stripePriceId);
      handlePlanSelect(firstPlan.stripePriceId);
    }
  }, [plansData]);

  const handlePlanSelect = async (planId) => {
    setSelectedPlan(planId);
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiHostname}/api/v1/stripe/create-setup-intent`,
        {
          planId: planId,
          organizationType
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setClientSecret(response.data.clientSecret);
    } catch (error) {
      console.error('Error creating SetupIntent:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePlan = async () => {
    try {
      await changePlan({ newPlanId }).unwrap();
      setOpenChangePlanDialog(false);
      subscriptionDetailsRefetch();
    } catch (error) {
      console.error('Error changing plan:', error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription(undefined).unwrap();
      setOpenCancelDialog(false);
      subscriptionDetailsRefetch();
    } catch (error) {
      console.error('Error cancelling subscription:', error);
    }
  };

  const handleRestartSubscription = async () => {
    try {
      await restartSubscription(selectedPlan).unwrap();
      subscriptionDetailsRefetch();
    } catch (error) {
      console.error('Error restarting subscription:', error);
    }
  };

  if (subscriptionLoading || plansLoading || loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress size={40} thickness={4} />
      </Box>
    );
  }

  const renderSubscriptionStatus = () => (
    <StyledPaper elevation={0} sx={{ mb: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 3
        }}
      >
        <Typography variant="h5" fontWeight={600}>
          Subscription Status
        </Typography>
        <StatusBadge
          label={subscriptionDetails?.status}
          status={subscriptionDetails?.status}
          icon={
            subscriptionDetails?.status === 'active' ? (
              <CheckCircle />
            ) : (
              <Info />
            )
          }
        />
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Current Plan
            </Typography>
            <Typography variant="h6">
              {subscriptionDetails?.planName || 'No active plan'}
            </Typography>
          </Box>
          {subscriptionDetails?.status === 'trialing' && (
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Trial Period
              </Typography>
              <Typography variant="h6">
                Ends on {formatDate(subscriptionDetails?.trialEnd)}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {subscriptionDetails?.nextPaymentDate && (
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Next Payment Date
              </Typography>
              <Typography variant="h6">
                {formatDate(subscriptionDetails.nextPaymentDate)}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>

      {subscriptionDetails?.status === 'active' && (
        <Alert
          severity="success"
          sx={{
            mt: 3,
            borderRadius: 2,
            backgroundColor: alpha(theme.palette.success.main, 0.1),
            border: `1px solid ${alpha(theme.palette.success.main, 0.2)}`,
            '& .MuiAlert-icon': {
              color: theme.palette.success.main
            }
          }}
        >
          Your subscription is active and in good standing.
        </Alert>
      )}

      {subscriptionDetails?.status === 'past_due' && (
        <Alert
          severity="warning"
          sx={{
            mt: 3,
            borderRadius: 2,
            backgroundColor: alpha(theme.palette.warning.main, 0.1),
            border: `1px solid ${alpha(theme.palette.warning.main, 0.2)}`,
            '& .MuiAlert-icon': {
              color: theme.palette.warning.main
            }
          }}
        >
          Your payment is past due. Please update your payment method.
        </Alert>
      )}

      {subscriptionDetails?.status !== 'inactive' &&
        subscriptionDetails?.status !== 'canceled' && (
          <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <ActionButton
              variant="contained"
              color="primary"
              onClick={() => setOpenChangePlanDialog(true)}
              startIcon={<SwapHoriz />}
            >
              Change Plan
            </ActionButton>
            <ActionButton
              variant="outlined"
              color="error"
              onClick={() => setOpenCancelDialog(true)}
              startIcon={<DeleteOutline />}
            >
              Cancel Subscription
            </ActionButton>
          </Box>
        )}

      {subscriptionDetails?.status === 'canceled' && (
        <Box sx={{ mt: 3 }}>
          <ActionButton
            variant="contained"
            onClick={handleRestartSubscription}
            startIcon={<CreditScore />}
          >
            Restart Subscription
          </ActionButton>
        </Box>
      )}
    </StyledPaper>
  );

  const renderPaymentMethod = () =>
    cardDetails && (
      <StyledPaper elevation={0} sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight={600} gutterBottom>
          Payment Method
        </Typography>
        <PaymentMethodCard>
          <CreditCardIcon />
          <Box>
            <Typography variant="subtitle1" fontWeight={500}>
              **** **** **** {cardDetails.last4}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Expires: {cardDetails.expMonth}/{cardDetails.expYear}
            </Typography>
          </Box>
        </PaymentMethodCard>
      </StyledPaper>
    );

  const renderPlans = () => (
    <>
      <Grid container spacing={3}>
        {plansData?.map((plan) => (
          <Grid item xs={12} md={4} key={plan.stripePriceId}>
            <StyledCard selected={selectedPlan === plan.stripePriceId}>
              <CardContent>
                <Typography variant="h5" gutterBottom fontWeight={600}>
                  {plan.name}
                </Typography>
                <PlanPrice>
                  ${plan.price}
                  <span className="period">/{plan.interval}</span>
                </PlanPrice>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {plan.description}
                </Typography>

                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setSelectedPlanFeatures({
                      name: plan.name,
                      features: planFeatures[plan.planTier] || []
                    });
                    setOpenFeaturesDialog(true);
                  }}
                  endIcon={<ArrowForward />}
                  sx={{
                    mb: 2,
                    color: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.light || '#f5f5ff'
                    }
                  }}
                >
                  View Features
                </Button>
              </CardContent>
              <CardActions sx={{ p: 2, pt: 0 }}>
                <ActionButton
                  fullWidth
                  variant={
                    selectedPlan === plan.stripePriceId
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() => handlePlanSelect(plan.stripePriceId)}
                  endIcon={<ArrowForward />}
                >
                  {selectedPlan === plan.stripePriceId
                    ? 'Selected'
                    : 'Select Plan'}
                </ActionButton>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </>
  );

  const renderPaymentHistory = () => (
    <StyledPaper elevation={0}>
      <Typography variant="h5" fontWeight={600} gutterBottom>
        Payment History
      </Typography>
      {paymentHistoryLoading ? (
        <Box display="flex" justifyContent="center" p={4}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Amount</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentHistory?.map((payment) => (
                <TableRow key={payment.id}>
                  <StyledTableCell>{formatDate(payment.date)}</StyledTableCell>
                  <StyledTableCell>
                    ${payment.amount.toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell>
                    <StatusBadge
                      label={payment.status}
                      status={payment.status}
                      size="small"
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </StyledPaper>
  );

  const renderDialogs = () => (
    <>
      <StyledDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Warning color="error" />
            Cancel Subscription
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel your subscription? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ActionButton onClick={() => setOpenCancelDialog(false)}>
            Keep Subscription
          </ActionButton>
          <ActionButton
            color="error"
            variant="contained"
            onClick={handleCancelSubscription}
          >
            Cancel Subscription
          </ActionButton>
        </DialogActions>
      </StyledDialog>

      <StyledDialog
        open={openChangePlanDialog}
        onClose={() => setOpenChangePlanDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SwapHoriz color="primary" />
            Change Subscription Plan
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 3 }}>
            Select the new plan you would like to switch to:
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel>New Plan</InputLabel>
            <Select
              value={newPlanId}
              onChange={(e) => setNewPlanId(e.target.value)}
              label="New Plan"
            >
              {plansData?.map((plan) => (
                <MenuItem key={plan.stripePriceId} value={plan.stripePriceId}>
                  {plan.name} - ${plan.price}/{plan.interval}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <ActionButton onClick={() => setOpenChangePlanDialog(false)}>
            Cancel
          </ActionButton>
          <ActionButton
            variant="contained"
            onClick={handleChangePlan}
            disabled={!newPlanId}
          >
            Change Plan
          </ActionButton>
        </DialogActions>
      </StyledDialog>
    </>
  );

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <StyledTabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
        >
          <Tab
            label="Subscription Details"
            icon={<CreditScore sx={{ fontSize: 20 }} />}
            iconPosition="start"
          />
          <Tab
            label="Payment History"
            icon={<History sx={{ fontSize: 20 }} />}
            iconPosition="start"
          />
        </StyledTabs>

        {tabValue === 0 && (
          <>
            {renderSubscriptionStatus()}
            {renderPaymentMethod()}

            <Typography variant="h5" fontWeight={600} sx={{ mb: 3 }}>
              Available Plans
            </Typography>
            {renderPlans()}

            <StyledPaper elevation={0} sx={{ mt: 4 }}>
              {subscriptionDetails?.status !== 'inactive' ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      mb: 3
                    }}
                  >
                    <CreditCardIcon color="primary" />
                    <Typography variant="h5" fontWeight={600}>
                      Update Payment Method
                    </Typography>
                  </Box>
                  <Divider sx={{ mb: 3 }} />
                  <Elements stripe={stripePromise}>
                    <UpdatePaymentForm
                      onUpdatePaymentMethod={updatePaymentMethod}
                    />
                  </Elements>
                </>
              ) : (
                selectedPlan &&
                clientSecret && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3
                      }}
                    >
                      <CreditCardIcon color="primary" />
                      <Typography variant="h5" fontWeight={600}>
                        Complete Subscription
                      </Typography>
                    </Box>
                    <Divider sx={{ mb: 3 }} />
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                      <CheckoutForm
                        selectedPlan={selectedPlan}
                        organizationType={organizationType}
                        planTier={
                          plansData.find(
                            (plan) => plan.stripePriceId === selectedPlan
                          )?.planTier
                        }
                      />
                    </Elements>
                  </>
                )
              )}
            </StyledPaper>
          </>
        )}

        {tabValue === 1 && renderPaymentHistory()}

        {renderDialogs()}
      </Container>

      <FeaturesDialog
        open={openFeaturesDialog}
        onClose={() => setOpenFeaturesDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <StarIcon color="primary" />
            <Typography variant="h6">
              {selectedPlanFeatures.name} Features
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {selectedPlanFeatures.features.map((feature, index) => (
            <FeatureItem key={index}>
              <CheckCircle color="success" sx={{ fontSize: 20 }} />
              <Typography variant="body1">{feature}</Typography>
            </FeatureItem>
          ))}
          {selectedPlanFeatures.features.length === 0 && (
            <Typography color="text.secondary" align="center">
              No features available for this plan.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ActionButton
            onClick={() => setOpenFeaturesDialog(false)}
            variant="contained"
          >
            Close
          </ActionButton>
        </DialogActions>
      </FeaturesDialog>
    </Box>
  );
};

// Helper function to format dates
const formatDate = (dateString: string) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return isValid(date) ? format(date, 'PP') : 'Invalid Date';
};

export default SubscriptionComponent;
