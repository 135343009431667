import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Chip,
  Divider,
  Alert,
  styled,
  Tooltip
} from '@mui/material';
import {
  WbSunny,
  NightsStay,
  Warning,
  Hotel,
  LocalDrink,
  CheckCircle,
  Info,
  AccessibilityNew,
  Opacity,
  Thermostat,
  RecordVoiceOver
} from '@mui/icons-material';
import { ITask } from 'src/interfaces/new/tasks';

interface DayNightCheckData {
  // General Observation
  location: string;
  position: string;
  appearance: string[];
  breathing: string;

  // State
  consciousness: string;
  mood: string;
  behaviors: string[];
  awake: boolean;

  // Physical Needs
  padCheck: {
    checked: boolean;
    condition: string;
    changed: boolean;
  };

  fluids: {
    offered: boolean;
    accepted: boolean;
    amount?: number;
  };

  // Safety & Comfort
  callBell: boolean;
  bedrailPosition: string;
  bedHeight: string;
  roomTemperature: string;
  lightLevel: string;

  // Issues
  painObserved: boolean;
  painDetails?: {
    location: string;
    intensity: number;
  };
  concerningSymptoms: string[];
  assistanceProvided: string[];

  concerns: boolean;
  notes: string;
  timestamp: string;
}

interface DayNightCheckReportProps {
  data: DayNightCheckData | any;
  isNight?: boolean;
  task?: Partial<ITask>;
}

const ReportSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& + &': {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const DayNightCheckReport: React.FC<DayNightCheckReportProps> = ({
  data,
  isNight = false,
  task
}) => {
  const getPositionIcon = (position: string) => {
    switch (position.toLowerCase()) {
      case 'lying left':
        return '⬅️';
      case 'lying right':
        return '➡️';
      case 'lying back':
        return '⬆️';
      case 'sitting':
        return '🪑';
      case 'standing':
        return '🧍';
      default:
        return '🛏️';
    }
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      {/* Header */}
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        {isNight ? (
          <NightsStay color="primary" fontSize="large" />
        ) : (
          <WbSunny color="primary" fontSize="large" />
        )}
        <Box>
          <Typography variant="h6">
            {isNight ? 'Night Check' : 'Day Check'} Record
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {new Date(task.completedDate).toLocaleString()}
          </Typography>
          {/* completed by */}
          <Box display="flex" alignItems="center" gap={1} mt={1}>
            <RecordVoiceOver />
            <Typography variant="body2">
              Completed by: {task.completedBy?.firstName}{' '}
              {task.completedBy?.lastName}
            </Typography>

            {/* role */}
            <Tooltip title={task.completedBy?.role}>
              <Chip
                label={task.completedBy?.role}
                size="small"
                color="primary"
              />
            </Tooltip>
          </Box>
        </Box>
        <Box flexGrow={1} />
        {data.concerns && (
          <Chip
            icon={<Warning />}
            label="Requires Review"
            color="error"
            size="small"
          />
        )}
      </Box>

      <Divider />

      {/* Location & Position */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Location & Position
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="h6">
                {getPositionIcon(data.position)}
              </Typography>
              <Typography>
                {data.position} - {data.location}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {data.appearance.map((app, index) => (
                <Chip
                  key={index}
                  label={app}
                  size="small"
                  color={
                    ['restless', 'agitated', 'pale', 'flushed'].includes(
                      app.toLowerCase()
                    )
                      ? 'warning'
                      : 'default'
                  }
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </ReportSection>

      {/* State & Consciousness */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          State & Consciousness
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" gap={1} alignItems="center">
              <Hotel />
              <Typography>
                {data.consciousness}
                {data.awake ? ' - Awake' : ' - Sleeping'}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Breathing: {data.breathing}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Observed Behaviors
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {data.behaviors.map((behavior, index) => (
                <Chip
                  key={index}
                  label={behavior}
                  size="small"
                  color={
                    ['agitated', 'distressed', 'anxious'].includes(
                      behavior.toLowerCase()
                    )
                      ? 'error'
                      : ['calm', 'peaceful', 'settled'].includes(
                          behavior.toLowerCase()
                        )
                      ? 'success'
                      : 'default'
                  }
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </ReportSection>

      {/* Physical Needs */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Physical Needs
        </Typography>
        <Grid container spacing={2}>
          {/* Pad Check */}
          {data.padCheck.checked && (
            <Grid item xs={12} sm={6}>
              <Box mb={1}>
                <Chip
                  icon={<Opacity />}
                  label={`Pad ${data.padCheck.condition}`}
                  color={
                    data.padCheck.condition === 'dry' ? 'success' : 'warning'
                  }
                  size="small"
                />
                {data.padCheck.changed && (
                  <Chip
                    icon={<CheckCircle />}
                    label="Changed"
                    color="success"
                    size="small"
                    sx={{ ml: 1 }}
                  />
                )}
              </Box>
            </Grid>
          )}

          {/* Fluids */}
          {data.fluids.offered && (
            <Grid item xs={12} sm={6}>
              <Box display="flex" gap={1} alignItems="center">
                <LocalDrink
                  color={data.fluids.accepted ? 'success' : 'error'}
                />
                <Typography>
                  Fluids {data.fluids.accepted ? 'accepted' : 'refused'}
                  {data.fluids.amount && ` - ${data.fluids.amount}ml`}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Safety & Comfort */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Safety & Comfort
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" flexWrap="wrap" gap={1}>
              <Chip
                label={`Call Bell ${
                  data.callBell ? 'Within Reach' : 'Not Available'
                }`}
                color={data.callBell ? 'success' : 'error'}
                size="small"
              />
              <Chip label={`Bed Rails ${data.bedrailPosition}`} size="small" />
              <Chip label={`Bed ${data.bedHeight}`} size="small" />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display="flex" gap={2}>
              <Chip
                icon={<Thermostat />}
                label={`Room: ${data.roomTemperature}`}
                color={
                  data.roomTemperature === 'comfortable' ? 'success' : 'warning'
                }
                size="small"
              />
              <Chip label={`Light: ${data.lightLevel}`} size="small" />
            </Box>
          </Grid>
        </Grid>
      </ReportSection>

      {/* Issues & Assistance */}
      {(data.painObserved ||
        data.concerningSymptoms.length > 0 ||
        data.assistanceProvided.length > 0) && (
        <ReportSection>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Issues & Assistance
          </Typography>
          <Grid container spacing={2}>
            {data.painObserved && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  Pain Reported
                  {data.painDetails && (
                    <Box mt={1}>
                      <Typography variant="body2">
                        Location: {data.painDetails.location}
                      </Typography>
                      <Typography variant="body2">
                        Intensity: {data.painDetails.intensity}/10
                      </Typography>
                    </Box>
                  )}
                </Alert>
              </Grid>
            )}

            {data.concerningSymptoms.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Concerning Symptoms
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {data.concerningSymptoms.map((symptom, index) => (
                    <Chip
                      key={index}
                      label={symptom}
                      color="error"
                      size="small"
                    />
                  ))}
                </Box>
              </Grid>
            )}

            {data.assistanceProvided.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Assistance Provided
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {data.assistanceProvided.map((assistance, index) => (
                    <Chip
                      key={index}
                      label={assistance}
                      color="primary"
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </ReportSection>
      )}

      {/* Notes & Follow-up */}
      {(data.notes || data.concerns) && (
        <ReportSection>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Additional Information
          </Typography>
          {data.notes && (
            <Typography variant="body2" paragraph>
              {data.notes}
            </Typography>
          )}
          {data.concerns && (
            <Alert
              severity="warning"
              action={
                <Tooltip
                  title={
                    isNight ? 'Will alert night staff' : 'Requires follow-up'
                  }
                >
                  <Info />
                </Tooltip>
              }
            >
              {isNight
                ? 'Issues require immediate night staff attention'
                : 'Requires follow-up or review'}
            </Alert>
          )}
        </ReportSection>
      )}

      {/* Timestamp */}
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Typography variant="caption" color="textSecondary">
          Recorded: {new Date(data.timestamp).toLocaleString()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default DayNightCheckReport;
