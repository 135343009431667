import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid,
  useMediaQuery,
  useTheme,
  IconButton,
  CircularProgress,
  Button,
  Avatar,
  Dialog
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/redux/store'; // Make sure this path is correct
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useAppSelector } from 'src/redux/hook';
import GlassEffectBox from 'src/components/core/ui/glass';
import {
  useGetApplicationStatusQuery,
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation
} from 'src/redux/@api/carer-application';
import PersonalInfo from './components/personal';
import IdentificationDocuments from './components/identification';
import ProfessionalInformation from './components/proffesional';
import Skills from './components/skills';
import Availability from './components/availability';
import HealthAndSafety from './components/health-info';
import BankDetails from './components/bank';
import AdditionalInfo from './components/additional';
import Consents from './components/consents';
import axios from 'axios';
import { apiHostname } from 'src/api/api';
import { _setAvatar } from 'src/redux/reducers/user-slice';

interface Category {
  name: string;
  component: React.ComponentType<any>;
  label: string;
}

const categories: Category[] = [
  {
    name: 'personalInfo',
    component: PersonalInfo,
    label: 'Personal Information'
  },
  {
    name: 'identificationDocuments',
    component: IdentificationDocuments,
    label: 'Identification Documents'
  },
  {
    name: 'professionalInfo',
    component: ProfessionalInformation,
    label: 'Professional Information'
  },
  { name: 'skills', component: Skills, label: 'Skills' },
  { name: 'availability', component: Availability, label: 'Availability' },
  {
    name: 'healthAndSafety',
    component: HealthAndSafety,
    label: 'Health and Safety'
  },
  { name: 'bankDetails', component: BankDetails, label: 'Bank Details' },
  {
    name: 'additionalInfo',
    component: AdditionalInfo,
    label: 'Additional Information'
  },
  { name: 'consents', component: Consents, label: 'Consents' }
];

const CarerApplicationForm: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch<AppDispatch>();

  const [updateApplicationSection] = useUpdateApplicationSectionMutation();
  const { data: applicationStatus, isLoading: isApplicationStatusLoading } =
    useGetApplicationStatusQuery(undefined);
  const { data: carerApplication, isLoading: isCarerApplicationLoading } =
    useGetCarerApplicationQuery(undefined);

  const userState = useAppSelector((state) => state.userState);

  useEffect(() => {
    if (!isMobile) {
      setSelectedCategory(0);
    }
  }, [isMobile]);

  const handleCategoryChange = (index: number): void => {
    setSelectedCategory(index);
  };

  const handleCloseDialog = (): void => {
    setSelectedCategory(null);
  };

  const handleSubmit = async (
    data: any,
    index: number,
    fullSection: string
  ): Promise<void> => {
    try {
      if (selectedCategory === null) return;
      const currentCategory = categories[selectedCategory];
      await updateApplicationSection({
        section: fullSection || currentCategory.name,
        data,
        index
      }).unwrap();
      dispatch(
        showSnack({
          message: `${currentCategory.label} saved successfully`,
          color: 'success'
        })
      );
    } catch (error: any) {
      dispatch(
        showSnack({
          message: error.data?.message || 'An error occurred while saving',
          color: 'error'
        })
      );
    }
  };

  const handleProfilePictureUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    // new
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      const formData = new FormData();

      const fileExtension = file.name.substr(file.name.lastIndexOf('.'));
      const newFileName = `profile_picture_${
        userState.user._id
      }_${Date.now()}${fileExtension}`;
      const renamedFile = new File([file], newFileName, { type: file.type });

      formData.append('file', renamedFile);

      try {
        const response = await axios.post(
          `${apiHostname}/api/v1/pictures/${userState.user._id}/upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          dispatch(
            showSnack({
              message: 'Profile picture updated',
              color: 'success'
            })
          );
          setProfilePicture(response.data?.data?.avatarUrl);
          dispatch(_setAvatar(response.data?.data?.avatarUrl));
        } else {
          throw new Error(
            response.data.error || 'Failed to update profile picture'
          );
        }
      } catch (error) {
        dispatch(showSnack({ message: error.message, color: 'error' }));
      } finally {
        setIsUploading(false);
      }
    }
  };

  let statusColor: 'success' | 'warning' | 'error' | 'info' = 'info';
  if (applicationStatus?.data?.completionPercentage === 100) {
    statusColor = 'success';
  } else if (
    applicationStatus?.data?.completionPercentage > 50 &&
    applicationStatus?.data?.completionPercentage < 100
  ) {
    statusColor = 'warning';
  } else {
    statusColor = 'error';
  }

  const SideMenu: React.FC = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 2
      }}
    >
      {/* <Box sx={{ position: 'relative', width: 120, height: 120, mb: 2 }}>
        <CircularProgress
          variant="determinate"
          value={applicationStatus?.data?.completionPercentage || 0}
          size={120}
          thickness={4}
          color={statusColor}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0
          }}
        />
        <Avatar
          src={userState.user.avatarUrl || undefined}
          sx={{
            width: 100,
            height: 100,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      </Box> */}
      <Typography variant="subtitle1" gutterBottom>
        Application Progress:{' '}
        {Math.round(applicationStatus?.data?.completionPercentage || 0)}%
      </Typography>
      <List sx={{ width: '100%' }}>
        {categories.map((category, index) => (
          <ListItem
            button
            key={category.name}
            selected={selectedCategory === index}
            onClick={() => handleCategoryChange(index)}
          >
            <ListItemText
              sx={{
                color: selectedCategory === index ? 'primary.main' : 'gray'
              }}
              primary={category.label}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const renderSelectedComponent = () => {
    if (selectedCategory === null || isCarerApplicationLoading) {
      return (
        <Typography>Select a category from the sidebar to begin.</Typography>
      );
    }

    const SelectedComponent = categories[selectedCategory].component;
    return (
      <SelectedComponent
        initialData={
          carerApplication?.data?.[categories[selectedCategory].name]
        }
        selectedSection={categories[selectedCategory].name}
        onSubmit={handleSubmit}
      />
    );
  };

  return (
    <Box sx={{ flexGrow: 1, p: 1 }}>
      <Grid container spacing={3}>
        {!isMobile && (
          <Grid item md={3}>
            <Paper
              sx={{
                overflowY: 'auto',
                height: `calc(100vh - ${theme.header.height} - 3rem)`
              }}
              elevation={3}
            >
              <SideMenu />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} md={isMobile ? 12 : 9}>
          {!isMobile && (
            <Box
              sx={{
                height: `calc(100vh - ${theme.header.height} - 40px)`,
                overflowY: 'auto'
              }}
            >
              {renderSelectedComponent()}
            </Box>
          )}
          {isMobile && (
            <Paper
              sx={{
                overflowY: 'auto',
                height: `calc(100vh - ${theme.header.height} - 3rem)`
              }}
              elevation={3}
            >
              <SideMenu />
            </Paper>
          )}
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={isMobile && selectedCategory !== null}
        onClose={handleCloseDialog}
      >
        <Box
          sx={{
            p: 1
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ ml: 2, display: 'inline' }}
          >
            {selectedCategory !== null
              ? categories[selectedCategory].label
              : ''}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, p: 1 }}>{renderSelectedComponent()}</Box>
      </Dialog>
    </Box>
  );
};

export default CarerApplicationForm;
