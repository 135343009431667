import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Card,
  CircularProgress,
  styled,
  alpha,
  Fade,
  Tooltip,
  useTheme
} from '@mui/material';
import {
  Add,
  Delete,
  Save,
  Language,
  Psychology,
  LocalHospital,
  CheckCircle
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation,
  useDeleteArrayItemMutation
} from 'src/redux/@api/carer-application';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  overflow: 'visible',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0, 0, 0, 0.08)'
  }
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  '& svg': {
    fontSize: 28,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: 0,
    width: 40,
    height: 3,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.02)
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
    }
  }
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.02)
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
    }
  }
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  margin: theme.spacing(0.5),
  transition: 'all 0.2s ease',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.15)
  },
  '& .MuiChip-deleteIcon': {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark
    }
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1, 3),
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)'
  }
}));

interface SkillsProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const Skills: React.FC<SkillsProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();
  const [deleteArrayItem] = useDeleteArrayItemMutation();

  const [savingSection, setSavingSection] = useState<{
    [key: string]: boolean;
  }>({});
  const [deletingItem, setDeletingItem] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [savedSections, setSavedSections] = useState<{
    [key: string]: boolean;
  }>({});

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {}
    : initialData || {};

  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues
  });

  const languagesArray = useFieldArray({
    control,
    name: 'languages'
  });

  const careSkillsArray = useFieldArray({
    control,
    name: 'careSkills'
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const handleSaveSection = async (section: string, index?: number) => {
    if (!editable) return;
    const key = `${section}${index !== undefined ? `.${index}` : ''}`;
    setSavingSection((prev) => ({ ...prev, [key]: true }));

    try {
      let data =
        index !== undefined ? getValues(section)[index] : getValues(section);

      await updateApplicationSection({
        section: `skills.${section}`,
        data,
        index
      }).unwrap();

      await refetch();

      setSavedSections((prev) => ({ ...prev, [key]: true }));

      dispatch(
        showSnack({
          message: `${section} information saved successfully`,
          color: 'success'
        })
      );

      // Reset saved status after 3 seconds
      setTimeout(() => {
        setSavedSections((prev) => ({ ...prev, [key]: false }));
      }, 3000);
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: `Failed to save ${section} information`,
          color: 'error'
        })
      );
    } finally {
      setSavingSection((prev) => ({ ...prev, [key]: false }));
    }
  };

  const handleDeleteItem = async (section: string, index: number) => {
    if (!editable) return;
    const key = `${section}.${index}`;
    setDeletingItem((prev) => ({ ...prev, [key]: true }));

    try {
      await deleteArrayItem({
        section: `skills.${section}`,
        index
      }).unwrap();

      await refetch();

      dispatch(
        showSnack({
          message: `${section} item deleted successfully`,
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Delete failed:', error);
      dispatch(
        showSnack({
          message: `Failed to delete ${section} item`,
          color: 'error'
        })
      );
    } finally {
      setDeletingItem((prev) => ({ ...prev, [key]: false }));
    }
  };

  const handleDeleteSpecialization = async (index: number) => {
    if (!editable) return;
    try {
      await deleteArrayItem({
        section: 'skills.specializations',
        index
      }).unwrap();

      await refetch();

      dispatch(
        showSnack({
          message: 'Specialization deleted successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Delete failed:', error);
      dispatch(
        showSnack({
          message: 'Failed to delete specialization',
          color: 'error'
        })
      );
    }
  };

  const renderTextField = (name: string, label: string) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        editable ? (
          <StyledTextField
            {...field}
            label={label}
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
          />
        ) : (
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
            >
              {label}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {field.value || 'N/A'}
            </Typography>
          </Box>
        )
      }
    />
  );

  const renderArraySection = (
    title: string,
    fieldsArray: any,
    section: string,
    renderFields: (index: number) => React.ReactNode,
    icon: React.ReactNode
  ) => (
    <Box sx={{ mb: 4 }}>
      <SectionHeader>
        {icon}
        <SectionTitle>{title}</SectionTitle>
      </SectionHeader>

      {fieldsArray.fields.map((field: any, index: number) => {
        const isSaving = savingSection[`${section}.${index}`];
        const isDeleting = deletingItem[`${section}.${index}`];
        const isSaved = savedSections[`${section}.${index}`];

        return (
          <Fade in timeout={300} key={field.id}>
            <StyledCard
              sx={{
                mb: 2,
                position: 'relative',
                '&::before': isSaved
                  ? {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: '4px',
                      background: (theme) =>
                        `linear-gradient(to right, ${theme.palette.success.main}, ${theme.palette.success.light})`,
                      borderTopLeftRadius: '16px',
                      borderTopRightRadius: '16px'
                    }
                  : {}
              }}
            >
              <Box sx={{ p: 3 }}>
                {renderFields(index)}

                {editable && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 3,
                      pt: 2,
                      borderTop: 1,
                      borderColor: 'divider'
                    }}
                  >
                    <ActionButton
                      onClick={() => handleDeleteItem(section, index)}
                      startIcon={
                        isDeleting ? <CircularProgress size={20} /> : <Delete />
                      }
                      color="error"
                      sx={{
                        color: theme.palette.error.main,
                        borderColor: theme.palette.error.main,
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.error.main, 0.1)
                        }
                      }}
                      disabled={isDeleting}
                    >
                      {isDeleting ? 'Deleting...' : 'Remove'}
                    </ActionButton>

                    <ActionButton
                      onClick={() => handleSaveSection(section, index)}
                      startIcon={
                        isSaving ? <CircularProgress size={20} /> : <Save />
                      }
                      variant="contained"
                      disabled={isSaving}
                      sx={{
                        background: (theme) =>
                          `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                        '&:hover': {
                          background: (theme) =>
                            `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`
                        }
                      }}
                    >
                      {isSaving ? 'Saving...' : 'Save'}
                    </ActionButton>
                  </Box>
                )}
              </Box>
            </StyledCard>
          </Fade>
        );
      })}

      {editable && (
        <Button
          onClick={() => fieldsArray.append({})}
          startIcon={<Add />}
          variant="outlined"
          fullWidth
          sx={{
            mt: 2,
            borderStyle: 'dashed',
            borderWidth: 2,
            borderRadius: 2,
            py: 1.5,
            '&:hover': {
              borderStyle: 'dashed',
              borderWidth: 2,
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.05)
            }
          }}
        >
          Add {title}
        </Button>
      )}
    </Box>
  );
  const renderSpecializationsSection = () => {
    return (
      <Box sx={{ mb: 4 }}>
        <SectionHeader>
          <LocalHospital />
          <SectionTitle>Specializations</SectionTitle>
        </SectionHeader>

        <StyledCard>
          <Box sx={{ p: 3 }}>
            <Controller
              name="specializations"
              control={control}
              render={({ field }) => (
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                      mb: editable ? 3 : 0
                    }}
                  >
                    {field.value &&
                      field.value.map((spec: string, index: number) => (
                        <StyledChip
                          key={index}
                          label={spec}
                          onDelete={
                            editable
                              ? () => handleDeleteSpecialization(index)
                              : undefined
                          }
                          icon={<CheckCircle sx={{ fontSize: 16 }} />}
                        />
                      ))}
                  </Box>
                  {editable && (
                    <StyledTextField
                      fullWidth
                      variant="outlined"
                      placeholder="Type a specialization and press Enter to add"
                      size="small"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          const input = e.target as HTMLInputElement;
                          const newSpec = input.value.trim();
                          if (
                            newSpec &&
                            (!field.value || !field.value.includes(newSpec))
                          ) {
                            const newValue = [...(field.value || []), newSpec];
                            field.onChange(newValue);
                            input.value = '';
                            handleSaveSection('specializations');
                          }
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <Add
                            sx={{
                              color: 'action.active',
                              mr: 1
                            }}
                          />
                        )
                      }}
                    />
                  )}
                </Box>
              )}
            />
          </Box>
        </StyledCard>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        minHeight: '100vh',
        p: { xs: 2, sm: 3, md: 4 },
        borderRadius: 4,
        boxShadow: (theme) =>
          `0 0 40px ${alpha(theme.palette.primary.main, 0.08)}`
      }}
    >
      <form
        onSubmit={handleSubmit((data) => onSubmit(data, undefined, 'skills'))}
      >
        {/* Languages Section */}
        {renderArraySection(
          'Languages',
          languagesArray,
          'languages',
          (index: number) => (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                {renderTextField(`languages.${index}.language`, 'Language')}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={`languages.${index}.proficiency`}
                  control={control}
                  render={({ field }) =>
                    editable ? (
                      <StyledFormControl size="small" fullWidth margin="normal">
                        <InputLabel>Proficiency</InputLabel>
                        <Select {...field} label="Proficiency" size="small">
                          <MenuItem value="Basic">Basic</MenuItem>
                          <MenuItem value="Conversational">
                            Conversational
                          </MenuItem>
                          <MenuItem value="Fluent">Fluent</MenuItem>
                          <MenuItem value="Native">Native</MenuItem>
                        </Select>
                      </StyledFormControl>
                    ) : (
                      <Box sx={{ mb: 2 }}>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          display="block"
                        >
                          Proficiency
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {field.value || 'N/A'}
                        </Typography>
                      </Box>
                    )
                  }
                />
              </Grid>
            </Grid>
          ),
          <Language />
        )}

        {/* Care Skills Section */}
        {renderArraySection(
          'Care Skills',
          careSkillsArray,
          'careSkills',
          (index: number) => (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                {renderTextField(`careSkills.${index}.skill`, 'Skill')}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={`careSkills.${index}.experienceLevel`}
                  control={control}
                  render={({ field }) =>
                    editable ? (
                      <StyledFormControl size="small" fullWidth margin="normal">
                        <InputLabel>Experience Level</InputLabel>
                        <Select
                          {...field}
                          label="Experience Level"
                          size="small"
                        >
                          <MenuItem value="Novice">Novice</MenuItem>
                          <MenuItem value="Intermediate">Intermediate</MenuItem>
                          <MenuItem value="Expert">Expert</MenuItem>
                        </Select>
                      </StyledFormControl>
                    ) : (
                      <Box sx={{ mb: 2 }}>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          display="block"
                        >
                          Experience Level
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {field.value || 'N/A'}
                        </Typography>
                      </Box>
                    )
                  }
                />
              </Grid>
            </Grid>
          ),
          <Psychology />
        )}

        {/* Specializations Section */}
        {renderSpecializationsSection()}

        {/* Main Save Button */}
        {editable && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <ActionButton
              type="submit"
              variant="contained"
              startIcon={
                savingSection['skills'] ? (
                  <CircularProgress size={20} />
                ) : (
                  <Save />
                )
              }
              disabled={savingSection['skills']}
              sx={{
                background: (theme) =>
                  `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                '&:hover': {
                  background: (theme) =>
                    `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`
                }
              }}
            >
              {savingSection['skills'] ? 'Saving...' : 'Save All Skills'}
            </ActionButton>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default Skills;
