import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CircularProgress,
  InputAdornment,
  styled,
  alpha,
  Fade,
  Tooltip,
  useTheme
} from '@mui/material';
import {
  Save,
  AccountBalance,
  Person,
  CreditCard,
  FormatListNumbered,
  Lock,
  Security,
  Info
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation
} from 'src/redux/@api/carer-application';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  overflow: 'visible',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0, 0, 0, 0.08)'
  }
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  '& svg': {
    fontSize: 28,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: 0,
    width: 40,
    height: 3,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.02)
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
    },
    '& .MuiInputAdornment-root': {
      '& svg': {
        color: theme.palette.primary.main,
        fontSize: 20
      }
    }
  }
}));

const SecurityNote = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  backgroundColor: alpha(theme.palette.success.main, 0.1),
  border: `1px solid ${alpha(theme.palette.success.main, 0.2)}`,
  marginTop: theme.spacing(3),
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(1)
}));

interface BankDetailsProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const BankDetails: React.FC<BankDetailsProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();
  const [saving, setSaving] = useState(false);

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {
        accountHolderName: '',
        bankName: '',
        accountNumber: '',
        sortCode: ''
      }
    : initialData || {};

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const handleSave = async (data: any) => {
    if (!editable) return;
    setSaving(true);
    try {
      await updateApplicationSection({
        section: 'bankDetails',
        data
      }).unwrap();

      await refetch();

      dispatch(
        showSnack({
          message: 'Bank details saved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: 'Failed to save bank details',
          color: 'error'
        })
      );
    } finally {
      setSaving(false);
    }
  };

  const renderTextField = (
    name: string,
    label: string,
    icon: React.ReactNode,
    rules: any = {},
    type: string = 'text',
    tooltip?: string
  ) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) =>
        editable ? (
          <Tooltip title={tooltip} arrow>
            <StyledTextField
              {...field}
              label={label}
              fullWidth
              size="small"
              type={type}
              error={!!errors[name]}
              helperText={errors[name]?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{icon}</InputAdornment>
                )
              }}
            />
          </Tooltip>
        ) : (
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
            >
              {label}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mt: 0.5,
                p: 1.5,
                borderRadius: 2,
                bgcolor: alpha(theme.palette.primary.main, 0.05)
              }}
            >
              {icon}
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {field.value || 'N/A'}
              </Typography>
            </Box>
          </Box>
        )
      }
    />
  );

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        p: { xs: 2, sm: 3, md: 4 },
        borderRadius: 4,
        boxShadow: (theme) =>
          `0 0 40px ${alpha(theme.palette.primary.main, 0.08)}`
      }}
    >
      <Fade in timeout={500}>
        <StyledCard elevation={0}>
          <CardContent sx={{ p: 4 }}>
            <SectionHeader>
              <AccountBalance />
              <SectionTitle>Bank Details</SectionTitle>
            </SectionHeader>

            <form onSubmit={handleSubmit(handleSave)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {renderTextField(
                    'accountHolderName',
                    'Account Holder Name',
                    <Person />,
                    { required: 'Account holder name is required' }
                  )}
                </Grid>

                <Grid item xs={12}>
                  {renderTextField(
                    'bankName',
                    'Bank Name',
                    <AccountBalance />,
                    { required: 'Bank name is required' }
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  {renderTextField(
                    'accountNumber',
                    'Account Number',
                    <CreditCard />,
                    {
                      required: 'Account number is required',
                      pattern: {
                        value: /^\d{8}$/,
                        message: 'Account number must be 8 digits'
                      }
                    },
                    'password',
                    'Enter your 8-digit account number'
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  {renderTextField(
                    'sortCode',
                    'Sort Code',
                    <FormatListNumbered />,
                    {
                      required: 'Sort code is required',
                      pattern: {
                        value: /^\d{2}-\d{2}-\d{2}$/,
                        message: 'Sort code must be in the format 00-00-00'
                      }
                    },
                    'text',
                    'Enter in format: 00-00-00'
                  )}
                </Grid>
              </Grid>

              <SecurityNote>
                <Lock sx={{ color: 'success.main', mt: 0.5 }} />
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="success.main"
                    gutterBottom
                  >
                    Secure Information
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Your bank details are encrypted and stored securely. This
                    information is only used for payment processing.
                  </Typography>
                </Box>
              </SecurityNote>

              {editable && (
                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={
                      saving ? <CircularProgress size={20} /> : <Save />
                    }
                    disabled={saving}
                    sx={{
                      borderRadius: 2,
                      px: 4,
                      py: 1,
                      background: (theme) =>
                        `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      '&:hover': {
                        background: (theme) =>
                          `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                        boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)'
                      }
                    }}
                  >
                    {saving ? (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <CircularProgress size={20} color="inherit" />
                        <span>Saving Changes...</span>
                      </Box>
                    ) : (
                      'Save Bank Details'
                    )}
                  </Button>
                </Box>
              )}
            </form>
          </CardContent>
        </StyledCard>
      </Fade>
    </Box>
  );
};

export default BankDetails;
