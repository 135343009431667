import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  useMediaQuery,
  styled,
  alpha,
  Tooltip,
  Fade,
  Chip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Add,
  Delete,
  CloudUpload,
  Visibility,
  Save,
  AttachFile,
  School,
  Work,
  ContactMail,
  Badge,
  Assignment,
  VerifiedUser
} from '@mui/icons-material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { apiHostname } from 'src/api/api';
import {
  useDeleteArrayItemMutation,
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation,
  useDeleteDocumentMutation
} from 'src/redux/@api/carer-application';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0, 0, 0, 0.08)'
  }
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  '& svg': {
    fontSize: 28,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: 0,
    width: 40,
    height: 3,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.02)
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
    }
  }
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.02)
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
    }
  }
}));

const UploadButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1, 3),
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1, 3),
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)'
  }
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  borderColor: alpha(theme.palette.primary.main, 0.2),
  '& .MuiChip-icon': {
    color: theme.palette.primary.main
  }
}));

// Types and interfaces remain the same
interface ProfessionalInformationProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const ProfessionalInformation: React.FC<ProfessionalInformationProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const {
    data: carerApplication,
    isLoading: isCarerApplicationLoading,
    refetch
  } = useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();
  const [deleteArrayItem] = useDeleteArrayItemMutation();
  const [deleteDocument] = useDeleteDocumentMutation();

  const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File }>(
    {}
  );
  const [uploadingDocument, setUploadingDocument] = useState<{
    [key: string]: boolean;
  }>({});
  const [savingSection, setSavingSection] = useState<{
    [key: string]: boolean;
  }>({});
  const [savedSections, setSavedSections] = useState<{
    [key: string]: boolean;
  }>({});
  const [dbsFile, setDbsFile] = useState<File | null>(null);
  const [uploadingDbs, setUploadingDbs] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    section: string;
    index: number;
  } | null>(null);

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {}
    : initialData || {};

  const { control, handleSubmit, reset, watch, getValues } = useForm({
    defaultValues
  });

  // Field Arrays
  const qualificationsArray = useFieldArray({
    control,
    name: 'qualifications'
  });
  const trainingsArray = useFieldArray({ control, name: 'trainings' });
  const workExperienceArray = useFieldArray({
    control,
    name: 'workExperience'
  });
  const referencesArray = useFieldArray({ control, name: 'references' });
  const professionalRegistrationsArray = useFieldArray({
    name: 'professionalRegistrations',
    control
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  // File handling functions
  const handleFileSelect =
    (section: string, index: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setSelectedFiles((prev) => ({
          ...prev,
          [`${section}.${index}`]: file
        }));
      }
    };

  const handleDbsFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setDbsFile(file);
    }
  };

  const handleFileUpload = async (section: string, index?: number) => {
    const key = `${section}${index !== undefined ? `.${index}` : ''}`;
    const file = selectedFiles[key];
    if (file) {
      setUploadingDocument((prev) => ({ ...prev, [key]: true }));
      const formData = new FormData();
      formData.append('file', file);
      formData.append('section', `professionalInfo.${section}`);
      formData.append('documentType', section);
      if (index !== undefined) {
        formData.append('index', index.toString());
      }

      try {
        dispatch(
          showSnack({
            message: `Uploading ${section} document...`,
            color: 'info'
          })
        );
        const response = await axios.post(
          `${apiHostname}/api/v1/application/upload-document`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          dispatch(
            showSnack({
              message: `${section} document uploaded successfully`,
              color: 'success'
            })
          );
          await refetch();
        } else {
          throw new Error(response.data.error || 'Failed to upload document');
        }
      } catch (error: any) {
        console.error('Upload failed:', error);
        dispatch(
          showSnack({
            message: `Failed to upload ${section} document: ${error.message}`,
            color: 'error'
          })
        );
      } finally {
        setUploadingDocument((prev) => ({ ...prev, [key]: false }));
        setSelectedFiles((prev) => {
          const newFiles = { ...prev };
          delete newFiles[key];
          return newFiles;
        });
      }
    }
  };

  const handleDbsFileUpload = async () => {
    if (dbsFile) {
      setUploadingDbs(true);
      const formData = new FormData();
      formData.append('file', dbsFile);
      formData.append('section', 'professionalInfo.dbsCheck');
      formData.append('documentType', 'dbsCheck');

      try {
        const response = await axios.post(
          `${apiHostname}/api/v1/application/upload-document`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          dispatch(
            showSnack({
              message: 'DBS Check document uploaded successfully',
              color: 'success'
            })
          );
          await refetch();
        } else {
          throw new Error('Failed to upload DBS Check document');
        }
      } catch (error: any) {
        console.error('DBS Check upload failed:', error);
        dispatch(
          showSnack({
            message: `Failed to upload DBS Check document: ${error.message}`,
            color: 'error'
          })
        );
      } finally {
        setUploadingDbs(false);
        setDbsFile(null);
      }
    }
  };

  const handleDeleteDocument = async (section: string, index?: number) => {
    try {
      await deleteDocument({ section, index });
      await refetch();
      dispatch(
        showSnack({
          message: 'Document deleted successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Delete document failed:', error);
      dispatch(
        showSnack({ message: 'Failed to delete document', color: 'error' })
      );
    }
  };

  const handleDeleteItem = async (section: string, index: number) => {
    setItemToDelete({ section, index });
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    if (!itemToDelete) return;
    const { section, index } = itemToDelete;

    try {
      await deleteArrayItem({ section: `professionalInfo.${section}`, index });
      await refetch();
      dispatch(
        showSnack({
          message: `${section} item deleted successfully`,
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Delete failed:', error);
      dispatch(
        showSnack({
          message: `Failed to delete ${section} item`,
          color: 'error'
        })
      );
    } finally {
      setDeleteConfirmOpen(false);
      setItemToDelete(null);
    }
  };

  // ... (previous code remains the same)

  const renderTextField = (name: string, label: string) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        editable ? (
          <StyledTextField
            {...field}
            label={label}
            fullWidth
            variant="outlined"
            size="small"
            sx={{ mb: 1 }}
          />
        ) : (
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
            >
              {label}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {field.value || 'N/A'}
            </Typography>
          </Box>
        )
      }
    />
  );

  const renderDateField = (name: string, label: string) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        editable ? (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              {...field}
              label={label}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  fullWidth
                  size="small"
                  sx={{ mb: 1 }}
                />
              )}
            />
          </LocalizationProvider>
        ) : (
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
            >
              {label}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {field.value ? new Date(field.value).toLocaleDateString() : 'N/A'}
            </Typography>
          </Box>
        )
      }
    />
  );

  const renderArraySection = (
    title: string,
    fieldsArray: any,
    section: string,
    renderFields: (index: number) => React.ReactNode,
    icon: React.ReactNode
  ) => (
    <Box sx={{ mb: 4 }}>
      <SectionHeader>
        {icon}
        <SectionTitle>{title}</SectionTitle>
      </SectionHeader>

      {fieldsArray.fields.map((field: any, index: number) => {
        const isSaving = savingSection[`${section}.${index}`];
        const isSaved = savedSections[`${section}.${index}`];

        return (
          <Fade in timeout={300} key={field.id}>
            <StyledCard
              sx={{
                mb: 2,
                position: 'relative',
                '&::before': isSaved
                  ? {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: '4px',
                      background: (theme) =>
                        `linear-gradient(to right, ${theme.palette.success.main}, ${theme.palette.success.light})`,
                      borderTopLeftRadius: '16px',
                      borderTopRightRadius: '16px'
                    }
                  : {}
              }}
            >
              <Box sx={{ p: 3 }}>
                {renderFields(index)}
                {renderFileUpload(section, index)}

                {editable && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      pt: 2,
                      borderTop: 1,
                      borderColor: 'divider'
                    }}
                  >
                    <Button
                      onClick={() => handleDeleteItem(section, index)}
                      startIcon={<Delete />}
                      color="error"
                      sx={{
                        '&:hover': {
                          backgroundColor: (theme) =>
                            alpha(theme.palette.error.main, 0.1)
                        }
                      }}
                    >
                      Remove
                    </Button>
                    <Button
                      onClick={() => handleSaveSection(section, index)}
                      startIcon={
                        isSaving ? <CircularProgress size={20} /> : <Save />
                      }
                      variant="contained"
                      disabled={isSaving}
                      sx={{
                        background: (theme) =>
                          `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                        '&:hover': {
                          background: (theme) =>
                            `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`
                        }
                      }}
                    >
                      {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                  </Box>
                )}
              </Box>
            </StyledCard>
          </Fade>
        );
      })}

      {editable && (
        <Button
          onClick={() => {
            const lastIndex = fieldsArray.fields.length - 1;
            if (lastIndex >= 0 && selectedFiles[`${section}.${lastIndex}`]) {
              handleSaveSection(section, lastIndex).then(() => {
                fieldsArray.append({});
                refetch();
              });
            } else {
              fieldsArray.append({});
            }
          }}
          startIcon={<Add />}
          variant="outlined"
          fullWidth
          sx={{
            mt: 2,
            borderStyle: 'dashed',
            borderWidth: 2,
            '&:hover': {
              borderStyle: 'dashed',
              borderWidth: 2,
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.05)
            }
          }}
        >
          Add {title}
        </Button>
      )}
    </Box>
  );

  const renderFileUpload = (section: string, index?: number) => {
    const key = `${section}${index !== undefined ? `.${index}` : ''}`;
    const fileName =
      index !== undefined
        ? watch(`${section}.${index}.fileName`)
        : watch(`${section}.fileName`);
    const uploadUrl =
      index !== undefined
        ? watch(`${section}.${index}.uploadUrl`)
        : watch(`${section}.uploadUrl`);
    const selectedFile = selectedFiles[key];
    const isUploading = uploadingDocument[key];

    if (isCarerApplicationLoading) {
      return <CircularProgress size={24} />;
    }

    return (
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 1
        }}
      >
        {editable && (
          <Box>
            <input
              accept="image/*,.pdf"
              id={`${section}-upload-${index ?? ''}`}
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  setSelectedFiles((prev) => ({
                    ...prev,
                    [key]: file
                  }));
                }
              }}
            />
            <Button
              component="label"
              htmlFor={`${section}-upload-${index ?? ''}`}
              startIcon={<CloudUpload />}
              disabled={isUploading}
              variant="outlined"
              sx={{
                borderRadius: '8px',
                p: '8px 16px',
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.1),
                color: 'primary.main',
                border: 'none',
                '&:hover': {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.2)
                }
              }}
            >
              {selectedFile
                ? selectedFile.name
                : uploadUrl
                ? 'Replace File'
                : 'Upload File'}
            </Button>
          </Box>
        )}

        {isUploading && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CircularProgress size={20} />
            <Typography variant="body2" color="text.secondary">
              Uploading...
            </Typography>
          </Box>
        )}

        {uploadUrl && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <StyledChip
              icon={<AttachFile />}
              label={fileName || `${section} Document`}
              variant="outlined"
              onDelete={
                editable
                  ? () =>
                      handleDeleteDocument(`professionalInfo.${section}`, index)
                  : undefined
              }
            />
            <Tooltip title="View uploaded file">
              <IconButton
                onClick={() => window.open(uploadUrl, '_blank')}
                sx={{
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: (theme) =>
                      alpha(theme.palette.primary.main, 0.1)
                  }
                }}
                size="small"
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    );
  };

  const handleSaveSection = async (section: string, index?: number) => {
    const key = `${section}${index !== undefined ? `.${index}` : ''}`;
    setSavingSection((prev) => ({ ...prev, [key]: true }));

    try {
      let data;
      if (section === 'dbsCheck') {
        data = getValues('dbsCheck');
      } else {
        data =
          index !== undefined ? getValues(section)[index] : getValues(section);
      }

      await updateApplicationSection({
        section: `professionalInfo.${section}`,
        data,
        index
      }).unwrap();

      if (section === 'dbsCheck' && dbsFile) {
        await handleDbsFileUpload();
      } else if (selectedFiles[key]) {
        await handleFileUpload(section, index);
      }

      setSavedSections((prev) => ({ ...prev, [key]: true }));
      await refetch();

      dispatch(
        showSnack({
          message: `${section} information saved successfully`,
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: `Failed to save ${section} information`,
          color: 'error'
        })
      );
    } finally {
      setSavingSection((prev) => ({ ...prev, [key]: false }));

      // Reset saved section status after 3 seconds
      setTimeout(() => {
        setSavedSections((prev) => ({ ...prev, [key]: false }));
      }, 3000);
    }
  };

  const renderDbsSection = () => {
    const dbsDetails = watch('dbsCheck');
    const isSaving = savingSection['dbsCheck'];

    return (
      <Box sx={{ mb: 4 }}>
        <SectionHeader>
          <VerifiedUser />
          <SectionTitle>DBS Check</SectionTitle>
        </SectionHeader>

        <Fade in timeout={300}>
          <StyledCard>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  {renderTextField(
                    'dbsCheck.certificateNumber',
                    'DBS Certificate Number'
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {renderDateField('dbsCheck.issueDate', 'Issue Date')}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="dbsCheck.status"
                    control={control}
                    render={({ field }) =>
                      editable ? (
                        <StyledFormControl size="small" fullWidth>
                          <InputLabel>Status</InputLabel>
                          <Select {...field} label="Status" size="small">
                            <MenuItem value="Clear">Clear</MenuItem>
                            <MenuItem value="Not Clear">Not Clear</MenuItem>
                            <MenuItem value="Pending">Pending</MenuItem>
                            <MenuItem value="Not Applicable">
                              Not Applicable
                            </MenuItem>
                          </Select>
                        </StyledFormControl>
                      ) : (
                        <Box sx={{ mb: 2 }}>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            display="block"
                          >
                            Status
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {field.value || 'N/A'}
                          </Typography>
                        </Box>
                      )
                    }
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  DBS Certificate
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {editable && (
                    <Box>
                      <input
                        accept="image/*,.pdf"
                        id="dbs-certificate-upload"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleDbsFileSelect}
                      />
                      <Button
                        component="label"
                        htmlFor="dbs-certificate-upload"
                        startIcon={<CloudUpload />}
                        disabled={uploadingDbs}
                        variant="outlined"
                        sx={{
                          borderRadius: '8px',
                          p: '8px 16px',
                          backgroundColor: (theme) =>
                            alpha(theme.palette.primary.main, 0.1),
                          color: 'primary.main',
                          border: 'none',
                          '&:hover': {
                            backgroundColor: (theme) =>
                              alpha(theme.palette.primary.main, 0.2)
                          }
                        }}
                      >
                        {dbsFile
                          ? dbsFile.name
                          : dbsDetails?.uploadUrl
                          ? 'Replace Certificate'
                          : 'Upload Certificate'}
                      </Button>
                    </Box>
                  )}

                  {uploadingDbs && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CircularProgress size={20} />
                      <Typography variant="body2" color="text.secondary">
                        Uploading...
                      </Typography>
                    </Box>
                  )}

                  {dbsDetails?.uploadUrl && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <StyledChip
                        icon={<AttachFile />}
                        label="DBS Certificate"
                        variant="outlined"
                        onDelete={
                          editable
                            ? () =>
                                handleDeleteDocument(
                                  'professionalInfo.dbsCheck'
                                )
                            : undefined
                        }
                      />
                      <Tooltip title="View Certificate">
                        <IconButton
                          onClick={() =>
                            window.open(dbsDetails.uploadUrl, '_blank')
                          }
                          size="small"
                          sx={{
                            color: 'primary.main',
                            '&:hover': {
                              backgroundColor: (theme) =>
                                alpha(theme.palette.primary.main, 0.1)
                            }
                          }}
                        >
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Box>

              {editable && (
                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}
                >
                  <Button
                    onClick={() => handleSaveSection('dbsCheck', undefined)}
                    startIcon={
                      isSaving ? <CircularProgress size={20} /> : <Save />
                    }
                    variant="contained"
                    disabled={isSaving}
                    sx={{
                      background: (theme) =>
                        `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                      '&:hover': {
                        background: (theme) =>
                          `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`
                      }
                    }}
                  >
                    {isSaving ? 'Saving...' : 'Save DBS Check'}
                  </Button>
                </Box>
              )}
            </Box>
          </StyledCard>
        </Fade>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        minHeight: '100vh',
        p: { xs: 2, sm: 3, md: 4 },
        borderRadius: 4,
        boxShadow: (theme) =>
          `0 0 40px ${alpha(theme.palette.primary.main, 0.08)}`
      }}
    >
      {/* Qualifications Section */}
      {renderArraySection(
        'Qualifications',
        qualificationsArray,
        'qualifications',
        (index: number) => (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {renderTextField(
                `qualifications.${index}.name`,
                'Qualification Name'
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`qualifications.${index}.level`, 'Level')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(
                `qualifications.${index}.institution`,
                'Institution'
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDateField(
                `qualifications.${index}.dateObtained`,
                'Date Obtained'
              )}
            </Grid>
          </Grid>
        ),
        <School />
      )}

      {/* Trainings Section */}
      {renderArraySection(
        'Trainings',
        trainingsArray,
        'trainings',
        (index: number) => (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {renderTextField(`trainings.${index}.name`, 'Training Name')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`trainings.${index}.provider`, 'Provider')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDateField(
                `trainings.${index}.dateCompleted`,
                'Date Completed'
              )}
            </Grid>
          </Grid>
        ),
        <Assignment />
      )}

      {/* Work Experience Section */}
      {renderArraySection(
        'Work Experience',
        workExperienceArray,
        'workExperience',
        (index: number) => (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {renderTextField(`workExperience.${index}.jobTitle`, 'Job Title')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`workExperience.${index}.employer`, 'Employer')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDateField(
                `workExperience.${index}.startDate`,
                'Start Date'
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDateField(`workExperience.${index}.endDate`, 'End Date')}
            </Grid>
          </Grid>
        ),
        <Work />
      )}

      {/* References Section */}
      {renderArraySection(
        'References',
        referencesArray,
        'references',
        (index: number) => (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {renderTextField(`references.${index}.name`, 'Name')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`references.${index}.position`, 'Position')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`references.${index}.company`, 'Company')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`references.${index}.email`, 'Email')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(`references.${index}.phone`, 'Phone')}
            </Grid>
          </Grid>
        ),
        <ContactMail />
      )}

      {/* Professional Registrations Section */}
      {renderArraySection(
        'Professional Registrations',
        professionalRegistrationsArray,
        'professionalRegistrations',
        (index: number) => (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Controller
                name={`professionalRegistrations.${index}.type`}
                control={control}
                render={({ field }) =>
                  editable ? (
                    <StyledFormControl fullWidth>
                      <InputLabel>Type</InputLabel>
                      <Select {...field} label="Type" size="small">
                        <MenuItem value="NMC">NMC</MenuItem>
                        <MenuItem value="HCPC">HCPC</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </StyledFormControl>
                  ) : (
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        display="block"
                      >
                        Type
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {field.value || 'N/A'}
                      </Typography>
                    </Box>
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderTextField(
                `professionalRegistrations.${index}.registrationNumber`,
                'Registration Number'
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDateField(
                `professionalRegistrations.${index}.expiryDate`,
                'Expiry Date'
              )}
            </Grid>
          </Grid>
        ),
        <Badge />
      )}

      {/* DBS Check Section */}
      {renderDbsSection()}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 1
          }
        }}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteConfirmOpen(false)}
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            color="error"
            variant="contained"
            sx={{
              bgcolor: 'error.main',
              '&:hover': {
                bgcolor: 'error.dark'
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfessionalInformation;
