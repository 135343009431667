import { useContext } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';
import { useGetUnreadNotificationCountQuery } from 'src/redux/@api/notifications';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height} !important;
        @media (max-width: ${theme.breakpoints.values.sm}px) {
            height: ${theme.header.height};
        };
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 0)};
        right: 0;
        z-index: 6;
        position: fixed;
        padding-block:20px;
        justify-content: space-between;
        width: 100%;
        padding-top: env(safe-area-inset-top);
        height: calc(your_header_height + env(safe-area-inset-top));
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  return (
    <HeaderWrapper display="flex" alignItems="center" sx={{}}>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      ></Stack>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={'space-between'}
        width={'100%'}
        py={2}
        sx={{
          justifyContent: {
            xs: 'space-between',
            lg: 'flex-end'
          }
        }}
      >
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'flex' },
            alignItems: 'center'
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton
              color="primary"
              onClick={toggleSidebar}
              sx={{
                padding: '0px !important'
              }}
            >
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          <img
            src={require('src/assets/logos/logo_header.png')}
            style={{
              width: '100px',
              height: 'auto',
              cursor: 'pointer',
              marginBlock: 'auto',
              paddingTop: '5px',
              marginLeft: '10px'
            }}
            alt="logo of sitemark"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <HeaderButtons />
          <HeaderUserbox />
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
