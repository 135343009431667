// styles.ts
import { styled } from '@mui/material/styles';
import { Box, Paper, Avatar } from '@mui/material';

interface DayCellProps {
  isCurrentMonth: boolean;
  isToday: boolean;
}

export const CalendarContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5)
  },
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5]
}));

export const DayCell = styled(Paper)<DayCellProps>(
  ({ theme, isCurrentMonth, isToday }) => ({
    height: '120px',
    padding: theme.spacing(1),
    display: isCurrentMonth ? 'flex' : 'none',
    flexDirection: 'column',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.default,
    opacity: isCurrentMonth ? 1 : 0.5,
    border: isToday ? `2px solid ${theme.palette.primary.main}` : 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    [theme.breakpoints.down('sm')]: {
      height: '2.5rem',
      width: '2.5rem',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);

export const ShiftAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  fontSize: '0.75rem',
  marginRight: -8
}));

export const ShiftIndicator = styled(Box)(({ theme }) => ({}));

export const StyledDialog = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5]
}));
