import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Chip,
  IconButton,
  Stack,
  Divider,
  Alert,
  Grid,
  Tooltip,
  styled,
  Checkbox,
  FormGroup
} from '@mui/material';
import {
  Close as CloseIcon,
  Info as InfoIcon,
  Bathtub,
  Thermostat,
  AccessibilityNew,
  Assignment,
  Timer
} from '@mui/icons-material';

interface BathingData {
  // Basic Information
  bathingType: 'full_bath' | 'shower' | 'bed_bath' | 'strip_wash';
  bathingPreference: string;
  waterTemperature: 'warm' | 'hot' | 'cool';
  temperatureChecked: boolean;

  // Assistance Details
  assistanceLevel: 'independent' | 'minimal' | 'moderate' | 'full';
  caregiverCount: number;
  equipmentUsed: string[];
  timeSpent: number;

  // Care Details
  bodyPartsWashed: string[];
  hairWashed: boolean;
  hairCareProducts: string[];
  skinCareProducts: string[];
  oralCare: boolean;
  nailCare: boolean;
  shavingCompleted: boolean;

  // Skin Assessment
  skinAssessment: {
    condition: string;
    concerns: string[];
    pressureAreas: string[];
    bruising: boolean;
    rashes: boolean;
  };

  // Mobility & Safety
  mobilityLevel: string;
  transferMethod: string;
  fallRisk: boolean;
  safetyMeasures: string[];

  // Resident Response
  residentMood: string;
  cooperation: 'cooperative' | 'resistant' | 'agitated' | 'calm';
  painReported: boolean;
  painLocation?: string;
  painLevel?: number;

  // Completion Details
  clothingChanged: boolean;
  roomCleaned: boolean;
  linensChanged: boolean;
  completionTime: string;
  concerns: boolean;
  notes: string;
}

interface BathingDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: BathingData) => void;
  task: any;
  initialData?: Partial<BathingData>;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2)
  }
}));

const SectionBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper
}));

const bodyParts = [
  'Face',
  'Neck',
  'Arms',
  'Hands',
  'Chest',
  'Back',
  'Abdomen',
  'Legs',
  'Feet',
  'Perineal Area'
];

const skinConcerns = [
  'Dryness',
  'Redness',
  'Swelling',
  'Bruising',
  'Breaks',
  'Rashes',
  'Warmth',
  'Discoloration'
];

const pressurePoints = [
  'Sacrum',
  'Heels',
  'Elbows',
  'Shoulders',
  'Hips',
  'Ankles',
  'Other'
];

const equipment = [
  'Bath Lift',
  'Shower Chair',
  'Grab Rails',
  'Sliding Sheet',
  'Hoist',
  'Bath Board',
  'Non-slip Mat'
];

const safetyMeasures = [
  'Non-slip Mat Used',
  'Water Temperature Checked',
  'Call Bell Within Reach',
  'Door Privacy Maintained',
  'Equipment Checked Before Use',
  'Clear Path Maintained'
];

const BathingDialog: React.FC<BathingDialogProps> = ({
  open,
  onClose,
  onResolve,
  task,
  initialData
}) => {
  const [formData, setFormData] = useState<BathingData>({
    bathingType: 'full_bath',
    bathingPreference: '',
    waterTemperature: 'warm',
    temperatureChecked: false,
    assistanceLevel: 'minimal',
    caregiverCount: 1,
    equipmentUsed: [],
    timeSpent: 0,
    bodyPartsWashed: [],
    hairWashed: false,
    hairCareProducts: [],
    skinCareProducts: [],
    oralCare: false,
    nailCare: false,
    shavingCompleted: false,
    skinAssessment: {
      condition: 'normal',
      concerns: [],
      pressureAreas: [],
      bruising: false,
      rashes: false
    },
    mobilityLevel: '',
    transferMethod: '',
    fallRisk: false,
    safetyMeasures: [],
    residentMood: '',
    cooperation: 'cooperative',
    painReported: false,
    completionTime: new Date().toISOString(),
    concerns: false,
    notes: '',
    clothingChanged: false,
    roomCleaned: false,
    linensChanged: false
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setFormData((prev) => ({ ...prev, ...initialData }));
    }
  }, [initialData]);

  const handleChange = (field: keyof BathingData, value: any) => {
    setFormData((prev) => {
      if (field === 'painReported' && !value) {
        // Clear pain-related fields when pain is not reported
        const newData = { ...prev, [field]: value };
        delete newData.painLocation;
        delete newData.painLevel;
        return newData;
      }
      return { ...prev, [field]: value };
    });
  };

  const handleSkinAssessmentChange = (
    field: keyof BathingData['skinAssessment'],
    value: any
  ) => {
    setFormData((prev) => ({
      ...prev,
      skinAssessment: {
        ...prev.skinAssessment,
        [field]: value
      }
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.bodyPartsWashed.length) {
      setError('Please select body parts that were washed');
      return false;
    }

    if (formData.fallRisk && !formData.safetyMeasures.length) {
      setError('Please select safety measures for fall risk resident');
      return false;
    }

    if (
      formData.painReported &&
      (!formData.painLocation || !formData.painLevel)
    ) {
      setError('Please provide pain details');
      return false;
    }

    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onResolve(formData);
      onClose();
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <Bathtub color="primary" />
            <Typography variant="h6">Bathing Care Record</Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          {/* Basic Information */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Basic Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Bathing Type</InputLabel>
                  <Select
                    value={formData.bathingType}
                    onChange={(e) =>
                      handleChange('bathingType', e.target.value)
                    }
                  >
                    <MenuItem value="full_bath">Full Bath</MenuItem>
                    <MenuItem value="shower">Shower</MenuItem>
                    <MenuItem value="bed_bath">Bed Bath</MenuItem>
                    <MenuItem value="strip_wash">Strip Wash</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Water Temperature</InputLabel>
                  <Select
                    value={formData.waterTemperature}
                    onChange={(e) =>
                      handleChange('waterTemperature', e.target.value)
                    }
                  >
                    <MenuItem value="warm">Warm</MenuItem>
                    <MenuItem value="hot">Hot</MenuItem>
                    <MenuItem value="cool">Cool</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.temperatureChecked}
                      onChange={(e) =>
                        handleChange('temperatureChecked', e.target.checked)
                      }
                    />
                  }
                  label="Temperature Checked"
                />
              </Grid>
            </Grid>
          </SectionBox>

          {/* Assistance Details */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Assistance & Equipment
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Assistance Level</InputLabel>
                  <Select
                    value={formData.assistanceLevel}
                    onChange={(e) =>
                      handleChange('assistanceLevel', e.target.value)
                    }
                  >
                    <MenuItem value="independent">Independent</MenuItem>
                    <MenuItem value="minimal">Minimal Assistance</MenuItem>
                    <MenuItem value="moderate">Moderate Assistance</MenuItem>
                    <MenuItem value="full">Full Assistance</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Number of Caregivers"
                  size="small"
                  value={formData.caregiverCount}
                  onChange={(e) =>
                    handleChange('caregiverCount', parseInt(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 1, max: 4 } }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Equipment Used
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {equipment.map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      onClick={() => {
                        const newEquipment = formData.equipmentUsed.includes(
                          item
                        )
                          ? formData.equipmentUsed.filter((e) => e !== item)
                          : [...formData.equipmentUsed, item];
                        handleChange('equipmentUsed', newEquipment);
                      }}
                      color={
                        formData.equipmentUsed.includes(item)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.equipmentUsed.includes(item)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Time Spent (minutes)"
                  size="small"
                  value={formData.timeSpent}
                  onChange={(e) =>
                    handleChange('timeSpent', parseInt(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>
            </Grid>
          </SectionBox>

          {/* Care Details */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Care Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Body Parts Washed
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {bodyParts.map((part) => (
                    <Chip
                      key={part}
                      label={part}
                      onClick={() => {
                        const newParts = formData.bodyPartsWashed.includes(part)
                          ? formData.bodyPartsWashed.filter((p) => p !== part)
                          : [...formData.bodyPartsWashed, part];
                        handleChange('bodyPartsWashed', newParts);
                      }}
                      color={
                        formData.bodyPartsWashed.includes(part)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.bodyPartsWashed.includes(part)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.hairWashed}
                      onChange={(e) =>
                        handleChange('hairWashed', e.target.checked)
                      }
                    />
                  }
                  label="Hair Washed"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.oralCare}
                      onChange={(e) =>
                        handleChange('oralCare', e.target.checked)
                      }
                    />
                  }
                  label="Oral Care"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.nailCare}
                      onChange={(e) =>
                        handleChange('nailCare', e.target.checked)
                      }
                    />
                  }
                  label="Nail Care"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.shavingCompleted}
                      onChange={(e) =>
                        handleChange('shavingCompleted', e.target.checked)
                      }
                    />
                  }
                  label="Shaving Completed"
                />
              </Grid>
            </Grid>
          </SectionBox>

          {/* Skin Assessment */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Skin Assessment
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Skin Concerns
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {skinConcerns.map((concern) => (
                    <Chip
                      key={concern}
                      label={concern}
                      onClick={() => {
                        const newConcerns =
                          formData.skinAssessment.concerns.includes(concern)
                            ? formData.skinAssessment.concerns.filter(
                                (c) => c !== concern
                              )
                            : [...formData.skinAssessment.concerns, concern];
                        handleSkinAssessmentChange('concerns', newConcerns);
                      }}
                      color={
                        formData.skinAssessment.concerns.includes(concern)
                          ? 'error'
                          : 'default'
                      }
                      variant={
                        formData.skinAssessment.concerns.includes(concern)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Pressure Areas
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {pressurePoints.map((area) => (
                    <Chip
                      key={area}
                      label={area}
                      onClick={() => {
                        const newAreas =
                          formData.skinAssessment.pressureAreas.includes(area)
                            ? formData.skinAssessment.pressureAreas.filter(
                                (a) => a !== area
                              )
                            : [...formData.skinAssessment.pressureAreas, area];
                        handleSkinAssessmentChange('pressureAreas', newAreas);
                      }}
                      color={
                        formData.skinAssessment.pressureAreas.includes(area)
                          ? 'warning'
                          : 'default'
                      }
                      variant={
                        formData.skinAssessment.pressureAreas.includes(area)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Overall Skin Condition</InputLabel>
                  <Select
                    value={formData.skinAssessment.condition}
                    onChange={(e) =>
                      handleSkinAssessmentChange('condition', e.target.value)
                    }
                  >
                    <MenuItem value="normal">Normal</MenuItem>
                    <MenuItem value="dry">Dry</MenuItem>
                    <MenuItem value="fragile">Fragile</MenuItem>
                    <MenuItem value="broken">Broken</MenuItem>
                    <MenuItem value="requires_attention">
                      Requires Attention
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </SectionBox>

          {/* Mobility & Safety */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Mobility & Safety
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Mobility Level</InputLabel>
                  <Select
                    value={formData.mobilityLevel}
                    onChange={(e) =>
                      handleChange('mobilityLevel', e.target.value)
                    }
                  >
                    <MenuItem value="independent">Independent</MenuItem>
                    <MenuItem value="walks_with_aid">Walks with Aid</MenuItem>
                    <MenuItem value="wheelchair">Wheelchair User</MenuItem>
                    <MenuItem value="bed_bound">Bed Bound</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Transfer Method</InputLabel>
                  <Select
                    value={formData.transferMethod}
                    onChange={(e) =>
                      handleChange('transferMethod', e.target.value)
                    }
                  >
                    <MenuItem value="independent">Independent</MenuItem>
                    <MenuItem value="one_person">One Person Assist</MenuItem>
                    <MenuItem value="two_person">Two Person Assist</MenuItem>
                    <MenuItem value="hoist">Hoist Transfer</MenuItem>
                    <MenuItem value="standing_aid">Standing Aid</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.fallRisk}
                      onChange={(e) =>
                        handleChange('fallRisk', e.target.checked)
                      }
                      color="warning"
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        color={formData.fallRisk ? 'warning.main' : 'inherit'}
                      >
                        Fall Risk
                      </Typography>
                      {formData.fallRisk && (
                        <Tooltip title="Additional safety measures required">
                          <InfoIcon color="warning" fontSize="small" />
                        </Tooltip>
                      )}
                    </Box>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Safety Measures
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {safetyMeasures.map((measure) => (
                    <Chip
                      key={measure}
                      label={measure}
                      onClick={() => {
                        const newMeasures = formData.safetyMeasures.includes(
                          measure
                        )
                          ? formData.safetyMeasures.filter((m) => m !== measure)
                          : [...formData.safetyMeasures, measure];
                        handleChange('safetyMeasures', newMeasures);
                      }}
                      color={
                        formData.safetyMeasures.includes(measure)
                          ? 'success'
                          : 'default'
                      }
                      variant={
                        formData.safetyMeasures.includes(measure)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </SectionBox>

          {/* Resident Response */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Resident Response
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Cooperation Level</InputLabel>
                  <Select
                    value={formData.cooperation}
                    onChange={(e) =>
                      handleChange('cooperation', e.target.value)
                    }
                  >
                    <MenuItem value="cooperative">Cooperative</MenuItem>
                    <MenuItem value="resistant">Resistant</MenuItem>
                    <MenuItem value="agitated">Agitated</MenuItem>
                    <MenuItem value="calm">Calm</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Resident Mood</InputLabel>
                  <Select
                    value={formData.residentMood}
                    onChange={(e) =>
                      handleChange('residentMood', e.target.value)
                    }
                  >
                    <MenuItem value="happy">Happy</MenuItem>
                    <MenuItem value="content">Content</MenuItem>
                    <MenuItem value="anxious">Anxious</MenuItem>
                    <MenuItem value="distressed">Distressed</MenuItem>
                    <MenuItem value="angry">Angry</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.painReported}
                      onChange={(e) =>
                        handleChange('painReported', e.target.checked)
                      }
                      color="error"
                    />
                  }
                  label="Pain Reported"
                />
              </Grid>

              {formData.painReported && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Pain Location"
                      value={formData.painLocation || ''}
                      onChange={(e) =>
                        handleChange('painLocation', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      size="small"
                      label="Pain Level (0-10)"
                      value={formData.painLevel || ''}
                      onChange={(e) =>
                        handleChange('painLevel', parseInt(e.target.value))
                      }
                      InputProps={{ inputProps: { min: 0, max: 10 } }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </SectionBox>

          {/* Completion Details */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Completion Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.clothingChanged}
                      onChange={(e) =>
                        handleChange('clothingChanged', e.target.checked)
                      }
                    />
                  }
                  label="Clothing Changed"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.roomCleaned}
                      onChange={(e) =>
                        handleChange('roomCleaned', e.target.checked)
                      }
                    />
                  }
                  label="Room Cleaned"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.linensChanged}
                      onChange={(e) =>
                        handleChange('linensChanged', e.target.checked)
                      }
                    />
                  }
                  label="Linens Changed"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Additional Notes"
                  value={formData.notes}
                  onChange={(e) => handleChange('notes', e.target.value)}
                  placeholder="Enter any additional observations, concerns, or follow-up needed..."
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.concerns}
                      onChange={(e) =>
                        handleChange('concerns', e.target.checked)
                      }
                      color="error"
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        color={formData.concerns ? 'error' : 'inherit'}
                      >
                        Requires Follow-up
                      </Typography>
                      {formData.concerns && (
                        <Tooltip title="Will be flagged for review">
                          <InfoIcon color="error" fontSize="small" />
                        </Tooltip>
                      )}
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </SectionBox>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color={formData.concerns ? 'error' : 'primary'}
        >
          {formData.concerns ? 'Save & Flag for Review' : 'Save Record'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default BathingDialog;
