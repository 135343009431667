import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Chip,
  Divider,
  Alert,
  styled,
  Tooltip
} from '@mui/material';
import {
  Opacity,
  Warning,
  AccessibilityNew,
  SentimentSatisfiedAlt,
  LocalLaundryService,
  Thermostat,
  Timer,
  Person,
  RecordVoiceOver
} from '@mui/icons-material';
import { ITask } from 'src/interfaces/new/tasks';

interface PadCheckData {
  stoolType: string;
  stoolColor: string;
  stoolAmount: string;
  urineType: string;
  urineColor: string;
  urineAmount: string;
  careMethod: string;
  slidingSheetUsed: boolean;
  numberOfCarers: number;
  timeSpent: number;
  skinCondition: string[];
  skinColor: string;
  skinTemperature: string;
  pressureAreas: string[];
  cleaningMethod: string;
  cleaningProducts: string[];
  barierCream: boolean;
  padType: string;
  padSize: string;
  clothingChanged: boolean;
  clothingType: string;
  clothingCondition: string;
  mobilityLevel: string;
  residentMood: string;
  painLevel: number;
  timestamp: string;
}

interface PadCheckReportProps {
  data: PadCheckData | any;
  task?: Partial<ITask>;
}

const ReportSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& + &': {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const StoolIcon = styled('span')({
  fontSize: '24px',
  lineHeight: 1
});

const PadCheckReport: React.FC<PadCheckReportProps> = ({ data, task }) => {
  const getStoolTypeIcon = (type: string) => {
    switch (type) {
      case '1':
        return '💩';
      case '2':
        return '💩';
      case '3':
        return '💩';
      case '4':
        return '💩';
      case '5':
        return '💩';
      case '6':
        return '💩';
      case '7':
        return '💧';
      default:
        return '⚪';
    }
  };

  const hasUrgentConcerns = () => {
    return (
      data.painLevel > 5 ||
      data.pressureAreas.length > 0 ||
      data.skinCondition.some((condition) =>
        ['broken', 'red', 'inflamed'].includes(condition.toLowerCase())
      )
    );
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      {/* Header */}
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <Opacity color="primary" fontSize="large" />
        <Box>
          <Typography variant="h6">Pad Check Record</Typography>
          <Typography variant="caption" color="textSecondary">
            {new Date(task?.completedDate).toLocaleString()}
          </Typography>
          {/* completed by */}
          <Box display="flex" alignItems="center" gap={1} mt={1}>
            <RecordVoiceOver />
            <Typography variant="body2">
              Completed by: {task.completedBy?.firstName}{' '}
              {task.completedBy?.lastName}
            </Typography>

            {/* role */}
            <Tooltip title={task.completedBy?.role}>
              <Chip
                label={task.completedBy?.role}
                size="small"
                color="primary"
              />
            </Tooltip>
          </Box>
        </Box>
        <Box flexGrow={1} />
        {hasUrgentConcerns() && (
          <Chip
            icon={<Warning />}
            label="Requires Review"
            color="error"
            size="small"
          />
        )}
      </Box>

      <Divider />

      {/* Output Assessment */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Output Assessment
        </Typography>
        <Grid container spacing={2}>
          {/* Stool */}
          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              <Typography variant="subtitle2" gutterBottom>
                Stool
              </Typography>
              <Box display="flex" gap={2} alignItems="center">
                <StoolIcon>{getStoolTypeIcon(data.stoolType)}</StoolIcon>
                <Box>
                  <Typography>Type {data.stoolType}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {data.stoolColor} - {data.stoolAmount}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Urine */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Urine
            </Typography>
            <Box display="flex" gap={1} flexWrap="wrap">
              <Chip
                label={data.urineType}
                size="small"
                color={
                  ['wet', 'very wet'].includes(data.urineType.toLowerCase())
                    ? 'warning'
                    : 'default'
                }
              />
              <Chip label={data.urineColor} size="small" />
              <Chip label={data.urineAmount} size="small" />
            </Box>
          </Grid>
        </Grid>
      </ReportSection>

      {/* Care Provided */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Care Provided
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1} mb={1}>
              <Person />
              <Typography>
                {data.numberOfCarers} Carer{data.numberOfCarers > 1 ? 's' : ''}{' '}
                - {data.careMethod}
              </Typography>
            </Box>
            {data.slidingSheetUsed && (
              <Chip label="Sliding Sheet Used" size="small" color="primary" />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <Timer />
              <Typography>Time Spent: {data.timeSpent} minutes</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Cleaning Method & Products
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              <Chip label={data.cleaningMethod} size="small" color="primary" />
              {data.cleaningProducts.map((product, index) => (
                <Chip
                  key={index}
                  label={product}
                  size="small"
                  variant="outlined"
                />
              ))}
            </Box>
          </Grid>

          {data.barierCream && (
            <Grid item xs={12}>
              <Chip
                label="Barrier Cream Applied"
                size="small"
                color="success"
              />
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Skin Assessment */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Skin Assessment
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
              {data.skinCondition.map((condition, index) => (
                <Chip
                  key={index}
                  label={condition}
                  size="small"
                  color={
                    ['broken', 'red', 'inflamed'].includes(
                      condition.toLowerCase()
                    )
                      ? 'error'
                      : ['dry', 'fragile'].includes(condition.toLowerCase())
                      ? 'warning'
                      : 'default'
                  }
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <Thermostat />
              <Typography>Temperature: {data.skinTemperature}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>Color: {data.skinColor}</Typography>
          </Grid>

          {data.pressureAreas.length > 0 && (
            <Grid item xs={12}>
              <Alert severity="warning">
                <Typography variant="subtitle2">
                  Pressure Areas Identified:
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                  {data.pressureAreas.map((area, index) => (
                    <Chip
                      key={index}
                      label={area}
                      size="small"
                      color="warning"
                    />
                  ))}
                </Box>
              </Alert>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Pad & Clothing */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Pad & Clothing
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Pad Details
            </Typography>
            <Box display="flex" gap={1} flexWrap="wrap">
              <Chip label={`Type: ${data.padType}`} size="small" />
              <Chip label={`Size: ${data.padSize}`} size="small" />
            </Box>
          </Grid>

          {data.clothingChanged && (
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" gutterBottom>
                Clothing Changed
              </Typography>
              <Box display="flex" gap={1} flexWrap="wrap">
                <Chip
                  icon={<LocalLaundryService />}
                  label={data.clothingType}
                  size="small"
                />
                <Chip
                  label={data.clothingCondition}
                  size="small"
                  color={
                    ['soiled', 'wet'].includes(
                      data.clothingCondition.toLowerCase()
                    )
                      ? 'warning'
                      : 'default'
                  }
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Resident Status */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Resident Status
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <AccessibilityNew />
              <Typography>Mobility Level: {data.mobilityLevel}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <SentimentSatisfiedAlt />
              <Typography>Mood: {data.residentMood}</Typography>
            </Box>
          </Grid>

          {data.painLevel > 0 && (
            <Grid item xs={12}>
              <Alert severity={data.painLevel > 5 ? 'error' : 'warning'}>
                Pain Level: {data.painLevel}/10
              </Alert>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Timestamp */}
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Typography variant="caption" color="textSecondary">
          Care Provided: {new Date(data.timestamp).toLocaleString()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default PadCheckReport;
