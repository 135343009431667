import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Chip,
  IconButton,
  Stack,
  Alert,
  Grid,
  Tooltip,
  styled
} from '@mui/material';
import {
  Close as CloseIcon,
  LocalHospital,
  Warning,
  PhotoCamera,
  Assignment
} from '@mui/icons-material';

interface WoundCareData {
  // Wound Details
  woundLocation: string;
  woundType: string;
  woundStage?: string;
  woundSize: {
    length: number;
    width: number;
    depth: number;
  };

  // Wound Assessment
  appearance: string[];
  exudate: {
    amount: 'none' | 'light' | 'moderate' | 'heavy';
    type: string;
    odor: boolean;
  };
  surroundingSkin: string[];
  painLevel: number;

  // Treatment
  cleansingMethod: string;
  dressingType: string[];
  medicationsApplied: string[];

  // Progress
  healing: 'improving' | 'static' | 'deteriorating';
  comparedToPrevious: string;
  photographTaken: boolean;

  // Next Care
  nextDressing: string;
  specialInstructions: string;
  nursesReview: boolean;

  concerns: boolean;
  notes: string;
}

const woundTypes = [
  'Pressure Ulcer',
  'Surgical Wound',
  'Skin Tear',
  'Laceration',
  'Diabetic Ulcer',
  'Venous Ulcer',
  'Arterial Ulcer',
  'Moisture Lesion'
];

const appearances = [
  'Red',
  'Pink',
  'Yellow',
  'Black',
  'Granulating',
  'Sloughy',
  'Necrotic',
  'Macerated',
  'Epithelializing'
];

const surroundingSkinConditions = [
  'Healthy',
  'Erythema',
  'Macerated',
  'Dry',
  'Edematous',
  'Fragile',
  'Callused',
  'Inflamed'
];

const dressingTypes = [
  'Film',
  'Foam',
  'Hydrocolloid',
  'Alginate',
  'Hydrofiber',
  'Silver',
  'Iodine',
  'Gauze',
  'Compression'
];

const cleansingMethods = [
  'Saline',
  'Sterile Water',
  'Wound Cleanser',
  'Antiseptic Solution'
];

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2
  }
}));

interface WoundCareDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: WoundCareData) => void;
  task: any;
  initialData?: Partial<WoundCareData>;
}

const WoundCareDialog: React.FC<WoundCareDialogProps> = ({
  open,
  onClose,
  onResolve,
  task,
  initialData
}) => {
  const [formData, setFormData] = useState<WoundCareData>({
    woundLocation: '',
    woundType: '',
    woundSize: {
      length: 0,
      width: 0,
      depth: 0
    },
    appearance: [],
    exudate: {
      amount: 'none',
      type: '',
      odor: false
    },
    surroundingSkin: [],
    painLevel: 0,
    cleansingMethod: '',
    dressingType: [],
    medicationsApplied: [],
    healing: 'static',
    comparedToPrevious: '',
    photographTaken: false,
    nextDressing: '',
    specialInstructions: '',
    nursesReview: false,
    concerns: false,
    notes: ''
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setFormData((prev) => ({ ...prev, ...initialData }));
    }
  }, [initialData]);

  const handleChange = (field: keyof WoundCareData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleExudateChange = (
    field: keyof WoundCareData['exudate'],
    value: any
  ) => {
    setFormData((prev) => ({
      ...prev,
      exudate: {
        ...prev.exudate,
        [field]: value
      }
    }));
  };

  const handleSizeChange = (
    field: keyof WoundCareData['woundSize'],
    value: number
  ) => {
    setFormData((prev) => ({
      ...prev,
      woundSize: {
        ...prev.woundSize,
        [field]: value
      }
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.woundLocation || !formData.woundType) {
      setError('Please specify wound location and type');
      return false;
    }

    if (!formData.appearance.length) {
      setError('Please select wound appearance');
      return false;
    }

    if (!formData.cleansingMethod || !formData.dressingType.length) {
      setError('Please specify cleansing method and dressing type');
      return false;
    }

    if (!formData.nextDressing) {
      setError('Please specify next dressing change');
      return false;
    }

    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onResolve(formData);
      onClose();
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <LocalHospital color="primary" />
            <Typography variant="h6">Wound Care Record</Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          {/* Wound Identification */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Wound Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Wound Location"
                  value={formData.woundLocation}
                  onChange={(e) =>
                    handleChange('woundLocation', e.target.value)
                  }
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Wound Type</InputLabel>
                  <Select
                    value={formData.woundType}
                    onChange={(e) => handleChange('woundType', e.target.value)}
                  >
                    {woundTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {formData.woundType === 'Pressure Ulcer' && (
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Stage</InputLabel>
                    <Select
                      value={formData.woundStage || ''}
                      onChange={(e) =>
                        handleChange('woundStage', e.target.value)
                      }
                    >
                      <MenuItem value="1">Stage 1</MenuItem>
                      <MenuItem value="2">Stage 2</MenuItem>
                      <MenuItem value="3">Stage 3</MenuItem>
                      <MenuItem value="4">Stage 4</MenuItem>
                      <MenuItem value="unstageable">Unstageable</MenuItem>
                      <MenuItem value="suspected">
                        Suspected Deep Tissue Injury
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Box>

          {/* Wound Measurements */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Wound Size
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Length (cm)"
                  value={formData.woundSize.length}
                  onChange={(e) =>
                    handleSizeChange('length', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0, step: 0.1 } }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Width (cm)"
                  value={formData.woundSize.width}
                  onChange={(e) =>
                    handleSizeChange('width', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0, step: 0.1 } }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Depth (cm)"
                  value={formData.woundSize.depth}
                  onChange={(e) =>
                    handleSizeChange('depth', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0, step: 0.1 } }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* Wound Assessment */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Wound Assessment
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Appearance
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {appearances.map((app) => (
                    <Chip
                      key={app}
                      label={app}
                      onClick={() => {
                        const newAppearances = formData.appearance.includes(app)
                          ? formData.appearance.filter((a) => a !== app)
                          : [...formData.appearance, app];
                        handleChange('appearance', newAppearances);
                      }}
                      color={
                        formData.appearance.includes(app)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.appearance.includes(app)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Exudate Amount</InputLabel>
                  <Select
                    value={formData.exudate.amount}
                    onChange={(e) =>
                      handleExudateChange('amount', e.target.value)
                    }
                  >
                    <MenuItem value="none">None</MenuItem>
                    <MenuItem value="light">Light</MenuItem>
                    <MenuItem value="moderate">Moderate</MenuItem>
                    <MenuItem value="heavy">Heavy</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Exudate Type"
                  value={formData.exudate.type}
                  onChange={(e) => handleExudateChange('type', e.target.value)}
                  placeholder="e.g., Serous, Bloody..."
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.exudate.odor}
                      onChange={(e) =>
                        handleExudateChange('odor', e.target.checked)
                      }
                    />
                  }
                  label="Odor Present"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Surrounding Skin
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {surroundingSkinConditions.map((condition) => (
                    <Chip
                      key={condition}
                      label={condition}
                      onClick={() => {
                        const newConditions = formData.surroundingSkin.includes(
                          condition
                        )
                          ? formData.surroundingSkin.filter(
                              (c) => c !== condition
                            )
                          : [...formData.surroundingSkin, condition];
                        handleChange('surroundingSkin', newConditions);
                      }}
                      color={
                        formData.surroundingSkin.includes(condition)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.surroundingSkin.includes(condition)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Pain Level (0-10)"
                  value={formData.painLevel}
                  onChange={(e) =>
                    handleChange('painLevel', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0, max: 10 } }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* Treatment */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Treatment
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Cleansing Method</InputLabel>
                  <Select
                    value={formData.cleansingMethod}
                    onChange={(e) =>
                      handleChange('cleansingMethod', e.target.value)
                    }
                  >
                    {cleansingMethods.map((method) => (
                      <MenuItem key={method} value={method}>
                        {method}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Dressing Type
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {dressingTypes.map((type) => (
                    <Chip
                      key={type}
                      label={type}
                      onClick={() => {
                        const newTypes = formData.dressingType.includes(type)
                          ? formData.dressingType.filter((t) => t !== type)
                          : [...formData.dressingType, type];
                        handleChange('dressingType', newTypes);
                      }}
                      color={
                        formData.dressingType.includes(type)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.dressingType.includes(type)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Medications Applied"
                  placeholder="Enter medications/topical treatments applied"
                  value={formData.medicationsApplied.join(', ')}
                  onChange={(e) =>
                    handleChange(
                      'medicationsApplied',
                      e.target.value.split(',').map((s) => s.trim())
                    )
                  }
                />
              </Grid>
            </Grid>
          </Box>

          {/* Progress Assessment */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Progress
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Healing Status</InputLabel>
                  <Select
                    value={formData.healing}
                    onChange={(e) => handleChange('healing', e.target.value)}
                  >
                    <MenuItem value="improving">Improving</MenuItem>
                    <MenuItem value="static">Static</MenuItem>
                    <MenuItem value="deteriorating">Deteriorating</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Compared to Previous"
                  placeholder="Note any changes since last assessment"
                  value={formData.comparedToPrevious}
                  onChange={(e) =>
                    handleChange('comparedToPrevious', e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.photographTaken}
                      onChange={(e) =>
                        handleChange('photographTaken', e.target.checked)
                      }
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography>Photograph Taken</Typography>
                      <PhotoCamera fontSize="small" color="action" />
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Box>

          {/* Next Care */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Future Care Plan
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Next Dressing Change"
                  type="datetime-local"
                  value={formData.nextDressing}
                  onChange={(e) => handleChange('nextDressing', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.nursesReview}
                      onChange={(e) =>
                        handleChange('nursesReview', e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Requires Nurse Review"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label="Special Instructions"
                  value={formData.specialInstructions}
                  onChange={(e) =>
                    handleChange('specialInstructions', e.target.value)
                  }
                  placeholder="Enter any special care instructions..."
                />
              </Grid>
            </Grid>
          </Box>

          {/* Notes & Concerns */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Additional Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Notes"
                  value={formData.notes}
                  onChange={(e) => handleChange('notes', e.target.value)}
                  placeholder="Enter any additional observations or concerns..."
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.concerns}
                      onChange={(e) =>
                        handleChange('concerns', e.target.checked)
                      }
                      color="error"
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography>Requires Urgent Review</Typography>
                      {formData.concerns && (
                        <Tooltip title="Will flag for immediate nurse review">
                          <Warning color="error" fontSize="small" />
                        </Tooltip>
                      )}
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color={
            formData.concerns || formData.healing === 'deteriorating'
              ? 'error'
              : 'primary'
          }
          startIcon={
            formData.concerns || formData.healing === 'deteriorating' ? (
              <Warning />
            ) : (
              <Assignment />
            )
          }
        >
          {formData.concerns || formData.healing === 'deteriorating'
            ? 'Save & Flag for Review'
            : 'Save Assessment'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default WoundCareDialog;
