import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Chip,
  Divider,
  Alert,
  styled,
  LinearProgress,
  Tooltip
} from '@mui/material';
import {
  Groups,
  Warning,
  Timer,
  SentimentSatisfiedAlt,
  SentimentDissatisfied,
  RecordVoiceOver,
  EmojiPeople,
  CheckCircle,
  Info
} from '@mui/icons-material';
import { ITask } from 'src/interfaces/new/tasks';

interface SocialActivityData {
  activityType: string[];
  duration: number;
  location: string;
  groupActivity: boolean;
  participantCount?: number;
  participation: 'active' | 'passive' | 'refused';
  mood: 'happy' | 'content' | 'neutral' | 'anxious' | 'distressed';
  engagement: number;
  enjoyment: number;
  communication: 'verbal' | 'non-verbal' | 'both' | 'none';
  interactionQuality: 'good' | 'fair' | 'poor';
  behaviorsObserved: string[];
  concerns: boolean;
  notes: string;
  timestamp: string;
}

interface SocialActivityReportProps {
  data: SocialActivityData | any;
  task?: Partial<ITask>;
}

const ReportSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& + &': {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const RatingBar = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[200],
  '& .MuiLinearProgress-bar': {
    borderRadius: 5
  }
}));

const behaviorsMap = {
  positive: ['Sociable', 'Enthusiastic', 'Calm', 'Happy'],
  neutral: ['Quiet', 'Reserved', 'Tired'],
  negative: ['Withdrawn', 'Agitated', 'Anxious', 'Aggressive']
};

const SocialActivityReport: React.FC<SocialActivityReportProps> = ({
  data,
  task
}) => {
  const formatDuration = (minutes: number) => {
    if (minutes < 60) return `${minutes} minutes`;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const getMoodIcon = (mood: string) => {
    switch (mood) {
      case 'happy':
      case 'content':
        return <SentimentSatisfiedAlt color="success" />;
      case 'neutral':
        return <SentimentSatisfiedAlt color="action" />;
      case 'anxious':
      case 'distressed':
        return <SentimentDissatisfied color="error" />;
      default:
        return <SentimentSatisfiedAlt />;
    }
  };

  const getParticipationColor = (level: string) => {
    switch (level) {
      case 'active':
        return 'success';
      case 'passive':
        return 'warning';
      case 'refused':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      {/* Header */}
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <Groups color="primary" fontSize="large" />
        <Box>
          <Typography variant="h6">Social Activity Record</Typography>
          <Typography variant="caption" color="textSecondary">
            {new Date(task.completedDate).toLocaleString()}
          </Typography>

          {/* completed by */}
          <Box display="flex" alignItems="center" gap={1} mt={1}>
            <RecordVoiceOver />
            <Typography variant="body2">
              Completed by: {task.completedBy?.firstName}{' '}
              {task.completedBy?.lastName}
            </Typography>

            {/* role */}
            <Tooltip title={task.completedBy?.role}>
              <Chip
                label={task.completedBy?.role}
                size="small"
                color="primary"
              />
            </Tooltip>
          </Box>
        </Box>
        <Box flexGrow={1} />
        {data.concerns && (
          <Chip
            icon={<Warning />}
            label="Requires Review"
            color="error"
            size="small"
          />
        )}
      </Box>

      <Divider />

      {/* Activity Details */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Activity Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
              {data.activityType.map((type, index) => (
                <Chip key={index} label={type} color="primary" size="small" />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" gap={1}>
              <Timer color="action" />
              <Typography>Duration: {formatDuration(data.duration)}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Location: {data.location}</Typography>
          </Grid>

          {data.groupActivity && (
            <Grid item xs={12}>
              <Chip
                icon={<Groups />}
                label={`Group Activity - ${data.participantCount} participants`}
                color="primary"
                size="small"
              />
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Engagement & Response */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Engagement & Response
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              <Typography
                variant="subtitle2"
                gutterBottom
                display="flex"
                alignItems="center"
                gap={1}
              >
                Mood
                {getMoodIcon(data.mood)}
              </Typography>
              <Chip
                label={data.mood.charAt(0).toUpperCase() + data.mood.slice(1)}
                color={
                  ['happy', 'content'].includes(data.mood)
                    ? 'success'
                    : ['anxious', 'distressed'].includes(data.mood)
                    ? 'error'
                    : 'default'
                }
                size="small"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Participation
            </Typography>
            <Chip
              icon={<EmojiPeople />}
              label={
                data.participation.charAt(0).toUpperCase() +
                data.participation.slice(1)
              }
              color={getParticipationColor(data.participation)}
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Engagement Level
            </Typography>
            <Box display="flex" alignItems="center" gap={1}>
              <RatingBar
                variant="determinate"
                value={(data.engagement / 10) * 100}
                color={
                  data.engagement > 7
                    ? 'success'
                    : data.engagement > 4
                    ? 'warning'
                    : 'error'
                }
              />
              <Typography variant="body2">{data.engagement}/10</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Enjoyment Level
            </Typography>
            <Box display="flex" alignItems="center" gap={1}>
              <RatingBar
                variant="determinate"
                value={(data.enjoyment / 10) * 100}
                color={
                  data.enjoyment > 7
                    ? 'success'
                    : data.enjoyment > 4
                    ? 'warning'
                    : 'error'
                }
              />
              <Typography variant="body2">{data.enjoyment}/10</Typography>
            </Box>
          </Grid>
        </Grid>
      </ReportSection>

      {/* Communication */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Communication & Interaction
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Communication Style
            </Typography>
            <Chip
              icon={<RecordVoiceOver />}
              label={data.communication}
              color={data.communication === 'none' ? 'error' : 'primary'}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Interaction Quality
            </Typography>
            <Chip
              icon={<Info />}
              label={data.interactionQuality}
              color={
                data.interactionQuality === 'good'
                  ? 'success'
                  : data.interactionQuality === 'fair'
                  ? 'warning'
                  : 'error'
              }
              size="small"
            />
          </Grid>
        </Grid>
      </ReportSection>

      {/* Observed Behaviors */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Observed Behaviors
        </Typography>
        <Grid container spacing={2}>
          {['positive', 'neutral', 'negative'].map((category) => (
            <Grid item xs={12} key={category}>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {data.behaviorsObserved
                  .filter((behavior) =>
                    behaviorsMap[
                      category as keyof typeof behaviorsMap
                    ].includes(behavior)
                  )
                  .map((behavior, index) => (
                    <Chip
                      key={index}
                      label={behavior}
                      size="small"
                      color={
                        category === 'positive'
                          ? 'success'
                          : category === 'negative'
                          ? 'error'
                          : 'default'
                      }
                      variant="outlined"
                    />
                  ))}
              </Box>
            </Grid>
          ))}
        </Grid>
      </ReportSection>

      {/* Notes & Concerns */}
      {(data.notes || data.concerns) && (
        <ReportSection>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Additional Information
          </Typography>
          {data.notes && (
            <Typography variant="body2" paragraph>
              {data.notes}
            </Typography>
          )}
          {data.concerns && (
            <Alert severity="warning">
              Activity requires follow-up or review
            </Alert>
          )}
        </ReportSection>
      )}

      {/* Timestamp */}
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Typography variant="caption" color="textSecondary">
          Recorded: {new Date(data.timestamp).toLocaleString()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default SocialActivityReport;
