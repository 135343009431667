import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Paper,
  Chip,
  Autocomplete,
  CircularProgress,
  Avatar,
  IconButton,
  Divider,
  Snackbar,
  Alert,
  Card,
  CardContent
} from '@mui/material';
import {
  PhotoCamera as PhotoCameraIcon,
  ExpandMore as ExpandMoreIcon,
  Delete
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ICarePlan, IResident } from 'src/interfaces/resident';
import { apiHostname } from 'src/api/api';
import axios from 'axios';
import {
  useCreateCarePlanMutation,
  useDeleteResidentMutation,
  useGetCarePlanQuery,
  useGetResidentQuery,
  useUpdateCarePlanMutation,
  useUpdateResidentMutation
} from 'src/redux/@api/resident';
import { DeleteIcon, Edit, EditIcon } from 'lucide-react';
import DeleteResidentDialog from './deletion';
import { useDispatch } from 'react-redux';
import {
  updateResidentProfilePicture,
  updateResident as updateResidentState
} from 'src/redux/reducers/residents';

interface ResidentProfileProps {
  residentId: string;
  onClose: () => void;
}

const ResidentProfile: React.FC<ResidentProfileProps> = ({
  residentId,
  onClose
}) => {
  const theme = useTheme();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { data: resident, isLoading: isLoadingResident } =
    useGetResidentQuery(residentId);
  const { data: carePlan, isLoading: isLoadingCarePlan } =
    useGetCarePlanQuery(residentId);
  const [updateResident, { isLoading: isUpdatingResident }] =
    useUpdateResidentMutation();
  const [updateCarePlan, { isLoading: isUpdatingCarePlan }] =
    useUpdateCarePlanMutation();
  const [createCarePlan, { isLoading: isCreatingCarePlan }] =
    useCreateCarePlanMutation();
  const [deleteResident] = useDeleteResidentMutation();

  const dispatch = useDispatch();

  const [isUploading, setIsUploading] = useState(false);
  const [personalInfo, setPersonalInfo] = useState<IResident>({
    firstName: '',
    lastName: '',
    dateOfBirth: new Date(),
    roomNumber: '',
    admissionDate: new Date(),
    medicalConditions: [],
    allergies: [],
    group: null,
    emergencyContact: {
      name: '',
      relationship: '',
      phone: ''
    },
    tasks: [],
    taskSummary: {
      upcoming: 0,
      pending: 0,
      overdue: 0,
      total: 0,
      urgency: 'white'
    },
    profilePictureUrl: '',
    carePlan: '' as any
  });
  const [carePlanInfo, setCarePlanInfo] = useState<Partial<ICarePlan>>({
    mobilityAssistance: '',
    feedingRequirements: '',
    medicationNeeds: '',
    allergies: '',
    mentalHealth: '',
    behaviouralNotes: '',
    specialInstructions: ''
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  useEffect(() => {
    if (resident) {
      setPersonalInfo(resident);
    }
  }, [resident]);

  useEffect(() => {
    if (carePlan) {
      setCarePlanInfo(carePlan);
    }
  }, [carePlan]);

  const handlePersonalInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setPersonalInfo((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEmergencyContactChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setPersonalInfo((prev) => ({
      ...prev,
      emergencyContact: {
        ...prev.emergencyContact,
        [name]: value
      }
    }));
  };

  const handleCarePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCarePlanInfo((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setPersonalInfo((prev) => ({
        ...prev,
        dateOfBirth: date
      }));
    }
  };

  const handleSavePersonalInfo = async () => {
    try {
      const residentData: Partial<IResident> = {
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        dateOfBirth: personalInfo.dateOfBirth,
        roomNumber: personalInfo.roomNumber,
        admissionDate: personalInfo.admissionDate,
        medicalConditions: personalInfo.medicalConditions,
        allergies: personalInfo.allergies,
        group: personalInfo.group,
        emergencyContact: personalInfo.emergencyContact,
        profilePictureUrl: personalInfo.profilePictureUrl
      };
      await updateResident({ id: residentId, residentData }).unwrap();
      setSnackbar({
        open: true,
        message: 'Personal information saved successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving personal info:', error);
      setSnackbar({
        open: true,
        message: 'Error saving personal information',
        severity: 'error'
      });
    }
  };

  const handleSaveCarePlan = async () => {
    try {
      if (carePlan) {
        await updateCarePlan({ residentId, ...carePlanInfo }).unwrap();
      } else {
        await createCarePlan({ residentId, ...carePlanInfo }).unwrap();
      }
      setSnackbar({
        open: true,
        message: 'Care plan saved successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving care plan:', error);
      setSnackbar({
        open: true,
        message: 'Error saving care plan',
        severity: 'error'
      });
    }
  };

  const compressImage = (file: File, maxSizeMB = 2): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          // Calculate new dimensions while maintaining aspect ratio
          if (width > height) {
            if (width > 1920) {
              height *= 1920 / width;
              width = 1920;
            }
          } else {
            if (height > 1920) {
              width *= 1920 / height;
              height = 1920;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);

          const compressAndCheck = (quality: number) => {
            canvas.toBlob(
              (blob) => {
                if (blob) {
                  if (blob.size > maxSizeMB * 1024 * 1024 && quality > 0.1) {
                    compressAndCheck(quality - 0.05);
                  } else {
                    resolve(blob);
                  }
                } else {
                  reject(new Error('Failed to compress image'));
                }
              },
              'image/jpeg',
              quality
            );
          };

          compressAndCheck(0.9); // Start with 90% quality
        };
      };

      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsUploading(true);
      try {
        // Compress the image
        const compressedBlob = await compressImage(file);
        const compressedFile = new File([compressedBlob], file.name, {
          type: 'image/jpeg'
        });

        const formData = new FormData();
        formData.append('file', compressedFile);

        const response = await axios.post(
          `${apiHostname}/api/v1/pictures/resident/${residentId}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            timeout: 30000
          }
        );

        if (response.data.success) {
          setPersonalInfo((prev) => ({
            ...prev,
            profilePictureUrl: response.data.data.avatarUrl
          }));
          dispatch(
            updateResidentProfilePicture({
              residentId,
              profilePictureUrl: response.data.data.avatarUrl
            })
          );
          setSnackbar({
            open: true,
            message: 'Profile picture updated successfully',
            severity: 'success'
          });
        } else {
          throw new Error(response.data.error);
        }
      } catch (error) {
        console.error('Error uploading profile picture:', error);
        setSnackbar({
          open: true,
          message: 'Error uploading profile picture',
          severity: 'error'
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  if (isLoadingResident || isLoadingCarePlan) {
    return <CircularProgress />;
  }

  if (!resident) {
    return <Typography>No resident data available</Typography>;
  }

  const handleDeleteResident = async () => {
    try {
      await deleteResident(residentId).unwrap();
      setSnackbar({
        open: true,
        message: 'Resident deleted successfully',
        severity: 'success'
      });
      onClose();
      // Redirect to residents list or handle navigation as needed
    } catch (error) {
      console.error('Error deleting resident:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting resident',
        severity: 'error'
      });
    }
    setDeleteDialogOpen(false);
  };

  const isMobile = theme.breakpoints.down('sm');

  return (
    <Box sx={{ width: '100%', maxWidth: '1200px', margin: 'auto', p: 2 }}>
      {/* Header Card */}
      <Card sx={{ mb: 3, borderRadius: 2, position: 'relative' }}>
        <CardContent sx={{ p: 3 }}>
          <Grid container spacing={3} alignItems="center">
            {/* Profile Picture Section */}
            <Grid item xs={12} sm={2}>
              <Box
                sx={{
                  position: 'relative',
                  width: 'fit-content',
                  margin: isMobile ? 'auto' : 'initial'
                }}
              >
                <Avatar
                  src={personalInfo.profilePictureUrl}
                  sx={{
                    width: 100,
                    height: 100,
                    border: '3px solid white',
                    boxShadow: theme.shadows[3]
                  }}
                />
                {isUploading && (
                  <CircularProgress
                    size={110}
                    thickness={2}
                    sx={{
                      position: 'absolute',
                      top: -5,
                      left: -5,
                      zIndex: 1
                    }}
                  />
                )}
                <label htmlFor="icon-button-file">
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="icon-button-file"
                    type="file"
                    onChange={handleImageUpload}
                  />
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      position: 'absolute',
                      bottom: -4,
                      right: -4,
                      backgroundColor: 'white',
                      '&:hover': { backgroundColor: '#f5f5f5' }
                    }}
                  >
                    <PhotoCameraIcon fontSize="small" />
                  </IconButton>
                </label>
              </Box>
            </Grid>

            {/* Resident Info Section */}
            <Grid item xs={12} sm={10}>
              <Typography variant="h4" gutterBottom>
                {personalInfo.firstName} {personalInfo.lastName}
              </Typography>
              <Box display="flex" gap={1} mb={1}>
                <Chip
                  label={`Room ${personalInfo.roomNumber}`}
                  size="small"
                  color="primary"
                  variant="outlined"
                />
                <Chip
                  label={`Group ${personalInfo.group}`}
                  size="small"
                  color="secondary"
                  variant="outlined"
                />
              </Box>
              <Box display="flex" gap={1}>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<Delete />}
                  onClick={() => setDeleteDialogOpen(true)}
                  size="small"
                >
                  Delete
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Main Content Grid */}
      <Grid container spacing={3}>
        {/* Personal Information Card */}
        <Grid item xs={12} lg={6}>
          <Card sx={{ height: '100%', borderRadius: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                Personal Information
                <EditIcon fontSize="small" color="action" />
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    value={personalInfo.firstName || ''}
                    onChange={handlePersonalInfoChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    value={personalInfo.lastName || ''}
                    onChange={handlePersonalInfoChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Date of Birth"
                    value={personalInfo.dateOfBirth || null}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth size="small" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Room Number"
                    name="roomNumber"
                    value={personalInfo.roomNumber || ''}
                    onChange={handlePersonalInfoChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Group"
                    name="group"
                    value={personalInfo.group || ''}
                    onChange={handlePersonalInfoChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Medical Information Card */}
        <Grid item xs={12} lg={6}>
          <Card sx={{ height: '100%', borderRadius: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                Medical Information
                <EditIcon fontSize="small" color="action" />
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    options={[]}
                    freeSolo
                    value={personalInfo.medicalConditions || []}
                    onChange={(_, newValue) =>
                      setPersonalInfo((prev: any) => ({
                        ...prev,
                        medicalConditions: newValue
                      }))
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                          key={index}
                          size="small"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Medical Conditions"
                        placeholder="Add medical condition"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    options={[]}
                    freeSolo
                    value={personalInfo.allergies || []}
                    onChange={(_, newValue) =>
                      setPersonalInfo((prev: any) => ({
                        ...prev,
                        allergies: newValue
                      }))
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                          key={index}
                          size="small"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Allergies"
                        placeholder="Add allergy"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Emergency Contact Card */}
        <Grid item xs={12}>
          <Card sx={{ borderRadius: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                Emergency Contact
                <EditIcon fontSize="small" color="action" />
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={personalInfo.emergencyContact?.name || ''}
                    onChange={handleEmergencyContactChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Relationship"
                    name="relationship"
                    value={personalInfo.emergencyContact?.relationship || ''}
                    onChange={handleEmergencyContactChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    value={personalInfo.emergencyContact?.phone || ''}
                    onChange={handleEmergencyContactChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Care Plan Card */}
        <Grid item xs={12}>
          <Card sx={{ borderRadius: 2 }}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 3
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  Care Plan
                  <EditIcon fontSize="small" color="action" />
                </Typography>

                {carePlan && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSaveCarePlan}
                    disabled={isCreatingCarePlan}
                  >
                    {isCreatingCarePlan ? 'Creating...' : 'Save Care Plan'}
                  </Button>
                )}
              </Box>
              {!carePlan ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 8,
                    backgroundColor: 'grey.50',
                    borderRadius: 1
                  }}
                >
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                  >
                    No care plan has been created yet.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSaveCarePlan}
                    sx={{ mt: 2 }}
                    disabled={isCreatingCarePlan}
                  >
                    {isCreatingCarePlan ? 'Creating...' : 'Create Care Plan'}
                  </Button>
                </Box>
              ) : (
                <Grid container spacing={3}>
                  {/* Daily Living & Personal Care */}
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      Daily Living & Personal Care
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Daily Living Activities"
                          name="dailyLivingActivities"
                          value={carePlanInfo.dailyLivingActivities || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Describe assistance needed with daily activities, routines, and preferences"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Personal Hygiene"
                          name="personalHygiene"
                          value={carePlanInfo.personalHygiene || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Bathing, grooming, and personal care preferences and assistance needs"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Mobility & Safety */}
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      Mobility & Safety
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Mobility Assistance"
                          name="mobilityAssistance"
                          value={carePlanInfo.mobilityAssistance || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Detailed mobility needs and assistance requirements"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Transfer Assistance"
                          name="transferAssistance"
                          value={carePlanInfo.transferAssistance || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Specific transfer requirements and equipment needed"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Fall Risk Assessment"
                          name="fallRiskAssessment"
                          value={carePlanInfo.fallRiskAssessment || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Fall risk level and prevention measures"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Nutrition & Health */}
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      Nutrition & Health
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Nutritional Requirements"
                          name="nutritionalRequirements"
                          value={carePlanInfo.nutritionalRequirements || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Dietary requirements, restrictions, and preferences"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Feeding Requirements"
                          name="feedingRequirements"
                          value={carePlanInfo.feedingRequirements || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Assistance needed during meals and special considerations"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Hydration Needs"
                          name="hydrationNeeds"
                          value={carePlanInfo.hydrationNeeds || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Fluid intake requirements and preferences"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Medical Care */}
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      Medical Care
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Medication Needs"
                          name="medicationNeeds"
                          value={carePlanInfo.medicationNeeds || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Medication schedule, preferences, and special instructions"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Wound Care"
                          name="woundCare"
                          value={carePlanInfo.woundCare || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Wound care requirements and dressing changes"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Pain Management"
                          name="painManagement"
                          value={carePlanInfo.painManagement || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Pain assessment and management strategies"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Mental & Social Wellbeing */}
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      Mental & Social Wellbeing
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Mental Health"
                          name="mentalHealth"
                          value={carePlanInfo.mentalHealth || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Mental health needs and support requirements"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Cognitive Functioning"
                          name="cognitiveFunctioning"
                          value={carePlanInfo.cognitiveFunctioning || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Cognitive status and support needs"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Social Preferences"
                          name="socialPreferences"
                          value={carePlanInfo.socialPreferences || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Social activities preferences and engagement needs"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Additional Care Needs */}
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      Additional Care Needs
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Communication Needs"
                          name="communicationNeeds"
                          value={carePlanInfo.communicationNeeds || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Communication preferences and assistance requirements"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Spiritual Needs"
                          name="spiritualNeeds"
                          value={carePlanInfo.spiritualNeeds || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Spiritual and religious preferences and requirements"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="End of Life Preferences"
                          name="endOfLifePreferences"
                          value={carePlanInfo.endOfLifePreferences || ''}
                          onChange={handleCarePlanChange}
                          variant="outlined"
                          size="small"
                          placeholder="Advanced care directives and end of life preferences"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DeleteResidentDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteResident}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ResidentProfile;
