// tasks.ts
import { Task } from 'src/interfaces/tasks';
import { baseApi } from './base';
import { ITask } from 'src/interfaces/new/tasks';

interface CreateTaskRequest {
  taskName: string;
  description: string;
  taskType: string;
  frequency: {
    type: 'frequent' | 'daily' | 'weekly' | 'monthly' | 'one-time';
    interval?: number;
    timeOfDay?: string;
  };
  resident: string;
  taskData?: any;
}

export const tasksApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUpcomingTasks: builder.query({
      query: (residentId) => `residents/${residentId}/tasks/upcoming`
    }),
    getPendingTasks: builder.query({
      query: (residentId) => `tasks/${residentId}/pending`,
      providesTags: ['tasks']
    }),
    getTasksForResident: builder.query({
      query: (residentId: string) => `tasks/resident/${residentId}`,
      providesTags: ['tasks']
    }),

    resolveTask: builder.mutation({
      query: ({
        taskId,
        status,
        notes,
        taskData
      }: {
        taskId: string;
        status: 'completed' | 'missed';
        notes?: string;
        taskData?: any;
      }) => ({
        url: `tasks/${taskId}/resolve`,
        method: 'PUT',
        body: {
          status,
          notes,
          taskData
        }
      }),
      // Invalidate relevant cache on task resolution
      invalidatesTags: (result, error, { taskId }) => [
        { type: 'Task', id: taskId },
        'TaskSummary'
      ]
    }),

    // Get task by ID
    getTask: builder.query<ITask, string>({
      query: (taskId) => `task/${taskId}`,
      providesTags: (result, error, taskId) => [{ type: 'Task', id: taskId }]
    }),
    // completeTask: builder.mutation({
    //   query: (task) => ({
    //     url: `residents/tasks/${task.taskId}/complete`,
    //     method: 'PUT',
    //     body: task
    //   }),
    //   transformResponse: (response: { data: Task }) => response.data,
    //   invalidatesTags: (result, error, task) => [
    //     { type: 'Task', id: task._id },
    //     { type: 'Resident', id: task.residentId }
    //   ]
    // }),
    createTask: builder.mutation({
      query: (newTask) => ({
        url: 'tasks',
        method: 'POST',
        body: newTask
      })
    }),
    deleteTask: builder.mutation({
      query: (taskId) => ({
        url: `tasks/${taskId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['tasks']
    }),
    createMultipleTasks: builder.mutation({
      query: (newTasks) => ({
        url: 'residents/multiple',
        method: 'POST',
        body: newTasks
      })
    }),
    createBulkTasks: builder.mutation<ITask[], CreateTaskRequest[]>({
      query: (tasks) => ({
        url: '/tasks/multiple',
        method: 'POST',
        body: tasks
      })
    })
  })
});

export const {
  useGetUpcomingTasksQuery,
  useGetPendingTasksQuery,
  useCreateTaskMutation,
  useCreateMultipleTasksMutation,
  useGetTasksForResidentQuery,
  useResolveTaskMutation,
  useGetTaskQuery,
  useCreateBulkTasksMutation,
  useDeleteTaskMutation
} = tasksApi;
