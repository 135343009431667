import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IResident } from 'src/interfaces/resident';
import { residentsApi } from '../@api/resident';
import { ITask } from 'src/interfaces/new/tasks';
import { tasksApi } from '../@api/task';

interface TaskState {
  tasks: ITask[];
  loading: boolean;
  error: any;
}

const initialState: TaskState = {
  tasks: [],
  loading: false,
  error: null
};

export const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    updateTaskTatus: (
      state,
      action: PayloadAction<{
        taskId: string;
        status:
          | 'idle'
          | 'upcoming'
          | 'pending'
          | 'completed'
          | 'missed'
          | 'overdue';
      }>
    ) => {
      const { taskId, status } = action.payload;
      const task = state.tasks.find((task) => task._id === taskId);
      if (task) {
        task.status = status;
      }
    }
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      residentsApi.endpoints.getResidents.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        console.log('action.payload', action.payload);
      }
    );

    builder.addMatcher(
      tasksApi.endpoints.getTasksForResident.matchFulfilled,
      (
        state,
        action: PayloadAction<{
          data: ITask[];
          pagination: {
            page: number;
            limit: number;
            totalPages: number;
            total: number;
          };
        }>
      ) => {
        state.tasks = action.payload.data;
      }
    );
    builder.addMatcher(
      tasksApi.endpoints.resolveTask.matchPending,
      (state, action: PayloadAction<any>) => {
        state.loading = true;
      }
    );

    //detele task
    builder.addMatcher(
      tasksApi.endpoints.deleteTask.matchPending,
      (state, action: PayloadAction<any>) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      tasksApi.endpoints.deleteTask.matchFulfilled,
      (
        state,
        action: PayloadAction<{
          data: {
            taskId: string;
          };
        }>
      ) => {
        state.loading = false;
        state.tasks = state.tasks.filter(
          (task) => task._id !== action.payload.data.taskId
        );
      }
    );
  }
});

export const {} = taskSlice.actions;

export default taskSlice.reducer;
