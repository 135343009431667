// menuItems.ts
export interface MenuItem {
  label: string;
  icon: string;
  link?: string;
  submenu?: MenuItem[];
}

export const calculateMenuItems = (
  user: any,
  currentOrganization: any,
  homeSettings: any
): MenuItem[] => {
  if (!user || !currentOrganization || !user.role) {
    return [];
  }

  const commonItems: MenuItem[] = [
    {
      label: 'Home',
      icon: 'material-symbols-light:home-outline',
      link: '/home'
    },

    {
      label: 'Chat',
      icon: 'fluent:chat-20-regular',
      link: '/chat'
    }
  ];

  const roleSpecificItems: Record<string, MenuItem[]> = {
    admin: [
      {
        label: 'Invitations',
        icon: 'fluent:mail-20-regular',
        link: '/invitations'
      },
      {
        label: 'Invoices',
        icon: 'fluent:document-20-regular',
        link: '/invoices'
      },
      {
        label: 'Settings',
        icon: 'mingcute:settings-3-fill',
        link:
          currentOrganization.type === 'agency'
            ? '/agency-settings'
            : '/home-settings'
      }
    ],
    carer: [
      {
        label: 'Profile',
        icon: 'fluent:scan-person-20-regular',
        link: '/carer-profile'
      },
      {
        label: 'timesheets',
        icon: 'hugeicons:google-sheet',
        link: '/timesheets'
      }
    ],
    nurse: [
      {
        label: 'Profile',
        icon: 'fluent:scan-person-20-regular',
        link: '/nurse-profile'
      },
      {
        label: 'timesheets',
        icon: 'hugeicons:google-sheet',
        link: '/timesheets'
      }
    ]
  };

  if (currentOrganization.type === 'agency') {
    roleSpecificItems.admin.push(
      {
        label: 'Timesheets',
        icon: 'hugeicons:google-sheet',
        link: '/agency-timesheets'
      },
      {
        label: 'Homes',
        icon: 'material-symbols-light:home-work',
        link: '/home-users'
      },
      {
        label: 'Staffs',
        icon: 'fluent:scan-person-20-regular',
        link: '/staffs'
      }
    );
  } else if (currentOrganization.type === 'home') {
    roleSpecificItems.admin.push(
      {
        label: 'Timesheets',
        icon: 'hugeicons:google-sheet',
        link: '/home-timesheets'
      },
      {
        label: 'Agencies',
        icon: 'octicon:organization-24',
        link: '/agency-users'
      },
      {
        label: 'Staffs',
        icon: 'fluent:scan-person-20-regular',
        link: '/staffs'
      },
      {
        label: 'Residents',
        icon: 'healthicons:elderly-outline',
        link: '/home-residents'
      }
    );

    roleSpecificItems.carer.push({
      label: 'Residents',
      icon: 'healthicons:elderly-outline',
      link: '/carer-residents'
    });

    if (homeSettings.allowResident3rdParty) {
      roleSpecificItems.admin.push({
        label: 'Third Parties',
        icon: 'fluent:scan-person-20-regular',
        link: '/home-thirdparties'
      });
    }
  }

  return [...commonItems, ...(roleSpecificItems[user.role] || [])];
};
