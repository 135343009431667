import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Container,
  Grid,
  Typography,
  Fab,
  Box,
  Avatar,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  Badge,
  Tooltip
} from '@mui/material';
import { Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TaskManagementComponent from './views/task-management';
import AddResidentDialog from 'src/components/core/dialogs/add-residents';
import { useAppSelector } from 'src/redux/hook';
import { useGetResidentsQuery } from 'src/redux/@api/resident';
import TaskManagementDialog from './views/task-management';
import { useWebSocketConnection } from 'src/components/core/ws/task-hook';
import { wsHostname } from 'src/api/api';
import { IResident } from 'src/interfaces/resident';
import { Icon } from '@iconify/react';
import { useDispatch } from 'react-redux';
import { updateResident, updateTaskStatus } from 'src/redux/reducers/residents';
import { baseApi } from 'src/redux/@api/base';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { tasksApi } from 'src/redux/@api/task';

export interface ITaskSummary {
  upcoming: number;
  pending: number;
  overdue: number;
  total: number;
  urgency: 'white' | 'yellow' | 'orange' | 'red';
}

const POLLING_INTERVAL = 30000; // 30 seconds

const ResidentPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const [residents, setResidents] = useState<IResident[]>([]);
  const residents = useAppSelector((state) => state.residents.residents);
  const homeId = useAppSelector(
    (state) => state.userState.currentOrganization._id
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedResident, setSelectedResident] = useState<IResident | null>(
    null
  );
  const [taskManagementDialogOpen, setTaskManagementDialogOpen] =
    useState(false);

  const {
    data: residentsData,
    isLoading,
    refetch
  } = useGetResidentsQuery(undefined, {});

  const dispatch = useDispatch();

  const currentUser = useAppSelector((state) => state.userState.user);
  const currentOrganization = useAppSelector(
    (state) => state.userState.currentOrganization
  );

  // WebSocket related state and refs
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connected, setConnected] = useState(false);
  const wsRef = useRef<WebSocket | null>(null);
  const reconnectTimeout = useRef<NodeJS.Timeout>();

  const hapticsVibrate = async () => {
    await Haptics.vibrate();
  };

  // WebSocket connection setup
  useEffect(() => {
    const connectWebSocket = () => {
      console.log('Initializing WebSocket connection for timesheet scanning');
      // Clean up existing connection
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }

      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      console.log('Initializing WebSocket connection for timesheet scanning');

      const ws = new WebSocket(
        `${wsHostname}/ws-tasks?userId=${currentUser._id}&orgId=${currentOrganization?._id}`
      );

      ws.onopen = () => {
        console.log('WebSocket connected for timesheet scanning');
        setConnected(true);
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          handleWebSocketMessage(data);
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      ws.onclose = (event) => {
        console.log('WebSocket disconnected:', event);
        setConnected(false);

        // Only reconnect if we're still scanning and it wasn't a clean closure
        if (!event.wasClean) {
          console.log('Scheduling reconnection...');
          reconnectTimeout.current = setTimeout(() => {
            console.log('Attempting to reconnect...');
            connectWebSocket();
          }, 3000);
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      wsRef.current = ws;
      setSocket(ws);
    };

    connectWebSocket();

    // Cleanup function
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      setSocket(null);
      setConnected(false);
    };
  }, [currentUser._id, currentOrganization._id]);

  const handleWebSocketMessage = async (data: any) => {
    if (data.type === 'taskUpdate') {
      const taskUpdate = data.data;

      console.log('Received task update:', taskUpdate);

      dispatch(tasksApi.util.invalidateTags(['tasks']));

      dispatch(
        updateTaskStatus({
          residentId: taskUpdate.residentId,
          oldStatus: taskUpdate.oldStatus,
          newStatus: taskUpdate.status
        })
      );
      await hapticsVibrate();
    }
  };

  // Update the getTaskStatus function to match all possible urgency values
  const getTaskStatus = (taskSummary) => {
    const urgency = taskSummary?.urgency || 'white';
    let icon: string;
    let color: string;
    let tooltip: string;

    switch (urgency) {
      case 'violet':
        icon = 'material-symbols:sentiment-very-dissatisfied';
        color = '#702963';
        tooltip = 'Critical: Multiple overdue tasks need immediate attention';
        break;

      case 'red':
        icon = 'material-symbols:sentiment-sad';
        color = theme.palette.error.main;
        tooltip = 'Urgent: Overdue task needs attention';
        break;

      case 'orange':
        icon = 'fluent:emoji-sad-slight-20-filled';
        color = theme.palette.warning.main;
        tooltip = 'Warning: Multiple pending tasks need attention';
        break;

      case 'yellow':
        icon = 'heroicons:face-smile-solid';
        color = theme.palette.warning.light;
        tooltip = 'Notice: Tasks need attention soon';
        break;

      default: // 'white'
        icon = 'ri:emotion-happy-fill';
        color = theme.palette.success.main;
        tooltip = 'On track: No immediate action required';
    }

    return { icon, color, tooltip };
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container
      sx={{
        height: `calc(100vh - ${theme.header.height})`,
        overflowY: 'hidden',
        padding: 1
      }}
      maxWidth="xl"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: '60px' }}
      >
        <Typography variant="h4" component="h1">
          Residents
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={() => refetch()}
        >
          Refresh
        </Button>
      </Box>

      <Box
        sx={{
          height: `calc(100vh - ${theme.header.height} - 60px)`,
          overflowY: 'auto'
        }}
      >
        <Grid container spacing={1} p={1}>
          {residents?.map((resident) => {
            const status = getTaskStatus(resident.taskSummary);
            return (
              <Grid
                item
                xs={4}
                sm={4}
                md={2}
                key={resident._id}
                sx={{
                  height: isMobile ? '130px' : '200px'
                }}
              >
                <Card
                  onClick={() => {
                    setSelectedResident(resident);
                    setTaskManagementDialogOpen(true);
                  }}
                  sx={{
                    padding: 0,
                    position: 'relative',
                    zIndex: 100,
                    cursor: 'pointer',
                    transition: 'transform 0.3s',
                    '&:hover': { transform: 'scale(1.05)' },
                    overflow: 'visible',
                    height: '100%',
                    width: '100%'
                  }}
                >
                  <Tooltip title={status.tooltip}>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: -5,
                        left: -5,
                        zIndex: 200,
                        backgroundColor: status.color,
                        borderRadius: '50%',
                        padding: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0px 2px 4px rgba(0,0,0,0.2)'
                      }}
                    >
                      <Icon
                        icon={status.icon}
                        style={{
                          fontSize: isMobile ? '1.2rem' : '1.5rem',
                          color: '#fff'
                        }}
                      />
                    </Box>
                  </Tooltip>
                  <CardContent
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      padding: '3px !important',
                      zIndex: 1,
                      height: '100%'
                    }}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 0
                      }}
                    >
                      <Avatar
                        src={resident.profilePictureUrl}
                        alt={`${resident.firstName} ${resident.lastName}`}
                        sx={{
                          width: '100%',
                          height: '60%',
                          margin: '0 auto',
                          borderRadius: '10px !important',
                          objectFit: 'contain'
                        }}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '40%'
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            padding: '1px 2px',
                            borderRadius: '12px',
                            marginBottom: '4px',
                            fontSize: isMobile ? '0.6rem' : '0.7rem',
                            fontWeight: 'bold'
                          }}
                        >
                          Room {resident.roomNumber}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          textAlign="center"
                          sx={{
                            zIndex: 1000,
                            fontSize: isMobile ? '0.7rem' : '0.8rem',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            width: '100%',
                            wordBreak: 'break-word'
                          }}
                        >
                          {`${resident.firstName} ${resident.lastName}`.toUpperCase()}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Fab
        color="primary"
        aria-label="add resident"
        sx={{
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2)
        }}
        onClick={() => setDialogOpen(true)}
      >
        <AddIcon />
      </Fab>

      <AddResidentDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        homeId={homeId}
      />
      {selectedResident && (
        <TaskManagementDialog
          open={taskManagementDialogOpen}
          onClose={() => {
            setTaskManagementDialogOpen(false);
            setSelectedResident(null);
          }}
          resident={selectedResident}
        />
      )}
    </Container>
  );
};

export default ResidentPage;
