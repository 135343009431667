import React, { useEffect, useState } from 'react';
import {
  Typography,
  Avatar,
  Box,
  Chip,
  IconButton,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useTheme,
  Card,
  CardContent,
  CardActions,
  Tooltip,
  useMediaQuery,
  styled,
  Checkbox
} from '@mui/material';
import {
  DeleteOutlineOutlined,
  Email,
  Phone,
  Security,
  Add as AddIcon,
  Close as CloseIcon,
  Person,
  Lock
} from '@mui/icons-material';
import { getRoleColor } from './util';
import type { IStaff } from 'src/interfaces/staffs';
import { useGetCareStaffsQuery } from 'src/redux/@api/misc';
import Staffapplication from './staff-application';
import DeleteConfirmationDialog from 'src/components/core/ui/delete-confirmation';
import { useRemoveStaffMutation } from 'src/redux/@api/staff';
import {
  useGetAllPermissionsQuery,
  useUpdatePermisionsMutation
} from 'src/redux/@api/permissions';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4]
  }
}));

const CompactCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:last-child': {
    paddingBottom: theme.spacing(2)
  }
}));

const InfoTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8125rem'
  }
}));

const CompactAvatar = styled(Avatar)(({ theme }) => ({
  width: 56,
  height: 56,
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: 48,
    height: 48
  }
}));

const StaffCard = ({
  staff,
  onViewProfile,
  onDeleteStaff,
  onViewPermissions
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledCard variant="outlined">
      <CompactCardContent>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: isMobile ? 'row' : 'column',
            alignItems: isMobile ? 'center' : 'flex-start'
          }}
        >
          <CompactAvatar>
            {staff.user?.firstName[0]}
            {staff.user?.lastName[0]}
          </CompactAvatar>
          <Box sx={{ flex: 1, minWidth: 0, width: '100%' }}>
            <Typography
              variant="subtitle1"
              noWrap
              sx={{
                fontSize: isMobile ? '0.9rem' : '1rem',
                fontWeight: 'medium'
              }}
            >
              {staff.user?.firstName} {staff.user?.lastName}
            </Typography>
            <Chip
              label={staff.role.replace('_', ' ')}
              color={getRoleColor(staff.role)}
              size="small"
              variant="outlined"
              sx={{ mb: 1, fontSize: isMobile ? '0.75rem' : '0.8125rem' }}
            />
            <InfoTypography
              color="text.secondary"
              display="flex"
              alignItems="center"
              gap={0.5}
            >
              <Email sx={{ fontSize: isMobile ? 14 : 16 }} />
              {staff.user?.email}
            </InfoTypography>
            <InfoTypography
              color="text.secondary"
              display="flex"
              alignItems="center"
              gap={0.5}
            >
              <Phone sx={{ fontSize: isMobile ? 14 : 16 }} />
              {staff.user?.phone}
            </InfoTypography>
          </Box>
        </Box>
      </CompactCardContent>
      <CardActions
        sx={{
          pt: 0,
          px: 2,
          pb: 1,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            size="small"
            startIcon={<Person sx={{ fontSize: isMobile ? 16 : 20 }} />}
            onClick={() => onViewProfile(staff.user._id)}
            sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
          >
            Profile
          </Button>
          <Button
            size="small"
            startIcon={<Lock sx={{ fontSize: isMobile ? 16 : 20 }} />}
            onClick={() => onViewPermissions(staff.user._id)}
            sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
          >
            Permissions
          </Button>
        </Box>
        <Tooltip title="Delete staff">
          <IconButton
            size={isMobile ? 'small' : 'medium'}
            color="error"
            onClick={() => onDeleteStaff(staff.user._id)}
          >
            <DeleteOutlineOutlined />
          </IconButton>
        </Tooltip>
      </CardActions>
    </StyledCard>
  );
};

const CareStaffList: React.FC = () => {
  const [filter, setFilter] = useState<any>('all');
  const [staffs, setCareStaffs] = useState<IStaff[]>([]);
  const [viewProfile, setViewProfile] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [staffToDelete, setStaffToDelete] = useState<IStaff | null>(null);
  const [permissionsDialogOpen, setPermissionsDialogOpen] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [newPermission, setNewPermission] = useState<string>('');

  const theme = useTheme();

  const {
    data: careStaffs,
    isLoading,
    isError,
    error,
    refetch
  } = useGetCareStaffsQuery(undefined);

  const [removeStaff] = useRemoveStaffMutation();
  const [updateStaffPermissions] = useUpdatePermisionsMutation();

  const { data: allPermissions, isLoading: permissionsLoading } =
    useGetAllPermissionsQuery(undefined);

  useEffect(() => {
    if (careStaffs) {
      setCareStaffs(careStaffs);
    }
  }, [careStaffs]);

  const filteredStaff =
    filter === 'all' ? staffs : staffs.filter((staff) => staff.role === filter);

  const roles = ['all', ...new Set(staffs.map((staff) => staff.role))];

  const handleDeleteStaff = (staffId: string) => {
    const staff = staffs.find((s) => s._id === staffId);
    if (staff) {
      setStaffToDelete(staff);
      setDeleteDialogOpen(true);
    }
  };

  const confirmDeleteStaff = async () => {
    if (staffToDelete) {
      await removeStaff(staffToDelete._id);
      await refetch();
    }
    setDeleteDialogOpen(false);
    setStaffToDelete(null);
  };

  const handleViewPermissions = (staffId: string) => {
    const staff = staffs.find((s) => s.user._id === staffId);
    if (staff) {
      setSelectedStaff(staffId);
      setSelectedPermissions(staff.permissions || []);
      setPermissionsDialogOpen(true);
    }
  };

  const handlePermissionChange = (permission: string) => {
    setSelectedPermissions((prev) =>
      prev.includes(permission)
        ? prev.filter((p) => p !== permission)
        : [...prev, permission]
    );
  };

  const handleAddNewPermission = () => {
    if (newPermission && !selectedPermissions.includes(newPermission)) {
      setSelectedPermissions([...selectedPermissions, newPermission]);
      setNewPermission('');
    }
  };

  const handleSavePermissions = async () => {
    if (selectedStaff) {
      await updateStaffPermissions({
        userId: selectedStaff,
        permissions: selectedPermissions
      });
      await refetch();
      setPermissionsDialogOpen(false);
    }
  };

  const getPermissionKeyword = (permission: string) => {
    const foundPermission = allPermissions?.permissions.find(
      (p) => p.permission === permission
    );
    return foundPermission ? foundPermission.keyword : permission;
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
    >
      <Box sx={{ width: 220, mt: 1 }}>
        <FormControl size="small" fullWidth>
          <InputLabel>Filter by Role</InputLabel>
          <Select
            value={filter}
            label="Filter by Role"
            onChange={(e) => setFilter(e.target.value)}
          >
            {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {role === 'all' ? 'All Roles' : role.replace('_', ' ')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={2}>
        {filteredStaff.map((staff) => (
          <Grid item xs={12} sm={6} md={4} key={staff._id}>
            <StaffCard
              staff={staff}
              onViewProfile={(id) => {
                setSelectedStaff(id);
                setViewProfile(true);
              }}
              onDeleteStaff={handleDeleteStaff}
              onViewPermissions={handleViewPermissions}
            />
          </Grid>
        ))}
      </Grid>

      {/* Profile Dialog */}
      <Dialog
        open={viewProfile && selectedStaff !== null}
        onClose={() => setViewProfile(false)}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            padding: '0px !important',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              width: '95vw',
              maxHeight: '90vh',
              minHeight: '90vh'
            }
          }
        }}
      >
        <DialogContent sx={{ padding: '0px !important' }}>
          <Staffapplication userId={selectedStaff} isInternal={true} />
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={confirmDeleteStaff}
        itemName={`${staffToDelete?.user?.firstName} ${staffToDelete?.user?.lastName}`}
      />

      {/* Permissions Dialog */}
      <Dialog
        open={permissionsDialogOpen}
        onClose={() => setPermissionsDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Staff Permissions</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', height: '60vh' }}
        >
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth size="small">
              <InputLabel>Add Permission</InputLabel>
              <Select
                value={newPermission}
                onChange={(e) => setNewPermission(e.target.value as string)}
                label="Add Permission"
              >
                {allPermissions?.permissions
                  .filter((p) => !selectedPermissions.includes(p.permission))
                  .map((p) => (
                    <MenuItem key={p.permission} value={p.permission}>
                      {p.keyword}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddNewPermission}
              disabled={!newPermission}
              sx={{ mt: 1 }}
            >
              Add Permission
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {selectedPermissions.map((permission) => (
                <Chip
                  key={permission}
                  label={getPermissionKeyword(permission)}
                  onDelete={() => handlePermissionChange(permission)}
                  deleteIcon={
                    <CloseIcon
                      fontSize="small"
                      onClick={() => handlePermissionChange(permission)}
                    />
                  }
                  sx={{
                    bgcolor: 'transparent',
                    border: '1px solid',
                    borderColor: 'primary.main',
                    '& .MuiChip-deleteIcon': {
                      color: 'primary.main'
                    },
                    '& .MuiChip-label': {
                      color: 'text.primary'
                    }
                  }}
                />
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPermissionsDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleSavePermissions}
            variant="contained"
            color="primary"
          >
            Save Permissions
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CareStaffList;
