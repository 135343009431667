import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  styled,
  alpha,
  Fade,
  useTheme,
  Tooltip,
  CircularProgress
} from '@mui/material';
import {
  Save,
  DirectionsCar,
  Public,
  NoteAdd,
  LocationOn,
  CheckCircle,
  Info
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation
} from 'src/redux/@api/carer-application';

const StyledCard = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  padding: theme.spacing(3),
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0, 0, 0, 0.08)'
  }
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  '& svg': {
    fontSize: 28,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: 0,
    width: 40,
    height: 3,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.02)
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
    }
  }
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&.Mui-checked': {
    color: theme.palette.primary.main
  }
}));

const PreferenceCard = styled(Box)(
  ({ theme, active }: { theme?: any; active: boolean }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    backgroundColor: alpha(
      active ? theme.palette.primary.main : theme.palette.grey[500],
      0.1
    ),
    border: `1px solid ${alpha(
      active ? theme.palette.primary.main : theme.palette.grey[500],
      0.2
    )}`,
    transition: 'all 0.2s ease',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& svg': {
      color: active ? theme.palette.primary.main : theme.palette.grey[500]
    }
  })
);

const NotesBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  backgroundColor: alpha(theme.palette.info.main, 0.1),
  border: `1px solid ${alpha(theme.palette.info.main, 0.2)}`,
  marginTop: theme.spacing(2)
}));

interface AdditionalInfoProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const AdditionalInfo: React.FC<AdditionalInfoProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();
  const [saving, setSaving] = useState(false);

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {
        hasTransport: false,
        willingToTravel: false,
        maxTravelDistance: 0,
        additionalNotes: ''
      }
    : initialData || {};

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  const hasTransport = watch('hasTransport');
  const willingToTravel = watch('willingToTravel');

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const handleSave = async (data: any) => {
    if (!editable) return;
    setSaving(true);
    try {
      await updateApplicationSection({
        section: 'additionalInfo',
        data
      }).unwrap();

      await refetch();

      dispatch(
        showSnack({
          message: 'Additional information saved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: 'Failed to save additional information',
          color: 'error'
        })
      );
    } finally {
      setSaving(false);
    }
  };

  const renderPreferenceCard = (
    name: string,
    label: string,
    icon: React.ReactNode
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const isActive = field.value;
        return editable ? (
          <PreferenceCard active={isActive}>
            {icon}
            <Box sx={{ flex: 1 }}>
              <FormControlLabel
                control={<StyledCheckbox {...field} checked={field.value} />}
                label={label}
              />
            </Box>
            {isActive && <CheckCircle fontSize="small" />}
          </PreferenceCard>
        ) : (
          <PreferenceCard active={isActive}>
            {icon}
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {label}: {isActive ? 'Yes' : 'No'}
            </Typography>
          </PreferenceCard>
        );
      }}
    />
  );

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        p: { xs: 2, sm: 3, md: 4 },
        borderRadius: 4,
        boxShadow: (theme) =>
          `0 0 40px ${alpha(theme.palette.primary.main, 0.08)}`
      }}
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <Fade in timeout={500}>
          <StyledCard>
            <SectionHeader>
              <LocationOn />
              <SectionTitle>Travel & Additional Information</SectionTitle>
            </SectionHeader>

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                {renderPreferenceCard(
                  'hasTransport',
                  'I have my own transport',
                  <DirectionsCar />
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                {renderPreferenceCard(
                  'willingToTravel',
                  'I am willing to travel',
                  <Public />
                )}
              </Grid>

              {(hasTransport || willingToTravel) && (
                <Grid item xs={12} md={6}>
                  <Controller
                    name="maxTravelDistance"
                    control={control}
                    rules={{
                      min: { value: 0, message: 'Distance cannot be negative' },
                      max: { value: 1000, message: 'Distance seems too high' }
                    }}
                    render={({ field }) =>
                      editable ? (
                        <StyledTextField
                          {...field}
                          label="Maximum Travel Distance"
                          type="number"
                          fullWidth
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocationOn color="primary" />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                miles
                              </InputAdornment>
                            )
                          }}
                          error={!!errors.maxTravelDistance}
                          helperText={errors.maxTravelDistance?.message}
                        />
                      ) : (
                        <Box>
                          <Typography variant="caption" color="text.secondary">
                            Maximum Travel Distance
                          </Typography>
                          <Typography variant="h6">
                            {field.value} miles
                          </Typography>
                        </Box>
                      )
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Controller
                  name="additionalNotes"
                  control={control}
                  render={({ field }) =>
                    editable ? (
                      <NotesBox>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: 2
                          }}
                        >
                          <Info color="info" />
                          <Typography variant="subtitle2" color="info.main">
                            Additional Notes
                          </Typography>
                        </Box>
                        <StyledTextField
                          {...field}
                          placeholder="Add any additional information that might be relevant..."
                          multiline
                          rows={4}
                          fullWidth
                          variant="outlined"
                        />
                      </NotesBox>
                    ) : (
                      <NotesBox>
                        <Typography
                          variant="subtitle2"
                          color="info.main"
                          gutterBottom
                        >
                          Additional Notes
                        </Typography>
                        <Typography>
                          {field.value || 'No additional notes provided.'}
                        </Typography>
                      </NotesBox>
                    )
                  }
                />
              </Grid>
            </Grid>

            {editable && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={saving ? <CircularProgress size={20} /> : <Save />}
                  disabled={saving}
                  sx={{
                    borderRadius: 2,
                    px: 4,
                    py: 1,
                    background: (theme) =>
                      `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      background: (theme) =>
                        `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)'
                    }
                  }}
                >
                  {saving ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CircularProgress size={20} color="inherit" />
                      <span>Saving Changes...</span>
                    </Box>
                  ) : (
                    'Save Changes'
                  )}
                </Button>
              </Box>
            )}
          </StyledCard>
        </Fade>
      </form>
    </Box>
  );
};

export default AdditionalInfo;
