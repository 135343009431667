import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IResident } from 'src/interfaces/resident';
import { residentsApi } from '../@api/resident';
import { tasksApi } from '../@api/task';

interface ResidentState {
  residents: IResident[];
  loading: boolean;
  error: any;
}

interface ITaskSummary {
  upcoming: number;
  pending: number;
  overdue: number;
  total: number;
  urgency: 'white' | 'yellow' | 'orange' | 'red' | 'violet';
}

interface TaskUpdatePayload {
  residentId: string;
  oldStatus: string;
  newStatus: string;
}

const initialState: ResidentState = {
  residents: [],
  loading: false,
  error: null
};

export const residentSlice = createSlice({
  name: 'residents',
  initialState,
  reducers: {
    updateResident: (state, action: PayloadAction<IResident>) => {
      const updatedResident = action.payload;
      const index = state.residents.findIndex(
        (resident) => resident._id === updatedResident._id
      );

      if (index !== -1) {
        // Create new task summary with proper type casting
        const taskSummary: ITaskSummary = {
          upcoming: updatedResident.taskSummary?.upcoming || 0,
          pending: updatedResident.taskSummary?.pending || 0,
          overdue: updatedResident.taskSummary?.overdue || 0,
          total: updatedResident.taskSummary?.total || 0,
          urgency: updatedResident.taskSummary?.urgency || 'white'
        };

        // Update the resident while preserving the existing properties
        state.residents[index] = {
          ...state.residents[index],
          ...updatedResident,
          taskSummary: {
            ...state.residents[index].taskSummary,
            ...taskSummary
          }
        };

        // Recalculate total
        state.residents[index].taskSummary.total =
          taskSummary.upcoming + taskSummary.pending + taskSummary.overdue;

        // Recalculate urgency
        if (taskSummary.overdue > 2) {
          state.residents[index].taskSummary.urgency = 'violet';
        } else if (taskSummary.overdue > 0) {
          state.residents[index].taskSummary.urgency = 'red';
        } else if (taskSummary.pending > 2) {
          state.residents[index].taskSummary.urgency = 'orange';
        } else if (taskSummary.pending > 0 || taskSummary.upcoming > 0) {
          state.residents[index].taskSummary.urgency = 'yellow';
        } else {
          state.residents[index].taskSummary.urgency = 'white';
        }
      }
    },

    updateTaskStatus: (state, action: PayloadAction<TaskUpdatePayload>) => {
      const { residentId, oldStatus, newStatus } = action.payload;
      const residentIndex = state.residents.findIndex(
        (resident) => resident._id === residentId
      );

      if (residentIndex !== -1) {
        const resident = state.residents[residentIndex];
        const summary = { ...resident.taskSummary };

        // Decrement old status count
        if (oldStatus === 'upcoming')
          summary.upcoming = Math.max(0, summary.upcoming - 1);
        if (oldStatus === 'pending')
          summary.pending = Math.max(0, summary.pending - 1);
        if (oldStatus === 'overdue')
          summary.overdue = Math.max(0, summary.overdue - 1);

        // Increment new status count
        if (newStatus === 'upcoming') summary.upcoming++;
        if (newStatus === 'pending') summary.pending++;
        if (newStatus === 'overdue') summary.overdue++;

        // Update total
        summary.total = summary.upcoming + summary.pending + summary.overdue;

        // Calculate new urgency
        if (summary.overdue > 2) {
          summary.urgency = 'violet';
        } else if (summary.overdue > 0) {
          summary.urgency = 'red';
        } else if (summary.pending > 2) {
          summary.urgency = 'orange';
        } else if (summary.pending > 0 || summary.upcoming > 0) {
          summary.urgency = 'yellow';
        } else {
          summary.urgency = 'white';
        }

        // Update the resident's task summary
        state.residents[residentIndex].taskSummary = summary;
      }
    },

    updateResidentProfilePicture: (
      state,
      action: PayloadAction<{
        residentId: string;
        profilePictureUrl: string;
      }>
    ) => {
      const { residentId, profilePictureUrl } = action.payload;
      const index = state.residents.findIndex(
        (resident) => resident._id === residentId
      );

      if (index !== -1) {
        state.residents[index].profilePictureUrl = profilePictureUrl;
      }
    },

    fetchResidentsStart(state) {
      state.loading = true;
    },

    fetchResidentsSuccess(state, action) {
      state.loading = false;
      state.residents = action.payload;
    },

    fetchResidentsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      residentsApi.endpoints.getResidents.matchFulfilled,
      (state, action: PayloadAction<IResident[]>) => {
        console.log('action.payload.,,,', action.payload);
        state.residents = action.payload;
      }
    );
  }
});

export const {
  fetchResidentsStart,
  fetchResidentsSuccess,
  fetchResidentsFailure,
  updateResident,
  updateResidentProfilePicture,
  updateTaskStatus
} = residentSlice.actions;

export default residentSlice.reducer;
