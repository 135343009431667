import React, { useState, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
  Menu,
  ListItemIcon,
  ListItemText,
  Box,
  Tooltip,
  alpha,
  useTheme,
  IconButton
} from '@mui/material';
import {
  Edit,
  Delete,
  Refresh,
  CheckCircle as AvailableIcon,
  Cancel as UnavailableIcon,
  Info as InfoIcon
} from '@mui/icons-material';
import moment from 'moment';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useAssignUsersToShiftMutation } from 'src/redux/@api/shift';
import { useGetAvailableStaffQuery } from 'src/redux/@api/staff';
import ConfirmationDialog from './confirm-assign';

interface AssignStaffDialogProps {
  open: boolean;
  onClose: () => void;
  shift: {
    _id: string;
    date: string;
    count: number;
    homeId: { _id: string; name: string };
    shiftPattern: { _id: string; name: string };
    shiftAssignments?: Array<{ user: string }>;
  };
  onAssign: (staffIds: string[]) => void;
}

const AssignStaffDialog: React.FC<AssignStaffDialogProps> = ({
  open,
  onClose,
  shift,
  onAssign
}) => {
  const theme = useTheme();
  const [selectedStaff, setSelectedStaff] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');
  const [similarShifts, setSimilarShifts] = useState<any[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isAssigning, setIsAssigning] = useState(false);
  const [editingStaffId, setEditingStaffId] = useState<string | null>(null);

  const dispatch = useDispatch();
  const shifts = useAppSelector((state) => state.shifts.shifts);
  const [assignCarers] = useAssignUsersToShiftMutation();

  // Get available staff using new query
  const {
    data: availableStaffResponse,
    isLoading,
    isError,
    error,
    refetch: refetchAvailableStaff
  } = useGetAvailableStaffQuery(
    {
      shiftDate: shift.date,
      careHomeId: shift.homeId._id,
      shiftPatternId: shift.shiftPattern._id
    },
    {
      skip: !open
    }
  );

  const assignedStaffIds = useMemo(
    () => shift.shiftAssignments?.map((assignment) => assignment.user) || [],
    [shift.shiftAssignments]
  );

  const handleStaffToggle = (staffId: string) => {
    const newSelectedStaff = selectedStaff.includes(staffId)
      ? selectedStaff.filter((id) => id !== staffId)
      : [...selectedStaff, staffId];

    setSelectedStaff(newSelectedStaff);

    if (newSelectedStaff.length > 0) {
      const foundSimilarShifts = findSimilarShifts();
      if (foundSimilarShifts.length > 0) {
        setSimilarShifts(foundSimilarShifts);
        setShowConfirmation(true);
      }
    }
  };

  const filteredStaff = useMemo(() => {
    console.log(availableStaffResponse);
    if (!availableStaffResponse?.data) return [];

    return availableStaffResponse.data.filter((staff) => {
      const nameMatch = `${staff.user.firstName} ${staff.user.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const roleMatch = roleFilter === 'all' || staff.role === roleFilter;
      const isUnassigned = !assignedStaffIds.includes(staff.user._id);
      return nameMatch && roleMatch && isUnassigned;
    });
  }, [availableStaffResponse?.data, searchTerm, roleFilter, assignedStaffIds]);

  const handleAssign = async (assignToAll: boolean) => {
    if (selectedStaff.length === 0 || isAssigning) return;

    try {
      setIsAssigning(true); // Prevent multiple submissions

      const assignments = assignToAll
        ? [shift, ...similarShifts].map((s) => ({
            shiftId: s._id,
            userIds: selectedStaff
          }))
        : [
            {
              shiftId: shift._id,
              userIds: selectedStaff
            }
          ];

      const result = await assignCarers({
        assignments,
        shiftId: shift._id
      }).unwrap();

      // Process the result once
      if (result.some((r) => 'error' in r)) {
        dispatch(
          showSnack({
            message: 'Some assignments failed. Please check and try again.',
            color: 'warning'
          })
        );
      } else {
        dispatch(
          showSnack({
            message: 'Staff assigned successfully',
            color: 'success'
          })
        );

        // Call onAssign only once with the result
        if (Array.isArray(result)) {
          onAssign(result);
        }

        // Close the dialog only after successful assignment
        onClose();
      }
    } catch (error) {
      console.error('Failed to assign staff:', error);
      dispatch(
        showSnack({
          message: 'Failed to assign staff. Please try again.',
          color: 'error'
        })
      );
    } finally {
      setIsAssigning(false); // Reset loading state
      setShowConfirmation(false); // Close confirmation dialog
      setSelectedStaff([]); // Clear selections
    }
  };

  const findSimilarShifts = () => {
    const currentDate = moment(shift.date);
    return shifts
      .filter((s) => {
        const shiftDate = moment(s.date);
        return (
          shiftDate.isAfter(currentDate) &&
          s.shiftPattern._id === shift.shiftPattern._id &&
          s._id !== shift._id &&
          !s.isCompleted &&
          (!s.shiftAssignments || s.shiftAssignments.length < s.count)
        );
      })
      .sort((a, b) => moment(a.date).diff(moment(b.date)));
  };

  const renderStaffCard = (staff: any) => {
    const isAvailable = staff.availability.isAvailable;
    const reason = staff.availability.reason;

    return (
      <Box
        sx={{
          p: 2,
          border: 1,
          borderColor: 'divider',
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          bgcolor: isAvailable
            ? 'transparent'
            : alpha(theme.palette.action.disabled, 0.1)
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Checkbox
            checked={selectedStaff.includes(staff.user._id)}
            onChange={() => handleStaffToggle(staff.user._id)}
            disabled={
              !isAvailable ||
              (selectedStaff.length + assignedStaffIds.length >= shift.count &&
                !selectedStaff.includes(staff.user._id))
            }
          />
          <Box>
            <Typography variant="subtitle2">
              {staff?.user?.firstName} {staff?.user?.lastName}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {staff.role}
            </Typography>
          </Box>
        </Box>
        <Tooltip
          title={reason || (isAvailable ? 'Available' : 'Not Available')}
        >
          <Box sx={{ color: isAvailable ? 'success.main' : 'error.main' }}>
            {isAvailable ? <AvailableIcon /> : <UnavailableIcon />}
          </Box>
        </Tooltip>
      </Box>
    );
  };

  if (isLoading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  if (isError) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Box display="flex" alignItems="center" gap={1} color="error.main">
            <UnavailableIcon />
            <Typography>Error loading staff data</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  const remainingStaff = shift.count - assignedStaffIds.length;

  const handleEditClick = (staffId: string) => {
    setEditingStaffId(staffId);
    setAnchorEl(document.getElementById(`staff-chip-${staffId}`));
  };

  const handleEditClose = () => {
    setAnchorEl(null);
    setEditingStaffId(null);
  };

  const handleRemove = async () => {
    if (!editingStaffId) return;

    try {
      await assignCarers({
        assignments: [
          {
            shiftId: shift._id,
            userIds: []
          }
        ],
        unassignUserId: editingStaffId
      }).unwrap();

      dispatch(
        showSnack({
          message: 'Staff member removed successfully',
          color: 'success'
        })
      );

      handleEditClose();
      onAssign([]); // Trigger refresh
    } catch (error) {
      dispatch(
        showSnack({
          message: 'Failed to remove staff member',
          color: 'error'
        })
      );
    }
  };

  const handleReplace = () => {
    // Close the menu first
    handleEditClose();

    // Filter current staff member from selection
    setSelectedStaff(selectedStaff.filter((id) => id !== editingStaffId));

    // You might want to scroll to or highlight the staff list
    // to make it clear they should select a replacement
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            Assign Staff to {shift.shiftPattern.name}
          </Typography>
          <Box>
            <Chip
              label={`${
                availableStaffResponse?.meta.availableCount || 0
              } Available`}
              color="primary"
              size="small"
            />
            <IconButton
              color="primary"
              onClick={() => {
                refetchAvailableStaff();
              }}
            >
              <Refresh />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box mb={3}>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Required Staff: {remainingStaff > 0 ? remainingStaff : 0} more
          </Typography>
          {assignedStaffIds.length > 0 && (
            <Box mt={1}>
              <Typography variant="subtitle2" gutterBottom>
                Currently Assigned:
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {shift.shiftAssignments?.map((assignment) => {
                  const staff = availableStaffResponse?.data.find(
                    (s) => s.user._id === assignment.user
                  );
                  return (
                    <Chip
                      id={`staff-chip-${assignment.user}`} // Add this id
                      key={assignment.user}
                      label={`${staff?.user.firstName || ''} ${
                        staff?.user.lastName || ''
                      }`}
                      onDelete={() => handleEditClick(assignment.user)}
                      deleteIcon={<Edit />}
                    />
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>

        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Search staff"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Role</InputLabel>
              <Select
                value={roleFilter}
                label="Role"
                onChange={(e) => setRoleFilter(e.target.value)}
              >
                <MenuItem value="all">All roles</MenuItem>
                <MenuItem value="carer">Carer</MenuItem>
                <MenuItem value="nurse">Nurse</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box display="flex" flexDirection="column" gap={1}>
          {filteredStaff.map((staff) => renderStaffCard(staff))}
          {filteredStaff.length === 0 && (
            <Box
              sx={{
                p: 3,
                textAlign: 'center',
                bgcolor: alpha(theme.palette.primary.main, 0.03),
                borderRadius: 1
              }}
            >
              <Typography color="text.secondary">
                No staff members found matching your criteria
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => setShowConfirmation(true)}
          disabled={selectedStaff.length === 0}
          variant="contained"
          color="primary"
        >
          Assign Staff
        </Button>
      </DialogActions>

      <ConfirmationDialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        similarShifts={similarShifts}
        onConfirm={handleAssign}
      />
    </Dialog>
  );
};

export default AssignStaffDialog;
