import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  useTheme,
  useMediaQuery,
  IconButton,
  Divider,
  styled,
  Paper,
  Fade,
  Zoom,
  CircularProgress,
  Avatar
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Mail,
  Phone,
  MapPin,
  Calendar,
  Briefcase,
  User,
  Edit2,
  AlertCircle,
  Navigation,
  X,
  Save
} from 'lucide-react';
import { ICarerApplication } from 'src/interfaces/application';
import { useAppSelector } from 'src/redux/hook';
import { apiHostname } from 'src/api/api';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { _setAvatar } from 'src/redux/reducers/user-slice';

// Styled Components
const ProfileHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  color: theme.palette.common.white,
  padding: theme.spacing(8, 4),
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(10, 6)
  }
}));

const ProfileAvatar = styled(Box)(({ theme }) => ({
  width: 120,
  height: 120,
  borderRadius: '50%',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '2.5rem',
  fontWeight: 700,
  boxShadow: theme.shadows[3],
  border: '4px solid rgba(255, 255, 255, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)'
  }
}));

const ContactChip = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  padding: theme.spacing(1, 2),
  borderRadius: 20,
  '& svg': {
    opacity: 0.8
  }
}));

const InfoCard = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(4),
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8]
  }
}));

const FloatingEditButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  width: 64,
  height: 64,
  borderRadius: '50%',
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  color: theme.palette.common.white,
  boxShadow: theme.shadows[4],
  zIndex: theme.zIndex.fab,
  '&:hover': {
    background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
    transform: 'scale(1.1)'
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3),
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: 0,
    width: 40,
    height: 3,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 2
  }
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0.5)
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.primary,
  fontWeight: 500
}));

interface PersonalInformationProps {
  initialData?: ICarerApplication['personalInfo'];
  onSubmit: (data: any, index: number, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  initialData,
  onSubmit,
  editable = true
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isEditing, setIsEditing] = useState(false);
  const [showEditButton, setShowEditButton] = useState(true);

  const userState = useAppSelector((state) => state.userState);

  const [isUploading, setIsUploading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    userState.user.avatarUrl || ''
  );

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ICarerApplication['personalInfo']>({
    defaultValues: {
      ...initialData,
      address: initialData?.address || {},
      emergencyContact: initialData?.emergencyContact || {},
      jobTitle: initialData?.jobTitle || 'Health Care Assistant'
    }
  });

  const onSubmitForm = (data: ICarerApplication['personalInfo']) => {
    onSubmit(data, undefined, 'personalInfo');
    if (editable) {
      setIsEditing(false);
      setShowEditButton(true);
    }
  };

  const handleProfilePictureUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    // new
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      const formData = new FormData();

      const fileExtension = file.name.substr(file.name.lastIndexOf('.'));
      const newFileName = `profile_picture_${
        userState.user._id
      }_${Date.now()}${fileExtension}`;
      const renamedFile = new File([file], newFileName, { type: file.type });

      formData.append('file', renamedFile);

      try {
        const response = await axios.post(
          `${apiHostname}/api/v1/pictures/${userState.user._id}/upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          dispatch(
            showSnack({
              message: 'Profile picture updated',
              color: 'success'
            })
          );
          setProfilePicture(response.data?.data?.avatarUrl);
          dispatch(_setAvatar(response.data?.data?.avatarUrl));
        } else {
          throw new Error(
            response.data.error || 'Failed to update profile picture'
          );
        }
      } catch (error) {
        dispatch(showSnack({ message: error.message, color: 'error' }));
      } finally {
        setIsUploading(false);
      }
    }
  };

  const ProfileView = () => {
    const formValues = control._formValues;

    return (
      <Box p={0}>
        <ProfileHeader>
          <Box maxWidth="lg" margin="auto">
            <Grid container spacing={4} alignItems="center">
              <Grid
                item
                xs={12}
                md={2}
                sx={{ textAlign: { xs: 'center', md: 'left' } }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    justifyContent: 'center'
                  }}
                >
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="profile-picture-upload"
                    type="file"
                    onChange={handleProfilePictureUpload}
                  />
                  <label htmlFor="profile-picture-upload">
                    <Avatar
                      src={profilePicture}
                      sx={{
                        width: 100,
                        height: 100,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          transform: 'translate(-50%, -50%) scale(1.05)',
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }
                        },
                        '&:hover::before': {
                          content: '"Edit"',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: 'white',
                          zIndex: 1,
                          fontSize: '0.875rem',
                          fontWeight: 500
                        }
                      }}
                    />
                  </label>
                  <Box sx={{ mb: 2, textAlign: 'center' }} />
                </Box>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography
                  variant="h3"
                  component="h1"
                  sx={{
                    fontWeight: 700,
                    mb: 1,
                    textAlign: { xs: 'center', md: 'left' }
                  }}
                >
                  {formValues.title} {formValues.firstName}{' '}
                  {formValues.lastName}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.8)',
                    mb: 3,
                    textAlign: { xs: 'center', md: 'left' }
                  }}
                >
                  {formValues.jobTitle}
                </Typography>
                <Grid
                  container
                  spacing={2}
                  justifyContent={{ xs: 'center', md: 'flex-start' }}
                >
                  <Grid item>
                    <ContactChip>
                      <Mail size={18} />
                      <Typography>{formValues.email}</Typography>
                    </ContactChip>
                  </Grid>
                  <Grid item>
                    <ContactChip>
                      <Phone size={18} />
                      <Typography>{formValues.phone}</Typography>
                    </ContactChip>
                  </Grid>
                  <Grid item>
                    <ContactChip>
                      <MapPin size={18} />
                      <Typography>
                        {formValues.address?.city}, {formValues.address?.county}
                      </Typography>
                    </ContactChip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </ProfileHeader>

        <Box
          sx={{
            maxWidth: 'lg',
            margin: 'auto',
            mt: -6,
            px: { xs: 2, sm: 3, md: 4 },
            position: 'relative',
            zIndex: 1
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <InfoCard>
                <SectionTitle>Personal Details</SectionTitle>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <InfoLabel>Full Name</InfoLabel>
                    <InfoValue>
                      {formValues.title} {formValues.firstName}{' '}
                      {formValues.middleName} {formValues.lastName}
                    </InfoValue>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InfoLabel>Date of Birth</InfoLabel>
                    <InfoValue>
                      {formValues.dateOfBirth
                        ? new Date(formValues.dateOfBirth).toLocaleDateString()
                        : 'N/A'}
                    </InfoValue>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InfoLabel>Gender</InfoLabel>
                    <InfoValue>{formValues.gender}</InfoValue>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InfoLabel>National Insurance</InfoLabel>
                    <InfoValue>{formValues.nationalInsuranceNumber}</InfoValue>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 4 }} />

                <SectionTitle>Address Information</SectionTitle>
                <Box
                  sx={{
                    bgcolor: 'rgba(0, 0, 0, 0.02)',
                    p: 3,
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      mb: 2
                    }}
                  >
                    <Navigation
                      size={20}
                      style={{ color: theme.palette.primary.main }}
                    />
                    <Typography variant="subtitle1" fontWeight={500}>
                      Current Address
                    </Typography>
                  </Box>
                  <Typography sx={{ pl: 3.5 }}>
                    {formValues.address?.street},
                    <br />
                    {formValues.address?.city}, {formValues.address?.county},
                    <br />
                    {formValues.address?.postcode},{' '}
                    {formValues.address?.country}
                  </Typography>
                </Box>
              </InfoCard>
            </Grid>

            <Grid item xs={12} md={5}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <InfoCard
                    sx={{
                      bgcolor: theme.palette.error.light + '10',
                      borderLeft: `4px solid ${theme.palette.error.main}`
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3
                      }}
                    >
                      <AlertCircle
                        size={24}
                        style={{ color: theme.palette.error.main }}
                      />
                      <SectionTitle sx={{ mb: 0 }}>
                        Emergency Contact
                      </SectionTitle>
                    </Box>
                    <Box sx={{ pl: 4 }}>
                      <InfoLabel>Name</InfoLabel>
                      <InfoValue>{formValues.emergencyContact?.name}</InfoValue>
                      <InfoLabel sx={{ mt: 2 }}>Relationship</InfoLabel>
                      <InfoValue>
                        {formValues.emergencyContact?.relationship}
                      </InfoValue>
                      <InfoLabel sx={{ mt: 2 }}>Contact Number</InfoLabel>
                      <InfoValue>
                        {formValues.emergencyContact?.phone}
                      </InfoValue>
                    </Box>
                  </InfoCard>
                </Grid>

                <Grid item>
                  <InfoCard
                    sx={{
                      background: `linear-gradient(45deg, ${theme.palette.primary.light}15, ${theme.palette.secondary.light}15)`
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3
                      }}
                    >
                      <Briefcase
                        size={24}
                        style={{ color: theme.palette.primary.main }}
                      />
                      <SectionTitle sx={{ mb: 0 }}>
                        Professional Info
                      </SectionTitle>
                    </Box>
                    <Box sx={{ pl: 4 }}>
                      <InfoLabel>Current Position</InfoLabel>
                      <InfoValue>{formValues.jobTitle}</InfoValue>
                      <InfoLabel sx={{ mt: 2 }}>Department</InfoLabel>
                      <InfoValue>Healthcare Services</InfoValue>
                    </Box>
                  </InfoCard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {editable && showEditButton && (
          <Zoom in={showEditButton}>
            <FloatingEditButton
              onClick={() => {
                setIsEditing(true);
                setShowEditButton(false);
              }}
            >
              <Edit2 size={24} />
            </FloatingEditButton>
          </Zoom>
        )}
      </Box>
    );
  };

  const FormTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create(['box-shadow', 'transform']),
      '&:hover': {
        transform: 'translateY(-1px)'
      },
      '&.Mui-focused': {
        boxShadow: `0 4px 12px ${theme.palette.primary.main}25`
      }
    }
  }));

  const renderField = (
    name: string,
    label: string,
    rules = {},
    options = {}
  ) => (
    <Controller
      name={name as any}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormTextField
          {...field}
          label={label}
          fullWidth
          variant="outlined"
          error={!!errors[name]}
          helperText={errors[name]?.message}
          {...options}
        />
      )}
    />
  );

  const renderSelect = (
    name: string,
    label: string,
    options: { value: string; label: string }[]
  ) => (
    <Controller
      name={name as any}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            {...field}
            label={label}
            sx={{
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
              '&:hover': {
                transform: 'translateY(-1px)'
              }
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', pb: 5 }}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        {isEditing ? (
          <Fade in={isEditing}>
            <Box maxWidth="lg" sx={{ mx: 'auto', p: { xs: 2, sm: 3, md: 4 } }}>
              <Paper
                elevation={3}
                sx={{
                  p: { xs: 2, sm: 3, md: 4 },
                  borderRadius: 4,
                  backgroundColor: 'background.paper'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 4,
                    pb: 2,
                    borderBottom: 1,
                    borderColor: 'divider'
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    Edit Personal Information
                  </Typography>
                  <IconButton
                    onClick={() => setIsEditing(false)}
                    sx={{
                      color: 'error.main',
                      '&:hover': { bgcolor: 'error.light' + '20' }
                    }}
                  >
                    <X size={24} />
                  </IconButton>
                </Box>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    {renderSelect('title', 'Title', [
                      { value: 'Mr', label: 'Mr' },
                      { value: 'Mrs', label: 'Mrs' },
                      { value: 'Miss', label: 'Miss' },
                      { value: 'Ms', label: 'Ms' },
                      { value: 'Dr', label: 'Dr' },
                      { value: 'Other', label: 'Other' }
                    ])}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderField('firstName', 'First Name', {
                      required: 'First name is required'
                    })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderField('lastName', 'Last Name', {
                      required: 'Last name is required'
                    })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderField('middleName', 'Middle Name')}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderField('preferredName', 'Preferred Name')}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Controller
                        name="dateOfBirth"
                        control={control}
                        rules={{ required: 'Date of birth is required' }}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            label="Date of Birth"
                            renderInput={(params) => (
                              <FormTextField
                                {...params}
                                fullWidth
                                error={!!errors.dateOfBirth}
                                helperText={errors.dateOfBirth?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {renderSelect('gender', 'Gender', [
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' },
                      { value: 'Non-binary', label: 'Non-binary' },
                      { value: 'Other', label: 'Other' },
                      { value: 'Prefer not to say', label: 'Prefer not to say' }
                    ])}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderSelect('jobTitle', 'Job Title', [
                      {
                        value: 'Health Care Assistant',
                        label: 'Health Care Assistant'
                      },
                      {
                        value: 'Senior Care Assistant',
                        label: 'Senior Care Assistant'
                      },
                      { value: 'Nurse', label: 'Nurse' }
                    ])}
                  </Grid>
                  <Grid item xs={12}>
                    {renderField(
                      'nationalInsuranceNumber',
                      'National Insurance Number',
                      {
                        required: 'National Insurance number is required',
                        pattern: {
                          value:
                            /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D\s]$/,
                          message: 'Invalid National Insurance number'
                        }
                      }
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        mt: 2,
                        mb: 3,
                        fontWeight: 600,
                        color: 'primary.main'
                      }}
                    >
                      Address Details
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {renderField('address.street', 'Street', {
                      required: 'Street is required'
                    })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderField('address.city', 'City', {
                      required: 'City is required'
                    })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderField('address.county', 'County', {
                      required: 'County is required'
                    })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderField('address.postcode', 'Postcode', {
                      required: 'Postcode is required'
                    })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderField('address.country', 'Country', {
                      required: 'Country is required'
                    })}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        mt: 2,
                        mb: 3,
                        fontWeight: 600,
                        color: 'primary.main'
                      }}
                    >
                      Contact Information
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {renderField('phone', 'Phone Number', {
                      required: 'Phone number is required',
                      pattern: {
                        value:
                          /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
                        message: 'Invalid UK phone number'
                      }
                    })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderField('email', 'Email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address'
                      }
                    })}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        mt: 2,
                        mb: 3,
                        fontWeight: 600,
                        color: 'primary.main'
                      }}
                    >
                      Emergency Contact
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {renderField(
                      'emergencyContact.name',
                      'Emergency Contact Name',
                      {
                        required: 'Emergency contact name is required'
                      }
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderField(
                      'emergencyContact.relationship',
                      'Relationship',
                      {
                        required: 'Relationship is required'
                      }
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {renderField(
                      'emergencyContact.phone',
                      'Emergency Contact Phone',
                      {
                        required: 'Emergency contact phone is required',
                        pattern: {
                          value:
                            /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
                          message: 'Invalid UK phone number'
                        }
                      }
                    )}
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                    mt: 6
                  }}
                >
                  <Button
                    onClick={() => {
                      setIsEditing(false);
                      setShowEditButton(true);
                    }}
                    variant="outlined"
                    size="large"
                    sx={{
                      borderRadius: 2,
                      px: 4,
                      borderColor: 'error.main',
                      color: 'error.main',
                      '&:hover': {
                        borderColor: 'error.dark',
                        backgroundColor: 'error.light' + '10'
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    startIcon={<Save size={20} />}
                    sx={{
                      borderRadius: 2,
                      px: 4,
                      background: (theme) =>
                        `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                      '&:hover': {
                        background: (theme) =>
                          `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`
                      }
                    }}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Fade>
        ) : (
          <ProfileView />
        )}
      </form>
    </Box>
  );
};

export default PersonalInformation;
