import React, { Fragment, useEffect, useState, useRef } from 'react';
import {
  Box,
  useTheme,
  Grid,
  Typography,
  useMediaQuery,
  Slide,
  Button,
  Fab
} from '@mui/material';
import { useAppSelector } from 'src/redux/hook';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  QuickStatsCarerGrid,
  QuickStatsGrid
} from 'src/components/core/ui/widget';
import InvitationDialog from 'src/components/core/dialogs/invitations';
import ChatPopup from 'src/components/core/dialogs/chat-popup';
import { Permission, type IOrganizationRole } from 'src/interfaces/heirarchy';
import { Chat } from '@mui/icons-material';
import { AppCard } from 'src/components/core/ui/styled';
import ModernShiftCalendar from 'src/components/core/ui/shift-calendar';
import CareStaffShiftCalendar from 'src/components/core/ui/care-staff-calendar';
import { useLazyGetOrganizationRoleQuery } from 'src/redux/@api/organisation';
import { useLazyGetQuickStatsQuery } from 'src/redux/@api/timesheet';
import { shiftsApi } from 'src/redux/@api/shift';

export default function Home() {
  const { user, currentOrganization, staffType } = useAppSelector(
    (state) => state.userState
  );
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentRole, setCurrentRole] = useState<IOrganizationRole | null>(
    null
  );
  const [chatOpen, setChatOpen] = useState(false);
  const [slideIn, setSlideIn] = useState(false);
  const [openInvitation, setOpenInvitation] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wsRef = useRef<WebSocket | null>(null);

  const [getQuickStats, { data: quickStatsData }] = useLazyGetQuickStatsQuery();
  const [getOrgRole] = useLazyGetOrganizationRoleQuery();

  const wsHostname = process.env.REACT_APP_WS_HOSTNAME;

  useEffect(() => {
    if (user?._id) {
      getQuickStats({ month: currentMonth, year: currentYear });
    }
  }, [user?._id, currentMonth, currentYear, getQuickStats]);

  useEffect(() => {
    async function loadOrganizationRole() {
      if (user && currentOrganization) {
        try {
          const role = await getOrgRole(currentOrganization._id).unwrap();
          setCurrentRole(role);
        } catch (error) {
          console.error('Failed to fetch organization role:', error);
        }
      }
    }
    loadOrganizationRole();
  }, [user, currentOrganization, getOrgRole]);

  useEffect(() => {
    setSlideIn(true);
  }, [user._id]);

  useEffect(() => {
    if (currentOrganization?._id && user._id) {
      wsRef.current = new WebSocket(
        `${wsHostname}?userId=${user._id}&orgId=${currentOrganization._id}`
      );

      wsRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Received message:', data);
      };

      wsRef.current.onopen = () => {
        console.log('Websocket connection established');
      };

      return () => {
        if (wsRef.current) {
          wsRef.current.close();
        }
      };
    }
  }, [currentOrganization?._id, user._id, wsHostname]);

  const handleMonthChange = (month: number, year: number) => {
    setCurrentMonth(month);
    setCurrentYear(year);
  };

  const handleRefresh = async () => {
    try {
      await getQuickStats({ month: currentMonth, year: currentYear });
      dispatch(shiftsApi.util.invalidateTags(['Shift']));
    } catch (error) {
      console.error('Error during refresh:', error);
    }
  };

  const hasPermission = (permission: Permission) => {
    return currentRole?.permissions.includes(permission);
  };

  const quickStats = [
    {
      label: 'Total Shifts',
      value: quickStatsData?.totalShifts || 0,
      iconColor: 'white',
      bgColor: '#1976d2'
    },
    {
      label: 'Completed Shifts',
      value: quickStatsData?.completedShifts || 0,
      iconColor: 'white',
      bgColor: '#4caf50'
    },
    {
      label: 'Pending Shifts',
      value: quickStatsData?.pendingShifts || 0,
      iconColor: 'white',
      bgColor: '#ff9800'
    },
    {
      label: 'Previous Month',
      value: quickStatsData?.previousMonthShifts || 0,
      iconColor: 'white',
      bgColor: '#9c27b0'
    }
  ];

  const quickStatsCarer = [
    {
      label: 'Total Shifts',
      value: quickStatsData?.totalAssigned || 0,
      iconColor: 'white',
      bgColor: '#1976d2'
    },
    {
      label: 'Completed Shifts',
      value: quickStatsData?.completedShifts || 0,
      iconColor: 'white',
      bgColor: '#4caf50'
    },
    {
      label: 'Signed Shifts',
      value: quickStatsData?.signedShifts || 0,
      iconColor: 'white',
      bgColor: '#ff9800'
    },
    {
      label: 'Previous Month',
      value: quickStatsData?.previousMonthSignedShifts || 0,
      iconColor: 'white',
      bgColor: '#9c27b0'
    }
  ];

  const baseBottomBoxes = [
    {
      label: 'Settings',
      icon: 'iconamoon:profile-duotone',
      link: `/app/dashboards/settings`,
      permission: Permission.EDIT_ORGANIZATION
    },
    {
      label: 'Timesheets',
      icon: 'iconamoon:profile-duotone',
      link: `/app/dashboards/timesheets`,
      permission: Permission.VIEW_TIMESHEETS
    },
    {
      label: 'Documents',
      icon: 'iconamoon:profile-duotone',
      link: `/app/dashboards/documents`,
      permission: Permission.VIEW_DOCUMENTS
    }
  ];

  let bottomBoxes = [...baseBottomBoxes];

  if (currentOrganization?.type === 'agency') {
    bottomBoxes.push({
      label: 'Homes',
      icon: 'iconamoon:profile-duotone',
      link: `/app/dashboards/home-users`,
      permission: Permission.VIEW_HOMES
    });
  } else if (currentOrganization?.type === 'home') {
    bottomBoxes.push(
      {
        label: 'Residents',
        icon: 'iconamoon:profile-duotone',
        link: `/app/dashboards/residents`,
        permission: Permission.VIEW_RESIDENTS
      },
      {
        label: 'Agencies',
        icon: 'iconamoon:profile-duotone',
        link: `/app/dashboards/agency-users`,
        permission: Permission.VIEW_AGENCIES
      }
    );
  }

  const renderCalendar = () => {
    if (staffType === 'care') {
      return <CareStaffShiftCalendar onMonthChange={handleMonthChange} />;
    }
    return <ModernShiftCalendar onMonthChange={handleMonthChange} />;
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: `calc(100vh - ${theme.header.height} - 10px)`,
        overflow: 'auto',
        bgcolor: 'inherit',
        p: theme.spacing(2),
        pb: theme.spacing(3),
        flexGrow: 1
      }}
    >
      <Box>
        <Slide direction="down" in={slideIn} mountOnEnter unmountOnExit>
          <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
            Welcome, {user.firstName}!
          </Typography>
        </Slide>

        <Grid container>
          {staffType === 'care' ? (
            <QuickStatsCarerGrid quickStats={quickStatsCarer} />
          ) : (
            <QuickStatsGrid quickStats={quickStats} />
          )}
        </Grid>

        {isMobile ? (
          <Box sx={{ mt: 3 }}>{renderCalendar()}</Box>
        ) : (
          <Grid container sx={{ mt: 3 }} gap={10}>
            <Grid item xs={12} md={12}>
              {renderCalendar()}
            </Grid>
          </Grid>
        )}

        {isMobile && (
          <Fragment>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h5" gutterBottom>
                Recent Activity
              </Typography>
              <AppCard sx={{ p: 2 }}>
                <Typography>No recent activity</Typography>
              </AppCard>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h5" gutterBottom>
                Upcoming Events
              </Typography>
              <AppCard sx={{ p: 2 }}>
                <Typography>No upcoming events</Typography>
              </AppCard>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h5" gutterBottom>
                Quick Actions
              </Typography>
              <Grid container spacing={2}>
                {bottomBoxes.map(
                  (box, index) =>
                    hasPermission(box.permission) && (
                      <Grid item key={index}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => navigate(box.link)}
                        >
                          {box.label}
                        </Button>
                      </Grid>
                    )
                )}
              </Grid>
            </Box>
          </Fragment>
        )}

        <InvitationDialog
          open={openInvitation}
          onClose={() => {
            setOpenInvitation(false);
            localStorage.removeItem('invToken');
          }}
        />

        {hasPermission(Permission.USE_CHAT) && (
          <Fab
            color="primary"
            aria-label="chat"
            onClick={() => setChatOpen((prev) => !prev)}
          >
            <Chat />
          </Fab>
        )}

        <ChatPopup open={chatOpen} onClose={() => setChatOpen(false)} />
      </Box>
    </Box>
  );
}
