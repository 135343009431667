import OpacityIcon from '@mui/icons-material/Opacity';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import BathtubIcon from '@mui/icons-material/Bathtub';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HotelIcon from '@mui/icons-material/Hotel';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { Icon } from '@iconify/react';
import { useMemo } from 'react';

interface TaskType {
  type: string;
  label: string;
  icon: JSX.Element;
  description?: string;
  defaultFrequency?: 'frequent' | 'daily' | 'weekly' | 'monthly' | 'one-time';
}

export const taskTypes: TaskType[] = [
  {
    type: 'pad check',
    label: 'Pad/Continence Check',
    icon: <Icon icon="icon-park:clothes-diapers" />,
    description: 'Check and change continence pads',
    defaultFrequency: 'frequent'
  },
  {
    type: 'wellbeing',
    label: 'Wellbeing Check',
    icon: <WbSunnyIcon />,
    description: 'Regular wellness check',
    defaultFrequency: 'daily'
  },
  {
    type: 'medication',
    label: 'Medication',
    icon: <MedicalServicesIcon />,
    description: 'Medication administration',
    defaultFrequency: 'daily'
  },
  {
    type: 'vital signs',
    label: 'Vital Signs',
    icon: <MonitorHeartIcon />,
    description: 'Check blood pressure, temperature, pulse, etc.',
    defaultFrequency: 'daily'
  },
  {
    type: 'meal',
    label: 'Meal/Nutrition',
    icon: <RestaurantIcon />,
    description: 'Meal service and nutrition monitoring',
    defaultFrequency: 'daily'
  },
  {
    type: 'fluid intake',
    label: 'Fluid Intake',
    icon: <OpacityIcon />,
    description: 'Monitor and record fluid intake',
    defaultFrequency: 'frequent'
  },
  {
    type: 'hygiene',
    label: 'Hygiene',
    icon: <CleanHandsIcon />,
    description: 'Personal hygiene assistance',
    defaultFrequency: 'daily'
  },
  {
    type: 'bathing',
    label: 'Bathing',
    icon: <BathtubIcon />,
    description: 'Bathing or showering assistance',
    defaultFrequency: 'weekly'
  },
  {
    type: 'positioning',
    label: 'Positioning/Turning',
    icon: <AccessibilityNewIcon />,
    description: 'Repositioning for pressure relief',
    defaultFrequency: 'frequent'
  },
  {
    type: 'exercise',
    label: 'Exercise/Mobility',
    icon: <FitnessCenterIcon />,
    description: 'Physical activity and mobility assistance',
    defaultFrequency: 'daily'
  },
  {
    type: 'social',
    label: 'Social Activity',
    icon: <SocialDistanceIcon />,
    description: 'Social interaction and activities',
    defaultFrequency: 'daily'
  },
  {
    type: 'sleep check',
    label: 'Sleep Check',
    icon: <HotelIcon />,
    description: 'Monitor sleep patterns and rest periods',
    defaultFrequency: 'daily'
  },
  {
    type: 'wound care',
    label: 'Wound Care',
    icon: <LocalHospitalIcon />,
    description: 'Wound assessment and dressing changes',
    defaultFrequency: 'daily'
  }
  // {
  //   type: 'assessment',
  //   label: 'Assessment',
  //   icon: <NoteAltIcon />,
  //   description: 'General health assessment',
  //   defaultFrequency: 'weekly'
  // },
  // {
  //   type: 'notes',
  //   label: 'Progress Notes',
  //   icon: <EventNoteIcon />,
  //   description: 'Record observations and updates',
  //   defaultFrequency: 'daily'
  // }
];

// Helper function to get task type details
export const getTaskTypeDetails = (type: string): TaskType | undefined => {
  return taskTypes.find((task) => task.type === type);
};

// Helper function to get default frequency for a task type
export const getDefaultFrequency = (type: string): string => {
  const taskType = getTaskTypeDetails(type);
  return taskType?.defaultFrequency || 'daily';
};

// Helper function to get formatted label for a task type
export const getTaskLabel = (type: string): string => {
  const taskType = getTaskTypeDetails(type);
  return taskType?.label || type;
};

export default taskTypes;
