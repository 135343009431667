import React, { useContext, useEffect, useRef, useMemo } from 'react';
import {
  Box,
  styled,
  Divider,
  useTheme,
  Typography,
  Drawer,
  useMediaQuery,
  type DrawerProps
} from '@mui/material';
import SidebarMenu from './SidebarMenu';
import Scrollbar from 'src/components/Scrollbar';
import { useSidebar } from '../sidebarContext';
import { SidebarContext } from 'src/contexts/SidebarContext';
import type { Theme } from '@mui/material/styles';
import { SidebarMenuProvider } from './SidebarMenu/context';

const SidebarWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isexpanded'
})<{
  theme: Theme;
  isexpanded: boolean;
}>(
  ({ theme, isexpanded }) => `
  width: ${isexpanded ? theme.sidebar.width : '80px'};
  min-width: ${isexpanded ? theme.sidebar.width : '80px'};
  color: ${theme.colors.alpha.trueWhite[70]};
  position: fixed;
  z-index: 7;
  height: 100%;
  padding-bottom: 68px;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
`
);

const SidebarWrapperD = styled(Box)(
  ({ theme }) => `
  width: ${theme.sidebar.width};
  min-width: ${theme.sidebar.width};
  position: relative;
  z-index: 7;
  height: 100vh;
  background: ${theme.colors.secondary.main};
  ${theme.breakpoints.up('sm')}{
    background: ${theme.colors.secondary.main};
    height: 98vh;
    margin-top: 1vh;
    margin-left: 15px;
    border-radius: ${theme.general.borderRadius};
  };
  overflow-y: hidden;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
`
);

const SidebarContent = React.memo(() => {
  const theme = useTheme();
  const { isExpanded } = useSidebar();

  return (
    <Scrollbar>
      <Box mt={3} display="flex" justifyContent="center" alignItems="center">
        <img
          src={require('src/assets/logos/bvc_logo_drawer.png')}
          alt="logo"
          width={50}
          style={{
            position: 'absolute',
            top: 10
          }}
        />
      </Box>
      <Divider
        sx={{
          mt: theme.spacing(4),
          mx: theme.spacing(2),
          background: theme.colors.alpha.trueWhite[10]
        }}
      />
      <SidebarMenu />
    </Scrollbar>
  );
});

const Sidebar = React.memo(() => {
  const { isExpanded, setIsExpanded } = useSidebar();
  const theme = useTheme();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const handleMouseEnter = () => {
    if (!isMobile) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setIsExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      timeoutRef.current = setTimeout(() => {
        setIsExpanded(false);
      }, 300);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const sidebarWrapperProps = useMemo(
    () => ({
      sx: {
        display: { xs: 'none', lg: 'block' },
        background: theme.colors.secondary.main,
        boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.1)'
      },
      theme: theme,
      isexpanded: isExpanded,
      ref: sidebarRef,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave
    }),
    [isExpanded, theme, isMobile]
  );

  const drawerProps = useMemo(
    (): DrawerProps => ({
      sx: {
        boxShadow: `${theme.sidebar.boxShadow}`,
        display: { xs: 'block', lg: 'none' }
      },
      anchor: theme.direction === 'rtl' ? 'right' : 'left',
      open: sidebarToggle,
      onClose: closeSidebar,
      variant: 'temporary',
      elevation: 9
    }),
    [theme, sidebarToggle, closeSidebar]
  );

  return (
    <SidebarMenuProvider>
      {!isMobile && (
        <SidebarWrapper {...sidebarWrapperProps}>
          <SidebarContent />
        </SidebarWrapper>
      )}
      <Drawer {...drawerProps}>
        <SidebarWrapperD>
          <SidebarContent />
        </SidebarWrapperD>
      </Drawer>
    </SidebarMenuProvider>
  );
});

export default Sidebar;
