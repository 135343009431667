import { Download } from '@mui/icons-material';
import {
  Dialog,
  Box,
  Typography,
  Button,
  CircularProgress,
  Divider,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import { useState } from 'react';

const StyledWarningDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '12px',
    padding: theme.spacing(2)
  }
}));

// Add this component before the main InvoiceDialog component
interface SaveWarningDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
  onSaveAndDownload: () => Promise<void>;
  isSaving?: boolean;
}

const SaveWarningDialog: React.FC<SaveWarningDialogProps> = ({
  open,
  onClose,
  onSave,
  onSaveAndDownload,
  isSaving
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const theme = useTheme();

  const handleAction = async (action: 'save' | 'saveAndDownload') => {
    try {
      setIsProcessing(true);
      if (action === 'save') {
        await onSave();
      } else {
        await onSaveAndDownload();
      }
    } catch (error) {
      console.error('Action failed:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <StyledWarningDialog
      open={open}
      onClose={!isProcessing ? onClose : undefined}
      maxWidth="xs"
      fullWidth
    >
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mb: 2,
            color: theme.palette.warning.main
          }}
        >
          <Box
            sx={{
              bgcolor: alpha(theme.palette.warning.main, 0.1),
              p: 1,
              borderRadius: '50%'
            }}
          >
            <Download color="warning" sx={{ fontSize: 28 }} />
          </Box>
          <Typography variant="h6">Invoice Not Saved</Typography>
        </Box>

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: 3, pl: 7 }}
        >
          The invoice needs to be saved before downloading. Would you like to:
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            pl: 7,
            mb: 3
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleAction('save')}
            disabled={isProcessing}
            startIcon={isProcessing ? <CircularProgress size={20} /> : null}
            sx={{ justifyContent: 'flex-start' }}
          >
            Save Invoice Only
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAction('saveAndDownload')}
            disabled={isProcessing}
            startIcon={isProcessing ? <CircularProgress size={20} /> : null}
            sx={{ justifyContent: 'flex-start' }}
          >
            Save and Download PDF
          </Button>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1
          }}
        >
          <Button
            variant="text"
            onClick={onClose}
            disabled={isProcessing}
            sx={{ color: theme.palette.text.secondary }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </StyledWarningDialog>
  );
};

export default SaveWarningDialog;
