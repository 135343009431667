import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Radio,
  RadioGroup,
  FormLabel,
  styled,
  alpha,
  Fade,
  Divider,
  useTheme
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Save,
  HealthAndSafety as HealthAndSafetyIcon,
  Vaccines,
  Assignment,
  Restaurant,
  Info,
  Warning,
  CheckCircle
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation
} from 'src/redux/@api/carer-application';

// Styled Components
const StyledCard = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  padding: theme.spacing(3),
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0, 0, 0, 0.08)'
  }
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  '& svg': {
    fontSize: 28,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: 0,
    width: 40,
    height: 3,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.02)
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
    }
  }
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.primary.main
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1)
  }
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.primary.main
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1)
  }
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: -11,
  '& .MuiFormControlLabel-label': {
    fontSize: '0.9rem',
    color: theme.palette.text.secondary
  }
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
  fontSize: '1rem',
  marginBottom: theme.spacing(1),
  '&.Mui-focused': {
    color: theme.palette.text.primary
  }
}));

const VaccinationCard = styled(Box)(
  ({ theme, completed }: { theme?: any; completed: boolean }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    backgroundColor: alpha(
      completed ? theme.palette.success.main : theme.palette.grey[500],
      0.1
    ),
    border: `1px solid ${alpha(
      completed ? theme.palette.success.main : theme.palette.grey[500],
      0.2
    )}`,
    transition: 'all 0.2s ease'
  })
);

interface HealthAndSafetyProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const HealthAndSafety: React.FC<HealthAndSafetyProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();
  const [saving, setSaving] = useState(false);
  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {
        healthDeclaration: {
          hasHealthConditions: 'false',
          healthConditionsDetails: '',
          requiresAdjustments: 'false',
          adjustmentsDetails: '',
          vaccinationStatus: {
            tetanus: false,
            tetanusDate: null,
            covid19: false,
            covid19Date: null,
            fluShot: false,
            fluShotDate: null
          }
        },
        manualHandlingTraining: {
          completed: false,
          completionDate: null
        },
        foodHygieneCertificate: {
          held: false,
          expiryDate: null
        }
      }
    : initialData || {};

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues
  });

  const hasHealthConditions =
    watch('healthDeclaration.hasHealthConditions') === 'true';
  const requiresAdjustments =
    watch('healthDeclaration.requiresAdjustments') === 'true';

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const handleSave = async (data: any) => {
    if (!editable) return;
    setSaving(true);
    try {
      await updateApplicationSection({
        section: 'healthAndSafety',
        data
      }).unwrap();

      await refetch();

      dispatch(
        showSnack({
          message: 'Health and safety information saved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: 'Failed to save health and safety information',
          color: 'error'
        })
      );
    } finally {
      setSaving(false);
    }
  };

  const renderDatePicker = (name: string, label: string) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        editable ? (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              {...field}
              label={label}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  fullWidth
                  size="small"
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: 'background.paper'
                    }
                  }}
                />
              )}
            />
          </LocalizationProvider>
        ) : (
          <Box>
            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
            >
              {label}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {field.value ? new Date(field.value).toLocaleDateString() : 'N/A'}
            </Typography>
          </Box>
        )
      }
    />
  );

  const renderTextField = (
    name: string,
    label: string,
    multiline = false,
    rows = 1
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        editable ? (
          <StyledTextField
            {...field}
            label={label}
            multiline={multiline}
            rows={rows}
            fullWidth
            size="small"
            error={!!error}
            helperText={error?.message}
          />
        ) : (
          <Box>
            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
            >
              {label}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {field.value || 'N/A'}
            </Typography>
          </Box>
        )
      }
    />
  );

  const renderHealthDeclarationSection = () => (
    <Fade in timeout={500}>
      <StyledCard sx={{ mb: 4 }}>
        <SectionHeader>
          <SectionTitle>Health Declaration</SectionTitle>
        </SectionHeader>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="healthDeclaration.hasHealthConditions"
              control={control}
              render={({ field }) =>
                editable ? (
                  <FormControl component="fieldset" fullWidth>
                    <StyledFormLabel>
                      Do you have any health conditions that may affect your
                      work?
                    </StyledFormLabel>
                    <RadioGroup {...field} row>
                      <StyledFormControlLabel
                        value="true"
                        control={<StyledRadio />}
                        label="Yes"
                      />
                      <StyledFormControlLabel
                        value="false"
                        control={<StyledRadio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                ) : (
                  <Box>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      display="block"
                    >
                      Health Conditions
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {field.value === 'true' ? 'Yes' : 'No'}
                    </Typography>
                  </Box>
                )
              }
            />
          </Grid>

          {hasHealthConditions && (
            <Grid item xs={12}>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 2,
                  bgcolor: (theme) => alpha(theme.palette.warning.main, 0.1),
                  border: 1,
                  borderColor: (theme) => alpha(theme.palette.warning.main, 0.2)
                }}
              >
                <Box
                  sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }}
                >
                  <Warning color="warning" />
                  <Typography variant="subtitle2" color="warning.main">
                    Health Condition Details
                  </Typography>
                </Box>
                {renderTextField(
                  'healthDeclaration.healthConditionsDetails',
                  'Please provide details of your health conditions',
                  true,
                  4
                )}
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              name="healthDeclaration.requiresAdjustments"
              control={control}
              render={({ field }) =>
                editable ? (
                  <FormControl component="fieldset" fullWidth>
                    <StyledFormLabel>
                      Do you require any adjustments to perform your work?
                    </StyledFormLabel>
                    <RadioGroup {...field} row>
                      <StyledFormControlLabel
                        value="true"
                        control={<StyledRadio />}
                        label="Yes"
                      />
                      <StyledFormControlLabel
                        value="false"
                        control={<StyledRadio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                ) : (
                  <Box>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      display="block"
                    >
                      Requires Adjustments
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {field.value === 'true' ? 'Yes' : 'No'}
                    </Typography>
                  </Box>
                )
              }
            />
          </Grid>

          {requiresAdjustments && (
            <Grid item xs={12}>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 2,
                  bgcolor: (theme) => alpha(theme.palette.info.main, 0.1),
                  border: 1,
                  borderColor: (theme) => alpha(theme.palette.info.main, 0.2)
                }}
              >
                <Box
                  sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }}
                >
                  <Info color="info" />
                  <Typography variant="subtitle2" color="info.main">
                    Adjustment Requirements
                  </Typography>
                </Box>
                {renderTextField(
                  'healthDeclaration.adjustmentsDetails',
                  'Please provide details of required adjustments',
                  true,
                  4
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </StyledCard>
    </Fade>
  );

  const renderVaccinationSection = () => {
    const vaccineTypes = [
      { key: 'tetanus', label: 'Tetanus' },
      { key: 'covid19', label: 'COVID-19' },
      { key: 'fluShot', label: 'Flu Shot' }
    ];

    return (
      <Fade in timeout={500}>
        <StyledCard sx={{ mb: 4 }}>
          <SectionHeader>
            <Vaccines />
            <SectionTitle>Vaccination Status</SectionTitle>
          </SectionHeader>

          <Grid container spacing={3}>
            {vaccineTypes.map(({ key, label }) => {
              const isVaccinated = watch(
                `healthDeclaration.vaccinationStatus.${key}`
              );
              return (
                <Grid item xs={12} key={key}>
                  <VaccinationCard completed={isVaccinated}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name={`healthDeclaration.vaccinationStatus.${key}`}
                          control={control}
                          render={({ field }) =>
                            editable ? (
                              <StyledFormControlLabel
                                control={
                                  <StyledCheckbox
                                    {...field}
                                    checked={field.value}
                                  />
                                }
                                label={`${label} Vaccination`}
                              />
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1
                                }}
                              >
                                {field.value ? (
                                  <CheckCircle
                                    color="success"
                                    fontSize="small"
                                  />
                                ) : null}
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: 500 }}
                                >
                                  {label} Vaccination:{' '}
                                  {field.value ? 'Completed' : 'Not Completed'}
                                </Typography>
                              </Box>
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        {renderDatePicker(
                          `healthDeclaration.vaccinationStatus.${key}Date`,
                          `${label} Vaccination Date`
                        )}
                      </Grid>
                    </Grid>
                  </VaccinationCard>
                </Grid>
              );
            })}
          </Grid>
        </StyledCard>
      </Fade>
    );
  };

  const renderTrainingSection = () => {
    const hasManualHandling = watch('manualHandlingTraining.completed');
    const hasFoodHygiene = watch('foodHygieneCertificate.held');

    return (
      <Fade in timeout={500}>
        <StyledCard sx={{ mb: 4 }}>
          <SectionHeader>
            <Assignment />
            <SectionTitle>Training & Certifications</SectionTitle>
          </SectionHeader>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <VaccinationCard completed={hasManualHandling}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="manualHandlingTraining.completed"
                      control={control}
                      render={({ field }) =>
                        editable ? (
                          <StyledFormControlLabel
                            control={
                              <StyledCheckbox
                                {...field}
                                checked={field.value}
                              />
                            }
                            label="Manual Handling Training"
                          />
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1
                            }}
                          >
                            {field.value ? (
                              <CheckCircle color="success" fontSize="small" />
                            ) : null}
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 500 }}
                            >
                              Manual Handling Training:{' '}
                              {field.value ? 'Completed' : 'Not Completed'}
                            </Typography>
                          </Box>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {renderDatePicker(
                      'manualHandlingTraining.completionDate',
                      'Completion Date'
                    )}
                  </Grid>
                </Grid>
              </VaccinationCard>
            </Grid>

            <Grid item xs={12}>
              <VaccinationCard completed={hasFoodHygiene}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="foodHygieneCertificate.held"
                      control={control}
                      render={({ field }) =>
                        editable ? (
                          <StyledFormControlLabel
                            control={
                              <StyledCheckbox
                                {...field}
                                checked={field.value}
                              />
                            }
                            label="Food Hygiene Certificate"
                          />
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1
                            }}
                          >
                            {field.value ? (
                              <CheckCircle color="success" fontSize="small" />
                            ) : null}
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 500 }}
                            >
                              Food Hygiene Certificate:{' '}
                              {field.value ? 'Held' : 'Not Held'}
                            </Typography>
                          </Box>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {renderDatePicker(
                      'foodHygieneCertificate.expiryDate',
                      'Expiry Date'
                    )}
                  </Grid>
                </Grid>
              </VaccinationCard>
            </Grid>
          </Grid>
        </StyledCard>
      </Fade>
    );
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        p: { xs: 2, sm: 3, md: 4 },
        borderRadius: 4,
        boxShadow: (theme) =>
          `0 0 40px ${alpha(theme.palette.primary.main, 0.08)}`
      }}
    >
      <form onSubmit={handleSubmit(handleSave)}>
        {renderHealthDeclarationSection()}
        {renderVaccinationSection()}

        {editable && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button
              type="submit"
              variant="contained"
              startIcon={saving ? <CircularProgress size={20} /> : <Save />}
              disabled={saving}
              sx={{
                borderRadius: 2,
                px: 4,
                py: 1,
                background: (theme) =>
                  `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  background: (theme) =>
                    `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                  boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)'
                },
                '&:disabled': {
                  background: (theme) => theme.palette.grey[300]
                }
              }}
            >
              {saving ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CircularProgress size={20} color="inherit" />
                  <span>Saving Changes...</span>
                </Box>
              ) : (
                'Save Health & Safety Information'
              )}
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default HealthAndSafety;
