import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Avatar,
  Chip,
  useTheme,
  useMediaQuery,
  styled,
  Pagination,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  Search,
  Home,
  Business,
  MoreVert,
  Delete,
  Email,
  People
} from '@mui/icons-material';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import SearchOrganizationsModal from 'src/components/core/ui/org-search';
import {
  useGetLinkedOrganizationsQuery,
  useUnlinkOrganizationsMutation
} from 'src/redux/@api/organisation';
import InviteOrganizationDialog from './invite-dialog';
import StaffDialog from './org-views/staffs';

interface Organization {
  _id: number;
  name: string;
  email: string;
  logoUrl: string;
  type: 'agency' | 'home';
  status: 'Active' | 'Inactive';
}

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8]
  },
  position: 'relative',
  borderRadius: theme.shape.borderRadius
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  paddingBottom: theme.spacing(1)
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  paddingTop: 0
}));

const TypeIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  minHeight: `calc(100vh - ${theme.header.height})`,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3)
}));

const LogoAvatar = styled(Avatar)(({ theme }) => ({
  width: 60,
  height: 60
}));

const LinkedOrganizationsList: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [page, setPage] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [organizationToRemove, setOrganizationToRemove] =
    useState<Organization | null>(null);

  const [openStaffDialog, setOpenStaffDialog] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);

  // Add these handler functions
  const handleOpenStaffDialog = (orgId: number) => {
    setSelectedOrgId(orgId);
    setOpenStaffDialog(true);
  };

  const handleCloseStaffDialog = () => {
    setOpenStaffDialog(false);
    setSelectedOrgId(null);
  };

  const userState = useAppSelector((state) => state.userState);

  const {
    data: linkedOrganizations,
    isLoading,
    isError,
    refetch: refetchLinkedOrganizations
  } = useGetLinkedOrganizationsQuery(
    userState?.currentOrganization.type === 'agency' ? 'home' : 'agency'
  );

  const [unlinkOrganization] = useUnlinkOrganizationsMutation();

  useEffect(() => {
    if (linkedOrganizations && linkedOrganizations?.length > 0) {
      setOrganizations(linkedOrganizations);
    } else {
      setOrganizations([]);
    }
  }, [linkedOrganizations]);

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const itemsPerPage = 9;
  const pageCount = Math.ceil(organizations.length / itemsPerPage);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const handleOpenSearchModal = () => {
    setOpenSearchModal(true);
  };

  const handleCloseSearchModal = () => {
    setOpenSearchModal(false);
  };

  const handleOpenRemoveDialog = (org: Organization) => {
    setOrganizationToRemove(org);
    setOpenRemoveDialog(true);
  };

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
    setOrganizationToRemove(null);
  };

  const handleRemoveOrganization = async () => {
    try {
      await unlinkOrganization({
        organizationId1: userState.currentOrganization._id,
        organizationId2: organizationToRemove?._id
      }).unwrap();
      dispatch(
        showSnack({ message: 'Organization removed', color: 'success' })
      );
    } catch (error) {
      dispatch(
        showSnack({ message: 'Error removing organization', color: 'error' })
      );
    }
  };

  const getGridColumns = () => {
    if (isMobile) return 12;
    if (isTablet) return 6;
    return 4;
  };

  const currentPageData = organizations.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  //invitations
  const [openInviteDialog, setOpenInviteDialog] = useState(false);

  const handleOpenInviteDialog = () => {
    setOpenInviteDialog(true);
  };

  const handleCloseInviteDialog = () => {
    setOpenInviteDialog(false);
  };

  return (
    <ContentWrapper>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Linked Organizations
          </Typography>
          {userState.user.role === 'admin' && (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="outlined"
                startIcon={<Email />}
                onClick={handleOpenInviteDialog}
                sx={{ borderRadius: '20px' }}
              >
                Invite
              </Button>
              <Button
                variant="contained"
                startIcon={<Search />}
                onClick={handleOpenSearchModal}
                sx={{ borderRadius: '20px' }}
              >
                Search
              </Button>
            </Box>
          )}
        </Box>

        <Grid container spacing={3} sx={{ mb: 4 }}>
          {currentPageData.map((org) => (
            <Grid item xs={12} sm={6} md={4} key={org._id}>
              <StyledCard>
                <StyledCardHeader
                  avatar={
                    <LogoAvatar src={org.logoUrl} alt={org.name}></LogoAvatar>
                  }
                  action={
                    <Tooltip title="Remove organization">
                      <IconButton
                        aria-label="remove"
                        onClick={() => {
                          handleOpenRemoveDialog(org);
                        }}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </Tooltip>
                  }
                  title={
                    <Typography variant="h6" component="div">
                      {org.name}
                    </Typography>
                  }
                  subheader={org.email}
                />
                <StyledCardContent>
                  <Box
                    sx={{
                      mt: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Chip
                      icon={org.type === 'home' ? <Home /> : <Business />}
                      label={org.type}
                      size="small"
                      color={org.type === 'home' ? 'primary' : 'secondary'}
                    />
                    {org.type === 'agency' && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleOpenStaffDialog(org._id)}
                        startIcon={<People />}
                      >
                        View Staff
                      </Button>
                    )}
                  </Box>
                </StyledCardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box
        sx={{ display: 'flex', justifyContent: 'center', mt: 'auto', pb: 2 }}
      >
        <Pagination
          count={pageCount}
          page={page}
          onChange={handleChangePage}
          color="primary"
          size={isMobile ? 'small' : 'medium'}
        />
      </Box>

      <SearchOrganizationsModal
        open={openSearchModal}
        onClose={handleCloseSearchModal}
      />

      <Dialog
        open={openRemoveDialog}
        onClose={handleCloseRemoveDialog}
        aria-labelledby="remove-dialog-title"
        aria-describedby="remove-dialog-description"
      >
        <DialogTitle id="remove-dialog-title">
          {'Remove Organization'}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" id="remove-dialog-description">
            Are you sure you want to remove {organizationToRemove?.name} from
            your linked organizations? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemoveDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleRemoveOrganization}
            color="error"
            variant="contained"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      <InviteOrganizationDialog
        open={openInviteDialog}
        onClose={handleCloseInviteDialog}
      />

      <StaffDialog
        open={openStaffDialog}
        onClose={handleCloseStaffDialog}
        organizationId={selectedOrgId as any}
      />
    </ContentWrapper>
  );
};

export default LinkedOrganizationsList;
