import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Chip,
  IconButton,
  Stack,
  Alert,
  Grid,
  Tooltip,
  styled
} from '@mui/material';
import {
  Close as CloseIcon,
  AccessibilityNew,
  Warning,
  ArrowForward
} from '@mui/icons-material';

interface PositioningData {
  // Basic Info
  newPosition: string;
  previousPosition?: string;
  timeInPreviousPosition?: number;

  // Equipment
  equipmentUsed: string[];
  pillowsUsed: number;

  // Care Details
  skinCondition: {
    redness: boolean;
    breakdown: boolean;
    location?: string;
  };
  pressureAreas: string[];
  isComfortable: boolean;

  // Monitoring
  painReported: boolean;
  painLevel?: number;
  requiresFrequentPositioning: boolean;

  // Notes
  notes: string;
  concerns: boolean;
}

interface PositioningDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: PositioningData) => void;
  task: any;
  initialData?: Partial<PositioningData>;
}

const positions = [
  'Left Side',
  'Right Side',
  'Back',
  'Semi-Fowler',
  'Sitting Up',
  'Chair',
  'Walking',
  'Other'
];

const equipment = [
  'Pressure Mattress',
  'Positioning Cushions',
  'Slide Sheet',
  'Hoist',
  'Profiling Bed'
];

const pressurePoints = [
  'Heels',
  'Elbows',
  'Sacrum',
  'Shoulders',
  'Hips',
  'Other'
];

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2
  }
}));

const SectionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& + &': {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const PositioningDialog: React.FC<PositioningDialogProps> = ({
  open,
  onClose,
  onResolve,
  task,
  initialData
}) => {
  const [formData, setFormData] = useState<PositioningData>({
    newPosition: '',
    equipmentUsed: [],
    pillowsUsed: 0,
    skinCondition: {
      redness: false,
      breakdown: false
    },
    pressureAreas: [],
    isComfortable: true,
    painReported: false,
    requiresFrequentPositioning: false,
    notes: '',
    concerns: false
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setFormData((prev) => ({ ...prev, ...initialData }));
    }
  }, [initialData]);

  const handleChange = (field: keyof PositioningData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSkinConditionChange = (
    field: keyof PositioningData['skinCondition'],
    value: any
  ) => {
    setFormData((prev) => ({
      ...prev,
      skinCondition: {
        ...prev.skinCondition,
        [field]: value
      }
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.newPosition) {
      setError('Please select new position');
      return false;
    }

    if (formData.skinCondition.breakdown && !formData.skinCondition.location) {
      setError('Please specify location of skin breakdown');
      return false;
    }

    if (formData.painReported && typeof formData.painLevel === 'undefined') {
      setError('Please specify pain level');
      return false;
    }

    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onResolve(formData);
      onClose();
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <AccessibilityNew color="primary" />
            <Typography variant="h6">Positioning Record</Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={2}>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          {/* Position Selection */}
          <SectionBox>
            <Typography color="primary" gutterBottom>
              Position
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {positions.map((position) => (
                    <Chip
                      key={position}
                      label={position}
                      onClick={() => handleChange('newPosition', position)}
                      color={
                        formData.newPosition === position
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.newPosition === position
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>
              {formData.previousPosition && (
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2" color="textSecondary">
                      {formData.previousPosition}
                    </Typography>
                    <ArrowForward fontSize="small" color="action" />
                    <Typography variant="body2">
                      {formData.newPosition}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </SectionBox>

          {/* Equipment */}
          <SectionBox>
            <Typography color="primary" gutterBottom>
              Equipment
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {equipment.map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      onClick={() => {
                        const newEquipment = formData.equipmentUsed.includes(
                          item
                        )
                          ? formData.equipmentUsed.filter((e) => e !== item)
                          : [...formData.equipmentUsed, item];
                        handleChange('equipmentUsed', newEquipment);
                      }}
                      color={
                        formData.equipmentUsed.includes(item)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.equipmentUsed.includes(item)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  label="Number of Pillows"
                  value={formData.pillowsUsed}
                  onChange={(e) =>
                    handleChange('pillowsUsed', parseInt(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0, max: 10 } }}
                />
              </Grid>
            </Grid>
          </SectionBox>

          {/* Skin Check */}
          <SectionBox>
            <Typography color="primary" gutterBottom>
              Skin Check
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.skinCondition.redness}
                      onChange={(e) =>
                        handleSkinConditionChange('redness', e.target.checked)
                      }
                      color="warning"
                    />
                  }
                  label="Redness Present"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.skinCondition.breakdown}
                      onChange={(e) =>
                        handleSkinConditionChange('breakdown', e.target.checked)
                      }
                      color="error"
                    />
                  }
                  label="Skin Breakdown"
                />
              </Grid>
              {(formData.skinCondition.redness ||
                formData.skinCondition.breakdown) && (
                <Grid item xs={12}>
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    {pressurePoints.map((point) => (
                      <Chip
                        key={point}
                        label={point}
                        onClick={() => {
                          const newPoints = formData.pressureAreas.includes(
                            point
                          )
                            ? formData.pressureAreas.filter((p) => p !== point)
                            : [...formData.pressureAreas, point];
                          handleChange('pressureAreas', newPoints);
                        }}
                        color={
                          formData.pressureAreas.includes(point)
                            ? 'error'
                            : 'default'
                        }
                        variant={
                          formData.pressureAreas.includes(point)
                            ? 'filled'
                            : 'outlined'
                        }
                      />
                    ))}
                  </Box>
                </Grid>
              )}
            </Grid>
          </SectionBox>

          {/* Comfort & Pain */}
          <SectionBox>
            <Typography color="primary" gutterBottom>
              Comfort & Pain
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.isComfortable}
                      onChange={(e) =>
                        handleChange('isComfortable', e.target.checked)
                      }
                    />
                  }
                  label="Comfortable in Position"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.painReported}
                      onChange={(e) =>
                        handleChange('painReported', e.target.checked)
                      }
                    />
                  }
                  label="Pain Reported"
                />
              </Grid>
              {formData.painReported && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    label="Pain Level (0-10)"
                    value={formData.painLevel || ''}
                    onChange={(e) =>
                      handleChange('painLevel', parseInt(e.target.value))
                    }
                    InputProps={{ inputProps: { min: 0, max: 10 } }}
                  />
                </Grid>
              )}
            </Grid>
          </SectionBox>

          {/* Notes */}
          <SectionBox>
            <Typography color="primary" gutterBottom>
              Additional Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label="Notes"
                  value={formData.notes}
                  onChange={(e) => handleChange('notes', e.target.value)}
                  placeholder="Enter any additional observations..."
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.concerns}
                      onChange={(e) =>
                        handleChange('concerns', e.target.checked)
                      }
                      color="error"
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography>Requires Follow-up</Typography>
                      {formData.concerns && <Warning color="error" />}
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </SectionBox>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color={formData.concerns ? 'error' : 'primary'}
        >
          {formData.concerns ? 'Save & Flag' : 'Save Record'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default PositioningDialog;
