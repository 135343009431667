import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Box,
  Chip,
  Alert,
  Stack,
  Divider,
  FormControlLabel,
  Switch,
  useTheme,
  styled,
  RadioGroup,
  Radio,
  Tooltip,
  IconButton
} from '@mui/material';
import {
  MedicationOutlined,
  Warning,
  CheckCircle,
  Cancel,
  AccessTime,
  Info
} from '@mui/icons-material';

interface MedicationData {
  // Medication Details
  medicationType: string;
  medicationName: string;
  dosage: string;
  route: string;
  scheduled_time: string;
  actual_time: string;

  // Status & Verification
  status: 'administered' | 'refused' | 'unavailable' | 'held' | 'missed';
  verified: boolean;
  doubleChecked: boolean;
  doubleCheckMethod: 'in-person' | 'video' | 'phone';

  // Administration Details
  givenFrom: 'blister' | 'mds' | 'bottle' | 'other';
  water_offered: boolean;
  position: string;
  crushRequired: boolean;
  thickenedFluid: boolean;

  // PRN Specific
  isPRN: boolean;
  prnReason?: string;
  lastPRNTime?: string;
  effectiveness?: string;

  // Monitoring
  sideEffectsObserved: string[];
  swallowingDifficulties: boolean;

  // Refusal Details
  refusalReason?: string;
  alternativeOffered?: string;

  // Comments
  notes: string;
  followUpRequired: boolean;
}

const medicationTypes = [
  'Regular Medication',
  'PRN (As Needed)',
  'Short Course',
  'Antibiotic',
  'Controlled Drug',
  'Injectable',
  'Topical',
  'Eye Drops',
  'Inhaler',
  'Liquid',
  'Patch'
];

const routes = [
  'Oral',
  'Sublingual',
  'Topical',
  'Transdermal',
  'Inhaled',
  'Nasal',
  'Otic',
  'Ophthalmic',
  'Rectal',
  'Injectable'
];

const positions = [
  'Sitting',
  'Standing',
  'Semi-reclined',
  'Lying Down',
  'Side-lying'
];

const sideEffects = [
  'Drowsiness',
  'Nausea',
  'Dizziness',
  'Headache',
  'Rash',
  'Stomach Pain',
  'Confusion',
  'Other'
];

const refusalReasons = [
  'Refuses to Take',
  'Feeling Unwell',
  'Swallowing Difficulties',
  'Side Effects',
  "Doesn't Want It",
  'Too Many Medications',
  'Pain/Discomfort',
  'Other'
];

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: theme.spacing(2)
  }
}));

const StatusChip = styled(Chip)(({ theme }) => ({
  '&.administered': {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText
  },
  '&.refused': {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText
  },
  '&.unavailable': {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText
  },
  '&.held': {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText
  },
  '&.missed': {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText
  }
}));

interface MedicationDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: MedicationData) => void;
  task?: Partial<MedicationData>;
  medicationInfo?: {
    name: string;
    scheduledTime: string;
    dosage: string;
    instructions?: string;
  };
}

const MedicationDialog: React.FC<MedicationDialogProps> = ({
  open,
  onClose,
  onResolve,
  task,
  medicationInfo
}) => {
  const [formData, setFormData] = useState<MedicationData>({
    medicationType: '',
    medicationName: medicationInfo?.name || '',
    dosage: medicationInfo?.dosage || '',
    route: 'Oral',
    scheduled_time: medicationInfo?.scheduledTime || '',
    actual_time: new Date().toLocaleTimeString(),
    status: 'administered',
    verified: false,
    doubleChecked: false,
    doubleCheckMethod: 'in-person',
    givenFrom: 'mds',
    water_offered: true,
    position: 'Sitting',
    crushRequired: false,
    thickenedFluid: false,
    isPRN: false,
    sideEffectsObserved: [],
    swallowingDifficulties: false,
    notes: '',
    followUpRequired: false
  });

  const [error, setError] = useState<string | null>(null);

  const handleChange = (field: keyof MedicationData, value: any) => {
    setFormData((prev) => {
      const newData = { ...prev, [field]: value };

      // Auto-adjustments based on changes
      if (field === 'status') {
        if (value === 'refused') {
          newData.verified = false;
          newData.doubleChecked = false;
        }
      }

      if (field === 'isPRN' && !value) {
        newData.prnReason = undefined;
        newData.lastPRNTime = undefined;
        newData.effectiveness = undefined;
      }

      return newData;
    });
  };

  const validateForm = (): boolean => {
    if (!formData.medicationName || !formData.dosage) {
      setError('Medication name and dosage are required');
      return false;
    }

    if (formData.status === 'administered') {
      if (!formData.verified) {
        setError('Medication must be verified before administration');
        return false;
      }
      if (
        formData.medicationType === 'Controlled Drug' &&
        !formData.doubleChecked
      ) {
        setError('Controlled drugs require double checking');
        return false;
      }
    }

    if (formData.status === 'refused' && !formData.refusalReason) {
      setError('Please provide reason for refusal');
      return false;
    }

    if (formData.isPRN && !formData.prnReason) {
      setError('Please provide reason for PRN medication');
      return false;
    }

    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    onResolve(formData);
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          <MedicationOutlined color="primary" />
          <Typography variant="h6">Medication Administration</Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          {/* Medication Details */}
          <Box>
            <Typography variant="subtitle1" gutterBottom color="primary">
              Medication Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Medication Type</InputLabel>
                  <Select
                    value={formData.medicationType}
                    onChange={(e) =>
                      handleChange('medicationType', e.target.value)
                    }
                  >
                    {medicationTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Medication Name"
                  value={formData.medicationName}
                  onChange={(e) =>
                    handleChange('medicationName', e.target.value)
                  }
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Dosage"
                  value={formData.dosage}
                  onChange={(e) => handleChange('dosage', e.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Route</InputLabel>
                  <Select
                    value={formData.route}
                    onChange={(e) => handleChange('route', e.target.value)}
                  >
                    {routes.map((route) => (
                      <MenuItem key={route} value={route}>
                        {route}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Scheduled Time"
                  type="time"
                  value={formData.scheduled_time}
                  onChange={(e) =>
                    handleChange('scheduled_time', e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Actual Time"
                  type="time"
                  value={formData.actual_time}
                  onChange={(e) => handleChange('actual_time', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </Box>

          <Divider />

          {/* Status and Verification */}
          <Box>
            <Typography variant="subtitle1" gutterBottom color="primary">
              Status & Verification
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" gap={1}>
                  {[
                    'administered',
                    'refused',
                    'unavailable',
                    'held',
                    'missed'
                  ].map((status) => (
                    <StatusChip
                      key={status}
                      label={status.charAt(0).toUpperCase() + status.slice(1)}
                      onClick={() => handleChange('status', status)}
                      className={formData.status === status ? status : ''}
                      variant={
                        formData.status === status ? 'filled' : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              {formData.status === 'administered' && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.verified}
                          onChange={(e) =>
                            handleChange('verified', e.target.checked)
                          }
                        />
                      }
                      label={
                        <Box display="flex" alignItems="center" gap={1}>
                          <Typography>Verified</Typography>
                          <Tooltip title="Verify medication against MAR chart">
                            <Info fontSize="small" color="primary" />
                          </Tooltip>
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.doubleChecked}
                          onChange={(e) =>
                            handleChange('doubleChecked', e.target.checked)
                          }
                        />
                      }
                      label="Double Checked"
                    />
                  </Grid>

                  {formData.doubleChecked && (
                    <Grid item xs={12}>
                      <FormControl>
                        <Typography variant="subtitle2" gutterBottom>
                          Double Check Method
                        </Typography>
                        <RadioGroup
                          row
                          value={formData.doubleCheckMethod}
                          onChange={(e) =>
                            handleChange('doubleCheckMethod', e.target.value)
                          }
                        >
                          <FormControlLabel
                            value="in-person"
                            control={<Radio />}
                            label="In Person"
                          />
                          <FormControlLabel
                            value="video"
                            control={<Radio />}
                            label="Video Call"
                          />
                          <FormControlLabel
                            value="phone"
                            control={<Radio />}
                            label="Phone"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Box>

          <Divider />

          {/* Administration Details */}
          <Box>
            <Typography variant="subtitle1" gutterBottom color="primary">
              Administration Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Given From</InputLabel>
                  <Select
                    value={formData.givenFrom}
                    onChange={(e) => handleChange('givenFrom', e.target.value)}
                  >
                    <MenuItem value="blister">Blister Pack</MenuItem>
                    <MenuItem value="mds">MDS</MenuItem>
                    <MenuItem value="bottle">Bottle</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Position</InputLabel>
                  <Select
                    value={formData.position}
                    onChange={(e) => handleChange('position', e.target.value)}
                  >
                    {positions.map((pos) => (
                      <MenuItem key={pos} value={pos}>
                        {pos}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.water_offered}
                      onChange={(e) =>
                        handleChange('water_offered', e.target.checked)
                      }
                    />
                  }
                  label="Water Offered"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.crushRequired}
                      onChange={(e) =>
                        handleChange('crushRequired', e.target.checked)
                      }
                    />
                  }
                  label="Crushing Required"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.thickenedFluid}
                      onChange={(e) =>
                        handleChange('thickenedFluid', e.target.checked)
                      }
                    />
                  }
                  label="Thickened Fluid Required"
                />
              </Grid>
            </Grid>
          </Box>

          <Divider />

          {/* PRN Section */}
          <Box>
            <Typography variant="subtitle1" gutterBottom color="primary">
              PRN Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.isPRN}
                      onChange={(e) => handleChange('isPRN', e.target.checked)}
                    />
                  }
                  label="PRN Medication"
                />
              </Grid>

              {formData.isPRN && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Reason for PRN"
                      value={formData.prnReason || ''}
                      onChange={(e) =>
                        handleChange('prnReason', e.target.value)
                      }
                      required
                      multiline
                      rows={2}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Last PRN Given"
                      type="datetime-local"
                      value={formData.lastPRNTime || ''}
                      onChange={(e) =>
                        handleChange('lastPRNTime', e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Effectiveness</InputLabel>
                      <Select
                        value={formData.effectiveness || ''}
                        onChange={(e) =>
                          handleChange('effectiveness', e.target.value)
                        }
                      >
                        <MenuItem value="effective">Effective</MenuItem>
                        <MenuItem value="partially">
                          Partially Effective
                        </MenuItem>
                        <MenuItem value="not_effective">Not Effective</MenuItem>
                        <MenuItem value="too_soon">Too Soon to Tell</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          <Divider />

          {/* Monitoring Section */}
          <Box>
            <Typography variant="subtitle1" gutterBottom color="primary">
              Monitoring
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Side Effects Observed
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {sideEffects.map((effect) => (
                    <Chip
                      key={effect}
                      label={effect}
                      onClick={() => {
                        const current = formData.sideEffectsObserved;
                        const updated = current.includes(effect)
                          ? current.filter((e) => e !== effect)
                          : [...current, effect];
                        handleChange('sideEffectsObserved', updated);
                      }}
                      color={
                        formData.sideEffectsObserved.includes(effect)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.sideEffectsObserved.includes(effect)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.swallowingDifficulties}
                      onChange={(e) =>
                        handleChange('swallowingDifficulties', e.target.checked)
                      }
                    />
                  }
                  label="Swallowing Difficulties"
                />
              </Grid>
            </Grid>
          </Box>

          {/* Refusal Section - Only show if status is 'refused' */}
          {formData.status === 'refused' && (
            <>
              <Divider />
              <Box>
                <Typography variant="subtitle1" gutterBottom color="primary">
                  Refusal Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Refusal Reason</InputLabel>
                      <Select
                        value={formData.refusalReason || ''}
                        onChange={(e) =>
                          handleChange('refusalReason', e.target.value)
                        }
                        required
                      >
                        {refusalReasons.map((reason) => (
                          <MenuItem key={reason} value={reason}>
                            {reason}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Alternative Offered"
                      value={formData.alternativeOffered || ''}
                      onChange={(e) =>
                        handleChange('alternativeOffered', e.target.value)
                      }
                      multiline
                      rows={2}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          <Divider />

          {/* Notes Section */}
          <Box>
            <Typography variant="subtitle1" gutterBottom color="primary">
              Additional Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Notes"
                  value={formData.notes}
                  onChange={(e) => handleChange('notes', e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.followUpRequired}
                      onChange={(e) =>
                        handleChange('followUpRequired', e.target.checked)
                      }
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography>Follow-up Required</Typography>
                      {formData.followUpRequired && (
                        <Warning color="warning" fontSize="small" />
                      )}
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            formData.status === 'administered' &&
            (!formData.verified ||
              (formData.medicationType === 'Controlled Drug' &&
                !formData.doubleChecked))
          }
          startIcon={
            formData.status === 'administered' ? <CheckCircle /> : <Warning />
          }
          color={formData.status === 'administered' ? 'primary' : 'warning'}
        >
          {formData.status === 'administered'
            ? 'Confirm Administration'
            : 'Record Status'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default MedicationDialog;
