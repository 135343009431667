import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  useTheme,
  IconButton,
  TextField,
  SelectChangeEvent,
  useMediaQuery,
  Typography,
  Tabs,
  Tab,
  Tooltip
} from '@mui/material';
import {
  FilterList as FilterIcon,
  Close as CloseIcon,
  FilterList,
  CameraAlt as CameraIcon
} from '@mui/icons-material';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  useLazyGetTimesheetsQuery,
  useScanBarcodeMutation
} from 'src/redux/@api/timesheet';
import { useAppSelector } from 'src/redux/hook';
import { ITimesheetResponse } from 'src/interfaces/timesheet';
import { useGetLinkedOrganizationsQuery } from 'src/redux/@api/organisation';
import {
  useGetOtherShiftPatternsQuery,
  useGetYourShiftPatternsQuery
} from 'src/redux/@api/shift';
import TimesheetList from './timesheet-components/timesheet-list';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useDispatch } from 'react-redux';
import { useTimesheetWebSocket } from 'src/components/core/ws/timesheet/TimesheetWebSocket';
import { TimesheetWebSocketProvider } from 'src/components/core/ws/timesheet/TimesheetWebSocketProvider';

interface FilterOptions {
  status: string;
  startDate: Date;
  endDate: Date;
  shiftPatternId: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`timesheet-tabpanel-${index}`}
      aria-labelledby={`timesheet-tab-${index}`}
      {...other}
      style={{ height: '100%', overflow: 'auto' }}
    >
      {value === index && <Box sx={{ height: '100%' }}>{children}</Box>}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `timesheet-tab-${index}`,
    'aria-controls': `timesheet-tabpanel-${index}`
  };
};

export default function CarerTimesheets() {
  const theme = useTheme();
  const scrollRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [paginatedTimesheets, setPaginatedTimesheets] = useState<
    ITimesheetResponse[]
  >([]);

  const userState = useAppSelector((state) => state.userState);
  const currentOrganization = userState.currentOrganization;

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    status: 'all',
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    shiftPatternId: ''
  });

  const [getTimesheets, { data: timesheetResponse, isLoading }] =
    useLazyGetTimesheetsQuery();

  const getStatusFromTabIndex = (index: number): string => {
    switch (index) {
      case 1:
        return 'pending';
      case 2:
        return 'approved';
      case 3:
        return 'rejected';
      default:
        return 'all';
    }
  };

  const loadTimesheets = useCallback(
    async (pageNumber: number, isInitial: boolean = false) => {
      if (isLoadingMore) return;

      setIsLoadingMore(true);
      try {
        const result = await getTimesheets({
          status: getStatusFromTabIndex(tabValue),
          page: pageNumber,
          limit: 20,
          ...filterOptions
        }).unwrap();

        if (isInitial) {
          setPaginatedTimesheets(result.data || []);
        } else {
          setPaginatedTimesheets((prev) => [...prev, ...(result.data || [])]);
        }

        setHasMore((result.pagination?.totalPages || 0) > pageNumber);
        setPage(pageNumber);
      } catch (error) {
        console.error('Error loading timesheets:', error);
      } finally {
        setIsLoadingMore(false);
      }
    },
    [filterOptions, tabValue, getTimesheets]
  );

  useEffect(() => {
    loadTimesheets(1, true);
  }, [filterOptions, tabValue]);

  const handleScroll = useCallback(
    (event: Event) => {
      const target = event.target as HTMLDivElement;
      if (!target) return;

      const reachedBottom =
        Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <
        1;

      if (reachedBottom && hasMore && !isLoadingMore) {
        loadTimesheets(page + 1);
      }
    },
    [hasMore, isLoadingMore, page, loadTimesheets]
  );

  useEffect(() => {
    const currentScrollRef = scrollRef.current;
    if (currentScrollRef) {
      currentScrollRef.addEventListener('scroll', handleScroll);
      return () => currentScrollRef.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const { data: yourShiftPatterns = [] } = useGetYourShiftPatternsQuery(
    currentOrganization._id,
    {
      skip: !filterDialogOpen
    }
  );

  const handleFilterChange = (field: keyof FilterOptions, value: any) => {
    setFilterOptions((prev) => ({
      ...prev,
      [field]: value
    }));
    setPage(1);
    setPaginatedTimesheets([]);
    setHasMore(true);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPage(1);
    setPaginatedTimesheets([]);
    setHasMore(true);
  };

  const handleResetFilters = () => {
    setFilterOptions({
      status: 'all',
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      shiftPatternId: ''
    });
  };

  const [isSupported, setIsSupported] = useState(false);
  const dispatch = useDispatch();
  const [sendScanedQrCode] = useScanBarcodeMutation();
  const [scanning, setScanning] = useState(false);
  const [scanResult, setScanResult] = useState<string | null>(null);

  // WebSocket related state and refs
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connected, setConnected] = useState(false);
  const wsRef = useRef<WebSocket | null>(null);
  const reconnectTimeout = useRef<NodeJS.Timeout>();
  const [isScanning, setIsScanning] = useState(false);

  // WebSocket connection setup
  useEffect(() => {
    const connectWebSocket = () => {
      // Clean up existing connection
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }

      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }

      // Only connect if we're actively scanning
      if (!isScanning) {
        return;
      }

      console.log('Initializing WebSocket connection for timesheet scanning');

      const ws = new WebSocket(
        `${process.env.REACT_APP_WS_HOSTNAME}/timesheet-ws?userId=${userState.user._id}&orgId=${currentOrganization._id}`
      );

      ws.onopen = () => {
        console.log('WebSocket connected for timesheet scanning');
        setConnected(true);
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received WebSocket message:', data);
          handleWebSocketMessage(data);
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      ws.onclose = (event) => {
        console.log('WebSocket disconnected:', event);
        setConnected(false);

        // Only reconnect if we're still scanning and it wasn't a clean closure
        if (!event.wasClean && isScanning) {
          console.log('Scheduling reconnection...');
          reconnectTimeout.current = setTimeout(() => {
            console.log('Attempting to reconnect...');
            connectWebSocket();
          }, 3000);
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      wsRef.current = ws;
      setSocket(ws);
    };

    connectWebSocket();

    // Cleanup function
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      setSocket(null);
      setConnected(false);
    };
  }, [isScanning, userState.user._id, currentOrganization._id]);

  const handleWebSocketMessage = (data: any) => {
    const { type, payload } = data;

    switch (type) {
      case 'TIMESHEET_PROCESSED':
        setIsScanning(false); // Stop scanning mode
        if (payload.status === 'success') {
          dispatch(
            showSnack({
              message: 'Timesheet approved successfully',
              color: 'success'
            })
          );
          loadTimesheets(1, true);
        } else {
          dispatch(
            showSnack({
              message: `Error processing timesheet: ${payload.error}`,
              color: 'error'
            })
          );
        }
        break;
    }
  };

  const handleScanClick = async () => {
    if (!isSupported) {
      dispatch(
        showSnack({
          message: 'Barcode scanning is not supported on this device',
          color: 'error'
        })
      );
      return;
    }

    try {
      setIsScanning(true); // Start scanning mode
      const granted = await requestPermissions();
      if (!granted) {
        setIsScanning(false);
        showPermissionDeniedAlert();
        return;
      }

      const { barcodes } = await BarcodeScanner.scan();

      if (barcodes && barcodes.length > 0) {
        const scannedValue = barcodes[0].rawValue;

        try {
          const [barcode, carerId] = scannedValue.split(':');

          await sendScanedQrCode({
            barcode,
            carerId
          }).unwrap();

          dispatch(
            showSnack({
              message: 'QR code scanned successfully. Processing...',
              color: 'info'
            })
          );
        } catch (error) {
          setIsScanning(false);
          console.error('Error processing QR code:', error);
          dispatch(
            showSnack({
              message: 'Failed to process QR code',
              color: 'error'
            })
          );
        }
      } else {
        setIsScanning(false);
        dispatch(
          showSnack({
            message: 'No QR code detected',
            color: 'warning'
          })
        );
      }
    } catch (error) {
      setIsScanning(false);
      console.error('Scan failed:', error);

      if (!error.message?.includes('User denied')) {
        dispatch(
          showSnack({
            message: 'Failed to scan QR code',
            color: 'error'
          })
        );
      }
    }
  };

  // Check if barcode scanning is supported on device
  useEffect(() => {
    const checkSupport = async () => {
      try {
        const result = await BarcodeScanner.isSupported();
        setIsSupported(result.supported);
      } catch (error) {
        console.error('Error checking barcode support:', error);
      }
    };

    checkSupport();
  }, []);

  // Request camera permissions
  const requestPermissions = async (): Promise<boolean> => {
    try {
      const { camera } = await BarcodeScanner.requestPermissions();
      return camera === 'granted' || camera === 'limited';
    } catch (error) {
      console.error('Error requesting permissions:', error);
      return false;
    }
  };
  const showPermissionDeniedAlert = () => {
    dispatch(
      showSnack({
        message: 'Please grant camera permission to use the barcode scanner.',
        color: 'error'
      })
    );
  };

  const renderTimesheetList = () => (
    <Box
      ref={scrollRef}
      sx={{
        height: `calc(100vh - ${theme.header.height} - 40px - 60px)`,
        overflow: 'auto'
      }}
    >
      <Grid container spacing={1} p={1}>
        <TimesheetList
          timesheets={paginatedTimesheets}
          userState={userState}
          currentOrganization={currentOrganization}
          onApproveTimesheet={() => {}}
          onApproveWithoutReview={() => {}}
        />
      </Grid>
      {isLoadingMore && (
        <Box sx={{ textAlign: 'center', p: 2 }}>
          <Typography>Loading more timesheets...</Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${theme.header.height})`
      }}
      p={1}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', height: '50px' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="timesheet tabs"
          variant="scrollable"
          scrollButtons={isMobile ? 'auto' : false}
          allowScrollButtonsMobile
          sx={{
            '.MuiTabs-indicator': { height: '2px' },
            '.MuiTab-root': { minWidth: 'unset' }
          }}
        >
          <Tab label="All" {...a11yProps(0)} />
          <Tab label="Pending" {...a11yProps(1)} />
          <Tab label="Approved" {...a11yProps(2)} />
          <Tab label="Rejected" {...a11yProps(3)} />
        </Tabs>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          px: 2,
          height: '50px'
        }}
      >
        <Tooltip
          title={isSupported ? 'Scan QR Code' : 'QR scanning not supported'}
        >
          <span>
            <IconButton
              onClick={handleScanClick}
              color="primary"
              disabled={!isSupported}
            >
              <CameraIcon />
            </IconButton>
          </span>
        </Tooltip>
        <IconButton onClick={() => setFilterDialogOpen(true)} color="primary">
          <FilterList />
        </IconButton>

        {timesheetResponse && (
          <Typography variant="body2" color="textSecondary">
            Showing {paginatedTimesheets.length} of{' '}
            {timesheetResponse?.pagination?.total} timesheets
          </Typography>
        )}
      </Box>
      <Box sx={{ overflow: 'hidden' }}>
        <TabPanel value={tabValue} index={0}>
          {renderTimesheetList()}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {renderTimesheetList()}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          {renderTimesheetList()}
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          {renderTimesheetList()}
        </TabPanel>
      </Box>

      {/* Filter Dialog */}
      <Dialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Filter Timesheets
          <IconButton
            onClick={() => setFilterDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  inputFormat="dd/MM/yyyy"
                  value={filterOptions.startDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      handleFilterChange('startDate', newValue);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date"
                  value={filterOptions.endDate}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    if (newValue) {
                      handleFilterChange('endDate', newValue);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  minDate={filterOptions.startDate}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="shift-pattern-select-label">
                  Shift Pattern
                </InputLabel>
                <Select
                  labelId="shift-pattern-select-label"
                  value={filterOptions.shiftPatternId}
                  onChange={(e: SelectChangeEvent) =>
                    handleFilterChange('shiftPatternId', e.target.value)
                  }
                  label="Shift Pattern"
                >
                  <MenuItem value="">All</MenuItem>
                  {yourShiftPatterns.map((pattern) => (
                    <MenuItem key={pattern._id} value={pattern._id}>
                      {pattern.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleResetFilters}>
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={() => setFilterDialogOpen(false)}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      {/* Loading State */}
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Typography>Loading timesheets...</Typography>
        </Box>
      )}
    </Box>
  );
}
