import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Grid,
  Typography,
  Box,
  CircularProgress,
  FormControlLabel,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  alpha,
  Fade,
  useTheme,
  Paper,
  Checkbox
} from '@mui/material';
import {
  Save,
  Check,
  Info,
  GppGood,
  Security,
  Article,
  VerifiedUser,
  ArrowForward
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation
} from 'src/redux/@api/carer-application';

const StyledCard = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  padding: theme.spacing(3),
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0, 0, 0, 0.08)'
  }
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  '& svg': {
    fontSize: 28,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: 0,
    width: 40,
    height: 3,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius
  }
}));

const ConsentCard = styled(Paper)(
  ({ theme, agreed }: { theme?: any; agreed: boolean }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    backgroundColor: alpha(
      agreed ? theme.palette.success.main : theme.palette.grey[500],
      0.05
    ),
    border: `1px solid ${alpha(
      agreed ? theme.palette.success.main : theme.palette.grey[500],
      0.2
    )}`,
    transition: 'all 0.2s ease',
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',

    '&:hover': {
      backgroundColor: alpha(
        agreed ? theme.palette.success.main : theme.palette.grey[500],
        0.1
      ),
      transform: 'translateY(-2px)',
      boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.1)}`
    },

    '&:after': agreed
      ? {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          width: '4px',
          height: '100%',
          background: theme.palette.success.main
        }
      : {}
  })
);

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

const getConsentIcon = (type: string) => {
  switch (type) {
    case 'dataProcessing':
      return <Security fontSize="large" />;
    case 'backgroundCheck':
      return <GppGood fontSize="large" />;
    case 'termsAndConditions':
      return <Article fontSize="large" />;
    default:
      return <Info fontSize="large" />;
  }
};

const consentDetails = {
  dataProcessing: {
    title: 'Data Processing Agreement',
    description:
      'We handle your data with utmost care and in compliance with GDPR regulations.',
    icon: <Security />,
    color: 'primary'
  },
  backgroundCheck: {
    title: 'Background Check Authorization',
    description:
      'Authorization for conducting necessary background verifications.',
    icon: <GppGood />,
    color: 'info'
  },
  termsAndConditions: {
    title: 'Terms and Conditions',
    description: 'Our standard terms and conditions for care services.',
    icon: <Article />,
    color: 'secondary'
  }
};

interface ConsentsProps {
  initialData?: any;
  onSubmit: (data: any, index: number | undefined, section: string) => void;
  selectedSection: string;
  editable?: boolean;
}

const Consents: React.FC<ConsentsProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();

  const [saving, setSaving] = useState(false);
  const [openDialog, setOpenDialog] = useState<string | null>(null);

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {
        dataProcessing: false,
        backgroundCheck: false,
        termsAndConditions: false
      }
    : initialData || {};

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const handleSave = async (data: any) => {
    if (!editable) return;
    setSaving(true);
    try {
      await updateApplicationSection({
        section: 'consents',
        data
      }).unwrap();

      await refetch();

      dispatch(
        showSnack({
          message: 'Consents saved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: 'Failed to save consents',
          color: 'error'
        })
      );
    } finally {
      setSaving(false);
    }
  };

  const handleOpenDialog = (dialogType: string) => {
    setOpenDialog(dialogType);
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  const renderConsentDialog = (type: string) => {
    const content = consentDetails[type as keyof typeof consentDetails];
    return (
      <StyledDialog
        open={openDialog === content.title}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {content.icon}
            <Typography variant="h6">{content.title}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" paragraph>
            {content.description}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            By agreeing to these terms, you acknowledge that you have read and
            understood our policies.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            sx={{
              borderRadius: 2,
              background: (theme) =>
                `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`
            }}
          >
            I Understand
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  };

  const renderConsent = (name: string, label: string, type: string) => {
    const consentValue = watch(name);

    return (
      <Controller
        name={name}
        control={control}
        rules={{ required: `You must agree to ${label}` }}
        render={({ field }) => (
          <Box sx={{ mb: 3 }}>
            <ConsentCard agreed={field.value} elevation={0}>
              <Box sx={{ display: 'flex', gap: 2, flex: 1 }}>
                {getConsentIcon(type)}
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      mb: 1
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {
                        consentDetails[type as keyof typeof consentDetails]
                          .title
                      }
                    </Typography>
                    {field.value && (
                      <Check sx={{ color: theme.palette.success.main }} />
                    )}
                  </Box>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {
                      consentDetails[type as keyof typeof consentDetails]
                        .description
                    }
                  </Typography>
                  {editable && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          sx={{
                            color: theme.palette.primary.main,
                            '&.Mui-checked': {
                              color: theme.palette.primary.main
                            }
                          }}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          I agree to the{' '}
                          <Link
                            component="button"
                            type="button"
                            onClick={() =>
                              handleOpenDialog(
                                consentDetails[
                                  type as keyof typeof consentDetails
                                ].title
                              )
                            }
                            sx={{ textDecoration: 'none' }}
                          >
                            terms
                          </Link>
                        </Typography>
                      }
                    />
                  )}
                </Box>
              </Box>
              <Button
                size="small"
                onClick={() =>
                  handleOpenDialog(
                    consentDetails[type as keyof typeof consentDetails].title
                  )
                }
                endIcon={<ArrowForward />}
                sx={{
                  minWidth: 'auto',
                  color: field.value ? 'success.main' : 'text.secondary'
                }}
              >
                View Details
              </Button>
            </ConsentCard>
            {errors[name] && (
              <Typography
                color="error"
                variant="caption"
                sx={{ ml: 2, mt: 0.5 }}
              >
                {errors[name]?.message}
              </Typography>
            )}
          </Box>
        )}
      />
    );
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        p: { xs: 2, sm: 3, md: 4 },
        borderRadius: 4,
        boxShadow: (theme) =>
          `0 0 40px ${alpha(theme.palette.primary.main, 0.08)}`
      }}
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <Fade in timeout={500}>
          <StyledCard>
            <SectionHeader>
              <VerifiedUser />
              <SectionTitle>Consents and Agreements</SectionTitle>
            </SectionHeader>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                {renderConsent(
                  'dataProcessing',
                  'data processing terms',
                  'dataProcessing'
                )}
              </Grid>

              <Grid item xs={12}>
                {renderConsent(
                  'backgroundCheck',
                  'background checks',
                  'backgroundCheck'
                )}
              </Grid>

              <Grid item xs={12}>
                {renderConsent(
                  'termsAndConditions',
                  'terms and conditions',
                  'termsAndConditions'
                )}
              </Grid>
            </Grid>

            {editable && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={saving ? <CircularProgress size={20} /> : <Save />}
                  disabled={saving}
                  sx={{
                    borderRadius: 2,
                    px: 4,
                    py: 1,
                    background: (theme) =>
                      `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      background: (theme) =>
                        `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)'
                    }
                  }}
                >
                  {saving ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CircularProgress size={20} color="inherit" />
                      <span>Saving Changes...</span>
                    </Box>
                  ) : (
                    'Save Consents'
                  )}
                </Button>
              </Box>
            )}
          </StyledCard>
        </Fade>

        {Object.keys(consentDetails).map((type) => renderConsentDialog(type))}
      </form>
    </Box>
  );
};

export default Consents;
