// timesheets.ts
import { ITimesheetResponse } from 'src/interfaces/timesheet';
import { baseApi } from './base';
interface TimesheetFilters {
  organizationId?: string;
  status?: string;
  startDate?: Date;
  endDate?: Date;
  shiftPatternId?: string;
  page?: number;
  limit?: number;
}

interface PaginatedResponse<T> {
  data: T[];
  pagination: {
    total: number;
    page: number;
    pageSize: number;
    totalPages: number;
  };
}

export const timesheetsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTimesheets: builder.query({
      query: (filters) => ({
        url: '/timesheet',
        method: 'GET',
        params: {
          page: filters?.page || 1,
          limit: filters?.limit || 9,
          organizationId: filters?.organizationId,
          status: filters?.status,
          startDate: filters?.startDate,
          endDate: filters?.endDate,
          shiftPatternId: filters?.shiftPatternId
        }
      }),
      providesTags: ['Timesheets']
    }),
    getQuickStats: builder.query({
      query: ({ month, year }) => ({
        url: 'shift/utils/quickstats',
        method: 'GET',
        params: { month, year }
      })
    }),
    createTimesheetRequest: builder.mutation({
      query: (data: {
        shiftId: string;
        shiftPatternId: string;
        homeId: string;
      }) => ({
        url: `timesheet`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Timesheets']
    }),

    approveTimesheet: builder.mutation({
      query: ({
        timesheetId,
        rating,
        review
      }: {
        timesheetId: string;
        rating?: number;
        review?: string;
      }) => ({
        url: `timesheet/${timesheetId}/approve`,
        method: 'PATCH',
        body: {
          rating,
          review
        }
      }),
      invalidatesTags: ['Timesheets']
    }),
    rejectTimesheet: builder.mutation({
      query: (timesheetId: string) => ({
        url: `timesheet/${timesheetId}/reject`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Timesheets']
    }),
    // New endpoint for generating QR code
    createTimesheetAndGenerateQRCode: builder.mutation({
      query: ({
        shiftId,
        homeId,
        shiftPattern
      }: {
        shiftId: string;
        homeId: string;
        shiftPattern: string;
      }) => ({
        url: 'shift/generate-qr',
        method: 'POST',
        body: {
          shiftId,
          homeId,
          shiftPattern
        }
      })
    }),
    validateQRCode: builder.mutation<
      { message: string; timesheet },
      { token: string }
    >({
      query: (body) => ({
        url: 'timesheet/validate-qr',
        method: 'POST',
        body
      })
    }),
    scanBarcode: builder.mutation({
      query: (body) => ({
        url: 'timesheet/scan-qr',
        method: 'POST',
        body
      })
    }),

    checkTimesheetQrcodeStatus: builder.query({
      query: (qrCode) => ({
        url: `timesheet/check-status?qrCode=${qrCode}`,
        method: 'GET'
      })
    })
  })
});

export const {
  useValidateQRCodeMutation,
  useGetTimesheetsQuery,
  useLazyGetTimesheetsQuery,
  useGetQuickStatsQuery,
  useCreateTimesheetRequestMutation,
  useApproveTimesheetMutation,
  useRejectTimesheetMutation,
  useCreateTimesheetAndGenerateQRCodeMutation,
  useLazyGetQuickStatsQuery,
  useScanBarcodeMutation,
  useCheckTimesheetQrcodeStatusQuery
} = timesheetsApi;
