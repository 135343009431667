import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  useTheme,
  IconButton,
  TextField,
  Typography,
  Autocomplete,
  Tabs,
  Tab,
  useMediaQuery,
  Fab
} from '@mui/material';
import { FilterList, Receipt, Close as CloseIcon } from '@mui/icons-material';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

import { useLazyGetTimesheetsQuery } from 'src/redux/@api/timesheet';
import { useAppSelector } from 'src/redux/hook';
import { ITimesheetResponse } from 'src/interfaces/timesheet';
import { useGetLinkedOrganizationsQuery } from 'src/redux/@api/organisation';
import {
  useGetOtherShiftPatternsQuery,
  useGetYourShiftPatternsQuery
} from 'src/redux/@api/shift';
import TimesheetList from './timesheet-components/timesheet-list';
import { useGetCareStaffsQuery } from 'src/redux/@api/staff';
import InvoiceDialog from 'src/components/core/dialogs/shifts/invoice-dialog';
import { TimesheetUploadDialog } from './timesheet-components/upload-manual';
import { UploadIcon } from 'lucide-react';
import { TimesheetActions } from './timesheet-components/actions';
import { useDispatch } from 'react-redux';
import {
  useCalculateInvoiceQuery,
  useLazyCalculateInvoiceQuery
} from 'src/redux/@api/invoices';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

interface FilterOptions {
  organizationId: string;
  careUserId: string;
  startDate: Date;
  endDate: Date;
  shiftPatternId: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`timesheet-tabpanel-${index}`}
      aria-labelledby={`timesheet-tab-${index}`}
      {...other}
      style={{ height: '100%', overflow: 'auto' }}
    >
      {value === index && <Box sx={{ height: '100%' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `timesheet-tab-${index}`,
    'aria-controls': `timesheet-tabpanel-${index}`
  };
}

export function AgencyTimesheets() {
  const theme = useTheme();
  const scrollRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tabValue, setTabValue] = useState(0);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [invoiceSelectOpen, setInvoiceSelectOpen] = useState(false);
  const [selectedHomeId, setSelectedHomeId] = useState('');
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [paginatedTimesheets, setPaginatedTimesheets] = useState<
    ITimesheetResponse[]
  >([]);

  const userState = useAppSelector((state) => state.userState);
  const currentOrganization = userState.currentOrganization;

  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [invoiceError, setInvoiceError] = useState('');
  const [invoiceData, setInvoiceData] = useState({
    timesheets: [],
    totalAmount: 0,
    home: {
      homeId: '',
      homeName: '',
      homeAddress: '',
      homeEmail: '',
      homePhone: ''
    }
  });

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    organizationId: 'all',
    careUserId: 'all',
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    shiftPatternId: ''
  });

  const [getTimesheets, { data: timesheetResponse, isLoading }] =
    useLazyGetTimesheetsQuery();

  // Function to format the filter parameters for the API
  const formatFilterParams = useCallback(
    (options: FilterOptions) => {
      const params: any = {
        status: getStatusFromTabIndex(tabValue),
        page: page,
        limit: 20
      };

      if (options.organizationId && options.organizationId !== 'all') {
        params.organizationId = options.organizationId;
      }

      if (options.careUserId && options.careUserId !== 'all') {
        params.careUserId = options.careUserId;
      }

      if (options.startDate) {
        params.startDate = format(options.startDate, 'yyyy-MM-dd');
      }

      if (options.endDate) {
        params.endDate = format(options.endDate, 'yyyy-MM-dd');
      }

      if (options.shiftPatternId) {
        params.shiftPatternId = options.shiftPatternId;
      }

      return params;
    },
    [tabValue, page]
  );

  const getStatusFromTabIndex = (index: number): string => {
    switch (index) {
      case 1:
        return 'pending';
      case 2:
        return 'approved';
      default:
        return 'all';
    }
  };

  //invoiceing
  const [invoiceStartDate, setInvoiceStartDate] = useState(
    startOfMonth(new Date())
  );
  const [invoiceEndDate, setInvoiceEndDate] = useState(endOfMonth(new Date()));
  const [isInvoiceFetching, setIsInvoiceFetching] = useState(false);
  const [invoiceTimesheets, setInvoiceTimesheets] = useState([]);

  const [getInvoiceTimesheets] = useLazyGetTimesheetsQuery();
  const [calculateInvoice] = useLazyCalculateInvoiceQuery();
  const dispatch = useDispatch();

  // new

  const handleCreateInvoice = async () => {
    try {
      const result = await calculateInvoice({
        homeId: selectedHomeId,
        startDate: invoiceStartDate,
        endDate: invoiceEndDate
      }).unwrap();

      if (!result || !result.timesheets.length) {
        setInvoiceError(
          'No approved timesheets found for the selected period for the selected care home.'
        );
        return;
      }

      const selectedHome = linkedOrganizations.find(
        (org) => org._id === selectedHomeId
      );

      setInvoiceData({
        ...result,
        home: {
          homeId: selectedHome?._id || '',
          homeName: selectedHome?.name || '',
          homeAddress: selectedHome?.address || '',
          homeEmail: selectedHome?.email || '',
          homePhone: selectedHome?.phone || ''
        }
      });

      setInvoiceSelectOpen(false);
      setInvoiceDialogOpen(true);
    } catch (error) {
      console.error('Error calculating invoice:', error);
      setInvoiceError('Failed to calculate invoice. Please try again.');
      dispatch(
        showSnack({
          message: 'Failed to calculate invoice. Please try again.',
          color: 'error'
        })
      );
    }
  };

  // Function to fetch timesheets for invoice
  const fetchInvoiceTimesheets = async (homeId) => {
    setIsInvoiceFetching(true);
    try {
      const result = await getInvoiceTimesheets({
        status: 'approved',
        organizationId: homeId,
        startDate: invoiceStartDate,
        endDate: invoiceEndDate,
        page: 1,
        limit: 1000 // Fetch all approved timesheets for the period
      }).unwrap();

      return result.data || [];
    } catch (error) {
      console.error('Error fetching invoice timesheets:', error);
      return [];
    } finally {
      setIsInvoiceFetching(false);
    }
  };
  const calculateInvoiceData = (timesheets) => {
    if (!timesheets.length) return null;

    // Sort timesheets by date
    const sortedTimesheets = [...timesheets].sort(
      (a, b) =>
        new Date(a.shift_?.date).getTime() - new Date(b.shift_?.date).getTime()
    );

    const firstTimesheet = sortedTimesheets[0];
    const lastTimesheet = sortedTimesheets[sortedTimesheets.length - 1];

    let totalAmount = 0;
    const processedTimesheets = timesheets
      .map((timesheet) => {
        const homeRate = timesheet?.shift_?.shiftPattern?.rates.find(
          (rate) => rate.careHomeId === timesheet?.shift_.homeId
        );
        const isWeekend = moment(timesheet?.shift_?.date).isoWeekday() > 5;
        const hourlyPay = isWeekend
          ? homeRate?.weekendRate
          : homeRate?.weekdayRate;

        const timing = timesheet?.shift_?.shiftPattern?.timings.find(
          (timing) => timing.careHomeId === timesheet?.home?._id
        );

        if (!timing) return null;

        const startTime = moment(timing?.startTime, 'HH:mm');
        const endTime = moment(timing?.endTime, 'HH:mm');

        let shiftDuration;
        if (endTime.isBefore(startTime)) {
          shiftDuration = moment.duration(
            endTime.add(1, 'day').diff(startTime)
          );
        } else {
          shiftDuration = moment.duration(endTime.diff(startTime));
        }

        const hours = shiftDuration.asHours();
        const amount = hourlyPay * hours;
        totalAmount += amount;

        return {
          ...timesheet,
          hourlyRate: hourlyPay,
          hours: hours,
          amount: amount,
          shiftDate: timesheet?.shift_?.date,
          shiftType: timesheet?.shift_?.shiftPattern?.name,
          carerName: `${timesheet?.carer?.firstName} ${timesheet?.carer?.lastName}`,
          homeName: timesheet?.home?.name
        };
      })
      .filter(Boolean);

    return {
      timesheets: processedTimesheets,
      totalAmount,
      totalTimesheets: processedTimesheets.length,
      firstShift: {
        date: firstTimesheet?.shift_?.date,
        type: firstTimesheet?.shift_?.shiftPattern?.name
      },
      lastShift: {
        date: lastTimesheet?.shift_?.date,
        type: lastTimesheet?.shift_?.shiftPattern?.name
      }
    };
  };

  const loadTimesheets = useCallback(
    async (pageNumber: number, isInitial: boolean = false) => {
      if (isLoadingMore) return;

      setIsLoadingMore(true);
      try {
        const params = formatFilterParams({
          ...filterOptions
        });

        const result = await getTimesheets({
          ...params,
          page: pageNumber
        }).unwrap();

        if (isInitial) {
          setPaginatedTimesheets(result.data || []);
        } else {
          setPaginatedTimesheets((prev) => [...prev, ...(result.data || [])]);
        }

        setHasMore((result.pagination?.totalPages || 0) > pageNumber);
        setPage(pageNumber);
      } catch (error) {
        console.error('Error loading timesheets:', error);
      } finally {
        setIsLoadingMore(false);
      }
    },
    [filterOptions, tabValue, getTimesheets, formatFilterParams, isLoadingMore]
  );

  useEffect(() => {
    setPage(1);
    setHasMore(true);
    setPaginatedTimesheets([]);
    loadTimesheets(1, true);
  }, [filterOptions, tabValue]);

  const handleScroll = useCallback(
    (event: Event) => {
      const target = event.target as HTMLDivElement;
      if (!target) return;

      console.log('Scrolling...', target.scrollTop, target.scrollHeight);

      const reachedBottom =
        Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <
        1;

      if (reachedBottom && hasMore && !isLoadingMore) {
        loadTimesheets(page + 1);
      }
    },
    [hasMore, isLoadingMore, page, loadTimesheets]
  );

  useEffect(() => {
    const currentScrollRef = scrollRef.current;
    if (currentScrollRef) {
      console.log('Adding scroll event listener');
      currentScrollRef.addEventListener('scroll', handleScroll);
      return () => currentScrollRef.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const { data: linkedOrganizations = [] } =
    useGetLinkedOrganizationsQuery('home');
  const { data: otherShiftPatterns = [] } = useGetOtherShiftPatternsQuery(
    filterOptions.organizationId,
    {
      skip:
        !filterOptions.organizationId ||
        !filterDialogOpen ||
        filterOptions.organizationId === currentOrganization._id
    }
  );

  const { data: yourShiftPatterns = [] } = useGetYourShiftPatternsQuery(
    currentOrganization._id,
    {
      skip: !filterDialogOpen && uploadDialogOpen === false
    }
  );

  const { data: careStaffs = [] } = useGetCareStaffsQuery();

  const handleFilterChange = (field: keyof FilterOptions, value: any) => {
    setFilterOptions((prev) => {
      const newOptions = {
        ...prev,
        [field]: value
      };

      // Reset related fields when organization changes
      if (field === 'organizationId') {
        newOptions.shiftPatternId = '';
      }

      return newOptions;
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPaginatedTimesheets([]);
    setPage(1);
    setHasMore(true);
  };

  const renderCareStaffAutocomplete = () => (
    <Autocomplete
      options={careStaffs}
      getOptionLabel={(option) =>
        `${option.user.firstName} ${option.user.lastName}`
      }
      value={
        careStaffs.find(
          (staff) => staff.user._id === filterOptions.careUserId
        ) || null
      }
      onChange={(event, newValue) => {
        handleFilterChange('careUserId', newValue ? newValue.user._id : 'all');
      }}
      renderInput={(params) => (
        <TextField {...params} label="Carer" fullWidth />
      )}
    />
  );

  const renderTimesheetList = () => (
    <Box
      p={1}
      ref={scrollRef}
      sx={{
        height: `calc(100vh - ${theme.header.height} - 40px - 60px)`,
        overflow: 'auto'
      }}
    >
      <Grid container spacing={1} alignItems="flex-start">
        <TimesheetList
          timesheets={paginatedTimesheets}
          userState={userState}
          currentOrganization={currentOrganization}
          onApproveTimesheet={() => {}}
          onApproveWithoutReview={() => {}}
        />
      </Grid>
      {isLoadingMore && (
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center', p: 2 }}>
            <Typography>Loading more timesheets...</Typography>
          </Box>
        </Grid>
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${theme.header.height})`,
        overflow: 'hidden'
      }}
      p={1}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', height: '50px' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="timesheet tabs"
        >
          <Tab label="All Timesheets" {...a11yProps(0)} />
          <Tab label="Pending" {...a11yProps(1)} />
          <Tab label="Approved" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          px: 2,
          height: '50px',
          overflowY: 'hidden'
        }}
      >
        <IconButton onClick={() => setFilterDialogOpen(true)} color="primary">
          <FilterList />
        </IconButton>
        {timesheetResponse && (
          <Typography variant="body2" color="textSecondary">
            Showing {paginatedTimesheets.length} of{' '}
            {timesheetResponse?.pagination?.total} timesheets
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          overflow: 'hidden'
        }}
      >
        <TabPanel value={tabValue} index={0}>
          {renderTimesheetList()}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {renderTimesheetList()}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          {renderTimesheetList()}
        </TabPanel>
      </Box>

      {/* Filter Dialog */}
      <Dialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Filter Timesheets
          <IconButton
            onClick={() => setFilterDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Organization</InputLabel>
                <Select
                  value={filterOptions.organizationId}
                  onChange={(e) =>
                    handleFilterChange('organizationId', e.target.value)
                  }
                  label="Organization"
                >
                  <MenuItem value="all">All Organizations</MenuItem>
                  {linkedOrganizations.map((org) => (
                    <MenuItem key={org._id} value={org._id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {renderCareStaffAutocomplete()}
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={filterOptions.startDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      handleFilterChange('startDate', newValue);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date"
                  value={filterOptions.endDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      handleFilterChange('endDate', newValue);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  minDate={filterOptions.startDate}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Shift Pattern</InputLabel>
                <Select
                  value={filterOptions.shiftPatternId}
                  onChange={(e) =>
                    handleFilterChange('shiftPatternId', e.target.value)
                  }
                  label="Shift Pattern"
                >
                  <MenuItem value="">All Patterns</MenuItem>
                  {yourShiftPatterns.map((pattern) => (
                    <MenuItem key={pattern._id} value={pattern._id}>
                      {pattern.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setFilterOptions({
                organizationId: 'all',
                careUserId: 'all',
                startDate: startOfMonth(new Date()),
                endDate: endOfMonth(new Date()),
                shiftPatternId: ''
              });
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={() => setFilterDialogOpen(false)}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      {/* Invoice Dialog */}
      <InvoiceDialog
        open={invoiceDialogOpen}
        onClose={() => setInvoiceDialogOpen(false)}
        timesheets={invoiceData.timesheets}
        totalAmount={invoiceData.totalAmount}
        home={invoiceData.home}
        selectedStartDate={filterOptions.startDate.toISOString()}
        selectedEndDate={filterOptions.endDate.toISOString()}
      />

      <Dialog
        open={invoiceSelectOpen}
        onClose={() => setInvoiceSelectOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Select Home and Date Range for Invoice
          <IconButton
            onClick={() => setInvoiceSelectOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={3}>
            {
              // Error Message
              invoiceError && (
                <Grid item xs={12}>
                  <Typography color="error">{invoiceError}</Typography>
                </Grid>
              )
            }
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Care Home</InputLabel>
                <Select
                  value={selectedHomeId}
                  onChange={(e) => setSelectedHomeId(e.target.value)}
                  label="Care Home"
                >
                  {linkedOrganizations.map((org) => (
                    <MenuItem key={org._id} value={org._id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Invoice Start Date"
                  value={invoiceStartDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      setInvoiceStartDate(newValue);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Invoice End Date"
                  value={invoiceEndDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      setInvoiceEndDate(newValue);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  minDate={invoiceStartDate}
                />
              </LocalizationProvider>
            </Grid>

            {isInvoiceFetching && (
              <Grid item xs={12}>
                <Box sx={{ textAlign: 'center', py: 2 }}>
                  <Typography color="text.secondary">
                    Fetching approved timesheets...
                  </Typography>
                </Box>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary">
                Select a care home and date range to generate an invoice. This
                will include all approved timesheets within the selected period.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setInvoiceSelectOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCreateInvoice}
            disabled={!selectedHomeId || isInvoiceFetching}
          >
            Create Invoice
          </Button>
        </DialogActions>
      </Dialog>

      {/* Loading State */}
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Typography>Loading timesheets...</Typography>
        </Box>
      )}

      {/* Actions SpeedDial */}
      {userState?.currentOrganization.type === 'agency' &&
        userState.user.role === 'admin' && (
          <TimesheetActions
            onUploadClick={() => setUploadDialogOpen(true)}
            onInvoiceClick={() => setInvoiceSelectOpen(true)}
          />
        )}
      <TimesheetUploadDialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        linkedOrganizations={linkedOrganizations}
        shiftPatterns={[...yourShiftPatterns, ...otherShiftPatterns]}
        careStaffs={careStaffs}
      />
    </Box>
  );
}

export default AgencyTimesheets;
