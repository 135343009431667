import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Chip,
  Divider,
  Alert,
  styled,
  Tooltip
} from '@mui/material';
import {
  AccessibilityNew,
  Warning,
  SwapHoriz,
  Bed,
  Timer,
  CheckCircle,
  Info,
  ArrowForward,
  RecordVoiceOver
} from '@mui/icons-material';
import { ITask } from 'src/interfaces/new/tasks';

interface PositioningData {
  // Position Details
  newPosition: string;
  previousPosition?: string;
  timeInPreviousPosition?: number;

  // Equipment
  equipmentUsed: string[];
  pillowsUsed: number;

  // Care Details
  skinCondition: {
    redness: boolean;
    breakdown: boolean;
    location?: string;
  };
  pressureAreas: string[];
  isComfortable: boolean;

  // Monitoring
  painReported: boolean;
  painLevel?: number;
  requiresFrequentPositioning: boolean;

  // Notes
  notes: string;
  concerns: boolean;
  timestamp: string;
}

interface PositioningReportProps {
  data: PositioningData | any;
  task: Partial<ITask>;
}

const ReportSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& + &': {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const PositioningReport: React.FC<PositioningReportProps> = ({
  data,
  task
}) => {
  const getPositionIcon = (position: string) => {
    switch (position.toLowerCase()) {
      case 'left side':
        return '⬅️';
      case 'right side':
        return '➡️';
      case 'back':
        return '⬆️';
      case 'sitting':
        return '🪑';
      case 'semi-fowler':
        return '↗️';
      default:
        return '🛏️';
    }
  };

  const formatDuration = (minutes?: number) => {
    if (!minutes) return 'Unknown';
    if (minutes < 60) return `${minutes} minutes`;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      {/* Header */}
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <AccessibilityNew color="primary" fontSize="large" />
        <Box>
          <Typography variant="h6">Position Change Record</Typography>
          <Typography variant="caption" color="textSecondary">
            {new Date(task.completedDate).toLocaleString()}
          </Typography>
          {/* completed by */}
          <Box display="flex" alignItems="center" gap={1} mt={1}>
            <RecordVoiceOver />
            <Typography variant="body2">
              Completed by: {task.completedBy?.firstName}{' '}
              {task.completedBy?.lastName}
            </Typography>

            {/* role */}
            <Tooltip title={task.completedBy?.role}>
              <Chip
                label={task.completedBy?.role}
                size="small"
                color="primary"
              />
            </Tooltip>
          </Box>
        </Box>
        <Box flexGrow={1} />
        {data.concerns && (
          <Chip
            icon={<Warning />}
            label="Requires Review"
            color="error"
            size="small"
          />
        )}
      </Box>

      <Divider />

      {/* Position Change */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Position Details
        </Typography>
        <Grid container spacing={2} alignItems="center">
          {data.previousPosition && (
            <Grid item>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="h6">
                  {getPositionIcon(data.previousPosition)}
                </Typography>
                <Typography>{data.previousPosition}</Typography>
              </Box>
            </Grid>
          )}

          {data.previousPosition && (
            <Grid item>
              <ArrowForward color="action" />
            </Grid>
          )}

          <Grid item>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="h6">
                {getPositionIcon(data.newPosition)}
              </Typography>
              <Typography>{data.newPosition}</Typography>
            </Box>
          </Grid>

          {data.timeInPreviousPosition && (
            <Grid item xs={12}>
              <Chip
                icon={<Timer />}
                label={`Previous position duration: ${formatDuration(
                  data.timeInPreviousPosition
                )}`}
                size="small"
                color="default"
              />
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Equipment Used */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Equipment & Support
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {data.equipmentUsed.map((equipment, index) => (
                <Chip
                  key={index}
                  label={equipment}
                  size="small"
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Chip
              icon={<Info />}
              label={`${data.pillowsUsed} Pillows Used`}
              size="small"
            />
          </Grid>
        </Grid>
      </ReportSection>

      {/* Skin Assessment */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Skin Assessment
        </Typography>
        <Grid container spacing={2}>
          {(data.skinCondition.redness || data.skinCondition.breakdown) && (
            <Grid item xs={12}>
              <Alert severity="warning">
                <Box>
                  {data.skinCondition.redness && (
                    <Typography>Redness observed</Typography>
                  )}
                  {data.skinCondition.breakdown && (
                    <Typography color="error">
                      Skin breakdown present
                    </Typography>
                  )}
                  {data.skinCondition.location && (
                    <Typography>
                      Location: {data.skinCondition.location}
                    </Typography>
                  )}
                </Box>
              </Alert>
            </Grid>
          )}

          {data.pressureAreas.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Pressure Areas
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {data.pressureAreas.map((area, index) => (
                  <Chip key={index} label={area} size="small" color="warning" />
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Comfort & Pain */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Comfort Assessment
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Chip
              icon={data.isComfortable ? <CheckCircle /> : <Warning />}
              label={data.isComfortable ? 'Comfortable' : 'Discomfort Reported'}
              color={data.isComfortable ? 'success' : 'warning'}
            />
          </Grid>

          {data.painReported && (
            <Grid item xs={12}>
              <Alert severity="warning">
                Pain Reported
                {data.painLevel !== undefined && (
                  <Typography variant="body2">
                    Pain Level: {data.painLevel}/10
                  </Typography>
                )}
              </Alert>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Additional Information */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Additional Information
        </Typography>
        <Grid container spacing={2}>
          {data.requiresFrequentPositioning && (
            <Grid item xs={12}>
              <Alert severity="info">Requires frequent repositioning</Alert>
            </Grid>
          )}

          {data.notes && (
            <Grid item xs={12}>
              <Typography variant="body2">{data.notes}</Typography>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Alerts Section */}
      {(data.concerns || data.skinCondition.breakdown || data.painReported) && (
        <ReportSection>
          <Alert
            severity="warning"
            action={
              <Tooltip title="May require assessment">
                <Info />
              </Tooltip>
            }
          >
            {data.skinCondition.breakdown
              ? 'Skin breakdown requires immediate attention'
              : 'Positioning concerns identified'}
          </Alert>
        </ReportSection>
      )}

      {/* Timestamp */}
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Typography variant="caption" color="textSecondary">
          Recorded: {new Date(data.timestamp).toLocaleString()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default PositioningReport;
