import React from 'react';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import {
  Upload as UploadIcon,
  Receipt as ReceiptIcon,
  Add as AddIcon,
  Close as CloseIcon
} from '@mui/icons-material';

export function TimesheetActions({ onUploadClick, onInvoiceClick }) {
  return (
    <SpeedDial
      ariaLabel="Timesheet actions"
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16
      }}
      icon={<SpeedDialIcon icon={<AddIcon />} openIcon={<CloseIcon />} />}
      direction="up"
    >
      <SpeedDialAction
        icon={<UploadIcon />}
        tooltipTitle="Upload Timesheet"
        onClick={onUploadClick}
      />
      <SpeedDialAction
        icon={<ReceiptIcon />}
        tooltipTitle="Create Invoice"
        onClick={onInvoiceClick}
      />
    </SpeedDial>
  );
}
