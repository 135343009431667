import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Stack
} from '@mui/material';
import { Close, Download, Business } from '@mui/icons-material';
import moment from 'moment';

interface ViewInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  invoice: any;
  home: {
    homeName: string;
    homeAddress: string;
    homeEmail: string;
    homePhone: string;
  };
  agency: {
    agencyName: string;
    agencyAddress: string;
    agencyEmail: string;
    agencyPhone: string;
  };
}

const ViewInvoiceDialog: React.FC<ViewInvoiceDialogProps> = ({
  open,
  onClose,
  invoice,
  home,
  agency
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { minHeight: '80vh' }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Typography variant="h6">Invoice #{invoice.invoiceNumber}</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>

      <DialogContent>
        <Stack spacing={4}>
          {/* Header Info */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                From
              </Typography>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
              >
                <Business />
                <Typography variant="subtitle1">{agency.agencyName}</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                {agency.agencyAddress}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {agency.agencyEmail}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {agency.agencyPhone}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                To
              </Typography>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
              >
                <Business />
                <Typography variant="subtitle1">{home.homeName}</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                {home.homeAddress}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {home.homeEmail}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {home.homePhone}
              </Typography>
            </Grid>
          </Grid>

          {/* Invoice Details */}
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={3}>
                <Typography variant="body2" color="text.secondary">
                  Invoice Date
                </Typography>
                <Typography variant="body1">
                  {moment(invoice.createdAt).format('MMM D, YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="body2" color="text.secondary">
                  Due Date
                </Typography>
                <Typography variant="body1">
                  {moment(invoice.dueDate).format('MMM D, YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="body2" color="text.secondary">
                  Status
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {invoice.status}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="body2" color="text.secondary">
                  Total Amount
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  £{invoice.totalAmount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          {/* Period Info */}
          <Box>
            <Typography variant="body2" color="text.secondary">
              Period
            </Typography>
            <Typography variant="body1">
              {moment(invoice.startDate).format('MMM D')} -{' '}
              {moment(invoice.endDate).format('MMM D, YYYY')}
            </Typography>
          </Box>

          {/* Shift Summary */}
          <Box>
            <Typography variant="h6" gutterBottom>
              Shift Summary
            </Typography>
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Shift Type</TableCell>
                    <TableCell align="right">Count</TableCell>
                    <TableCell align="right">Weekday Hours</TableCell>
                    <TableCell align="right">Weekend Hours</TableCell>
                    <TableCell align="right">Holiday Hours</TableCell>
                    <TableCell align="right">Total Hours</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.shiftSummary.map((shift: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{shift.shiftType}</TableCell>
                      <TableCell align="right">{shift.count}</TableCell>
                      <TableCell align="right">{shift.weekdayHours}</TableCell>
                      <TableCell align="right">{shift.weekendHours}</TableCell>
                      <TableCell align="right">{shift.holidayHours}</TableCell>
                      <TableCell align="right">{shift.totalHours}</TableCell>
                      <TableCell align="right">
                        £{shift.totalAmount.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="right"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Total Amount
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      £{invoice.totalAmount.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* Rate Information */}
          <Box>
            <Typography variant="h6" gutterBottom>
              Rate Information
            </Typography>
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell align="right">Weekday Rate</TableCell>
                    <TableCell align="right">Weekend Rate</TableCell>
                    <TableCell align="right">Holiday Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.shiftSummary.map((shift: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{shift.shiftType}</TableCell>
                      <TableCell align="right">
                        £{shift.weekdayRate.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        £{shift.weekendRate.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        £{shift.holidayRate.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
        <Button
          variant="contained"
          startIcon={<Download />}
          onClick={() => {
            // Implement PDF download functionality
            console.log('Download PDF');
          }}
        >
          Download PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewInvoiceDialog;
