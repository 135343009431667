import { useEffect, useState } from 'react';
import { Navigate, type NonIndexRouteObject } from 'react-router';
import { useAppSelector } from './redux/hook';
import type { RootState } from './redux/store';
import { useDispatch } from 'react-redux';
import {
  _setCurrentOrganization,
  _setCurrentOrganizationRoles,
  _setPendingJoinRequest,
  _setUser
} from './redux/reducers/user-slice';
import { CircularProgress } from '@mui/material';
import BaseLayout from './layouts/BaseLayout';
import SidebarLayout from './layouts/SidebarLayout';
import Home from './content/dashboards/Home';
import AgencySettings from './content/dashboards/agency/agency-settings';
import CareHomeSettings from './content/dashboards/home/home-settings';
import StaffsComponent from './content/dashboards/users/home-staffs';
import Login from './content/authentication/login-page';
import SignupProcess from './content/authentication/auth-forms/signup';
import PendingJoinRequest from './components/core/ui/pending';
import Invitations from './content/dashboards/common/invitations';
import CarerApplicationForm from './content/dashboards/carers/carer-settings';
import LinkedOrganizationsList from './content/dashboards/common/organizations';
import StaffTabs from './content/pages/staffs/staffs';
import ChatPage from './content/applications/Chat/chat-page';
import PaymentSection from './content/stripe/payment';
import CarerResumePage from './content/dashboards/carers/profile';
import HomeResidentPage from './content/dashboards/home/home-residents';
import CarerResidentPage from './content/dashboards/carers/carer-residents';
import EmailVerification from './components/core/ui/email-verfication';
import { useLazyGetUserQuery } from './redux/@api/auth';
import Staffapplication from './content/pages/staffs/staff-application';
import AccountDeletionPending from './content/pages/Status/deletion';

import { Device } from '@capacitor/device';
import ResidentProgressDashboard from './content/dashboards/home/views/dialogs/charts';
import Charts from './content/dashboards/common/charts';
import PullToRefresh from 'react-simple-pull-to-refresh';
import store from './redux/store';
import { timesheetsApi } from './redux/@api/timesheet';
import { chatApi } from './redux/@api/chat';
import { residentsApi } from './redux/@api/resident';
import { organizationsApi } from './redux/@api/organisation';
import { invitationsApi } from './redux/@api/invitations';
import { staffApi } from './redux/@api/staff';
import HomeTimesheets from './content/dashboards/home/timesheets';
import AgencyTimesheets from './content/dashboards/agency/timesheet';
import Timesheets from './content/dashboards/carers/timesheets';
import InvitationHandler from './components/core/dialogs/invitationHandler';
import { useSearchParams } from 'react-router-dom';
import InvoiceTable from './content/dashboards/common/invoices';

const getDeviceInfo = async () => {
  try {
    const info = await Device.getInfo();
    const id = await Device.getId();
    return id.identifier;
  } catch (error) {
    console.error('Error getting device info:', error);
    return null;
  }
};

export const PrivateRoute = ({ element, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasCurrentOrganization, setHasCurrentOrganization] = useState(false);
  const [hasPendingRequest, setHasPendingRequest] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [getUser] = useLazyGetUserQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const identifier = await getDeviceInfo();

        console.log('Identifier:', identifier);
        const response = await getUser({
          identifier
        }).unwrap();
        setIsAuthenticated(true);
        setHasCurrentOrganization(!!response.currentOrganization);
        setHasPendingRequest(response.pendingJoinRequest?.status === 'pending');
        setIsEmailVerified(response.user.emailVerified);
        setIsActive(!response.user.accountDeletionRequested);
      } catch (error) {
        console.error('Failed to fetch user:', error);
        localStorage.removeItem('token');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isActive) {
    return <Navigate to="/deletion-pending" />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!isEmailVerified) {
    return <Navigate to="/verify-email" />;
  }

  if (!hasCurrentOrganization) {
    if (hasPendingRequest) {
      return <Navigate to="/pending-registration" />;
    }
    return <Navigate to="/signup" />;
  }

  return element;
};

const PublicRoute = ({ element, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasCurrentOrganization, setHasCurrentOrganization] = useState(false);
  const [hasPendingRequest, setHasPendingRequest] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [getUser] = useLazyGetUserQuery();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('orgInvitationToken');

  if (token) {
    localStorage.setItem('orgInvitationToken', token);
  }

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const identifier = await getDeviceInfo();
        const response = await getUser({
          identifier
        }).unwrap();

        setIsAuthenticated(true);
        setHasCurrentOrganization(!!response.currentOrganization);
        setHasPendingRequest(response.pendingJoinRequest?.status === 'pending');
        setIsActive(!response.user.accountDeletionRequested);
      } catch (error) {
        console.error('Failed to fetch user:', error);
        localStorage.removeItem('token');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }
  if (!isActive) {
    return element;
  }

  if (!isAuthenticated) {
    return element;
  }

  if (!hasCurrentOrganization) {
    if (hasPendingRequest) {
      return <Navigate to="/pending-registration" />;
    }
    return element;
  }

  return <Navigate to="/" />;
};
const routes: NonIndexRouteObject[] = [
  {
    path: '',
    element: <PrivateRoute element={<BaseLayout />} />,
    children: [
      { path: '/', element: <Navigate to={'/home'} /> },
      { path: 'overview', element: <Navigate to="/" replace /> },
      {
        path: 'status',
        children: [
          { path: '', element: <Navigate to="404" replace /> },
          { path: '404', element: <></> },
          { path: '500', element: <></> },
          { path: 'maintenance', element: <></> },
          { path: 'coming-soon', element: <></> }
        ]
      }
    ]
  },
  {
    path: 'home',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <Home /> }]
  },
  // { path: 'agency-settings', element: <AgencySettings /> },

  {
    path: 'agency-settings',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <AgencySettings /> }]
  },
  {
    path: 'home-settings',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <CareHomeSettings /> }]
  },
  {
    path: 'home-staffs',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <StaffsComponent /> }]
  },
  {
    path: 'carer-settings',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <CarerApplicationForm /> }]
  },
  {
    path: 'nurse-profile',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <CarerApplicationForm /> }]
  },
  {
    path: 'carer-profile',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <CarerApplicationForm /> }]
  },
  {
    path: 'carer-resume',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <CarerResumePage /> }]
  },
  {
    path: 'carer/:id',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <AgencySettings /> }]
  },
  {
    path: 'employee-portal',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <AgencySettings /> }]
  },
  {
    path: 'home-users',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PullToRefresh
            onRefresh={async () => {
              return Promise.all([
                store.dispatch(
                  organizationsApi.util.invalidateTags(['LinkedOrganizations'])
                )
              ]);
            }}
          >
            <LinkedOrganizationsList />
          </PullToRefresh>
        )
      }
    ]
  },
  {
    path: 'home-residents',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PullToRefresh
            onRefresh={async () => {
              return Promise.all([
                store.dispatch(residentsApi.util.invalidateTags(['Resident']))
              ]);
            }}
          >
            <HomeResidentPage />
          </PullToRefresh>
        )
      }
    ]
  },
  {
    path: 'carer-residents',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <CarerResidentPage /> }]
  },
  {
    path: 'home-thirdparties',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <AgencySettings /> }]
  },
  {
    path: 'agency-users',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PullToRefresh
            onRefresh={async () => {
              return Promise.all([
                store.dispatch(
                  organizationsApi.util.invalidateTags(['LinkedOrganizations'])
                )
              ]);
            }}
          >
            <LinkedOrganizationsList />
          </PullToRefresh>
        )
      }
    ]
  },
  {
    path: 'agency-staffs',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <AgencySettings /> }]
  },
  {
    path: '/invoices',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <InvoiceTable /> }]
  },
  {
    path: 'home-invoices',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <AgencySettings /> }]
  },
  {
    path: 'agency-payroll-enrollment',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <AgencySettings /> }]
  },
  {
    path: 'chat',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PullToRefresh
            onRefresh={async () => {
              // store.dispatch(chatApi.util.invalidateTags(['ChatMessages', 'Staffs']));
              return Promise.all([
                store.dispatch(
                  chatApi.util.invalidateTags(['ChatMessages', 'Staffs'])
                )
              ]);
            }}
          >
            <ChatPage />
          </PullToRefresh>
        )
      }
    ]
  },
  {
    path: 'invitations',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PullToRefresh
            onRefresh={async () => {
              return Promise.all([
                store.dispatch(
                  invitationsApi.util.invalidateTags(['Invitations'])
                )
              ]);
            }}
          >
            <Invitations />
          </PullToRefresh>
        )
      }
    ]
  },
  {
    path: 'staffs',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PullToRefresh
            onRefresh={async () => {
              return Promise.all([
                store.dispatch(staffApi.util.invalidateTags(['Staffs']))
              ]);
            }}
          >
            <StaffTabs />
          </PullToRefresh>
        )
      }
    ]
  },
  {
    path: 'carer-timesheets',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <AgencySettings /> }]
  },
  {
    path: 'nurse-timesheets',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <AgencySettings /> }]
  },
  {
    path: 'home-timesheets',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <HomeTimesheets /> }]
  },

  {
    path: 'home-timesheets',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PullToRefresh
            onRefresh={async () => {
              // store.dispatch(timesheetsApi.util.invalidateTags(['Timesheets']));
              return Promise.all([
                store.dispatch(
                  timesheetsApi.util.invalidateTags(['Timesheets'])
                )
              ]);
            }}
          >
            <HomeTimesheets />
          </PullToRefresh>
        )
      }
    ]
  },
  {
    path: 'agency-timesheets',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PullToRefresh
            onRefresh={async () => {
              // store.dispatch(timesheetsApi.util.invalidateTags(['Timesheets']));
              return Promise.all([
                store.dispatch(
                  timesheetsApi.util.invalidateTags(['Timesheets'])
                )
              ]);
            }}
          >
            <AgencyTimesheets />
          </PullToRefresh>
        )
      }
    ]
  },

  {
    path: 'timesheets',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PullToRefresh
            onRefresh={async () => {
              // store.dispatch(timesheetsApi.util.invalidateTags(['Timesheets']));
              return Promise.all([
                store.dispatch(
                  timesheetsApi.util.invalidateTags(['Timesheets'])
                )
              ]);
            }}
          >
            <Timesheets />
          </PullToRefresh>
        )
      }
    ]
  },
  {
    path: 'payments',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <PaymentSection /> }]
  },
  {
    path: 'charts',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [{ path: '', element: <Charts /> }]
  },
  {
    path: '/deletion-pending',
    element: <PublicRoute element={<AccountDeletionPending />} />
  },
  {
    path: '/join-organization',
    element: <PublicRoute element={<Navigate to="/" />} />
  },
  { path: '/login', element: <PublicRoute element={<Login />} /> },
  { path: '/signup', element: <PublicRoute element={<SignupProcess />} /> },
  { path: '/pending-registration', element: <PendingJoinRequest /> },
  {
    path: '/verify-email',
    element: <PublicRoute element={<EmailVerification />} />
  },
  { path: '*', element: <Navigate to="/404" /> }
];

export default routes;
