import React, { useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Tabs,
  Tab,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { IResident } from 'src/interfaces/resident';
import { useGetTasksQuery } from 'src/redux/@api/resident';
import AllTasksTab from './tabs/all-task';
import PendingTasksTab from './tabs/pending-tasks';
import HistoricalTasksTab from './tabs/historical-tasks';
import ResidentProfile from './dialogs/resident-profile';
import TaskResolveDialog from './tabs/rosolve-task';
import AddTaskDialog from './dialogs/add-tasks';
import {
  useGetTasksForResidentQuery,
  useResolveTaskMutation
} from 'src/redux/@api/task';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { he } from 'date-fns/locale';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { useAppSelector } from 'src/redux/hook';

interface TaskManagementDialogProps {
  open: boolean;
  onClose: () => void;
  resident: IResident;
}

interface TabPermission {
  label: string;
  requiredPermission: string;
}

type TabName = 'all' | 'pending' | 'historical' | 'profile';

interface TabConfig {
  name: TabName;
  label: string;
  requiredPermission: string;
}

const TaskManagementDialog: React.FC<TaskManagementDialogProps> = ({
  open,
  onClose,
  resident
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [addTaskDialogOpen, setAddTaskDialogOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [currentTab, setCurrentTab] = useState<TabName>('all');
  const [resolveDialogOpen, setResolveDialogOpen] = useState(false);

  const { data: tasks, refetch: refetchTasks } = useGetTasksForResidentQuery(
    resident._id,
    {
      skip: !open
    }
  );

  const hapticsVibrate = async () => {
    await Haptics.vibrate();
  };

  const [resolveTask] = useResolveTaskMutation();
  const dispatch = useDispatch();

  const handleAddTask = () => {
    setAddTaskDialogOpen(true);
  };

  const handleTaskAdded = () => {
    setAddTaskDialogOpen(false);
    refetchTasks();
  };

  const handleResolveTask = (task) => {
    setSelectedTask(task);
    setResolveDialogOpen(true);
  };

  const handleCompleteTask = async (taskData: any) => {
    if (selectedTask) {
      try {
        // await completeTask({ taskId: selectedTask._id, taskData }).unwrap();
        await resolveTask({
          taskId: selectedTask._id,
          taskData,
          status: 'completed'
        }).unwrap();
        setResolveDialogOpen(false);
        setSelectedTask(null);
        refetchTasks();
        dispatch(showSnack({ color: 'success', message: 'Task completed' }));
      } catch (error) {
        dispatch(
          showSnack({ color: 'error', message: 'Error completing task' })
        );
        console.error('Error completing task:', error);
      }
    }
  };

  const handleTaskResolved = async (taskData) => {
    await handleCompleteTask(taskData);
  };

  const staffType = useAppSelector((state) => state.userState.staffType);
  const userRole = useAppSelector((state) => state.userState.user?.role) as
    | 'carer'
    | 'nurse'
    | 'admin'
    | 'senior carer';
  const permissions = useAppSelector((state) => state.userState.permissions);

  const tabs: TabPermission[] = useMemo(
    () => [
      { label: 'All', requiredPermission: 'view_all_tasks' },
      { label: 'Pending', requiredPermission: 'view_pending_tasks' },
      { label: 'Historical', requiredPermission: 'view_historical_tasks' },
      { label: 'Profile', requiredPermission: 'view_resident_profile' }
    ],
    []
  );

  const tabConfigs: TabConfig[] = useMemo(
    () => [
      { name: 'all', label: 'All', requiredPermission: 'view_all_tasks' },
      {
        name: 'pending',
        label: 'Pending',
        requiredPermission: 'view_pending_tasks'
      },
      {
        name: 'historical',
        label: 'Historical',
        requiredPermission: 'view_historical_tasks'
      },
      {
        name: 'profile',
        label: 'Profile',
        requiredPermission: 'view_resident_profile'
      }
    ],
    []
  );

  const availableTabs = useMemo(
    () =>
      tabConfigs.filter((tab) => permissions.includes(tab.requiredPermission)),
    [permissions, tabConfigs]
  );

  React.useEffect(() => {
    if (
      !availableTabs.find((tab) => tab.name === currentTab) &&
      availableTabs.length > 0
    ) {
      setCurrentTab(availableTabs[0].name);
    }
  }, [availableTabs, currentTab]);

  const renderTabContent = (tabName: TabName) => {
    const hasPermission = (permission: string) =>
      permissions.includes(permission);

    switch (tabName) {
      case 'all':
        return hasPermission('view_all_tasks') ? (
          <AllTasksTab
            tab={tabName}
            onResolveTask={handleResolveTask}
            resident={resident}
          />
        ) : null;
      case 'pending':
        return hasPermission('view_pending_tasks') ? (
          <PendingTasksTab
            tab={tabName}
            onResolveTask={handleResolveTask}
            resident={resident}
          />
        ) : null;
      case 'historical':
        return hasPermission('view_historical_tasks') ? (
          <HistoricalTasksTab resident={resident} />
        ) : null;
      case 'profile':
        return hasPermission('view_resident_profile') ? (
          <ResidentProfile residentId={resident._id} onClose={onClose} />
        ) : null;
      default:
        return (
          <Typography>
            You don't have permission to view this content
          </Typography>
        );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            [theme.breakpoints.down('sm')]: {
              margin: 0
            }
          },
          '$ .MuiDialog-root': {
            width: '95vw',
            height: '90vh',
            [theme.breakpoints.down('sm')]: {
              width: '98vw !important'
            }
          }
        }}
        PaperProps={{
          sx: {
            height: '95vh',
            maxHeight: 'none',
            width: '98vw',
            [theme.breakpoints.down('sm')]: {
              height: '90vh',
              width: '95vw !important'
            }
          }
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <Typography variant={isMobile ? 'h5' : 'h4'} component="span">
              Task Management: {resident.firstName} {resident.lastName}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                mt: isMobile ? 2 : 0
              }}
            >
              {permissions.includes('view_resident_charts') && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Icon icon="fe:line-chart" />}
                  size="small"
                  onClick={() =>
                    navigate('/charts', { state: { residentId: resident._id } })
                  }
                >
                  Charts
                </Button>
              )}
              {permissions.includes('add_task') && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Icon icon="ri:add-line" />}
                  onClick={handleAddTask}
                  size="small"
                >
                  Add Task
                </Button>
              )}
            </Box>
          </Box>
          <Tabs
            value={currentTab}
            onChange={(_, newValue: TabName) => setCurrentTab(newValue)}
            variant={isMobile ? 'fullWidth' : 'standard'}
            sx={{
              mt: 2,
              '& .MuiTab-root': {
                minWidth: isMobile ? 'auto' : 120,
                px: isMobile ? 1 : 2
              }
            }}
          >
            {availableTabs.map((tab) => (
              <Tab key={tab.name} value={tab.name} label={tab.label} />
            ))}
          </Tabs>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            backgroundColor: theme.colors.secondary.light + ' !important'
          }}
          dividers
        >
          <Box sx={{ height: '100%', overflow: 'auto' }}>
            {renderTabContent(currentTab)}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>

        {permissions.includes('add_task') && (
          <AddTaskDialog
            open={addTaskDialogOpen}
            onClose={() => setAddTaskDialogOpen(false)}
            onTaskAdded={handleTaskAdded}
            residentId={resident._id}
          />
        )}

        {permissions.includes('resolve_task') && (
          <TaskResolveDialog
            open={resolveDialogOpen}
            onClose={() => setResolveDialogOpen(false)}
            onResolve={handleTaskResolved}
            task={selectedTask}
          />
        )}
      </Dialog>
    </LocalizationProvider>
  );
};

export default TaskManagementDialog;
