// base.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiHostname } from 'src/api/api';

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiHostname}/api/v1`,
    prepareHeaders: async (headers, { getState }) => {
      const token = await localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      return headers;
    }
  }),
  tagTypes: [
    'ShiftType',
    'ShiftPattern',
    'Invoice',
    'Resident',
    'Task',
    'AssignedStaffs',
    'CarePlan',
    'Application',
    'Shift',
    'AgencyShift',
    'Payment',
    'ChatMessages',
    'Notifications',
    'NotificationsCount',
    'StaffsForChat',
    'Staffs',
    'Timesheets',
    'Invitations',
    'Agencies',
    'LinkedOrganizations',
    'LinkedOrgranizationAdmins',
    'LinkedOrganizationAdminForCarer',
    'Analytics',
    'StaffAnalytics', // Staff-related metrics
    'ShiftAnalytics', // Shift-related metrics
    'RevenueAnalytics', // Revenue-related metrics
    'CareHomeAnalytics', // Care home relationship metrics
    'StaffingTrends', // Staffing demand trends
    'UpcomingRequirements', // Upcoming shift requirements
    'DailyMetrics', // Daily performance metrics
    'WeeklyMetrics', // Weekly aggregated metrics
    'MonthlyMetrics', // Monthly aggregated metrics
    'StaffUtilization', // Staff utilization metrics
    'ShiftFillRate', // Shift fill rate metrics
    'RevenueGrowth', // Revenue growth metrics
    'CareHomeEngagement', // Care home engagement metrics
    'UrgentRequirements', // Urgent staffing needs
    'StaffAvailability',
    'Invoices',
    'FavoriteStaffs',
    'FavoriteStatus',
    'TaskSummary',
    'tasks'
  ],
  endpoints: () => ({})
});
