import React, { useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import TaskGrid from './task-grid';
import { useGetHistoricTasksQuery } from 'src/redux/@api/resident';
import dayjs from 'dayjs';
import { ITask } from 'src/interfaces/new/tasks';
import HistoricTaskMapDialog from '../historic/historic-map';
import { IResident } from 'src/interfaces/resident';

interface HistoricalTasksTabProps {
  resident: IResident;
}

const HistoricalTasksTab: React.FC<HistoricalTasksTabProps> = ({
  resident
}) => {
  const [startDate, setStartDate] = useState<Date>(
    dayjs().startOf('month').toDate()
  );
  const [endDate, setEndDate] = useState<Date>(dayjs().endOf('month').toDate());
  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);
  // Add a key that changes when dates change
  const gridKey = `${startDate.toISOString()}-${endDate.toISOString()}`;

  // Clear data when component unmounts
  useEffect(() => {
    return () => {
      setSelectedTask(null);
    };
  }, []);

  const handleStartDateChange = (newValue: Date | null) => {
    if (newValue) {
      setStartDate(newValue);
      setSelectedTask(null);
    }
  };

  const handleEndDateChange = (newValue: Date | null) => {
    if (newValue) {
      setEndDate(newValue);
      setSelectedTask(null);
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </Box>

      {/* Key prop forces re-render when dates change */}
      <TaskGrid
        resident={resident}
        key={gridKey}
        tab="historical"
        isHistorical
        onTaskClick={(task: ITask) => setSelectedTask(task)}
      />

      {selectedTask && (
        <HistoricTaskMapDialog
          open={Boolean(selectedTask)}
          onClose={() => setSelectedTask(null)}
          task={selectedTask}
        />
      )}
    </Box>
  );
};

export default HistoricalTasksTab;
