import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Grid,
  Fab,
  Autocomplete
} from '@mui/material';
import { Upload as UploadIcon, Close as CloseIcon } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch } from 'react-redux';
import { apiHostname } from 'src/api/api';
import axios from 'axios';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

export function TimesheetUploadDialog({
  open,
  onClose,
  linkedOrganizations = [],
  shiftPatterns = [],
  careStaffs = []
}) {
  const [formData, setFormData] = useState({
    organizationId: '',
    shiftPatternId: '',
    careUserId: '',
    shiftDate: null,
    file: null
  });

  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleChange('file', file);
    }
  };

  const handleSubmit = async () => {
    if (!formData.file) return;

    setIsUploading(true);
    const submitFormData = new FormData();

    // Create unique filename
    const fileExtension = formData.file.name.substr(
      formData.file.name.lastIndexOf('.')
    );
    const newFileName = `timesheet_${formData.organizationId}_${
      formData.careUserId
    }_${Date.now()}${fileExtension}`;
    const renamedFile = new File([formData.file], newFileName, {
      type: formData.file.type
    });

    // Append all form data
    submitFormData.append('file', renamedFile);
    submitFormData.append('homeId', formData.organizationId);
    submitFormData.append('shiftPatternId', formData.shiftPatternId);
    submitFormData.append('careUserId', formData.careUserId);
    submitFormData.append('shiftDate', formData.shiftDate.toISOString());

    try {
      const response = await axios.post(
        `${apiHostname}/api/v1/timesheets/upload`,
        submitFormData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (response.data.success) {
        dispatch(
          showSnack({
            message: 'Timesheet uploaded successfully',
            color: 'success'
          })
        );
        onClose();
      } else {
        throw new Error(response.data.error || 'Failed to upload timesheet');
      }
    } catch (error) {
      console.error('Error uploading timesheet:', error);
      dispatch(
        showSnack({
          message: error.message || 'Failed to upload timesheet',
          color: 'error'
        })
      );
    } finally {
      setIsUploading(false);
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Upload Timesheet
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Care Home</InputLabel>
              <Select
                value={formData.organizationId}
                onChange={(e) => handleChange('organizationId', e.target.value)}
                label="Care Home"
              >
                {linkedOrganizations.map((org) => (
                  <MenuItem key={org._id} value={org._id}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Shift Pattern</InputLabel>
              <Select
                value={formData.shiftPatternId}
                onChange={(e) => handleChange('shiftPatternId', e.target.value)}
                label="Shift Pattern"
              >
                {shiftPatterns.map((pattern) => (
                  <MenuItem key={pattern._id} value={pattern._id}>
                    {pattern.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              options={careStaffs}
              getOptionLabel={(option) =>
                `${option.user.firstName} ${option.user.lastName}`
              }
              value={
                careStaffs.find(
                  (staff) => staff.user._id === formData.careUserId
                ) || null
              }
              onChange={(event, newValue) => {
                handleChange('careUserId', newValue ? newValue.user._id : '');
              }}
              renderInput={(params) => (
                <TextField {...params} label="Carer" fullWidth />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Shift Date"
                value={formData.shiftDate}
                onChange={(newValue) => handleChange('shiftDate', newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                inputFormat="yyyy-MM-dd"
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              component="label"
              fullWidth
              startIcon={<UploadIcon />}
            >
              Select File
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.csv,.xlsx"
              />
            </Button>
            {formData.file && (
              <Box mt={1}>Selected file: {formData.file.name}</Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            isUploading ||
            !formData.organizationId ||
            !formData.shiftPatternId ||
            !formData.careUserId ||
            !formData.shiftDate ||
            !formData.file
          }
        >
          {isUploading ? 'Uploading...' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
