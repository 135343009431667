import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Typography,
  Box,
  Alert,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';

// Constants
const MAX_DISTANCE_METERS = 300;
const LOCATION_CHECK_TIMEOUT = 30000;
const isNativePlatform = Capacitor.isNativePlatform();

// Types
type LocationCoordinates = {
  latitude: number;
  longitude: number;
};

type PermissionState =
  | 'unknown'
  | 'checking'
  | 'granted'
  | 'denied'
  | 'prompt'
  | 'blocked';

type DialogStatus =
  | 'initial'
  | 'checking'
  | 'checking-permission'
  | 'permission-required'
  | 'permission-denied'
  | 'verifying'
  | 'success'
  | 'out-of-range'
  | 'error';

interface LocationVerificationDialogProps {
  open: boolean;
  postcode: string;
  onVerificationComplete: (success: boolean) => void;
  onClose: () => void;
  maxDistance?: number;
}

const LocationVerificationDialog: React.FC<LocationVerificationDialogProps> = ({
  open,
  postcode,
  onVerificationComplete,
  onClose,
  maxDistance = MAX_DISTANCE_METERS
}) => {
  const [status, setStatus] = useState<DialogStatus>('initial');
  const [error, setError] = useState<string | null>(null);
  const [distance, setDistance] = useState<number | null>(null);
  const [permissionStatus, setPermissionStatus] =
    useState<PermissionState>('unknown');

  useEffect(() => {
    if (!open) {
      setStatus('initial');
      setError(null);
      setDistance(null);
      setPermissionStatus('unknown');
    }
  }, [open]);

  const checkLocationPermission = async (): Promise<boolean> => {
    setStatus('checking-permission');

    try {
      if (isNativePlatform) {
        const status = await Geolocation.checkPermissions();
        setPermissionStatus(status.location as PermissionState);
        return status.location === 'granted';
      } else {
        if (!navigator.geolocation) {
          throw new Error('Geolocation is not supported by your browser');
        }

        if (navigator.permissions && navigator.permissions.query) {
          const result = await navigator.permissions.query({
            name: 'geolocation'
          });

          result.addEventListener('change', () => {
            setPermissionStatus(result.state as PermissionState);
          });

          setPermissionStatus(result.state as PermissionState);
          return result.state === 'granted';
        }

        setPermissionStatus('prompt');
        return false;
      }
    } catch (error) {
      console.error('Permission check error:', error);
      setPermissionStatus('denied');
      return false;
    }
  };

  const requestLocationPermission = async (): Promise<boolean> => {
    try {
      if (isNativePlatform) {
        const result = await Geolocation.requestPermissions();
        const granted = result.location === 'granted';
        setPermissionStatus(granted ? 'granted' : 'denied');
        return granted;
      } else {
        return await new Promise((resolve) => {
          navigator.geolocation.getCurrentPosition(
            () => {
              setPermissionStatus('granted');
              resolve(true);
            },
            (error) => {
              setPermissionStatus(error.code === 1 ? 'denied' : 'blocked');
              resolve(false);
            },
            { timeout: LOCATION_CHECK_TIMEOUT }
          );
        });
      }
    } catch (error) {
      console.error('Permission request error:', error);
      setPermissionStatus('denied');
      return false;
    }
  };

  const getPostcodeCoordinates = async (
    postcode: string
  ): Promise<LocationCoordinates> => {
    try {
      const response = await fetch(
        `https://api.postcodes.io/postcodes/${encodeURIComponent(postcode)}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (!data.result) {
        throw new Error('Invalid postcode');
      }

      return {
        latitude: data.result.latitude,
        longitude: data.result.longitude
      };
    } catch (error) {
      throw new Error(
        `Failed to get postcode location: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
    }
  };

  const getCurrentLocation = async (): Promise<LocationCoordinates> => {
    try {
      if (isNativePlatform) {
        const position = await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
          timeout: LOCATION_CHECK_TIMEOUT
        });

        return {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
      } else {
        return await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              });
            },
            (error) => {
              let errorMessage = 'Failed to get current location';

              switch (error.code) {
                case error.PERMISSION_DENIED:
                  errorMessage = 'Please enable location services to continue';
                  break;
                case error.POSITION_UNAVAILABLE:
                  errorMessage = 'Location information is unavailable';
                  break;
                case error.TIMEOUT:
                  errorMessage = 'Location request timed out';
                  break;
                default:
                  errorMessage = 'An unknown error occurred';
              }

              reject(new Error(errorMessage));
            },
            {
              enableHighAccuracy: true,
              timeout: LOCATION_CHECK_TIMEOUT,
              maximumAge: 0
            }
          );
        });
      }
    } catch (error) {
      throw new Error(
        `Failed to get current location: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
    }
  };

  const calculateDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number => {
    const R = 6371e3; // Earth's radius in meters
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  const handlePermissionRequest = async () => {
    const hasPermission = await requestLocationPermission();
    if (hasPermission) {
      verifyLocation();
    } else {
      setStatus('permission-denied');
      setError('Location permission is required for verification');
    }
  };

  const openAppSettings = () => {
    if (isNativePlatform) {
      // For Android, we'll open the app's settings page
      window.location.href = 'package:settings';
    }
  };

  const verifyLocation = async () => {
    try {
      setStatus('checking');
      setError(null);

      const hasPermission = await checkLocationPermission();

      if (!hasPermission) {
        if (permissionStatus === 'prompt') {
          handlePermissionRequest();
          return;
        }
        setStatus('permission-denied');
        setError('Location permission is required for verification');
        return;
      }

      const [postcodeLocation, currentLocation] = await Promise.all([
        getPostcodeCoordinates(postcode),
        getCurrentLocation()
      ]);

      const calculatedDistance = calculateDistance(
        postcodeLocation.latitude,
        postcodeLocation.longitude,
        currentLocation.latitude,
        currentLocation.longitude
      );

      setDistance(Math.round(calculatedDistance));
      const isWithinRange = calculatedDistance <= maxDistance;

      setStatus(isWithinRange ? 'success' : 'out-of-range');

      // Add a small delay before completing
      await new Promise((resolve) => setTimeout(resolve, 1000));
      onVerificationComplete(isWithinRange);
    } catch (error) {
      setStatus('error');
      setError(
        error instanceof Error ? error.message : 'An unknown error occurred'
      );
      onVerificationComplete(false);
    }
  };

  const renderPermissionInstructions = () => {
    if (isNativePlatform) {
      return (
        <List dense>
          <ListItem>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Open device settings" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Go to Apps / Permissions" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Find and select this app" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Enable Location permission" />
          </ListItem>
        </List>
      );
    }

    return (
      <List dense>
        <ListItem>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Click the lock icon in your browser's address bar" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Find Location permission" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Allow location access" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Refresh the page" />
        </ListItem>
      </List>
    );
  };

  const renderContent = () => {
    switch (status) {
      case 'checking':
      case 'checking-permission':
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2
            }}
          >
            <CircularProgress size={48} />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LocationOnIcon color="primary" />
              <Typography>
                {status === 'checking-permission'
                  ? 'Checking location permission...'
                  : 'Verifying your location...'}
              </Typography>
            </Box>
          </Box>
        );

      case 'permission-required':
      case 'permission-denied':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Alert severity="info">
              {status === 'permission-required'
                ? 'Location permission is required to verify your position'
                : 'Location permission has been denied. Please enable it in settings.'}
            </Alert>
            <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.50' }}>
              <Typography variant="subtitle2" gutterBottom>
                To enable location services:
              </Typography>
              {renderPermissionInstructions()}
            </Paper>
            {status === 'permission-required' ? (
              <Button
                variant="contained"
                startIcon={<LocationOnIcon />}
                onClick={handlePermissionRequest}
                fullWidth
              >
                Allow Location Access
              </Button>
            ) : (
              <Button
                variant="contained"
                startIcon={<SettingsIcon />}
                onClick={openAppSettings}
                fullWidth
              >
                Open Settings
              </Button>
            )}
            <Button variant="outlined" onClick={onClose} fullWidth>
              Cancel
            </Button>
          </Box>
        );

      case 'success':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Alert
              icon={<CheckCircleOutlineIcon fontSize="inherit" />}
              severity="success"
            >
              Location verified successfully!
            </Alert>
            {distance && (
              <Typography variant="body2" color="text.secondary" align="center">
                You are approximately {distance} meters from the specified
                location.
              </Typography>
            )}
            <Button variant="contained" onClick={onClose} fullWidth>
              Close
            </Button>
          </Box>
        );

      case 'out-of-range':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Alert severity="error">
              You are not at the specified location.
            </Alert>
            {distance && (
              <Typography variant="body2" color="text.secondary" align="center">
                You are approximately {distance} meters away from the specified
                location (maximum allowed distance is {maxDistance} meters).
              </Typography>
            )}
            <Button
              variant="contained"
              startIcon={<RefreshIcon />}
              onClick={verifyLocation}
              fullWidth
            >
              Retry Verification
            </Button>
          </Box>
        );

      case 'error':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Alert severity="error">{error}</Alert>
            <Button
              variant="contained"
              startIcon={<RefreshIcon />}
              onClick={verifyLocation}
              fullWidth
            >
              Retry Verification
            </Button>
          </Box>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (open && postcode) {
      verifyLocation();
    }
  }, [open, postcode]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { p: 2 }
      }}
    >
      <DialogTitle align="center">
        {status === 'permission-required' || status === 'permission-denied'
          ? 'Location Permission Required'
          : 'Verifying Your Location'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>{renderContent()}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default LocationVerificationDialog;
