import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Chip,
  IconButton,
  Stack,
  Alert,
  Grid,
  Tooltip,
  styled
} from '@mui/material';
import {
  Close as CloseIcon,
  NightsStay,
  Warning,
  Bed,
  MonitorHeart
} from '@mui/icons-material';

interface SleepCheckData {
  // Sleep Status
  sleepState: 'awake' | 'asleep' | 'restless' | 'disturbed';
  position: string;
  breathingPattern: 'normal' | 'irregular' | 'labored' | 'snoring';

  // Room Conditions
  roomTemperature: 'comfortable' | 'too_warm' | 'too_cold';
  lightLevel: 'dark' | 'dim' | 'bright';
  noiseLevel: 'quiet' | 'moderate' | 'noisy';

  // Physical Checks
  inContinent: boolean;
  padChecked: boolean;
  padChanged: boolean;
  skinCondition: string[];

  // Safety & Comfort
  callBellInReach: boolean;
  bedrailsPosition: 'up' | 'down' | 'partial';
  bedHeight: 'low' | 'high';
  pressureRelieved: boolean;

  // Issues & Concerns
  painObserved: boolean;
  anxietyObserved: boolean;
  assistanceProvided: string[];
  requestsMade: string;
  concerns: boolean;
  notes: string;
}

const positions = [
  'Left Side',
  'Right Side',
  'Back',
  'Front',
  'Semi-Reclined',
  'Sitting',
  'Out of Bed'
];

const skinIssues = ['Dry', 'Red', 'Warm', 'Broken', 'Swollen', 'Bruised'];

const assistanceTypes = [
  'Repositioning',
  'Pain Relief',
  'Toileting',
  'Drinks',
  'Reassurance',
  'Medication'
];

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2
  }
}));

interface SleepCheckDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: SleepCheckData) => void;
  task: any;
  initialData?: Partial<SleepCheckData>;
}

const SleepCheckDialog: React.FC<SleepCheckDialogProps> = ({
  open,
  onClose,
  onResolve,
  task,
  initialData
}) => {
  const [formData, setFormData] = useState<SleepCheckData>({
    sleepState: 'asleep',
    position: 'Left Side',
    breathingPattern: 'normal',
    roomTemperature: 'comfortable',
    lightLevel: 'dark',
    noiseLevel: 'quiet',
    inContinent: false,
    padChecked: false,
    padChanged: false,
    skinCondition: [],
    callBellInReach: true,
    bedrailsPosition: 'up',
    bedHeight: 'low',
    pressureRelieved: false,
    painObserved: false,
    anxietyObserved: false,
    assistanceProvided: [],
    requestsMade: '',
    concerns: false,
    notes: ''
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setFormData((prev) => ({ ...prev, ...initialData }));
    }
  }, [initialData]);

  const handleChange = (field: keyof SleepCheckData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.position) {
      setError('Please select resident position');
      return false;
    }

    if (formData.inContinent && !formData.padChecked) {
      setError('Please confirm pad check for incontinent resident');
      return false;
    }

    if (
      formData.painObserved &&
      !formData.assistanceProvided.includes('Pain Relief')
    ) {
      setError('Please record pain relief assistance provided');
      return false;
    }

    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onResolve(formData);
      onClose();
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <NightsStay color="primary" />
            <Typography variant="h6">Sleep Check Record</Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          {/* Sleep Status */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Sleep Status
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Sleep State</InputLabel>
                  <Select
                    value={formData.sleepState}
                    onChange={(e) => handleChange('sleepState', e.target.value)}
                  >
                    <MenuItem value="asleep">Asleep</MenuItem>
                    <MenuItem value="awake">Awake</MenuItem>
                    <MenuItem value="restless">Restless</MenuItem>
                    <MenuItem value="disturbed">Disturbed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Position</InputLabel>
                  <Select
                    value={formData.position}
                    onChange={(e) => handleChange('position', e.target.value)}
                  >
                    {positions.map((pos) => (
                      <MenuItem key={pos} value={pos}>
                        {pos}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Breathing Pattern</InputLabel>
                  <Select
                    value={formData.breathingPattern}
                    onChange={(e) =>
                      handleChange('breathingPattern', e.target.value)
                    }
                  >
                    <MenuItem value="normal">Normal</MenuItem>
                    <MenuItem value="irregular">Irregular</MenuItem>
                    <MenuItem value="labored">Labored</MenuItem>
                    <MenuItem value="snoring">Snoring</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* Room Conditions */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Room Conditions
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Temperature</InputLabel>
                  <Select
                    value={formData.roomTemperature}
                    onChange={(e) =>
                      handleChange('roomTemperature', e.target.value)
                    }
                  >
                    <MenuItem value="comfortable">Comfortable</MenuItem>
                    <MenuItem value="too_warm">Too Warm</MenuItem>
                    <MenuItem value="too_cold">Too Cold</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Light Level</InputLabel>
                  <Select
                    value={formData.lightLevel}
                    onChange={(e) => handleChange('lightLevel', e.target.value)}
                  >
                    <MenuItem value="dark">Dark</MenuItem>
                    <MenuItem value="dim">Dim</MenuItem>
                    <MenuItem value="bright">Bright</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Noise Level</InputLabel>
                  <Select
                    value={formData.noiseLevel}
                    onChange={(e) => handleChange('noiseLevel', e.target.value)}
                  >
                    <MenuItem value="quiet">Quiet</MenuItem>
                    <MenuItem value="moderate">Moderate</MenuItem>
                    <MenuItem value="noisy">Noisy</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* Safety & Comfort */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Safety & Comfort
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.callBellInReach}
                      onChange={(e) =>
                        handleChange('callBellInReach', e.target.checked)
                      }
                      color="success"
                    />
                  }
                  label="Call Bell in Reach"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Bed Rails</InputLabel>
                  <Select
                    value={formData.bedrailsPosition}
                    onChange={(e) =>
                      handleChange('bedrailsPosition', e.target.value)
                    }
                  >
                    <MenuItem value="up">Up</MenuItem>
                    <MenuItem value="down">Down</MenuItem>
                    <MenuItem value="partial">Partial</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Bed Height</InputLabel>
                  <Select
                    value={formData.bedHeight}
                    onChange={(e) => handleChange('bedHeight', e.target.value)}
                  >
                    <MenuItem value="low">Low</MenuItem>
                    <MenuItem value="high">High</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.pressureRelieved}
                      onChange={(e) =>
                        handleChange('pressureRelieved', e.target.checked)
                      }
                    />
                  }
                  label="Pressure Area Relieved"
                />
              </Grid>
            </Grid>
          </Box>

          {/* Issues & Assistance */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Issues & Assistance
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.painObserved}
                      onChange={(e) =>
                        handleChange('painObserved', e.target.checked)
                      }
                      color="error"
                    />
                  }
                  label="Pain Observed"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.anxietyObserved}
                      onChange={(e) =>
                        handleChange('anxietyObserved', e.target.checked)
                      }
                      color="warning"
                    />
                  }
                  label="Anxiety Observed"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Requests Made"
                  value={formData.requestsMade}
                  onChange={(e) => handleChange('requestsMade', e.target.value)}
                  placeholder="Enter any requests made by resident..."
                />
              </Grid>
            </Grid>
          </Box>

          {/* Notes & Concerns */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Additional Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Notes"
                  value={formData.notes}
                  onChange={(e) => handleChange('notes', e.target.value)}
                  placeholder="Enter any additional observations or concerns..."
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.concerns}
                      onChange={(e) =>
                        handleChange('concerns', e.target.checked)
                      }
                      color="error"
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography>Requires Follow-up</Typography>
                      {formData.concerns && (
                        <Tooltip title="Will flag for urgent review">
                          <Warning color="error" fontSize="small" />
                        </Tooltip>
                      )}
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color={
            formData.concerns || formData.painObserved ? 'error' : 'primary'
          }
          startIcon={
            formData.concerns || formData.painObserved ? (
              <Warning />
            ) : (
              <NightsStay />
            )
          }
        >
          {formData.concerns || formData.painObserved
            ? 'Save & Flag'
            : 'Save Record'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default SleepCheckDialog;
