// organizations.ts
import { updateOrganization } from '../reducers/organization-slice';
import { baseApi } from './base';

export const organizationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationRole: builder.query({
      query: (orgId: string) => `organizations/role/${orgId}`
    }),
    getOrganizationListing: builder.query({
      query: () => 'organizations/listing'
    }),
    updateOrganization: builder.mutation({
      query: (orgData: any) => ({
        url: 'organizations',
        method: 'PATCH',
        body: orgData
      })
      // invalidatesTags: ['Organization']
    }),
    addUserToOrganization: builder.mutation({
      query: (data: {
        userId: string;
        organizationId: string;
        role: string;
      }) => ({
        url: 'organizations/addUser',
        method: 'POST',
        body: data
      })
    }),
    sendLinkOrganization: builder.mutation({
      query: (data: {
        fromOrganizationId: string;
        toOrganizationId: string;
        message?: string;
      }) => ({
        url: 'organizations/linkInvitation',
        method: 'POST',
        body: data
      })
    }),
    getMyOrgInvitations: builder.query({
      query: () => `organizations/linkInvitations`
    }),
    responseToOrgInvitation: builder.mutation({
      query: (data: { invitationId: string; accept: boolean }) => ({
        url: 'organizations/respondToLinkInvitation',
        method: 'POST',
        body: data
      })
    }),
    unlinkOrganization: builder.mutation({
      query: (orgId: string) => ({
        url: `organizations/unlink/${orgId}`,
        method: 'POST'
      })
    }),
    unlinkOrganizations: builder.mutation({
      query: ({ organizationId1, organizationId2 }) => ({
        url: `organizations/unlink`,
        method: 'POST',
        body: {
          organizationId1,
          organizationId2
        }
      }),
      invalidatesTags: ['LinkedOrganizations']
    }),
    getLinkedOrganizations: builder.query({
      query: (type: string | undefined) => `organizations/linked?type=${type}`,
      providesTags: ['LinkedOrganizations']
    }),

    getLinkedOranizationById: builder.query({
      query: (orgId: string) => `organizations/linked/${orgId}`
    }),
    getLinkedOrgranizationAdmins: builder.query({
      query: () => `organizations/linked/admins`,
      providesTags: ['LinkedOrgranizationAdmins']
    }),
    getLinkedOrganizationAdminForCarer: builder.query({
      query: () => `organizations/linked/admins/carer`,
      providesTags: ['LinkedOrganizationAdminForCarer']
    }),
    searchOrganizations: builder.query({
      query: ({ term, exludedType }: { term: string; exludedType: string }) =>
        `organizations/search?searchTerm=${term}&excludeType=${exludedType}`
    }),

    requestOrgDelete: builder.mutation({
      query: (orgId: string) => ({
        url: `organizations/request-org-deletion`,
        method: 'POST'
      })
    }),

    cancelOrgDeleteRequest: builder.mutation({
      query: (orgId: string) => ({
        url: `organizations/cancel-org-deletion`,
        method: 'POST'
      })
    }),
    //invitations
    createOrganizationInvitation: builder.mutation({
      query: (data) => ({
        url: '/organizations/invitations/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Invitations']
    }),

    // Validate an invitation token
    validateInvitationToken: builder.query({
      query: (token) => ({
        url: `/organizations/invitations/validate/${token}`,
        method: 'GET'
      })
    }),

    // Accept an invitation
    acceptInvitation: builder.mutation({
      query: (token) => ({
        url: `/organizations/invitations/accept/${token}`,
        method: 'POST'
      }),
      invalidatesTags: ['Invitations']
    }),

    // Get pending invitations by email
    getPendingInvitationsByEmail: builder.query({
      query: (email) => ({
        url: `/organizations/invitations/email/${email}`,
        method: 'GET'
      }),
      providesTags: ['Invitations']
    }),

    // Get organization invitations
    getOrganizationInvitations: builder.query({
      query: (organizationId) => ({
        url: `/organizations/invitations/${organizationId}`,
        method: 'GET'
      }),
      providesTags: ['Invitations']
    }),

    // Revoke an invitation
    revokeInvitation: builder.mutation({
      query: (invitationId) => ({
        url: `/organizations/invitations/${invitationId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Invitations']
    })
  })
});

export const {
  useUpdateOrganizationMutation,
  useRequestOrgDeleteMutation,
  useCancelOrgDeleteRequestMutation,
  useGetOrganizationRoleQuery,
  useUnlinkOrganizationsMutation,
  useLazyGetOrganizationRoleQuery,
  useGetOrganizationListingQuery,
  useAddUserToOrganizationMutation,
  useSendLinkOrganizationMutation,
  useGetMyOrgInvitationsQuery,
  useResponseToOrgInvitationMutation,
  useGetLinkedOrganizationsQuery,
  useGetLinkedOrgranizationAdminsQuery,
  useGetLinkedOrganizationAdminForCarerQuery,
  useSearchOrganizationsQuery,
  useGetLinkedOranizationByIdQuery,

  useCreateOrganizationInvitationMutation,
  useValidateInvitationTokenQuery,
  useLazyValidateInvitationTokenQuery,
  useAcceptInvitationMutation,
  useGetPendingInvitationsByEmailQuery
} = organizationsApi;
