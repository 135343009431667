// First, let's create the shared StaffCard component that will be used across all staff lists
// StaffCard.tsx
import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Avatar,
  Box,
  Button,
  Tooltip,
  IconButton,
  Chip,
  Typography,
  useMediaQuery,
  useTheme,
  styled,
  Tabs,
  Tab
} from '@mui/material';
import {
  Person,
  Favorite,
  FavoriteBorder,
  Email,
  Phone,
  Badge,
  Business,
  Lock,
  People
} from '@mui/icons-material';
import CareStaffList from './care-staffs';
import AdminStaffList from './admin-staffs';
import OtherStaffList from './other-staffs';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4]
  }
}));

const CompactCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:last-child': {
    paddingBottom: theme.spacing(2)
  }
}));

const InfoTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8125rem'
  }
}));

const CompactAvatar = styled(Avatar)(({ theme }) => ({
  width: 56,
  height: 56,
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: 48,
    height: 48
  }
}));

export const StaffCard = ({ staff }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledCard variant="outlined">
      <CompactCardContent>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: isMobile ? 'row' : 'column',
            alignItems: isMobile ? 'center' : 'flex-start'
          }}
        >
          <CompactAvatar>
            {staff.name
              .split(' ')
              .map((n) => n[0])
              .join('')}
          </CompactAvatar>
          <Box sx={{ flex: 1, minWidth: 0, width: '100%' }}>
            <Typography
              variant="subtitle1"
              noWrap
              sx={{
                fontSize: isMobile ? '0.9rem' : '1rem',
                fontWeight: 'medium'
              }}
            >
              {staff.name}
            </Typography>
            <Chip
              label={staff.role}
              size="small"
              variant="outlined"
              color="primary"
              sx={{ mb: 1, fontSize: isMobile ? '0.75rem' : '0.8125rem' }}
            />
            <InfoTypography
              color="text.secondary"
              display="flex"
              alignItems="center"
              gap={0.5}
            >
              <Email sx={{ fontSize: isMobile ? 14 : 16 }} />
              {staff.email}
            </InfoTypography>
            <InfoTypography
              color="text.secondary"
              display="flex"
              alignItems="center"
              gap={0.5}
            >
              <Phone sx={{ fontSize: isMobile ? 14 : 16 }} />
              {staff.phone}
            </InfoTypography>
            {staff.specialization && (
              <InfoTypography
                color="text.secondary"
                display="flex"
                alignItems="center"
                gap={0.5}
              >
                <Badge sx={{ fontSize: isMobile ? 14 : 16 }} />
                {staff.specialization}
              </InfoTypography>
            )}
            {staff.department && (
              <InfoTypography
                color="text.secondary"
                display="flex"
                alignItems="center"
                gap={0.5}
              >
                <Business sx={{ fontSize: isMobile ? 14 : 16 }} />
                {staff.department}
              </InfoTypography>
            )}
          </Box>
        </Box>
      </CompactCardContent>
      <CardActions
        sx={{
          pt: 0,
          px: 2,
          pb: 1,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            size="small"
            startIcon={<Person sx={{ fontSize: isMobile ? 16 : 20 }} />}
            sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
          >
            Profile
          </Button>
          <Button
            size="small"
            startIcon={<Lock sx={{ fontSize: isMobile ? 16 : 20 }} />}
            sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
          >
            Permissions
          </Button>
        </Box>
        <Tooltip
          title={
            staff.isFavorite ? 'Remove from favorites' : 'Add to favorites'
          }
        >
          <IconButton
            size={isMobile ? 'small' : 'medium'}
            color={staff.isFavorite ? 'primary' : 'default'}
          >
            {staff.isFavorite ? <Favorite /> : <FavoriteBorder />}
          </IconButton>
        </Tooltip>
      </CardActions>
    </StyledCard>
  );
};

const StaffTabs: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', height: '100%', p: 1 }}>
      <Box sx={{ mb: 3 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="staff tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab icon={<People />} iconPosition="start" label="Care Staff" />
          <Tab icon={<Badge />} iconPosition="start" label="Admin Staff" />
          <Tab icon={<Business />} iconPosition="start" label="Other Staff" />
        </Tabs>
      </Box>

      <Box
        sx={{
          height: `calc(100vh - ${theme.header.height})`,
          overflow: 'auto',
          display: 'flex',
          pb: 2,
          width: '100%'
        }}
      >
        {tabValue === 0 && <CareStaffList />}
        {tabValue === 1 && <AdminStaffList />}
        {tabValue === 2 && <OtherStaffList staffList={[]} />}
      </Box>
    </Box>
  );
};

export default StaffTabs;
