import React from 'react';
import TaskGrid from './task-grid';
import { IResident } from 'src/interfaces/resident';

interface AllTasksTabProps {
  onResolveTask: (task: any) => void;
  tab: string;
  resident: IResident;
}

const AllTasksTab: React.FC<AllTasksTabProps> = ({
  onResolveTask,
  tab,
  resident
}) => {
  return <TaskGrid tab={tab} onTaskClick={onResolveTask} resident={resident} />;
};

export default AllTasksTab;
