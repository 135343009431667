import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
  styled
} from '@mui/material';
import {
  Close as CloseIcon,
  MedicationOutlined,
  MonitorHeart,
  Restaurant,
  Bathtub,
  AccessibilityNew,
  FitnessCenter,
  Groups,
  NightsStay,
  LocalHospital,
  EventNote,
  WbSunny
} from '@mui/icons-material';
import { ITask } from 'src/interfaces/new/tasks';
import MedicationReport from './medication';
import VitalSignsReport from './vital';
import MealFluidReport from './mealfluid';
import BathingReport from './bathing';
import PositioningReport from './positioning';
import ExerciseReport from './exercise';
import SocialActivityReport from './social';
import DayNightCheckReport from './daynightcheck';
import WoundCareReport from './wound';
import PadCheckReport from './padcheck';
import AssessmentReport from './assessment';

// Import all report components
// import MedicationReport from './medication';
// import VitalSignsReport from './vitals';
// import MealFluidReport from './meal-fluid';
// import BathingReport from './bathing';
// import PositioningReport from './positioning';
// import ExerciseReport from './exercise';
// import SocialActivityReport from './social-activity';
// import DayNightCheckReport from './day-night-check';
// import WoundCareReport from './wound-care';
// import AssessmentReport from './assessment';

interface HistoricTaskMapDialogProps {
  open: boolean;
  onClose: () => void;
  task: ITask;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2
  }
}));

const getTaskIcon = (taskType: string) => {
  switch (taskType) {
    case 'medication':
      return <MedicationOutlined />;
    case 'vital_signs':
      return <MonitorHeart />;
    case 'meal':
    case 'fluid_intake':
      return <Restaurant />;
    case 'bathing':
      return <Bathtub />;
    case 'positioning':
      return <AccessibilityNew />;
    case 'exercise':
      return <FitnessCenter />;
    case 'social':
      return <Groups />;
    case 'night_check':
      return <NightsStay />;
    case 'day_check':
      return <WbSunny />;
    case 'wound_care':
      return <LocalHospital />;
    case 'assessment':
      return <EventNote />;
    default:
      return <EventNote />;
  }
};

const getTaskTitle = (taskType: string) => {
  return taskType
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const HistoricTaskMapDialog: React.FC<HistoricTaskMapDialogProps> = ({
  open,
  onClose,
  task
}) => {
  const renderTaskReport = () => {
    const { taskData, ...rest } = task;
    switch (task.taskType) {
      case 'medication':
        return <MedicationReport data={taskData} task={rest} />;

      case 'vital signs':
        return <VitalSignsReport data={taskData} task={rest} />;

      case 'meal':
      case 'fluid intake':
        return <MealFluidReport data={taskData} task={rest} />;

      case 'bathing':
        return <BathingReport data={taskData} task={rest} />;

      case 'positioning':
        return <PositioningReport data={taskData} task={rest} />;

      case 'exercise':
        return <ExerciseReport data={taskData} task={rest} />;

      case 'social':
        return <SocialActivityReport data={taskData} task={rest} />;

      case 'night check':
      case 'day check':
        return <DayNightCheckReport data={taskData} task={rest} />;

      case 'wound care':
        return <WoundCareReport data={taskData} task={rest} />;
      case 'pad check':
        return <PadCheckReport data={taskData} task={rest} />;

      //   case 'assessment':
      //     return <AssessmentReport data={taskData} />;

      default:
        return (
          <Box p={3} textAlign="center">
            <Typography color="textSecondary">
              Report not available for this task type
            </Typography>
          </Box>
        );
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            {getTaskIcon(task.taskType)}
            <Box>
              <Typography variant="h6">
                {getTaskTitle(task.taskType)}
              </Typography>
              {/* <Typography variant="caption" color="textSecondary">
                {new Date(task.completedAt || task.createdAt).toLocaleString()}
              </Typography> */}
            </Box>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>{renderTaskReport()}</DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {/* Add any additional actions like print or export */}
      </DialogActions>
    </StyledDialog>
  );
};

export default HistoricTaskMapDialog;
