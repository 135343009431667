import React, { useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/system';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { useAppSelector } from 'src/redux/hook';
import SubscriptionDetails from './views/subscription-details';
import PlanDetails from './views/plan-details';
import PersonalInfoForm from './views/personal-info';
import BillingAddressForm from './views/billing';
import PaymentForm from './views/payment-form';
import {
  useCreateSubscriptionMutation,
  useGetPlanQuery,
  useGetSubscriptionDetailsQuery
} from 'src/redux/@api/stripe';
import SubscriptionComponent from 'src/components/core/stripe/Subscription';

const stripePromise = loadStripe(
  'pk_test_51Pid1H2M2oCccQjmdDbnK7EEm1aSwZ6Zy76aCgaDKvwUoLw5fjnd20Cq7PUqSEwbOmtoKGFB2ZxZHBaQgIlT0aDd00J4qRyAmm'
);

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3]
}));

const steps = [
  'Plan Details',
  'Personal Information',
  'Billing Address',
  'Payment Details'
];

const PaymentPage = () => {
  const { user, currentOrganization } = useAppSelector(
    (state) => state.userState
  );
  const organizationId = currentOrganization?._id;
  const [activeStep, setActiveStep] = useState(0);
  const [personalInfo, setPersonalInfo] = useState({
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
    phone: user.phone || ''
  });
  const [billingAddress, setBillingAddress] = useState({
    street: user.address?.street || '',
    city: user.address?.city || '',
    state: user.address?.state || '',
    zipCode: user.address?.zipCode || '',
    country: user.address?.country || ''
  });
  const [useDifferentAddress, setUseDifferentAddress] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

  const { data: plan, isLoading: isPlanLoading } = useGetPlanQuery(
    currentOrganization?.type
  );
  const { data: subscriptionDetails, isLoading: isSubscriptionLoading } =
    useGetSubscriptionDetailsQuery(organizationId);
  const [createSubscription, { isLoading: isCreatingSubscription }] =
    useCreateSubscriptionMutation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const isStepComplete = (step) => {
    switch (step) {
      case 0:
        return true; // Plan details step is always complete
      case 1:
        return Object.values(personalInfo).every((value) => value !== '');
      case 2:
        return Object.values(billingAddress).every((value) => value !== '');
      case 3:
        return true; // Will be handled by Stripe Elements
      default:
        return false;
    }
  };

  return <SubscriptionComponent />;
};

export default PaymentPage;

// import React from 'react';
// import { useAppSelector } from 'src/redux/hook';
// import SubscriptionManagement from './subsciption-management';

// type Props = {};

// export default function PaymentPage({}: Props) {
//   const { user, currentOrganization } = useAppSelector(
//     (state) => state.userState
//   );
//   const organizationId = currentOrganization?._id;
//   return <SubscriptionManagement organizationId={organizationId} />;
// }
