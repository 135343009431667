import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  Paper,
  CircularProgress,
  Alert,
  styled,
  alpha
} from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { IFieldVisibility } from 'src/interfaces/visiblity';
import {
  useGetFieldVisibilityQuery,
  useInitializeFieldVisibilityMutation,
  useUpdateFieldVisibilityMutation
} from 'src/redux/@api/visibility';
import FieldVisibilityCheckboxes from './visibility-checkboxes';

const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: alpha(theme.palette.primary.main, 0.03)
}));

const StyledInitializeContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

const StaffSettings: React.FC = () => {
  const [visibilityState, setVisibilityState] =
    useState<IFieldVisibility | null>(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const userState = useAppSelector((state) => state.user.user);

  const {
    data: fieldVisibility,
    isLoading,
    isError,
    refetch
  } = useGetFieldVisibilityQuery(undefined);

  const [updateFieldVisibility, { isLoading: isUpdating }] =
    useUpdateFieldVisibilityMutation();
  const [initializeFieldVisibility, { isLoading: isInitializing }] =
    useInitializeFieldVisibilityMutation();

  useEffect(() => {
    if (fieldVisibility) {
      setVisibilityState(fieldVisibility.fields);
    }
  }, [fieldVisibility]);

  const handleSaveVisibility = async (data: any) => {
    try {
      await updateFieldVisibility(data).unwrap();
      dispatch(
        showSnack({
          message: 'Visibility settings updated successfully',
          color: 'success'
        })
      );
      refetch();
    } catch (error) {
      dispatch(
        showSnack({
          message: 'Failed to update settings. Please try again',
          color: 'error'
        })
      );
    }
  };

  const handleInitializeVisibility = async () => {
    try {
      await initializeFieldVisibility(undefined).unwrap();
      dispatch(
        showSnack({
          message: 'Settings initialized successfully',
          color: 'success'
        })
      );
      refetch();
    } catch (error) {
      dispatch(
        showSnack({
          message: 'Failed to initialize settings. Please try again',
          color: 'error'
        })
      );
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        Failed to load visibility settings. Please refresh the page.
      </Alert>
    );
  }

  return (
    <Paper elevation={1} sx={{ mx: 2 }}>
      <StyledHeader>
        <VisibilityIcon color="primary" />
        <Box>
          <Typography variant="h6">Staff Profile Visibility</Typography>
          <Typography variant="body2" color="text.secondary">
            Control which sections of staff profiles are visible to care homes
          </Typography>
        </Box>
      </StyledHeader>

      {visibilityState ? (
        <>
          {isUpdating && (
            <Alert severity="info" sx={{ mx: 2, mt: 2 }}>
              Saving changes...
            </Alert>
          )}
          <Box>
            <FieldVisibilityCheckboxes
              initialData={visibilityState}
              onSave={handleSaveVisibility}
            />
          </Box>
        </>
      ) : (
        <StyledInitializeContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={handleInitializeVisibility}
            disabled={isInitializing}
            sx={{
              width: 'fit-content'
            }}
            startIcon={isInitializing ? <CircularProgress size={20} /> : null}
          >
            {isInitializing ? 'Initializing...' : 'Initialize Settings'}
          </Button>
        </StyledInitializeContainer>
      )}
    </Paper>
  );
};

export default StaffSettings;
