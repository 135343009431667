import React from 'react';
import { Card, CardContent, Typography, Box, Grid, alpha } from '@mui/material';
import {
  Sofa,
  Briefcase,
  CheckCircle,
  Clock,
  CalendarDays
} from 'lucide-react';

const QuickStatCard = ({ label, value, icon: Icon, iconColor, bgColor }) => {
  return (
    <Card
      sx={{
        borderRadius: 1.5,
        boxShadow: (theme) =>
          `0 2px 8px ${alpha(theme.palette.grey[400], 0.12)}`,
        height: '100%',
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: (theme) =>
            `0 12px 32px ${alpha(theme.palette.grey[400], 0.2)}`
        },
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      {/* Background decoration */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100px',
          height: '100px',
          background: `radial-gradient(circle at top right, ${bgColor}20, transparent 70%)`,
          borderRadius: '0 0 0 100%'
        }}
      />

      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
          p: 3,
          height: '100%',
          position: 'relative',
          backgroundColor: 'transparent',
          '&:last-child': { pb: { xs: 1.5, sm: 2 } } // Override Material-UI default
        }}
      >
        {/* Background gradient */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,

            borderRadius: 1
          }}
        />

        {/* Icon */}
        <Box
          sx={{
            backgroundColor: (theme) =>
              alpha(bgColor || theme.palette.primary.main, 0.8),
            borderRadius: 1,
            p: 1.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: (theme) =>
              `0 4px 12px ${alpha(
                bgColor || theme.palette.primary.main,
                0.16
              )}`,
            flexShrink: 0
          }}
        >
          <Icon color={iconColor || bgColor} size={24} strokeWidth={1.5} />
        </Box>

        {/* Content */}
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            pl: { xs: 0, sm: '44px' },
            pr: { xs: '32px', sm: 0 }
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              mb: 0.5,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: (theme) => theme.palette.text.secondary,
              letterSpacing: '0.5px'
            }}
          >
            {label}
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontWeight: 700,
              fontSize: { xs: '1.25rem', sm: '1.5rem' },
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: (theme) => theme.palette.text.primary,
              letterSpacing: '-0.5px'
            }}
          >
            {typeof value === 'number' ? value.toLocaleString() : value}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const QuickStatsCarerGrid = ({ quickStats }) => {
  const iconMap = {
    'Total Shifts': Briefcase,
    'Completed Shifts': CheckCircle,
    'Signed Shifts': Clock,
    'Previous Month': CalendarDays
  };

  const defaultColors = {
    'Total Shifts': {
      icon: '#2065D1',
      bg: '#2065D1'
    },
    'Completed Shifts': {
      icon: '#229A16',
      bg: '#229A16'
    },
    'Signed Shifts': {
      icon: '#7635DC',
      bg: '#7635DC'
    },
    'Previous Month': {
      icon: '#006C9C',
      bg: '#006C9C'
    }
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        '& > .MuiGrid-item': {
          height: { xs: '130px', sm: '150px' }
        }
      }}
    >
      {quickStats.map((stat, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <QuickStatCard
            label={stat.label}
            value={stat.value}
            icon={iconMap[stat.label] || Sofa}
            iconColor={stat.iconColor || defaultColors[stat.label]?.icon}
            bgColor={stat.bgColor || defaultColors[stat.label]?.bg}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const QuickStatsGrid = ({ quickStats }) => {
  const iconMap = {
    'Total Shifts': Briefcase,
    'Completed Shifts': CheckCircle,
    'Pending Shifts': Clock,
    'Previous Month': CalendarDays
  };

  const defaultColors = {
    'Total Shifts': {
      icon: '#2065D1',
      bg: '#2065D1'
    },
    'Completed Shifts': {
      icon: '#229A16',
      bg: '#229A16'
    },
    'Pending Shifts': {
      icon: '#B76E00',
      bg: '#B76E00'
    },
    'Previous Month': {
      icon: '#006C9C',
      bg: '#006C9C'
    }
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        '& > .MuiGrid-item': {
          height: { xs: '130px', sm: '150px' }
        }
      }}
    >
      {quickStats.map((stat, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <QuickStatCard
            label={stat.label}
            value={stat.value}
            icon={iconMap[stat.label] || Sofa}
            iconColor={stat.iconColor || defaultColors[stat.label]?.icon}
            bgColor={stat.bgColor || defaultColors[stat.label]?.bg}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export { QuickStatCard, QuickStatsGrid, QuickStatsCarerGrid };
