import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Chip,
  Divider,
  Alert,
  styled,
  LinearProgress,
  Tooltip
} from '@mui/material';
import {
  Restaurant,
  LocalCafe,
  Warning,
  CheckCircle,
  Cancel,
  AccessTime,
  EmojiPeople,
  Info,
  Fastfood,
  RecordVoiceOver,
  LocalDrink
} from '@mui/icons-material';
import { ITask } from 'src/interfaces/new/tasks';

interface MealFluidData {
  // Basic Info
  mealType?: 'breakfast' | 'lunch' | 'dinner' | 'snack';
  foodType: string;
  platterSize: 'small' | 'medium' | 'large';
  amountEaten: number;
  drinkType: string;
  fluidType: {
    hotDrinks: string[];
    coldDrinks: string[];
    thickenedFluids: string[];
  };

  // Support & Requirements
  assistanceLevel: 'independent' | 'minimal' | 'moderate' | 'full';
  dietaryRequirements: string[];
  eatingPosition: 'sitting' | 'semi-reclined' | 'bed';
  swallowingDifficulties: boolean;
  choking: boolean;

  // Monitoring
  refusal: boolean;
  refusalReason?: string;
  nutritionalSupplements?: string;
  comments: string;
  concerns: boolean;
  timestamp: string;
}

interface MealFluidReportProps {
  data: MealFluidData | any;
  task: Partial<ITask>;
}

const ReportSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& + &': {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[200],
  '& .MuiLinearProgress-bar': {
    borderRadius: 5
  }
}));

const MealFluidReport: React.FC<MealFluidReportProps> = ({ data, task }) => {
  const getProgressColor = (amount: number) => {
    if (amount < 25) return 'error';
    if (amount < 50) return 'warning';
    return 'success';
  };

  const getMealTypeIcon = () => {
    switch (data.mealType) {
      case 'breakfast':
        return '🍳';
      case 'lunch':
        return '🍽️';
      case 'dinner':
        return '🍖';
      case 'snack':
        return '🍎';
      default:
        return '🍽️';
    }
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      {/* Header */}
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <Box display="flex" alignItems="center" gap={1}>
          {data.mealType ? (
            <Typography fontSize="2rem">{getMealTypeIcon()}</Typography>
          ) : (
            <LocalCafe color="primary" fontSize="large" />
          )}
          <Box>
            <Typography variant="h6">
              {data.mealType
                ? `${
                    data.mealType.charAt(0).toUpperCase() +
                    data.mealType.slice(1)
                  } Record`
                : 'Fluid Intake Record'}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {new Date(task.completedDate).toLocaleString()}
            </Typography>
            {/* completed by */}
            <Box display="flex" alignItems="center" gap={1} mt={1}>
              <RecordVoiceOver />
              <Typography variant="body2">
                Completed by: {task.completedBy?.firstName}{' '}
                {task.completedBy?.lastName}
              </Typography>

              {/* role */}
              <Tooltip title={task.completedBy?.role}>
                <Chip
                  label={task.completedBy?.role}
                  size="small"
                  color="primary"
                />
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box flexGrow={1} />
        {data.concerns && (
          <Chip
            icon={<Warning />}
            label="Requires Review"
            color="error"
            size="small"
          />
        )}
      </Box>

      <Divider />

      {/* Food Intake Section */}
      {data.mealType && (
        <ReportSection>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Food Intake
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>
                Meal Details
              </Typography>
              <Typography variant="body2">Type: {data.foodType}</Typography>
              <Typography variant="body2">
                Portion Size: {data.platterSize}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>
                Amount Consumed
              </Typography>
              <Box display="flex" alignItems="center" gap={1} mb={1}>
                <Fastfood />
                <Box flexGrow={1}>
                  <ProgressBar
                    variant="determinate"
                    value={data.amountEaten}
                    color={getProgressColor(data.amountConsumed)}
                  />
                </Box>
                <Typography variant="body2">{data.amountConsumed}%</Typography>
              </Box>
            </Grid>
          </Grid>
        </ReportSection>
      )}
      {/* Fluid Intake Section */}
      <ReportSection>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Drinks Consumed
          </Typography>
          {/* total fluid consumed in ml */}
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" gap={1} mb={1}>
              <LocalDrink />
              <Typography variant="body2">{data.fluidIntake} ml</Typography>
            </Box>
          </Grid>

          {data.fluidType?.hotDrinks?.length > 0 && (
            <Box mb={1}>
              <Typography variant="body2" color="textSecondary">
                Hot Drinks:
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {data.fluidType.hotDrinks.map((drink, index) => (
                  <Chip
                    key={index}
                    label={drink}
                    size="small"
                    icon={<LocalCafe fontSize="small" />}
                  />
                ))}
              </Box>
            </Box>
          )}

          {data.fluidType?.coldDrinks?.length > 0 && (
            <Box mb={1}>
              <Typography variant="body2" color="textSecondary">
                Cold Drinks:
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {data.fluidType.coldDrinks.map((drink, index) => (
                  <Chip
                    key={index}
                    label={drink}
                    size="small"
                    icon={<LocalDrink fontSize="small" />}
                  />
                ))}
              </Box>
            </Box>
          )}

          {data.fluidType?.thickenedFluids?.length > 0 && (
            <Box>
              <Typography variant="body2" color="textSecondary">
                Thickened Fluids:
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {data.fluidType.thickenedFluids.map((drink, index) => (
                  <Chip
                    key={index}
                    label={drink}
                    size="small"
                    color="warning"
                  />
                ))}
              </Box>
            </Box>
          )}
        </Grid>
      </ReportSection>

      {/* Dietary Requirements & Support */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Support & Requirements
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Dietary Requirements
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {data.dietaryRequirements.map((req, index) => (
                <Chip key={index} label={req} size="small" color="info" />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" gutterBottom>
              Assistance Level
            </Typography>
            <Chip
              icon={<EmojiPeople />}
              label={data.assistanceLevel}
              color={
                data.assistanceLevel === 'independent' ? 'success' : 'primary'
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" gutterBottom>
              Position
            </Typography>
            <Typography variant="body2">{data.eatingPosition}</Typography>
          </Grid>
        </Grid>
      </ReportSection>

      {/* Monitoring & Safety */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Monitoring & Safety
        </Typography>
        <Grid container spacing={2}>
          {data.swallowingDifficulties && (
            <Grid item xs={12}>
              <Alert severity="warning">Swallowing difficulties observed</Alert>
            </Grid>
          )}

          {data.choking && (
            <Grid item xs={12}>
              <Alert severity="error">Choking incident occurred</Alert>
            </Grid>
          )}

          {data.refusal && (
            <Grid item xs={12}>
              <Alert severity="warning" icon={<Cancel />}>
                {data.refusalReason
                  ? `Meal/Drink refused: ${data.refusalReason}`
                  : 'Meal/Drink refused'}
              </Alert>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Supplements & Additional Info */}
      {(data.nutritionalSupplements || data.comments) && (
        <ReportSection>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Additional Information
          </Typography>
          {data.nutritionalSupplements && (
            <Box mb={2}>
              <Typography variant="subtitle2" gutterBottom>
                Nutritional Supplements
              </Typography>
              <Chip
                label={data.nutritionalSupplements}
                color="success"
                size="small"
              />
            </Box>
          )}
          {data.comments && (
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Notes
              </Typography>
              <Typography variant="body2">{data.comments}</Typography>
            </Box>
          )}
        </ReportSection>
      )}

      {/* Alerts Section */}
      {(data.concerns ||
        data.choking ||
        data.swallowingDifficulties ||
        data.amountEaten < 25) && (
        <ReportSection>
          <Alert
            severity={data.choking ? 'error' : 'warning'}
            action={
              <Tooltip title="May require dietary review">
                <Info color="inherit" />
              </Tooltip>
            }
          >
            {data.choking
              ? 'Choking incident requires follow-up'
              : 'Intake/safety concerns identified'}
          </Alert>
        </ReportSection>
      )}

      {/* Timestamp */}
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Typography variant="caption" color="textSecondary">
          Recorded: {new Date(data.timestamp).toLocaleString()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default MealFluidReport;
