import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  IconButton,
  CircularProgress,
  Tooltip,
  useTheme,
  useMediaQuery,
  Paper,
  styled,
  alpha,
  Card,
  Fade,
  Divider
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  CloudUpload,
  Delete,
  Visibility,
  Save,
  AttachFile,
  DocumentScanner,
  CalendarMonth,
  Numbers
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import axios from 'axios';
import { apiHostname } from 'src/api/api';
import {
  useGetCarerApplicationQuery,
  useUpdateApplicationSectionMutation,
  useDeleteDocumentMutation
} from 'src/redux/@api/carer-application';
import { ICarerApplication } from 'src/interfaces/application';

const FileUploadButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1.2, 3),
  transition: 'all 0.2s ease',
  textTransform: 'none',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`
  }
}));

// Styled Components
const DocumentCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0, 0, 0, 0.08)'
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.02)
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
    }
  }
}));

const UploadButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1.2, 3),
  transition: 'all 0.2s ease',
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)'
  }
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  padding: theme.spacing(0.5),
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  borderColor: alpha(theme.palette.primary.main, 0.2),
  '& .MuiChip-icon': {
    color: theme.palette.primary.main
  }
}));

const DocumentHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  '& svg': {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    fontSize: 28
  }
}));

const DocumentTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: 0,
    width: 40,
    height: 3,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius
  }
}));

interface IdentificationDocumentsProps {
  initialData?: ICarerApplication['identificationDocuments'];
  onSubmit: (data: ICarerApplication['identificationDocuments']) => void;
  selectedSection: string;
  editable?: boolean;
}

type DocumentType = 'passport' | 'drivingLicense' | 'biometricResidencePermit';

const IdentificationDocuments: React.FC<IdentificationDocumentsProps> = ({
  initialData,
  onSubmit,
  selectedSection,
  editable = true
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { data: carerApplication, refetch } =
    useGetCarerApplicationQuery(undefined);
  const [updateApplicationSection] = useUpdateApplicationSectionMutation();
  const [deleteDocument] = useDeleteDocumentMutation();

  const [selectedFiles, setSelectedFiles] = useState<{
    [key in DocumentType]?: File;
  }>({});
  const [uploadingDocument, setUploadingDocument] = useState<{
    [key in DocumentType]?: boolean;
  }>({});
  const [savingSection, setSavingSection] = useState<{
    [key: string]: boolean;
  }>({});

  const defaultValues = editable
    ? carerApplication?.data[selectedSection] || {
        rightToWorkStatus: '',
        passport: {},
        drivingLicense: {},
        biometricResidencePermit: {}
      }
    : initialData || {
        rightToWorkStatus: '',
        passport: {},
        drivingLicense: {},
        biometricResidencePermit: {}
      };

  const { control, handleSubmit, watch, setValue, reset } = useForm<
    ICarerApplication['identificationDocuments']
  >({
    defaultValues
  });

  useEffect(() => {
    if (editable && carerApplication?.data[selectedSection]) {
      reset(carerApplication.data[selectedSection]);
    } else if (!editable && initialData) {
      reset(initialData);
    }
  }, [carerApplication, selectedSection, reset, editable, initialData]);

  const rightToWorkStatus = watch('rightToWorkStatus');

  const handleFileSelect =
    (documentType: DocumentType) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setSelectedFiles((prev) => ({ ...prev, [documentType]: file }));
      }
    };

  const handleFileUpload = async (documentType: DocumentType) => {
    const file = selectedFiles[documentType];
    if (file) {
      setUploadingDocument((prev) => ({ ...prev, [documentType]: true }));
      const formData = new FormData();
      formData.append('file', file);
      formData.append('section', `identificationDocuments.${documentType}`);
      formData.append('documentType', documentType);

      try {
        const response = await axios.post(
          `${apiHostname}/api/v1/application/upload-document`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          setValue(
            `${documentType}.uploadUrl` as any,
            response.data.data.documentUrl
          );
          dispatch(
            showSnack({
              message: 'Document uploaded successfully',
              color: 'success'
            })
          );
        } else {
          throw new Error(response.data.error || 'Failed to upload document');
        }
      } catch (error: any) {
        console.error('Upload failed:', error);
        dispatch(
          showSnack({
            message: error.message || 'Failed to upload document',
            color: 'error'
          })
        );
      } finally {
        setUploadingDocument((prev) => ({ ...prev, [documentType]: false }));
        setSelectedFiles((prev) => {
          const newFiles = { ...prev };
          delete newFiles[documentType];
          return newFiles;
        });
      }
    }
  };

  const handleDeleteDocument = async (documentType: DocumentType) => {
    try {
      await deleteDocument({
        section: `identificationDocuments.${documentType}`
      }).unwrap();
      setValue(`${documentType}.uploadUrl` as any, undefined);
      dispatch(
        showSnack({
          message: 'Document deleted successfully',
          color: 'success'
        })
      );
    } catch (error) {
      console.error('Delete failed:', error);
      dispatch(
        showSnack({ message: 'Failed to delete document', color: 'error' })
      );
    }
  };

  const handleSaveSection = async (section: string, data: any) => {
    setSavingSection((prev) => ({ ...prev, [section]: true }));
    try {
      await updateApplicationSection({
        section: `identificationDocuments.${section}`,
        data
      }).unwrap();
      dispatch(
        showSnack({
          message: `${section} information saved successfully`,
          color: 'success'
        })
      );

      if (selectedFiles[section as DocumentType]) {
        await handleFileUpload(section as DocumentType);
      }

      await refetch();
    } catch (error) {
      console.error('Save failed:', error);
      dispatch(
        showSnack({
          message: `Failed to save ${section} information`,
          color: 'error'
        })
      );
    } finally {
      setSavingSection((prev) => ({ ...prev, [section]: false }));
    }
  };

  const renderFileUpload = (docType: DocumentType) => {
    const uploadUrl = watch(`${docType}.uploadUrl` as any);
    const isUploading = uploadingDocument[docType];

    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}
      >
        <input
          accept="image/*,.pdf"
          style={{ display: 'none' }}
          id={`${docType}-upload-input`}
          type="file"
          onChange={handleFileSelect(docType)}
        />
        <label htmlFor={`${docType}-upload-input`}>
          <FileUploadButton startIcon={<CloudUpload />} disabled={isUploading}>
            {selectedFiles[docType]
              ? selectedFiles[docType]?.name
              : uploadUrl
              ? 'Replace File'
              : 'Upload File'}
          </FileUploadButton>
        </label>

        {isUploading && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CircularProgress size={20} />
            <Typography variant="body2" color="text.secondary">
              Uploading...
            </Typography>
          </Box>
        )}

        {uploadUrl && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <StyledChip
              icon={<AttachFile />}
              label={`${docType} Document`}
              variant="outlined"
              onDelete={
                editable ? () => handleDeleteDocument(docType) : undefined
              }
            />
            <Tooltip title="View uploaded file">
              <IconButton
                onClick={() => window.open(uploadUrl, '_blank')}
                sx={{
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.1)
                  }
                }}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    );
  };

  const renderDocumentSection = (docType: DocumentType) => {
    const uploadUrl = watch(`${docType}.uploadUrl` as any);
    const isUploading = uploadingDocument[docType];
    const isSaving = savingSection[docType];

    return (
      <Fade in timeout={500}>
        <DocumentCard key={docType}>
          <DocumentHeader>
            <DocumentScanner />
            <DocumentTitle>
              {docType.split(/(?=[A-Z])/).join(' ')}
            </DocumentTitle>
          </DocumentHeader>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Numbers
                  sx={{ fontSize: 20, mr: 1, color: 'text.secondary' }}
                />
                <Typography variant="subtitle2" color="text.secondary">
                  {`${docType} Number`}
                </Typography>
              </Box>
              <Controller
                name={`${docType}.number` as any}
                control={control}
                render={({ field }) =>
                  editable ? (
                    <StyledTextField
                      {...field}
                      fullWidth
                      placeholder={`Enter ${docType} number`}
                      variant="outlined"
                    />
                  ) : (
                    <Typography variant="body1">
                      {field.value || 'N/A'}
                    </Typography>
                  )
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <CalendarMonth
                  sx={{ fontSize: 20, mr: 1, color: 'text.secondary' }}
                />
                <Typography variant="subtitle2" color="text.secondary">
                  {`${docType} Expiry Date`}
                </Typography>
              </Box>
              <Controller
                name={`${docType}.expiryDate` as any}
                control={control}
                render={({ field }) =>
                  editable ? (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        {...field}
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  ) : (
                    <Typography variant="body1">
                      {field.value
                        ? new Date(field.value).toLocaleDateString()
                        : 'N/A'}
                    </Typography>
                  )
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: 2
                }}
              >
                {editable && (
                  <Box component="div">
                    <input
                      accept="image/*,.pdf"
                      id={`${docType}-upload-input`}
                      type="file"
                      onChange={handleFileSelect(docType)}
                      style={{ display: 'none' }}
                    />
                    <Button
                      component="label"
                      htmlFor={`${docType}-upload-input`}
                      variant="outlined"
                      startIcon={<CloudUpload />}
                      disabled={isUploading}
                      sx={{
                        borderRadius: '8px',
                        p: '8px 16px',
                        transition: 'all 0.2s ease',
                        textTransform: 'none',
                        backgroundColor: (theme) =>
                          alpha(theme.palette.primary.main, 0.1),
                        color: 'primary.main',
                        border: 'none',
                        '&:hover': {
                          backgroundColor: (theme) =>
                            alpha(theme.palette.primary.main, 0.2),
                          boxShadow: (theme) =>
                            `0 4px 12px ${alpha(
                              theme.palette.primary.main,
                              0.2
                            )}`
                        }
                      }}
                    >
                      {selectedFiles[docType]
                        ? selectedFiles[docType]?.name
                        : uploadUrl
                        ? 'Replace File'
                        : 'Upload File'}
                    </Button>
                  </Box>
                )}

                {isUploading && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={20} />
                    <Typography variant="body2" color="text.secondary">
                      Uploading...
                    </Typography>
                  </Box>
                )}

                {uploadUrl && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <StyledChip
                      icon={<AttachFile />}
                      label={`${docType} Document`}
                      variant="outlined"
                      onDelete={
                        editable
                          ? () => handleDeleteDocument(docType)
                          : undefined
                      }
                    />
                    <Tooltip title="View uploaded file">
                      <IconButton
                        onClick={() => window.open(uploadUrl, '_blank')}
                        sx={{
                          color: 'primary.main',
                          '&:hover': {
                            backgroundColor: (theme) =>
                              alpha(theme.palette.primary.main, 0.1)
                          }
                        }}
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Box>
            </Grid>

            {editable && (
              <Grid item xs={12}>
                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}
                >
                  <Button
                    onClick={() =>
                      handleSaveSection(docType, watch(docType as any))
                    }
                    variant="contained"
                    startIcon={
                      isSaving ? <CircularProgress size={20} /> : <Save />
                    }
                    disabled={isSaving}
                    sx={{
                      borderRadius: 2,
                      px: 4,
                      py: 1,
                      background: (theme) =>
                        `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      '&:hover': {
                        background: (theme) =>
                          `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                        boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)'
                      }
                    }}
                  >
                    {isSaving ? 'Saving...' : `Save ${docType}`}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </DocumentCard>
      </Fade>
    );
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        borderRadius: 4,
        p: { xs: 2, sm: 3, md: 4 },
        boxShadow: (theme) =>
          `0 0 40px ${alpha(theme.palette.primary.main, 0.08)}`
      }}
    >
      <Grid container spacing={3}>
        {renderDocumentSection('passport')}
        {renderDocumentSection('drivingLicense')}
        {rightToWorkStatus === 'Visa Required' &&
          renderDocumentSection('biometricResidencePermit')}
      </Grid>
    </Box>
  );
};

export default IdentificationDocuments;
