import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { Shift } from './types';
import { useAppSelector } from 'src/redux/hook';
import AssignStaffDialog from './assign-staffs';
import ViewAssignedStaffDialog from './assigned-staffs';

import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import ShiftAccordion from './view-shift-accordion';
import {
  useAcceptShiftByAgencyMutation,
  useAssignUsersToShiftMutation,
  useDeleteShiftMutation,
  useGetSingleShiftQuery,
  useLazyGetSingleShiftQuery
} from 'src/redux/@api/shift';
import { openPaymentDialog } from 'src/redux/reducers/dialogs/payment';
import DeleteConfirmationDialog from '../delete-confirmation';
import { set } from 'date-fns';

interface ViewShiftDialogProps {
  open: boolean;
  onClose: () => void;
  selectedDate: moment.Moment | null;
  shifts: Shift[];
  onShiftsUpdate: (updatedShifts: Shift[]) => void;
  onEditClick: (shift: Shift) => void;
  onAddFurther: () => void;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2)
  }
}));

const ViewShiftDialog: React.FC<ViewShiftDialogProps> = ({
  open,
  onClose,
  selectedDate,
  shifts,
  onShiftsUpdate,
  onEditClick,
  onAddFurther
}) => {
  const dispatch = useDispatch();
  const [assignStaffDialogOpen, setAssignStaffDialogOpen] = useState(false);
  const [viewAssignedStaffDialogOpen, setViewAssignedStaffDialogOpen] =
    useState(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [shiftToDelete, setShiftToDelete] = useState<Shift | null>(null);

  const [shiftsForTheDay, setShiftsForTheDay] = useState<Shift[]>([...shifts]);
  const [selectedShift, setSelectedShift] = useState<Shift | null>(null);
  const [assignUsersToShift] = useAssignUsersToShiftMutation();
  const [acceptShiftByAgency] = useAcceptShiftByAgencyMutation();
  const [getSingleShift] = useLazyGetSingleShiftQuery();

  const [deleteShift] = useDeleteShiftMutation();

  const [assignedStaffs, setAssignedStaffs] = useState<any[] | null>(null);

  const userState = useAppSelector((state) => state.userState);

  useEffect(() => {
    setShiftsForTheDay(shifts);
  }, [shifts]);

  const handleOpenAssignStaff = (shift: Shift) => {
    setSelectedShift(shift);
    setAssignStaffDialogOpen(true);
  };

  const handleCloseAssignStaff = () => {
    setAssignStaffDialogOpen(false);
    setSelectedShift(null);
  };

  const handleOpenViewAssignedStaff = (shift: Shift) => {
    setSelectedShift(shift);
    setViewAssignedStaffDialogOpen(true);
  };

  const handleCloseViewAssignedStaff = () => {
    setViewAssignedStaffDialogOpen(false);
    setSelectedShift(null);
  };

  const handleAcceptShift = async (shift: Shift) => {
    try {
      console.log('peri');
      const updatedShift = await acceptShiftByAgency(shift._id).unwrap();
      onShiftsUpdate(
        shifts.map((s) => (s._id === updatedShift._id ? updatedShift : s))
      );
      dispatch(
        showSnack({ message: 'Shift accepted successfully', color: 'success' })
      );
    } catch (error) {
      console.log('Failed to accept shift:', error);
      if (error.status === 402) {
        dispatch(
          openPaymentDialog(
            error.data?.message || 'Please subscribe to accept the shift'
          )
        );
      }
      dispatch(
        showSnack({
          message: 'Failed to accept shift. Please try again.',
          color: 'error'
        })
      );
    }
  };

  const handleDeleteShift = async () => {
    try {
      await deleteShift(shiftToDelete?._id as string).unwrap();
      onShiftsUpdate(
        shifts.filter((shift) => shift._id !== shiftToDelete?._id)
      );
      dispatch(
        showSnack({ message: 'Shift deleted successfully', color: 'success' })
      );
    } catch (error) {
      console.log('Failed to delete shift:', error);
      dispatch(
        showSnack({
          message: 'Failed to delete shift. Please try again.',
          color: 'error'
        })
      );
    }
  };

  let totalShifts = 0;

  shiftsForTheDay.forEach((shift) => {
    totalShifts += shift.count;
  });

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Shifts for {selectedDate?.format('MMMM D, YYYY')}
        {userState.currentOrganization?.type === 'home' && (
          <Button
            sx={{
              float: 'right'
            }}
            variant="contained"
            size="small"
            onClick={() => onAddFurther()}
          >
            Add more
          </Button>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Total Shifts: {totalShifts}
        </Typography>
        <Grid container spacing={2}>
          {shiftsForTheDay.map((shift) => (
            <Grid item xs={12} sm={6} md={4} key={shift._id}>
              <ShiftAccordion
                shift={shift}
                userState={userState}
                onAssignStaff={handleOpenAssignStaff}
                onViewAssignedStaff={handleOpenViewAssignedStaff}
                onEditClick={onEditClick}
                onDeleteClick={() => {
                  setShiftToDelete(shift);
                  setOpenDeleteConfirmation(true);
                }}
                setAssignedStaffs={setAssignedStaffs}
                onShiftUpdate={(updatedShift) =>
                  onShiftsUpdate(
                    shifts.map((s) =>
                      s._id === updatedShift._id ? updatedShift : s
                    )
                  )
                }
                onAccept={handleAcceptShift}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      {selectedShift && (
        <>
          <AssignStaffDialog
            open={assignStaffDialogOpen}
            onClose={handleCloseAssignStaff}
            shift={selectedShift}
            onAssign={async (assignedShifts: any[]) => {
              const assignedShiftsMap = new Map(
                assignedShifts.map((shift) => [shift._id, shift])
              );
              const updatedShifts = shiftsForTheDay.map((shift) =>
                assignedShiftsMap.has(shift._id)
                  ? assignedShiftsMap.get(shift._id)
                  : shift
              );

              setShiftsForTheDay(updatedShifts);
              onShiftsUpdate(updatedShifts);
            }}
          />
        </>
      )}

      {selectedShift && assignedStaffs?.length > 0 && (
        <ViewAssignedStaffDialog
          open={viewAssignedStaffDialogOpen}
          onClose={handleCloseViewAssignedStaff}
          isInternal={
            selectedShift.agentId?._id === userState.currentOrganization?._id ||
            !selectedShift.agentId?._id
          }
          assignedStaffs={assignedStaffs || []}
          shift={selectedShift}
          onCallback={async () => {
            const data = await getSingleShift(selectedShift._id).unwrap();
            if (data) {
              setShiftsForTheDay(
                shiftsForTheDay.map((shift) =>
                  shift._id === data._id ? data : shift
                )
              );
            }
          }}
        />
      )}

      <DeleteConfirmationDialog
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onConfirm={async () => {
          if (shiftToDelete) {
            await handleDeleteShift();
            setShiftToDelete(null);
            setOpenDeleteConfirmation(false);
          }
        }}
        itemName="shift"
      />
    </StyledDialog>
  );
};

export default ViewShiftDialog;
