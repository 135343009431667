import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Chip,
  IconButton,
  Stack,
  Alert,
  Grid,
  TextField,
  styled
} from '@mui/material';
import {
  Close as CloseIcon,
  WbSunny,
  NightsStay,
  Warning,
  LocalDrink,
  RestaurantMenu,
  Person,
  AccessibilityNew,
  SingleBed
} from '@mui/icons-material';

interface DayNightCheckData {
  // General Observation
  location: string;
  position: string;
  appearance: string[];
  breathing: string;

  // State
  consciousness: string;
  mood: string;
  behaviors: string[];
  awake: boolean;

  // Physical Needs
  padCheck: {
    checked: boolean;
    condition: string;
    changed: boolean;
  };

  fluids: {
    offered: boolean;
    accepted: boolean;
    amount?: number;
  };

  // Safety & Comfort
  callBell: boolean;
  bedrailPosition: string;
  bedHeight: string;
  roomTemperature: string;
  lightLevel: string;

  // Issues
  painObserved: boolean;
  painDetails?: {
    location: string;
    intensity: number;
  };
  concerningSymptoms: string[];
  assistanceProvided: string[];

  concerns: boolean;
  notes: string;
}

const appearanceOptions = [
  'Normal',
  'Pale',
  'Flushed',
  'Sweating',
  'Cyanotic',
  'Restless'
];

const behaviorOptions = [
  'Calm',
  'Agitated',
  'Confused',
  'Distressed',
  'Withdrawn',
  'Cooperative'
];

const locations = ['In Bed', 'Chair', 'Bathroom', 'Walking', 'Common Area'];

const positions = [
  'Lying Left',
  'Lying Right',
  'Lying Back',
  'Semi-Reclined',
  'Sitting',
  'Standing'
];

const concerningSymptoms = [
  'Breathing Difficulty',
  'Restlessness',
  'Confusion',
  'Pain',
  'Anxiety',
  'Agitation'
];

const assistanceTypes = [
  'Repositioning',
  'Toileting',
  'Drinks',
  'Comfort',
  'Reassurance'
];

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2
  }
}));

const SectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`
}));

interface DayNightCheckDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: DayNightCheckData) => void;
  task: any;
  isNight?: boolean;
  initialData?: Partial<DayNightCheckData>;
}

const DayNightCheckDialog: React.FC<DayNightCheckDialogProps> = ({
  open,
  onClose,
  onResolve,
  task,
  isNight = false,
  initialData
}) => {
  const [formData, setFormData] = useState<DayNightCheckData>({
    location: '',
    position: '',
    appearance: [],
    breathing: 'normal',
    consciousness: 'alert',
    mood: 'calm',
    behaviors: [],
    awake: true,
    padCheck: {
      checked: false,
      condition: 'dry',
      changed: false
    },
    fluids: {
      offered: false,
      accepted: false
    },
    callBell: true,
    bedrailPosition: 'up',
    bedHeight: 'low',
    roomTemperature: 'comfortable',
    lightLevel: isNight ? 'dark' : 'adequate',
    painObserved: false,
    concerningSymptoms: [],
    assistanceProvided: [],
    concerns: false,
    notes: ''
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setFormData((prev) => ({ ...prev, ...initialData }));
    }
  }, [initialData]);

  const handleChange = (field: string, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleNestedChange = (parent: string, field: string, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [parent]: {
        ...prev[parent],
        [field]: value
      }
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.location || !formData.position) {
      setError('Please complete resident location and position');
      return false;
    }

    if (!formData.appearance.length) {
      setError('Please select resident appearance');
      return false;
    }

    if (formData.painObserved && !formData.painDetails) {
      setError('Please provide pain details');
      return false;
    }

    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onResolve(formData);
      onClose();
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            {isNight ? (
              <NightsStay color="primary" />
            ) : (
              <WbSunny color="primary" />
            )}
            <Typography variant="h6">
              {isNight ? 'Night Check' : 'Day Check'}
            </Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          {/* Location & Position */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Location & Position
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Location</InputLabel>
                  <Select
                    value={formData.location}
                    onChange={(e) => handleChange('location', e.target.value)}
                  >
                    {locations.map((loc) => (
                      <MenuItem key={loc} value={loc}>
                        {loc}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Position</InputLabel>
                  <Select
                    value={formData.position}
                    onChange={(e) => handleChange('position', e.target.value)}
                  >
                    {positions.map((pos) => (
                      <MenuItem key={pos} value={pos}>
                        {pos}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </SectionBox>

          {/* Appearance & State */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Appearance & State
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Appearance
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {appearanceOptions.map((app) => (
                    <Chip
                      key={app}
                      label={app}
                      onClick={() => {
                        const newAppearance = formData.appearance.includes(app)
                          ? formData.appearance.filter((a) => a !== app)
                          : [...formData.appearance, app];
                        handleChange('appearance', newAppearance);
                      }}
                      color={
                        formData.appearance.includes(app)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.appearance.includes(app)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Breathing</InputLabel>
                  <Select
                    value={formData.breathing}
                    onChange={(e) => handleChange('breathing', e.target.value)}
                  >
                    <MenuItem value="normal">Normal</MenuItem>
                    <MenuItem value="labored">Labored</MenuItem>
                    <MenuItem value="rapid">Rapid</MenuItem>
                    <MenuItem value="shallow">Shallow</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Consciousness</InputLabel>
                  <Select
                    value={formData.consciousness}
                    onChange={(e) =>
                      handleChange('consciousness', e.target.value)
                    }
                  >
                    <MenuItem value="alert">Alert</MenuItem>
                    <MenuItem value="drowsy">Drowsy</MenuItem>
                    <MenuItem value="sleeping">Sleeping</MenuItem>
                    <MenuItem value="unresponsive">Unresponsive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Behaviors
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {behaviorOptions.map((behavior) => (
                    <Chip
                      key={behavior}
                      label={behavior}
                      onClick={() => {
                        const newBehaviors = formData.behaviors.includes(
                          behavior
                        )
                          ? formData.behaviors.filter((b) => b !== behavior)
                          : [...formData.behaviors, behavior];
                        handleChange('behaviors', newBehaviors);
                      }}
                      color={
                        formData.behaviors.includes(behavior)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.behaviors.includes(behavior)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </SectionBox>

          {/* Physical Needs */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Physical Needs
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.padCheck.checked}
                      onChange={(e) =>
                        handleNestedChange(
                          'padCheck',
                          'checked',
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Pad Checked"
                />
              </Grid>

              {formData.padCheck.checked && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Pad Condition</InputLabel>
                      <Select
                        value={formData.padCheck.condition}
                        onChange={(e) =>
                          handleNestedChange(
                            'padCheck',
                            'condition',
                            e.target.value
                          )
                        }
                      >
                        <MenuItem value="dry">Dry</MenuItem>
                        <MenuItem value="damp">Damp</MenuItem>
                        <MenuItem value="wet">Wet</MenuItem>
                        <MenuItem value="soiled">Soiled</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.padCheck.changed}
                          onChange={(e) =>
                            handleNestedChange(
                              'padCheck',
                              'changed',
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="Pad Changed"
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.fluids.offered}
                      onChange={(e) =>
                        handleNestedChange(
                          'fluids',
                          'offered',
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Fluids Offered"
                />
              </Grid>

              {formData.fluids.offered && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.fluids.accepted}
                          onChange={(e) =>
                            handleNestedChange(
                              'fluids',
                              'accepted',
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="Fluids Accepted"
                    />
                  </Grid>

                  {formData.fluids.accepted && (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        label="Amount (ml)"
                        value={formData.fluids.amount || ''}
                        onChange={(e) =>
                          handleNestedChange(
                            'fluids',
                            'amount',
                            Number(e.target.value)
                          )
                        }
                        InputProps={{ inputProps: { min: 0 } }}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </SectionBox>

          {/* Safety & Comfort */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Safety & Comfort
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.callBell}
                      onChange={(e) =>
                        handleChange('callBell', e.target.checked)
                      }
                    />
                  }
                  label="Call Bell Within Reach"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Bed Rails</InputLabel>
                  <Select
                    value={formData.bedrailPosition}
                    onChange={(e) =>
                      handleChange('bedrailPosition', e.target.value)
                    }
                  >
                    <MenuItem value="up">Up</MenuItem>
                    <MenuItem value="down">Down</MenuItem>
                    <MenuItem value="partial">Partial</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Room Temperature</InputLabel>
                  <Select
                    value={formData.roomTemperature}
                    onChange={(e) =>
                      handleChange('roomTemperature', e.target.value)
                    }
                  >
                    <MenuItem value="comfortable">Comfortable</MenuItem>
                    <MenuItem value="too_warm">Too Warm</MenuItem>
                    <MenuItem value="too_cold">Too Cold</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Light Level</InputLabel>
                  <Select
                    value={formData.lightLevel}
                    onChange={(e) => handleChange('lightLevel', e.target.value)}
                  >
                    <MenuItem value="dark">Dark</MenuItem>
                    <MenuItem value="dim">Dim</MenuItem>
                    <MenuItem value="adequate">Adequate</MenuItem>
                    <MenuItem value="bright">Bright</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </SectionBox>

          {/* Issues & Concerns */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Issues & Concerns
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.painObserved}
                      onChange={(e) =>
                        handleChange('painObserved', e.target.checked)
                      }
                      color="warning"
                    />
                  }
                  label="Pain Observed"
                />
              </Grid>

              {formData.painObserved && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Pain Location"
                      value={formData.painDetails?.location || ''}
                      onChange={(e) =>
                        handleNestedChange(
                          'painDetails',
                          'location',
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      label="Pain Level (0-10)"
                      value={formData.painDetails?.intensity || 0}
                      onChange={(e) =>
                        handleNestedChange(
                          'painDetails',
                          'intensity',
                          Number(e.target.value)
                        )
                      }
                      InputProps={{ inputProps: { min: 0, max: 10 } }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Concerning Symptoms
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {concerningSymptoms.map((symptom) => (
                    <Chip
                      key={symptom}
                      label={symptom}
                      onClick={() => {
                        const newSymptoms =
                          formData.concerningSymptoms.includes(symptom)
                            ? formData.concerningSymptoms.filter(
                                (s) => s !== symptom
                              )
                            : [...formData.concerningSymptoms, symptom];
                        handleChange('concerningSymptoms', newSymptoms);
                      }}
                      color={
                        formData.concerningSymptoms.includes(symptom)
                          ? 'error'
                          : 'default'
                      }
                      variant={
                        formData.concerningSymptoms.includes(symptom)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Assistance Provided
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {assistanceTypes.map((assistance) => (
                    <Chip
                      key={assistance}
                      label={assistance}
                      onClick={() => {
                        const newAssistance =
                          formData.assistanceProvided.includes(assistance)
                            ? formData.assistanceProvided.filter(
                                (a) => a !== assistance
                              )
                            : [...formData.assistanceProvided, assistance];
                        handleChange('assistanceProvided', newAssistance);
                      }}
                      color={
                        formData.assistanceProvided.includes(assistance)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.assistanceProvided.includes(assistance)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </SectionBox>

          {/* Notes & Follow-up */}
          <SectionBox>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Notes & Follow-up
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Notes"
                  value={formData.notes}
                  onChange={(e) => handleChange('notes', e.target.value)}
                  placeholder="Enter any additional observations or concerns..."
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.concerns}
                      onChange={(e) =>
                        handleChange('concerns', e.target.checked)
                      }
                      color="error"
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography>
                        {isNight
                          ? 'Wake Staff/Nurse'
                          : 'Requires Immediate Review'}
                      </Typography>
                      {formData.concerns && <Warning color="error" />}
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </SectionBox>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color={
            formData.concerns || formData.concerningSymptoms.length > 0
              ? 'error'
              : 'primary'
          }
          startIcon={
            formData.concerns || formData.concerningSymptoms.length > 0 ? (
              <Warning />
            ) : isNight ? (
              <NightsStay />
            ) : (
              <WbSunny />
            )
          }
        >
          {formData.concerns || formData.concerningSymptoms.length > 0
            ? 'Save & Alert Staff'
            : 'Save Check'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default DayNightCheckDialog;
