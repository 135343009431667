import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Box,
  TextField,
  useTheme,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  Divider,
  Stack,
  Alert,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
  CircularProgress,
  Slider
} from '@mui/material';
import {
  Check,
  Info,
  Droplet,
  Timer,
  User,
  Users,
  Shirt,
  AlertTriangle,
  FileText,
  Activity
} from 'lucide-react';
import { BaseTaskDialogProps } from '../tabs/rosolve-task';
import { CleaningServices } from '@mui/icons-material';

interface PadCheckData {
  // Basic Assessment
  stoolType: string;
  stoolColor: string;
  stoolAmount: string;
  urineType: string;
  urineColor: string;
  urineAmount: string;

  // Care Method
  careMethod: string;
  slidingSheetUsed: boolean;
  numberOfCarers: number;
  timeSpent: number;

  // Skin Condition
  skinCondition: string[];
  skinColor: string;
  skinTemperature: string;
  pressureAreas: string[];

  // Hygiene
  cleaningMethod: string;
  cleaningProducts: string[];
  barierCream: boolean;
  padType: string;
  padSize: string;

  // Clothing
  clothingChanged: boolean;
  clothingType: string;
  clothingCondition: string;

  // Additional Info
  mobilityLevel: string;
  residentMood: string;
  painLevel: number;
  timestamp: string;
}

const stoolTypes = [
  {
    type: '1',
    description: 'Separate hard lumps',
    image: require('src/assets/images/stool/type1.png')
  },
  {
    type: '2',
    description: 'Lumpy and sausage-like',
    image: require('src/assets/images/stool/type2.png')
  },
  {
    type: '3',
    description: 'Sausage with cracks',
    image: require('src/assets/images/stool/type3.png')
  },
  {
    type: '4',
    description: 'Smooth and soft sausage',
    image: require('src/assets/images/stool/type4.png')
  },
  {
    type: '5',
    description: 'Soft blobs with clear edges',
    image: require('src/assets/images/stool/type5.png')
  },
  {
    type: '6',
    description: 'Mushy consistency with ragged edges',
    image: require('src/assets/images/stool/type6.png')
  },
  {
    type: '7',
    description: 'Entirely liquid',
    image: require('src/assets/images/stool/type7.png')
  }
];

const stoolColors = [
  'Brown',
  'Dark Brown',
  'Black',
  'Green',
  'Yellow',
  'Red-tinged',
  'Clay-colored'
];
const stoolAmounts = ['Small', 'Medium', 'Large'];

const urineTypes = [
  'Dry',
  'Slightly Damp',
  'Damp',
  'Wet',
  'Very Wet',
  'Leakage'
];
const urineColors = [
  'Clear',
  'Pale Yellow',
  'Dark Yellow',
  'Orange',
  'Red-tinged',
  'Brown',
  'Cloudy'
];
const urineAmounts = ['Small', 'Moderate', 'Large'];

const careMethods = [
  'Hoist Transfer',
  'Standing Transfer',
  'One Person Assist',
  'Two Person Assist',
  'Independent'
];
const skinConditions = [
  'Normal',
  'Dry',
  'Red',
  'Broken',
  'Bruised',
  'Swollen',
  'Warm',
  'Cool'
];
const skinColors = ['Normal', 'Pale', 'Flushed', 'Mottled', 'Cyanotic'];
const skinTemperatures = ['Normal', 'Warm', 'Hot', 'Cool', 'Cold'];
const pressureAreas = [
  'Sacrum',
  'Left Hip',
  'Right Hip',
  'Left Heel',
  'Right Heel',
  'Left Elbow',
  'Right Elbow',
  'Other'
];

const cleaningMethods = ['Wet Wipes', 'Wash', 'Shower', 'Bath'];
const cleaningProducts = [
  'Soap',
  'No-rinse Cleanser',
  'Barrier Wipes',
  'Foam Cleanser',
  'Antibacterial Wipes'
];
const padTypes = [
  'All-in-One',
  'Pull-ups',
  'Insert Pads',
  'Rectangular Pads',
  'Night Pads'
];
const padSizes = ['Small', 'Medium', 'Large', 'Extra Large'];

const clothingTypes = ['Day Clothes', 'Night Clothes', 'Hospital Gown'];
const clothingConditions = [
  'Clean & Dry',
  'Slightly Soiled',
  'Heavily Soiled',
  'Wet',
  'Damaged'
];

const mobilityLevels = [
  'Independent',
  'Walks with Aid',
  'Wheelchair User',
  'Bed Bound'
];
const residentMoods = [
  'Calm',
  'Agitated',
  'Distressed',
  'Sleeping',
  'Confused',
  'Cooperative',
  'Resistant'
];

// Styled Components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      width: `calc(100% - ${theme.spacing(2)})`,
      maxWidth: 'none'
    }
  }
}));

const SectionTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
  '& .icon': {
    color: theme.palette.primary.main
  },
  '& .title': {
    fontWeight: 600,
    color: theme.palette.text.primary
  }
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2),
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const ImageToggleButton = styled(ToggleButton)(({ theme }) => ({
  flexDirection: 'column',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  minWidth: 100,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
    '& .type-description': {
      color: theme.palette.primary.contrastText
    }
  },
  '& .type-image': {
    width: 60,
    height: 60,
    objectFit: 'contain'
  },
  '& .type-description': {
    fontSize: '0.75rem',
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

const InfoTooltip = styled(Tooltip)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[2],
    fontSize: '0.875rem',
    padding: theme.spacing(1, 2),
    maxWidth: 300
  }
}));

const PadCheckDialog: React.FC<BaseTaskDialogProps> = ({
  open,
  onClose,
  onResolve
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [formData, setFormData] = useState<PadCheckData>({
    stoolType: '',
    stoolColor: '',
    stoolAmount: '',
    urineType: '',
    urineColor: '',
    urineAmount: '',
    careMethod: '',
    slidingSheetUsed: false,
    numberOfCarers: 1,
    timeSpent: 0,
    skinCondition: [],
    skinColor: '',
    skinTemperature: '',
    pressureAreas: [],
    cleaningMethod: '',
    cleaningProducts: [],
    barierCream: false,
    padType: '',
    padSize: '',
    clothingChanged: false,
    clothingType: '',
    clothingCondition: '',
    mobilityLevel: '',
    residentMood: '',
    painLevel: 0,
    timestamp: new Date().toISOString()
  });

  const handleFormChange = (field: keyof PadCheckData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.stoolType || !formData.urineType) {
      setError('Please complete stool and urine assessment');
      return false;
    }
    if (!formData.careMethod) {
      setError('Please select care method');
      return false;
    }
    if (!formData.skinCondition.length) {
      setError('Please assess skin condition');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      await onResolve(formData);
      onClose();
    } catch (err) {
      setError('Failed to save assessment. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Resident Pad Check Assessment</Typography>
          {loading && <CircularProgress size={24} />}
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          {/* Stool Assessment Section */}
          <Box>
            <SectionTitle>
              <Activity className="icon" />
              <Typography variant="h6" className="title">
                Stool Assessment
              </Typography>
            </SectionTitle>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Bristol Stool Type
                </Typography>
                <Box display="flex" gap={1} overflow="auto" pb={1}>
                  {stoolTypes.map((stool) => (
                    <ImageToggleButton
                      key={stool.type}
                      value={stool.type}
                      selected={formData.stoolType === stool.type}
                      onChange={() => handleFormChange('stoolType', stool.type)}
                    >
                      <img
                        src={stool.image}
                        alt={`Type ${stool.type}`}
                        className="type-image"
                      />
                      <Typography className="type-description">
                        Type {stool.type}
                      </Typography>
                      <InfoTooltip title={stool.description} placement="top">
                        <IconButton size="small">
                          <Info size={16} />
                        </IconButton>
                      </InfoTooltip>
                    </ImageToggleButton>
                  ))}
                </Box>
              </Grid>

              {/* Additional form sections for color, amount, etc. */}
              {/* Similar implementation for all other sections using Grid and appropriate components */}
              {/* The full implementation would include all sections mentioned in the interface */}

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Color
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={formData.stoolColor}
                  exclusive
                  onChange={(_, value) => handleFormChange('stoolColor', value)}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {stoolColors.map((color) => (
                    <StyledToggleButton key={color} value={color}>
                      {color}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Amount
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={formData.stoolAmount}
                  exclusive
                  onChange={(_, value) =>
                    handleFormChange('stoolAmount', value)
                  }
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {stoolAmounts.map((amount) => (
                    <StyledToggleButton key={amount} value={amount}>
                      {amount}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          {/* Similar sections would follow for:
              - Urine Assessment
              - Care Method
              - Skin Assessment
              - Hygiene Care
              - Clothing Changes
              - Resident Status
              Each section would use appropriate components and layouts */}

          {/* Urine Assessment Section */}
          <Box>
            <SectionTitle>
              <Droplet className="icon" />
              <Typography variant="h6" className="title">
                Urine Assessment
              </Typography>
            </SectionTitle>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Saturation Level
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={formData.urineType}
                  exclusive
                  onChange={(_, value) => handleFormChange('urineType', value)}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {urineTypes.map((type) => (
                    <StyledToggleButton key={type} value={type}>
                      {type}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Color
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={formData.urineColor}
                  exclusive
                  onChange={(_, value) => handleFormChange('urineColor', value)}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {urineColors.map((color) => (
                    <StyledToggleButton key={color} value={color}>
                      {color}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Amount
                </Typography>
                <ToggleButtonGroup
                  value={formData.urineAmount}
                  exclusive
                  size="small"
                  onChange={(_, value) =>
                    handleFormChange('urineAmount', value)
                  }
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {urineAmounts.map((amount) => (
                    <StyledToggleButton key={amount} value={amount}>
                      {amount}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          {/* Care Method Section */}
          <Box>
            <SectionTitle>
              <Users className="icon" />
              <Typography variant="h6" className="title">
                Care Method & Assistance
              </Typography>
            </SectionTitle>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Care Method
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={formData.careMethod}
                  exclusive
                  onChange={(_, value) => handleFormChange('careMethod', value)}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {careMethods.map((method) => (
                    <StyledToggleButton key={method} value={method}>
                      {method}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Equipment Used
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.slidingSheetUsed}
                      onChange={(e) =>
                        handleFormChange('slidingSheetUsed', e.target.checked)
                      }
                    />
                  }
                  label="Sliding Sheet Used"
                />
                <TextField
                  fullWidth
                  type="number"
                  label="Time Spent (minutes)"
                  value={formData.timeSpent}
                  onChange={(e) =>
                    handleFormChange('timeSpent', parseInt(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0, max: 60 } }}
                  sx={{ mt: 2 }}
                />
              </Grid>
            </Grid>
          </Box>

          <Divider />

          {/* Skin Assessment Section */}
          <Box>
            <SectionTitle>
              <AlertTriangle className="icon" />
              <Typography variant="h6" className="title">
                Skin Assessment
              </Typography>
            </SectionTitle>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Skin Condition (select all that apply)
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {skinConditions.map((condition) => (
                    <StyledToggleButton
                      key={condition}
                      value={condition}
                      selected={formData.skinCondition.includes(condition)}
                      onChange={() => {
                        const newConditions = formData.skinCondition.includes(
                          condition
                        )
                          ? formData.skinCondition.filter(
                              (c) => c !== condition
                            )
                          : [...formData.skinCondition, condition];
                        handleFormChange('skinCondition', newConditions);
                      }}
                    >
                      {condition}
                    </StyledToggleButton>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Skin Temperature
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={formData.skinTemperature}
                  exclusive
                  onChange={(_, value) =>
                    handleFormChange('skinTemperature', value)
                  }
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {skinTemperatures.map((temp) => (
                    <StyledToggleButton key={temp} value={temp}>
                      {temp}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Skin Color
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={formData.skinColor}
                  exclusive
                  onChange={(_, value) => handleFormChange('skinColor', value)}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {skinColors.map((color) => (
                    <StyledToggleButton key={color} value={color}>
                      {color}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Pressure Areas (select all affected)
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {pressureAreas.map((area) => (
                    <StyledToggleButton
                      key={area}
                      value={area}
                      selected={formData.pressureAreas.includes(area)}
                      onChange={() => {
                        const newAreas = formData.pressureAreas.includes(area)
                          ? formData.pressureAreas.filter((a) => a !== area)
                          : [...formData.pressureAreas, area];
                        handleFormChange('pressureAreas', newAreas);
                      }}
                    >
                      {area}
                    </StyledToggleButton>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          {/* Hygiene Care Section */}
          <Box>
            <SectionTitle>
              <CleaningServices className="icon" />
              <Typography variant="h6" className="title">
                Hygiene Care
              </Typography>
            </SectionTitle>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Cleaning Method
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={formData.cleaningMethod}
                  exclusive
                  onChange={(_, value) =>
                    handleFormChange('cleaningMethod', value)
                  }
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {cleaningMethods.map((method) => (
                    <StyledToggleButton key={method} value={method}>
                      {method}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Products Used
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {cleaningProducts.map((product) => (
                    <StyledToggleButton
                      key={product}
                      value={product}
                      selected={formData.cleaningProducts.includes(product)}
                      onChange={() => {
                        const newProducts = formData.cleaningProducts.includes(
                          product
                        )
                          ? formData.cleaningProducts.filter(
                              (p) => p !== product
                            )
                          : [...formData.cleaningProducts, product];
                        handleFormChange('cleaningProducts', newProducts);
                      }}
                    >
                      {product}
                    </StyledToggleButton>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Pad Type
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={formData.padType}
                  exclusive
                  onChange={(_, value) => handleFormChange('padType', value)}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {padTypes.map((type) => (
                    <StyledToggleButton key={type} value={type}>
                      {type}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Pad Size
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={formData.padSize}
                  exclusive
                  onChange={(_, value) => handleFormChange('padSize', value)}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {padSizes.map((size) => (
                    <StyledToggleButton key={size} value={size}>
                      {size}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.barierCream}
                      onChange={(e) =>
                        handleFormChange('barierCream', e.target.checked)
                      }
                    />
                  }
                  label="Barrier Cream Applied"
                />
              </Grid>
            </Grid>
          </Box>

          <Divider />

          {/* Resident Status Section */}
          <Box>
            <SectionTitle>
              <User className="icon" />
              <Typography variant="h6" className="title">
                Resident Status
              </Typography>
            </SectionTitle>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Mobility Level
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={formData.mobilityLevel}
                  exclusive
                  onChange={(_, value) =>
                    handleFormChange('mobilityLevel', value)
                  }
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {mobilityLevels.map((level) => (
                    <StyledToggleButton key={level} value={level}>
                      {level}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Resident Mood
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={formData.residentMood}
                  exclusive
                  onChange={(_, value) =>
                    handleFormChange('residentMood', value)
                  }
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  fullWidth
                >
                  {residentMoods.map((mood) => (
                    <StyledToggleButton key={mood} value={mood}>
                      {mood}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Pain Level (0-10)
                </Typography>
                <Slider
                  value={formData.painLevel}
                  onChange={(_, value) => handleFormChange('painLevel', value)}
                  min={0}
                  max={10}
                  step={1}
                  marks
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 3 }}>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          Submit Assessment
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default PadCheckDialog;
