import React from 'react';
import TaskGrid from './task-grid';
import { IResident } from 'src/interfaces/resident';

interface PendingTasksTabProps {
  tab: string;
  resident: IResident;
  onResolveTask: (task: any) => void;
}

const PendingTasksTab: React.FC<PendingTasksTabProps> = ({
  resident,
  tab,
  onResolveTask
}) => {
  return <TaskGrid resident={resident} tab={tab} onTaskClick={onResolveTask} />;
};

export default PendingTasksTab;
