import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Chip,
  IconButton,
  Stack,
  Alert,
  Grid,
  Tooltip,
  styled
} from '@mui/material';
import {
  Close as CloseIcon,
  FitnessCenter,
  Warning,
  Schedule,
  MonitorHeart
} from '@mui/icons-material';

interface ExerciseData {
  // Basic Info
  exerciseType: string[];
  duration: number;
  intensity: 'light' | 'moderate' | 'vigorous';

  // Engagement
  participation: 'full' | 'partial' | 'refused';
  completedExercises: string[];
  repetitions?: number;
  sets?: number;

  // Physical Response
  breathlessness: 'none' | 'mild' | 'moderate' | 'severe';
  tirednessLevel: number;
  painReported: boolean;
  painLocation?: string;
  painLevel?: number;

  // Monitoring
  pulseBeforeExercise?: number;
  pulseAfterExercise?: number;
  restRequired: boolean;
  restDuration?: number;

  // Support
  assistanceLevel: 'independent' | 'minimal' | 'moderate' | 'full';
  equipmentUsed: string[];

  // Outcome
  completed: boolean;
  concerns: boolean;
  notes: string;
}

interface ExerciseDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (data: ExerciseData) => void;
  task: any;
  initialData?: Partial<ExerciseData>;
}

const exerciseTypes = [
  'Walking',
  'Chair Exercises',
  'Bed Exercises',
  'Standing Exercises',
  'Balance Training',
  'Stretching',
  'Range of Motion',
  'Strength Training',
  'Breathing Exercises'
];

const specificExercises = {
  Walking: [
    'Corridor Walk',
    'Room Walking',
    'Garden Walk',
    'With Frame',
    'With Stick'
  ],
  'Chair Exercises': [
    'Leg Raises',
    'Arm Exercises',
    'Seated Marching',
    'Ankle Rotations',
    'Seated Stretches'
  ],
  'Bed Exercises': [
    'Leg Lifts',
    'Ankle Pumps',
    'Knee Bends',
    'Hip Exercises',
    'Upper Body'
  ],
  'Standing Exercises': [
    'Standing Balance',
    'Heel Raises',
    'Mini Squats',
    'Side Stepping',
    'Stand to Sit'
  ],
  'Balance Training': [
    'Static Balance',
    'Dynamic Balance',
    'Weight Shifting',
    'Single Leg Stand',
    'Walking Line'
  ]
};

const equipment = [
  'Walking Frame',
  'Walking Stick',
  'Resistance Bands',
  'Exercise Ball',
  'Weights',
  'Balance Equipment',
  'Parallel Bars',
  'Chair'
];

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2
  }
}));

const ExerciseDialog: React.FC<ExerciseDialogProps> = ({
  open,
  onClose,
  onResolve,
  task,
  initialData
}) => {
  const [formData, setFormData] = useState<ExerciseData>({
    exerciseType: [],
    duration: 0,
    intensity: 'light',
    participation: 'full',
    completedExercises: [],
    breathlessness: 'none',
    tirednessLevel: 0,
    painReported: false,
    restRequired: false,
    assistanceLevel: 'independent',
    equipmentUsed: [],
    completed: false,
    concerns: false,
    notes: ''
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setFormData((prev) => ({ ...prev, ...initialData }));
    }
  }, [initialData]);

  const handleChange = (field: keyof ExerciseData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.exerciseType.length) {
      setError('Please select at least one exercise type');
      return false;
    }

    if (formData.painReported && !formData.painLocation) {
      setError('Please specify pain location');
      return false;
    }

    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onResolve(formData);
      onClose();
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <FitnessCenter color="primary" />
            <Typography variant="h6">Exercise Record</Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          {/* Exercise Selection */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Exercise Type & Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {exerciseTypes.map((type) => (
                    <Chip
                      key={type}
                      label={type}
                      onClick={() => {
                        const newTypes = formData.exerciseType.includes(type)
                          ? formData.exerciseType.filter((t) => t !== type)
                          : [...formData.exerciseType, type];
                        handleChange('exerciseType', newTypes);
                      }}
                      color={
                        formData.exerciseType.includes(type)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.exerciseType.includes(type)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              {formData.exerciseType.map(
                (type) =>
                  specificExercises[type as keyof typeof specificExercises] && (
                    <Grid item xs={12} key={type}>
                      <Typography variant="subtitle2" gutterBottom>
                        {type} - Specific Exercises
                      </Typography>
                      <Box display="flex" flexWrap="wrap" gap={1}>
                        {specificExercises[
                          type as keyof typeof specificExercises
                        ].map((exercise) => (
                          <Chip
                            key={exercise}
                            label={exercise}
                            onClick={() => {
                              const newExercises =
                                formData.completedExercises.includes(exercise)
                                  ? formData.completedExercises.filter(
                                      (e) => e !== exercise
                                    )
                                  : [...formData.completedExercises, exercise];
                              handleChange('completedExercises', newExercises);
                            }}
                            color={
                              formData.completedExercises.includes(exercise)
                                ? 'success'
                                : 'default'
                            }
                            variant={
                              formData.completedExercises.includes(exercise)
                                ? 'filled'
                                : 'outlined'
                            }
                            size="small"
                          />
                        ))}
                      </Box>
                    </Grid>
                  )
              )}

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Duration (minutes)"
                  value={formData.duration}
                  onChange={(e) =>
                    handleChange('duration', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Intensity</InputLabel>
                  <Select
                    value={formData.intensity}
                    onChange={(e) => handleChange('intensity', e.target.value)}
                  >
                    <MenuItem value="light">Light</MenuItem>
                    <MenuItem value="moderate">Moderate</MenuItem>
                    <MenuItem value="vigorous">Vigorous</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* Engagement & Response */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Engagement & Response
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Participation Level</InputLabel>
                  <Select
                    value={formData.participation}
                    onChange={(e) =>
                      handleChange('participation', e.target.value)
                    }
                  >
                    <MenuItem value="full">Full Participation</MenuItem>
                    <MenuItem value="partial">Partial Participation</MenuItem>
                    <MenuItem value="refused">Refused</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Breathlessness Level</InputLabel>
                  <Select
                    value={formData.breathlessness}
                    onChange={(e) =>
                      handleChange('breathlessness', e.target.value)
                    }
                  >
                    <MenuItem value="none">None</MenuItem>
                    <MenuItem value="mild">Mild</MenuItem>
                    <MenuItem value="moderate">Moderate</MenuItem>
                    <MenuItem value="severe">Severe</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Tiredness Level (0-10)"
                  value={formData.tirednessLevel}
                  onChange={(e) =>
                    handleChange('tirednessLevel', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0, max: 10 } }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* Pain Assessment */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Pain Assessment
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.painReported}
                      onChange={(e) =>
                        handleChange('painReported', e.target.checked)
                      }
                    />
                  }
                  label="Pain Reported"
                />
              </Grid>

              {formData.painReported && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Pain Location"
                      value={formData.painLocation || ''}
                      onChange={(e) =>
                        handleChange('painLocation', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Pain Level (0-10)"
                      value={formData.painLevel || ''}
                      onChange={(e) =>
                        handleChange('painLevel', Number(e.target.value))
                      }
                      InputProps={{ inputProps: { min: 0, max: 10 } }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          {/* Equipment & Assistance */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Support & Equipment
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {equipment.map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      onClick={() => {
                        const newEquipment = formData.equipmentUsed.includes(
                          item
                        )
                          ? formData.equipmentUsed.filter((e) => e !== item)
                          : [...formData.equipmentUsed, item];
                        handleChange('equipmentUsed', newEquipment);
                      }}
                      color={
                        formData.equipmentUsed.includes(item)
                          ? 'primary'
                          : 'default'
                      }
                      variant={
                        formData.equipmentUsed.includes(item)
                          ? 'filled'
                          : 'outlined'
                      }
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Assistance Level</InputLabel>
                  <Select
                    value={formData.assistanceLevel}
                    onChange={(e) =>
                      handleChange('assistanceLevel', e.target.value)
                    }
                  >
                    <MenuItem value="independent">Independent</MenuItem>
                    <MenuItem value="minimal">Minimal Assistance</MenuItem>
                    <MenuItem value="moderate">Moderate Assistance</MenuItem>
                    <MenuItem value="full">Full Assistance</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* Notes & Concerns */}
          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Additional Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Notes"
                  value={formData.notes}
                  onChange={(e) => handleChange('notes', e.target.value)}
                  placeholder="Enter any observations, progress, or concerns..."
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.completed}
                      onChange={(e) =>
                        handleChange('completed', e.target.checked)
                      }
                      color="success"
                    />
                  }
                  label="Exercise Completed"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.concerns}
                      onChange={(e) =>
                        handleChange('concerns', e.target.checked)
                      }
                      color="error"
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography>Requires Follow-up</Typography>
                      {formData.concerns && (
                        <Warning color="error" fontSize="small" />
                      )}
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color={formData.concerns ? 'error' : 'primary'}
          startIcon={formData.concerns ? <Warning /> : <FitnessCenter />}
        >
          {formData.concerns ? 'Save & Flag' : 'Save Record'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

// Utility functions
const getExerciseIcon = (type: string) => {
  switch (type) {
    case 'Walking':
      return '🚶';
    case 'Chair Exercises':
      return '💺';
    case 'Bed Exercises':
      return '🛏️';
    case 'Standing Exercises':
      return '🧍';
    case 'Balance Training':
      return '⚖️';
    case 'Stretching':
      return '🤸';
    case 'Range of Motion':
      return '🔄';
    case 'Strength Training':
      return '💪';
    case 'Breathing Exercises':
      return '🫁';
    default:
      return '🏋️';
  }
};

const getIntensityColor = (intensity: string) => {
  switch (intensity) {
    case 'light':
      return 'success';
    case 'moderate':
      return 'warning';
    case 'vigorous':
      return 'error';
    default:
      return 'default';
  }
};

const getBreathlessnessWarningLevel = (
  level: string
): 'success' | 'warning' | 'error' => {
  switch (level) {
    case 'none':
    case 'mild':
      return 'success';
    case 'moderate':
      return 'warning';
    case 'severe':
      return 'error';
    default:
      return 'success';
  }
};

export default ExerciseDialog;
