import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Avatar,
  Box,
  Button,
  Tooltip,
  CircularProgress,
  Chip,
  useTheme,
  useMediaQuery,
  styled
} from '@mui/material';
import {
  Close,
  Person,
  Favorite,
  FavoriteBorder,
  Email,
  Phone
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useAddFavoriteStaffMutation,
  useCheckIsFavoriteQuery,
  useGetAgencyCareStaffsQuery,
  useRemoveFavoriteStaffMutation
} from 'src/redux/@api/staff';

interface StaffMember {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  avatar?: string;
  role: string;
  isFavorite: boolean;
}

interface StaffDialogProps {
  open: boolean;
  onClose: () => void;
  organizationId: string;
}

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4]
  }
}));

const CompactCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:last-child': {
    paddingBottom: theme.spacing(2)
  }
}));

const InfoTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8125rem'
  }
}));

const CompactAvatar = styled(Avatar)(({ theme }) => ({
  width: 56,
  height: 56,
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: 48,
    height: 48
  }
}));

// Sample data generator for testing
const generateSampleStaff = (count: number) => {
  const roles = ['Nurse', 'Carer', 'Senior Carer', 'Manager'];
  return Array.from({ length: count }, (_, index) => ({
    _id: `staff-${index + 1}`,
    user: {
      firstName: `FirstName${index + 1}`,
      lastName: `LastName${index + 1}`,
      email: `staff${index + 1}@example.com`,
      phone: `+1234567${index.toString().padStart(4, '0')}`,
      role: roles[index % roles.length],
      avtarUrl: `https://i.pravatar.cc/150?img=${index + 1}`
    },
    isFavorite: Math.random() > 0.5
  }));
};

const StaffDialog: React.FC<StaffDialogProps> = ({
  open,
  onClose,
  organizationId
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedStaff, setSelectedStaff] = useState<StaffMember | null>(null);

  const { data: apiStaffMembers, isLoading } = useGetAgencyCareStaffsQuery(
    organizationId as any,
    {
      skip: !organizationId
    }
  );

  const [addFavorite] = useAddFavoriteStaffMutation();
  const [removeFavorite] = useRemoveFavoriteStaffMutation();

  const handleToggleFavorite = async (
    staffId: string,
    currentStatus: boolean
  ) => {
    try {
      if (currentStatus) {
        await removeFavorite(staffId).unwrap();
        dispatch(
          showSnack({
            message: 'Staff removed from favorites',
            color: 'success'
          })
        );
      } else {
        await addFavorite({ staffId }).unwrap();
        dispatch(
          showSnack({
            message: 'Staff added to favorites',
            color: 'success'
          })
        );
      }
    } catch (error) {
      dispatch(
        showSnack({
          message: 'Error updating favorite status',
          color: 'error'
        })
      );
    }
  };

  // Combine API data with sample data for testing
  const staffMembers = [...(apiStaffMembers || [])];

  const handleViewProfile = (staff: StaffMember) => {
    setSelectedStaff(staff);
  };
  const StaffCard = ({ staff }: { staff: any }) => {
    const { data: isFavorite, isLoading: checkingFavorite } =
      useCheckIsFavoriteQuery(staff.user._id, {
        pollingInterval: 0, // Disable polling
        refetchOnMountOrArgChange: true // Refetch when component mounts or staffId changes
      });

    return (
      <StyledCard variant="outlined">
        <CompactCardContent>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexDirection: isMobile ? 'row' : 'column',
              alignItems: isMobile ? 'center' : 'flex-start'
            }}
          >
            <CompactAvatar src={staff.user.avatarUrl} />
            <Box sx={{ flex: 1, minWidth: 0, width: '100%' }}>
              <Typography
                variant="subtitle1"
                noWrap
                sx={{
                  fontSize: isMobile ? '0.9rem' : '1rem',
                  fontWeight: 'medium'
                }}
              >
                {staff.user.firstName} {staff.user.lastName}
              </Typography>
              <Chip
                label={staff.user.role}
                size="small"
                variant="outlined"
                color="primary"
                sx={{ mb: 1, fontSize: isMobile ? '0.75rem' : '0.8125rem' }}
              />
              <InfoTypography
                color="text.secondary"
                display="flex"
                alignItems="center"
                gap={0.5}
              >
                <Email sx={{ fontSize: isMobile ? 14 : 16 }} />
                {staff.user.email}
              </InfoTypography>
              <InfoTypography
                color="text.secondary"
                display="flex"
                alignItems="center"
                gap={0.5}
              >
                <Phone sx={{ fontSize: isMobile ? 14 : 16 }} />
                {staff.user.phone}
              </InfoTypography>
            </Box>
          </Box>
        </CompactCardContent>
        <CardActions
          sx={{
            pt: 0,
            px: 2,
            pb: 1,
            justifyContent: 'space-between'
          }}
        >
          <Button
            size="small"
            startIcon={<Person sx={{ fontSize: isMobile ? 16 : 20 }} />}
            onClick={() => handleViewProfile(staff)}
            sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
          >
            Profile
          </Button>
          <Tooltip
            title={
              isFavorite?.isFavorite
                ? 'Remove from favorites'
                : 'Add to favorites'
            }
          >
            <IconButton
              size={isMobile ? 'small' : 'medium'}
              onClick={() =>
                handleToggleFavorite(
                  staff.user._id,
                  isFavorite?.isFavorite || false
                )
              }
              color={isFavorite?.isFavorite ? 'primary' : 'default'}
              disabled={checkingFavorite}
            >
              {checkingFavorite ? (
                <CircularProgress size={20} />
              ) : isFavorite?.isFavorite ? (
                <Favorite />
              ) : (
                <FavoriteBorder />
              )}
            </IconButton>
          </Tooltip>
        </CardActions>
      </StyledCard>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          height: fullScreen ? '100%' : '80vh',
          margin: fullScreen ? 0 : 2
        }
      }}
    >
      <DialogTitle
        sx={{
          px: 2,
          py: 1.5,
          position: 'sticky',
          top: 0,
          backgroundColor: theme.palette.background.paper,
          zIndex: 1
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Staff Members</Typography>
          <IconButton onClick={onClose} size="small" edge="end">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          p: 2,
          height: '100%',
          overflow: 'auto'
        }}
      >
        {isLoading ? (
          <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {staffMembers?.map((staff: any) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={staff._id}
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    paddingTop: '8px',
                    paddingBottom: '8px'
                  }
                }}
              >
                <StaffCard staff={staff} />
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default StaffDialog;
