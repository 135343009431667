import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Chip,
  Divider,
  Alert,
  styled,
  LinearProgress,
  Tooltip
} from '@mui/material';
import {
  MonitorHeart,
  Thermostat,
  Air,
  Warning,
  TrendingUp,
  TrendingDown,
  ArrowRight,
  RecordVoiceOver
} from '@mui/icons-material';
import { ITask } from 'src/interfaces/new/tasks';

interface VitalSignsData {
  // Blood Pressure
  systolic: number | null;
  diastolic: number | null;
  position: 'sitting' | 'lying' | 'standing';

  // Heart Rate
  pulse: number | null;
  pulseRhythm: 'regular' | 'irregular';

  // Temperature
  temperature: number | null;
  tempRoute: 'oral' | 'tympanic' | 'axillary';

  // Oxygen
  oxygenSaturation: number | null;
  supplementalOxygen: boolean;
  oxygenFlow?: number;

  // Respiration
  respirationRate: number | null;
  respirationType: 'regular' | 'irregular' | 'labored';

  // Additional Info
  beforeAfterMeal: 'before' | 'after' | 'not_relevant';
  consciousness: 'alert' | 'drowsy' | 'confused' | 'unresponsive';
  notes: string;
  concern: boolean;
  followUp: boolean;
  timestamp: string;
}

interface VitalSignsReportProps {
  data: VitalSignsData | any;
  task: Partial<ITask>;
}

const ReportSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& + &': {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const VitalValue = styled(Typography)<{ isAbnormal?: boolean }>(
  ({ theme, isAbnormal }) => ({
    fontSize: '1.5rem',
    fontWeight: 500,
    color: isAbnormal ? theme.palette.error.main : theme.palette.text.primary
  })
);

const ProgressIndicator = styled(LinearProgress)<{ value: number }>(
  ({ theme, value }) => ({
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.grey[200],
    '& .MuiLinearProgress-bar': {
      backgroundColor:
        value < 30
          ? theme.palette.error.main
          : value < 60
          ? theme.palette.warning.main
          : theme.palette.success.main
    }
  })
);

const VitalSignsReport: React.FC<VitalSignsReportProps> = ({ data, task }) => {
  // Helper function to check vital signs ranges
  const checkRange = (
    value: number | null,
    ranges: { min: number; max: number }
  ) => {
    if (value === null) return false;
    return value < ranges.min || value > ranges.max;
  };

  const vitalRanges = {
    systolic: { min: 90, max: 160 },
    diastolic: { min: 60, max: 100 },
    pulse: { min: 60, max: 100 },
    temperature: { min: 36, max: 37.8 },
    oxygenSaturation: { min: 95, max: 100 },
    respirationRate: { min: 12, max: 20 }
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      {/* Header with Time and Status */}
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <MonitorHeart color="primary" fontSize="large" />
        <Box flexGrow={1}>
          <Typography variant="h6">Vital Signs Record</Typography>
          <Typography variant="caption" color="textSecondary">
            {new Date(task.completedDate).toLocaleString()}
          </Typography>
          {/* completed by */}
          <Box display="flex" alignItems="center" gap={1} mt={1}>
            <RecordVoiceOver />
            <Typography variant="body2">
              Completed by: {task.completedBy?.firstName}{' '}
              {task.completedBy?.lastName}
            </Typography>

            {/* role */}
            <Tooltip title={task.completedBy?.role}>
              <Chip
                label={task.completedBy?.role}
                size="small"
                color="primary"
              />
            </Tooltip>
          </Box>
        </Box>
        {data.concern && (
          <Chip
            icon={<Warning />}
            label="Requires Attention"
            color="error"
            size="small"
          />
        )}
      </Box>

      <Divider />

      {/* Blood Pressure */}
      <ReportSection>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="primary" gutterBottom>
              Blood Pressure
            </Typography>
            <Box display="flex" alignItems="baseline" gap={1}>
              <VitalValue
                isAbnormal={
                  checkRange(data.systolic, vitalRanges.systolic) ||
                  checkRange(data.diastolic, vitalRanges.diastolic)
                }
              >
                {data.systolic}/{data.diastolic}
              </VitalValue>
              <Typography>mmHg</Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              Position: {data.position}
            </Typography>
          </Grid>

          {/* Heart Rate */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="primary" gutterBottom>
              Heart Rate
            </Typography>
            <Box display="flex" alignItems="baseline" gap={1}>
              <VitalValue
                isAbnormal={checkRange(data.pulse, vitalRanges.pulse)}
              >
                {data.pulse}
              </VitalValue>
              <Typography>bpm</Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              Rhythm: {data.pulseRhythm}
            </Typography>
          </Grid>
        </Grid>
      </ReportSection>

      {/* Temperature and Oxygen */}
      <ReportSection>
        <Grid container spacing={2}>
          {/* Temperature */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="primary" gutterBottom>
              Temperature
            </Typography>
            <Box display="flex" alignItems="baseline" gap={1}>
              <VitalValue
                isAbnormal={checkRange(
                  data.temperature,
                  vitalRanges.temperature
                )}
              >
                {data.temperature?.toFixed(1)}
              </VitalValue>
              <Typography>°C</Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              Route: {data.tempRoute}
            </Typography>
          </Grid>

          {/* Oxygen Saturation */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="primary" gutterBottom>
              Oxygen Saturation
            </Typography>
            <Box display="flex" alignItems="baseline" gap={1}>
              <VitalValue
                isAbnormal={checkRange(
                  data.oxygenSaturation,
                  vitalRanges.oxygenSaturation
                )}
              >
                {data.oxygenSaturation}
              </VitalValue>
              <Typography>%</Typography>
            </Box>
            {data.oxygenSaturation !== null && (
              <ProgressIndicator
                variant="determinate"
                value={data.oxygenSaturation}
              />
            )}
            {data.supplementalOxygen && (
              <Box mt={1}>
                <Chip
                  size="small"
                  color="info"
                  label={`O₂ ${data.oxygenFlow}L/min`}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </ReportSection>

      {/* Respiration */}
      <ReportSection>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          Respiration
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="baseline" gap={1}>
              <VitalValue
                isAbnormal={checkRange(
                  data.respirationRate,
                  vitalRanges.respirationRate
                )}
              >
                {data.respirationRate}
              </VitalValue>
              <Typography>breaths/min</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="textSecondary">
              Type: {data.respirationType}
            </Typography>
          </Grid>
        </Grid>
      </ReportSection>

      {/* Additional Information */}
      <ReportSection>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          Additional Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body2">
              Consciousness: {data.consciousness}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2">
              Timing: {data.beforeAfterMeal.replace('_', ' ')}
            </Typography>
          </Grid>
          {data.notes && (
            <Grid item xs={12}>
              <Typography variant="body2">Notes: {data.notes}</Typography>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Alerts Section */}
      {(data.concern ||
        data.followUp ||
        Object.keys(vitalRanges).some((key) =>
          checkRange(
            data[key as keyof typeof data] as number | null,
            vitalRanges[key as keyof typeof vitalRanges]
          )
        )) && (
        <ReportSection>
          <Alert
            severity={data.concern ? 'error' : 'warning'}
            sx={{ mb: data.followUp ? 1 : 0 }}
          >
            {data.concern
              ? 'Requires immediate attention'
              : 'Abnormal values detected'}
          </Alert>
          {data.followUp && <Alert severity="info">Follow-up required</Alert>}
        </ReportSection>
      )}

      {/* Timestamp */}
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Typography variant="caption" color="textSecondary">
          Recorded: {new Date(data.timestamp).toLocaleString()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default VitalSignsReport;
