// invoices.ts
import { baseApi } from './base';
import { format } from 'date-fns';
// Types
interface IShiftSummary {
  totalHours: number;
  weekdayHours: number;
  weekendHours: number;
  holidayHours: number;
  totalAmount: number;
  weekdayAmount: number;
  weekendAmount: number;
  holidayAmount: number;
  count: number;
  averageRate: number;
}

interface IProcessedTimesheet {
  _id: string;
  hourlyRate: number;
  hours: number;
  amount: number;
  shiftDate: string;
  shiftType: string;
  carerName: string;
  homeName: string;
  rateType: 'weekday' | 'weekend' | 'holiday';
  breakDuration?: number;
}

interface IInvoiceCalculationResponse {
  timesheets: IProcessedTimesheet[];
  totalAmount: number;
  totalHours: number;
  totalTimesheets: number;
  firstShift: {
    date: string;
    type: string;
  };
  lastShift: {
    date: string;
    type: string;
  };
  summary: {
    byShiftType: { [key: string]: IShiftSummary };
    totalWeekdayHours: number;
    totalWeekendHours: number;
    totalHolidayHours: number;
    totalWeekdayAmount: number;
    totalWeekendAmount: number;
    totalHolidayAmount: number;
    averageHourlyRate: number;
  };
}

interface IInvoice {
  _id: string;
  invoiceNumber: string;
  agencyId: {
    _id: string;
    name: string;
    email: string;
    phone: string;
    address: string;
  };
  homeId: {
    _id: string;
    name: string;
    email: string;
    phone: string;
    address: string;
  };
  startDate: string;
  endDate: string;
  totalAmount: number;
  status: 'pending' | 'paid' | 'cancelled';
  timesheetIds: string[];
  shiftSummary: any;
  createdAt: string;
  updatedAt: string;
}

// Request Types
interface ICalculateInvoiceParams {
  homeId: string;
  startDate: Date;
  endDate: Date;
}

interface ICreateInvoiceParams {
  homeId: string;
  startDate: string;
  endDate: string;
  timesheets: IProcessedTimesheet[];
  totalAmount: number;
  shiftSummary: any;
}

interface IUpdateInvoiceStatusParams {
  invoiceId: string;
  status: 'pending' | 'paid' | 'cancelled';
}

interface IGetInvoicesParams {
  status?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  limit?: number;
}

interface InvoiceTimesheet {
  hourlyRate: number;
  hours: number;
  amount: number;
  shiftDate: string;
  shiftType: string;
  carerName: string;
  homeName: string;
}

interface InvoiceCalculationResponse {
  timesheets: InvoiceTimesheet[];
  totalAmount: number;
  totalTimesheets: number;
  firstShift: {
    date: string;
    type: string;
  };
  lastShift: {
    date: string;
    type: string;
  };
}
interface InvoiceCalculationParams {
  homeId: string;
  startDate: Date;
  endDate: Date;
}

export const invoicesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Create invoice
    createInvoice: builder.mutation<IInvoice, ICreateInvoiceParams>({
      query: (data) => ({
        url: 'invoices',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Invoices', 'Timesheets']
    }),
    getInvoices: builder.query({
      query: () => '/invoices'
    }),
    getInvoiceById: builder.query({
      query: (id) => `/invoices/${id}`
    }),
    updateInvoiceStatus: builder.mutation({
      query: ({ invoiceId, status }) => ({
        url: `/invoices/${invoiceId}/status`,
        method: 'PATCH',
        body: { status }
      })
    }),

    calculateInvoice: builder.query<
      InvoiceCalculationResponse,
      InvoiceCalculationParams
    >({
      query: ({ homeId, startDate, endDate }) => ({
        url: 'invoices/calculate',
        params: {
          homeId,
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd')
        }
      })
    })
  })
});

export const {
  useCreateInvoiceMutation,
  useGetInvoicesQuery,
  useLazyGetInvoicesQuery,
  useGetInvoiceByIdQuery,
  useLazyGetInvoiceByIdQuery,
  useUpdateInvoiceStatusMutation,
  useCalculateInvoiceQuery,
  useLazyCalculateInvoiceQuery
} = invoicesApi;
