import React from 'react';
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Paper,
  CircularProgress,
  IconButton,
  Tooltip,
  LinearProgress,
  alpha,
  styled,
  Alert,
  Skeleton
} from '@mui/material';
import {
  People as PeopleIcon,
  Business as BusinessIcon,
  TrendingUp as TrendingUpIcon,
  Assignment as AssignmentIcon,
  AccessTime as TimeIcon,
  CalendarMonth as CalendarIcon,
  LocalHospital as HospitalIcon,
  PersonSearch as PersonSearchIcon,
  ErrorOutline as ErrorIcon
} from '@mui/icons-material';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import {
  useGetDashboardMetricsQuery,
  useGetStaffingTrendsQuery,
  useGetUpcomingRequirementsQuery
} from 'src/redux/@api/analytics';

// Utility functions for safe value handling
const safeNumber = (value: any, defaultValue = 0): number => {
  if (typeof value !== 'number' || isNaN(value)) return defaultValue;
  return value;
};

const safePercent = (
  value: number | undefined | null,
  total: number | undefined | null
): number => {
  const safeValue = safeNumber(value);
  const safeTotal = safeNumber(total);
  return safeTotal === 0 ? 0 : (safeValue / safeTotal) * 100;
};

const formatCurrency = (value: number | undefined | null): string => {
  return `£${safeNumber(value).toLocaleString()}`;
};

const calculateGrowth = (
  current: number | undefined | null,
  previous: number | undefined | null
): number => {
  const currentValue = safeNumber(current);
  const previousValue = safeNumber(previous);
  return previousValue === 0
    ? 0
    : ((currentValue - previousValue) / previousValue) * 100;
};

// Styled Components
const StyledMetricCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '5px',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5
  }
}));

const StyledIconBox = styled(Box)(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  backgroundColor: alpha(theme.palette.primary.main, 0.1)
}));

const StyledChartCard = styled(Paper)(({ theme }) => ({
  height: '100%',
  '& .apexcharts-canvas': {
    padding: theme.spacing(2)
  }
}));

const StyledProgressBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1)
}));

// Loading Placeholder Component
const MetricCardSkeleton: React.FC = () => (
  <StyledMetricCard elevation={0}>
    <Skeleton variant="circular" width={48} height={48} />
    <Skeleton
      variant="text"
      width="60%"
      height={40}
      style={{ marginBottom: 8 }}
    />
    <Skeleton variant="text" width="40%" />
    <Skeleton
      variant="rectangular"
      width="100%"
      height={4}
      style={{ marginTop: 16 }}
    />
  </StyledMetricCard>
);

const ChartCardSkeleton: React.FC = () => (
  <StyledChartCard>
    <Box sx={{ p: 3 }}>
      <Skeleton variant="text" width="40%" height={32} />
      <Skeleton variant="text" width="60%" />
      <Skeleton variant="rectangular" width="100%" height={350} />
    </Box>
  </StyledChartCard>
);

interface ErrorCardProps {
  message: string;
  retry?: () => void;
}

const ErrorCard: React.FC<ErrorCardProps> = ({ message, retry }) => (
  <Alert
    severity="error"
    action={
      retry && (
        <IconButton color="inherit" size="small" onClick={retry}>
          <ErrorIcon />
        </IconButton>
      )
    }
  >
    {message}
  </Alert>
);

// Main Component
const OrganizationOverviewTab: React.FC = () => {
  const theme = useTheme();

  // RTK Query hooks
  const {
    data: dashboardMetrics,
    isLoading: isDashboardLoading,
    error: dashboardError,
    refetch: refetchDashboard
  } = useGetDashboardMetricsQuery();

  const {
    data: staffingTrends,
    isLoading: isTrendsLoading,
    error: trendsError
  } = useGetStaffingTrendsQuery({ period: 'week' });

  const {
    data: upcomingRequirements,
    isLoading: isRequirementsLoading,
    error: requirementsError
  } = useGetUpcomingRequirementsQuery();

  // Safe data extraction
  const metrics = {
    staff: {
      total: safeNumber(dashboardMetrics?.staffMetrics?.totalStaff),
      active: safeNumber(dashboardMetrics?.staffMetrics?.activeStaff),
      utilization: safeNumber(dashboardMetrics?.staffMetrics?.utilization),
      specializations: dashboardMetrics?.staffMetrics?.specializations || []
    },
    careHomes: {
      total: safeNumber(dashboardMetrics?.careHomeMetrics?.total),
      active: safeNumber(dashboardMetrics?.careHomeMetrics?.active),
      new: safeNumber(dashboardMetrics?.careHomeMetrics?.newThisMonth),
      engagement: safePercent(
        dashboardMetrics?.careHomeMetrics?.active,
        dashboardMetrics?.careHomeMetrics?.total
      )
    },
    shifts: {
      total: safeNumber(dashboardMetrics?.shiftMetrics?.total),
      filled: safeNumber(dashboardMetrics?.shiftMetrics?.filled),
      pending: safeNumber(dashboardMetrics?.shiftMetrics?.pending),
      completed: safeNumber(dashboardMetrics?.shiftMetrics?.completed),
      fillRate: safeNumber(dashboardMetrics?.shiftMetrics?.fillRate),
      completionRate: safeNumber(dashboardMetrics?.shiftMetrics?.completionRate)
    },
    revenue: {
      current: safeNumber(dashboardMetrics?.revenueMetrics?.currentMonth),
      previous: safeNumber(dashboardMetrics?.revenueMetrics?.previousMonth),
      growth: calculateGrowth(
        dashboardMetrics?.revenueMetrics?.currentMonth,
        dashboardMetrics?.revenueMetrics?.previousMonth
      ),
      byType: dashboardMetrics?.revenueMetrics?.byShiftType || {}
    }
  };

  // Chart configurations
  const staffingTrendOptions: ApexOptions = {
    chart: {
      type: 'area',
      toolbar: { show: false },
      zoom: { enabled: false }
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth', width: 2 },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100]
      }
    },
    xaxis: {
      categories:
        staffingTrends?.map((trend) =>
          dayjs(trend?.date || new Date()).format('DD MMM')
        ) || [],
      axisBorder: { show: false },
      axisTicks: { show: false }
    },
    grid: { show: false },
    colors: [theme.palette.primary.main]
  };

  const staffingTrendSeries = [
    {
      name: 'Filled Shifts',
      data: staffingTrends?.map((trend) => safeNumber(trend?.filled)) || []
    }
  ];

  // Loading states
  if (isDashboardLoading || isTrendsLoading || isRequirementsLoading) {
    return (
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {[1, 2, 3, 4].map((index) => (
            <Grid item xs={12} md={3} key={index}>
              <MetricCardSkeleton />
            </Grid>
          ))}
          <Grid item xs={12} md={8}>
            <ChartCardSkeleton />
          </Grid>
          <Grid item xs={12} md={4}>
            <ChartCardSkeleton />
          </Grid>
        </Grid>
      </Box>
    );
  }

  // Error states
  if (dashboardError || trendsError || requirementsError) {
    return (
      <Box sx={{ p: 3 }}>
        <ErrorCard
          message="Error loading dashboard data. Please try again."
          retry={refetchDashboard}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Top Stats Row */}
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} md={3}>
          <StyledMetricCard elevation={0}>
            <StyledIconBox>
              <PeopleIcon color="primary" />
            </StyledIconBox>
            <Typography variant="h4" fontWeight="bold">
              {metrics.staff.total.toLocaleString()}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Active Staff Members
            </Typography>
            <StyledProgressBox>
              <LinearProgress
                variant="determinate"
                value={Math.min(metrics.staff.utilization, 100)}
                sx={{ flex: 1 }}
              />
              <Typography
                variant="caption"
                color={
                  metrics.staff.active > 0 ? 'success.main' : 'text.secondary'
                }
              >
                {metrics.staff.active} active
              </Typography>
            </StyledProgressBox>
          </StyledMetricCard>
        </Grid>

        <Grid item xs={12} md={3}>
          <StyledMetricCard elevation={0}>
            <StyledIconBox>
              <BusinessIcon color="primary" />
            </StyledIconBox>
            <Typography variant="h4" fontWeight="bold">
              {metrics.careHomes.active.toLocaleString()}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Partner Care Homes
            </Typography>
            <StyledProgressBox>
              <LinearProgress
                variant="determinate"
                value={Math.min(metrics.careHomes.engagement, 100)}
                sx={{ flex: 1 }}
              />
              <Tooltip title="New partnerships this month">
                <Typography
                  variant="caption"
                  color={
                    metrics.careHomes.new > 0
                      ? 'success.main'
                      : 'text.secondary'
                  }
                >
                  {metrics.careHomes.new > 0
                    ? `+${metrics.careHomes.new}`
                    : 'No new'}
                </Typography>
              </Tooltip>
            </StyledProgressBox>
          </StyledMetricCard>
        </Grid>

        <Grid item xs={12} md={3}>
          <StyledMetricCard elevation={0}>
            <StyledIconBox>
              <AssignmentIcon color="primary" />
            </StyledIconBox>
            <Typography variant="h4" fontWeight="bold">
              {metrics.shifts.filled.toLocaleString()}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Filled Shifts This Month
            </Typography>
            <StyledProgressBox>
              <LinearProgress
                variant="determinate"
                value={Math.min(metrics.shifts.fillRate, 100)}
                sx={{ flex: 1 }}
              />
              <Tooltip title="Completion rate">
                <Typography
                  variant="caption"
                  color={
                    metrics.shifts.completionRate >= 75
                      ? 'success.main'
                      : 'warning.main'
                  }
                >
                  {metrics.shifts.completionRate.toFixed(1)}% completed
                </Typography>
              </Tooltip>
            </StyledProgressBox>
          </StyledMetricCard>
        </Grid>

        <Grid item xs={12} md={3}>
          <StyledMetricCard elevation={0}>
            <StyledIconBox>
              <TrendingUpIcon color="primary" />
            </StyledIconBox>
            <Typography variant="h4" fontWeight="bold">
              {formatCurrency(metrics.revenue.current)}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Monthly Revenue
            </Typography>
            <StyledProgressBox>
              <LinearProgress
                variant="determinate"
                value={Math.min(Math.abs(metrics.revenue.growth), 100)}
                sx={{
                  flex: 1,
                  '& .MuiLinearProgress-bar': {
                    backgroundColor:
                      metrics.revenue.growth >= 0
                        ? theme.palette.success.main
                        : theme.palette.error.main
                  }
                }}
              />
              <Typography
                variant="caption"
                color={
                  metrics.revenue.growth >= 0 ? 'success.main' : 'error.main'
                }
              >
                {metrics.revenue.growth >= 0 ? '+' : ''}
                {metrics.revenue.growth.toFixed(1)}%
              </Typography>
            </StyledProgressBox>
          </StyledMetricCard>
        </Grid>
      </Grid>

      {/* Charts Row */}
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} md={8}>
          <StyledChartCard>
            <Box sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Staffing Demand Trends
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {staffingTrendSeries[0].data.length > 0
                  ? 'Overview of shift fulfillment over time'
                  : 'No trend data available for the selected period'}
              </Typography>
              {staffingTrendSeries[0].data.length > 0 ? (
                <ReactApexChart
                  options={staffingTrendOptions}
                  series={staffingTrendSeries}
                  type="area"
                  height={350}
                />
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={350}
                  bgcolor={alpha(theme.palette.primary.main, 0.03)}
                  borderRadius={1}
                >
                  <Typography color="text.secondary">
                    No data available
                  </Typography>
                </Box>
              )}
            </Box>
          </StyledChartCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledChartCard>
            <Box sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Staff Utilization
              </Typography>
              {metrics.staff.specializations.length > 0 ? (
                metrics.staff.specializations.map((spec, index) => (
                  <Box key={spec.name || `spec-${index}`} mb={2}>
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography variant="body2">
                        {spec.name || 'Unknown Role'}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {safeNumber(spec.utilization)}%
                      </Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={Math.min(safeNumber(spec.utilization), 100)}
                      sx={{
                        height: 6,
                        borderRadius: 3,
                        backgroundColor: alpha(theme.palette.primary.main, 0.1)
                      }}
                    />
                  </Box>
                ))
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={200}
                  bgcolor={alpha(theme.palette.primary.main, 0.03)}
                  borderRadius={1}
                >
                  <Typography color="text.secondary">
                    No specialization data available
                  </Typography>
                </Box>
              )}
            </Box>
          </StyledChartCard>
        </Grid>
      </Grid>

      {/* Bottom Row */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledChartCard>
            <Box sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Recent Activities
              </Typography>
              {[
                {
                  icon: CalendarIcon,
                  text: `${metrics.shifts.pending} shift${
                    metrics.shifts.pending !== 1 ? 's' : ''
                  } pending assignment`,
                  color:
                    metrics.shifts.pending > 0 ? 'warning.main' : 'success.main'
                },
                {
                  icon: PersonSearchIcon,
                  text: `${metrics.staff.active} staff member${
                    metrics.staff.active !== 1 ? 's' : ''
                  } active this week`
                },
                {
                  icon: HospitalIcon,
                  text:
                    metrics.careHomes.new > 0
                      ? `${metrics.careHomes.new} new care home partnership${
                          metrics.careHomes.new !== 1 ? 's' : ''
                        }`
                      : 'No new partnerships this month'
                }
              ].map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    my: 2
                  }}
                >
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: 1,
                      bgcolor: alpha(theme.palette.primary.main, 0.1)
                    }}
                  >
                    <item.icon color="primary" />
                  </Box>
                  <Typography
                    variant="body2"
                    color={item.color || 'text.primary'}
                  >
                    {item.text}
                  </Typography>
                </Box>
              ))}
            </Box>
          </StyledChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledChartCard>
            <Box sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Upcoming Shift Requirements
              </Typography>
              {Array.isArray(upcomingRequirements) &&
              upcomingRequirements.length > 0 ? (
                upcomingRequirements.map((req, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: 2,
                      borderBottom:
                        index < upcomingRequirements.length - 1
                          ? `1px solid ${theme.palette.divider}`
                          : 'none'
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle2">
                        {req.shiftType || 'Unknown Shift Type'}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {safeNumber(req.required) - safeNumber(req.filled)}{' '}
                        position
                        {safeNumber(req.required) - safeNumber(req.filled) !== 1
                          ? 's'
                          : ''}{' '}
                        needed
                      </Typography>
                    </Box>
                    {req.urgent && (
                      <Box
                        sx={{
                          px: 2,
                          py: 0.5,
                          borderRadius: 1,
                          bgcolor: alpha(theme.palette.error.main, 0.1),
                          color: theme.palette.error.main
                        }}
                      >
                        <Typography variant="caption">Urgent</Typography>
                      </Box>
                    )}
                  </Box>
                ))
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={200}
                  bgcolor={alpha(theme.palette.primary.main, 0.03)}
                  borderRadius={1}
                >
                  <Typography color="text.secondary">
                    No upcoming requirements
                  </Typography>
                </Box>
              )}
            </Box>
          </StyledChartCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrganizationOverviewTab;
