import React, { useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Chip,
  IconButton,
  Stack,
  Divider,
  Alert,
  CircularProgress,
  Tooltip,
  Paper,
  Grid
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  useCreateTaskMutation,
  useCreateBulkTasksMutation
} from 'src/redux/@api/task';
import dayjs, { Dayjs } from 'dayjs';
import { TASK_TYPE_CONFIGURATIONS } from '../interfaces/task-config';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { HelpOutline } from '@mui/icons-material';

interface AddTaskDialogProps {
  open: boolean;
  onClose: () => void;
  onTaskAdded: () => void;
  residentId: string;
}

interface NewTask {
  taskName: string;
  description: string;
  taskType: string;
  frequency: {
    type: 'frequent' | 'daily' | 'weekly' | 'monthly' | 'one-time';
    interval?: number;
    timeOfDay?: string;
  };
  status: 'upcoming';
  dueDate: string;
  resident: string;
}

const ContentSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  '& .section-title': {
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
}));

const TemplateCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4]
  },
  '&.selected': {
    borderColor: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    '& .MuiTypography-subtitle1': {
      color: theme.palette.primary.main
    }
  }
}));

const TimePicker = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'flex-start',
  marginBottom: theme.spacing(2)
}));

const TimeChipsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  minHeight: 40,
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 12,
    backgroudColor: theme.colors.secondary.main
  }
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2)
}));

const TimeChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  '& .MuiChip-deleteIcon': {
    color: theme.palette.primary.contrastText
  }
}));

const AddTaskDialog: React.FC<AddTaskDialogProps> = ({
  open,
  onClose,
  onTaskAdded,
  residentId
}) => {
  const [createTask] = useCreateTaskMutation();
  const [createBulkTasks] = useCreateBulkTasksMutation();

  const [newTask, setNewTask] = useState<NewTask>({
    taskName: '',
    description: '',
    taskType: '',
    frequency: {
      type: 'daily',
      timeOfDay: ''
    },
    status: 'upcoming',
    dueDate: dayjs().format('YYYY-MM-DD'),
    resident: residentId
  });
  const [timeSettings, setTimeSettings] = useState({
    useTimeInterval: false,
    startTime: null as Dayjs | null,
    endTime: null as Dayjs | null,
    intervalHours: 2
  });

  const [useTimeInterval, setUseTimeInterval] = useState(false);
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [tempSelectedTime, setTempSelectedTime] = useState<Dayjs | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

  const handleTemplateSelection = (template: any) => {
    setSelectedTemplate(template);
    setNewTask((prev) => ({
      ...prev,
      taskName: template.name,
      description: template.description || ''
    }));

    if (template.defaultTimes) {
      setSelectedTimes(template.defaultTimes);
    }
  };

  const handleAddTime = () => {
    if (tempSelectedTime) {
      const timeString = tempSelectedTime.format('HH:mm');
      if (!selectedTimes.includes(timeString)) {
        setSelectedTimes([...selectedTimes, timeString].sort());
      }
      setTempSelectedTime(null);
    }
  };

  const handleCreateTasks = async () => {
    try {
      if (!newTask.taskName || !newTask.taskType) {
        setError('Please fill in required fields');
        return;
      }

      let tasksToCreate = [];

      if (timeSettings.useTimeInterval) {
        if (!timeSettings.startTime || !timeSettings.endTime) {
          setError('Please specify both start and end times');
          return;
        }

        // Clone the times to avoid modifying the state
        let currentTime = timeSettings.startTime.clone();
        let endTime = timeSettings.endTime.clone();

        // If end time is before start time, add a day to end time
        if (endTime.isBefore(currentTime)) {
          endTime = endTime.add(1, 'day');
        }

        // Create tasks until we reach or pass the end time
        while (currentTime.isBefore(endTime) || currentTime.isSame(endTime)) {
          tasksToCreate.push({
            ...newTask,
            frequency: {
              ...newTask.frequency,
              timeOfDay: currentTime.format('HH:mm')
            },
            // Add the correct date if it's tomorrow
            dueDate: currentTime.isBefore(timeSettings.startTime)
              ? currentTime.add(1, 'day').format('YYYY-MM-DD')
              : currentTime.format('YYYY-MM-DD')
          });
          currentTime = currentTime.add(timeSettings.intervalHours, 'hour');
        }
      } else {
        if (selectedTimes.length === 0) {
          setError('Please select at least one time');
          return;
        }

        tasksToCreate = selectedTimes.map((time) => {
          const taskTime = dayjs(time, 'HH:mm');
          const now = dayjs();
          const isNextDay =
            taskTime.hour() < now.hour() ||
            (taskTime.hour() === now.hour() &&
              taskTime.minute() < now.minute());

          return {
            ...newTask,
            frequency: {
              ...newTask.frequency,
              timeOfDay: time
            },
            dueDate: isNextDay
              ? now.add(1, 'day').format('YYYY-MM-DD')
              : now.format('YYYY-MM-DD')
          };
        });
      }

      console.log('Tasks to create:', tasksToCreate);

      if (tasksToCreate.length === 0) {
        setError('No valid tasks to create');
        return;
      }

      if (tasksToCreate.length > 1) {
        await createBulkTasks(tasksToCreate).unwrap();
      } else {
        await createTask(tasksToCreate[0]).unwrap();
      }

      onTaskAdded();
      resetForm();
      onClose();
    } catch (error) {
      console.log(error);
      setError(error.data?.message || 'Error creating task(s)');
    }
  };

  const resetForm = () => {
    setNewTask({
      taskName: '',
      description: '',
      taskType: '',
      frequency: { type: 'daily' },
      status: 'upcoming',
      dueDate: dayjs().format('YYYY-MM-DD'),
      resident: residentId
    });
    setSelectedTimes([]);
    setUseTimeInterval(false);
    setError(null);
    setSelectedTemplate(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <StyledDialogTitle>
          <Typography variant="h6">Create Task</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>

        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            {error && (
              <Alert
                severity="error"
                onClose={() => setError(null)}
                sx={{ mb: 2 }}
              >
                {error}
              </Alert>
            )}

            {/* Task Type Selection */}
            <ContentSection>
              <Typography className="section-title" variant="h6">
                Select Task Type
                <Tooltip title="Choose the type of task to create">
                  <HelpOutline fontSize="small" color="action" />
                </Tooltip>
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={newTask.taskType}
                  onChange={(e) =>
                    setNewTask({ ...newTask, taskType: e.target.value })
                  }
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Choose a task type
                  </MenuItem>
                  {Object.entries(TASK_TYPE_CONFIGURATIONS).map(
                    ([type, config]) => (
                      <MenuItem key={type} value={type}>
                        <Typography>
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                        </Typography>
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </ContentSection>

            {/* Template Selection */}
            {newTask.taskType && (
              <ContentSection>
                <Typography className="section-title" variant="h6">
                  Choose Template
                  <Tooltip title="Select a predefined template for this task">
                    <HelpOutline fontSize="small" color="action" />
                  </Tooltip>
                </Typography>
                <Grid container spacing={2}>
                  {TASK_TYPE_CONFIGURATIONS[newTask.taskType].suggestions.map(
                    (template, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <TemplateCard
                          elevation={selectedTemplate === template ? 3 : 1}
                          onClick={() => handleTemplateSelection(template)}
                          className={
                            selectedTemplate === template ? 'selected' : ''
                          }
                        >
                          <Typography variant="subtitle1" gutterBottom>
                            {template.name}
                          </Typography>
                          {template.description && (
                            <Typography variant="body2" color="textSecondary">
                              {template.description}
                            </Typography>
                          )}
                        </TemplateCard>
                      </Grid>
                    )
                  )}
                </Grid>
              </ContentSection>
            )}

            {/* Optional Description */}
            {selectedTemplate && (
              <ContentSection>
                <Typography className="section-title" variant="h6">
                  Additional Details
                </Typography>
                <TextField
                  label="Description (Optional)"
                  multiline
                  rows={3}
                  value={newTask.description}
                  onChange={(e) =>
                    setNewTask({ ...newTask, description: e.target.value })
                  }
                  fullWidth
                  variant="outlined"
                />
              </ContentSection>
            )}

            {/* Time Selection */}
            {selectedTemplate && (
              <ContentSection>
                <Typography className="section-title" variant="h6">
                  Schedule Times
                  <Tooltip title="Set when this task should occur">
                    <HelpOutline fontSize="small" color="action" />
                  </Tooltip>
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={timeSettings.useTimeInterval}
                      onChange={(e) =>
                        setTimeSettings((prev) => ({
                          ...prev,
                          useTimeInterval: e.target.checked
                        }))
                      }
                    />
                  }
                  label="Use Time Range"
                  sx={{ mb: 2 }}
                />

                {timeSettings.useTimeInterval ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <DesktopTimePicker
                        label="Start Time"
                        value={timeSettings.startTime}
                        onChange={(newTime) =>
                          setTimeSettings((prev) => ({
                            ...prev,
                            startTime: newTime
                          }))
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DesktopTimePicker
                        label="End Time"
                        value={timeSettings.endTime}
                        onChange={(newTime) =>
                          setTimeSettings((prev) => ({
                            ...prev,
                            endTime: newTime
                          }))
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Interval</InputLabel>
                        <Select
                          value={timeSettings.intervalHours}
                          onChange={(e) =>
                            setTimeSettings((prev) => ({
                              ...prev,
                              intervalHours: Number(e.target.value)
                            }))
                          }
                        >
                          {[1, 2, 3, 4, 6, 8, 12].map((hours) => (
                            <MenuItem key={hours} value={hours}>
                              Every {hours} hour{hours > 1 ? 's' : ''}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <TimePicker>
                      <DesktopTimePicker
                        label="Select Time"
                        value={tempSelectedTime}
                        onChange={setTempSelectedTime}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                      <Button
                        onClick={handleAddTime}
                        variant="contained"
                        disabled={!tempSelectedTime}
                        startIcon={<AddIcon />}
                        sx={{ height: 56 }}
                      >
                        Add
                      </Button>
                    </TimePicker>

                    <TimeChipsContainer>
                      {selectedTimes.map((time, index) => (
                        <TimeChip
                          key={index}
                          label={time}
                          onDelete={() => {
                            const newTimes = [...selectedTimes];
                            newTimes.splice(index, 1);
                            setSelectedTimes(newTimes);
                          }}
                          icon={<AccessTimeIcon />}
                        />
                      ))}
                    </TimeChipsContainer>
                  </>
                )}
              </ContentSection>
            )}
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleCreateTasks}
            variant="contained"
            disabled={
              !selectedTemplate ||
              (!timeSettings.useTimeInterval && selectedTimes.length === 0) ||
              (timeSettings.useTimeInterval &&
                (!timeSettings.startTime || !timeSettings.endTime))
            }
          >
            Create{' '}
            {timeSettings.useTimeInterval
              ? 'Tasks'
              : selectedTimes.length > 1
              ? 'Tasks'
              : 'Task'}
          </Button>
        </DialogActions>
      </StyledDialog>
    </LocalizationProvider>
  );
};

export default AddTaskDialog;
