import React from 'react';
import { Dialog } from '@mui/material';
// Import all dialog components
import PadCheckDialog from '../dialogs/pad-check';
import MealFluidDialog from '../dialogs/meal';
import MedicationDialog from '../dialogs/medication';
import VitalSignsDialog from '../dialogs/vital-signs';
import BathingDialog from '../dialogs/bathing';
import PositioningDialog from '../dialogs/positioning';
import ExerciseDialog from '../excersice';
import SocialActivityDialog from '../dialogs/social';
import SleepCheckDialog from '../dialogs/sleep-check';
import WoundCareDialog from '../dialogs/wound';
import AssessmentDialog from '../dialogs/assessment';
import DayNightCheckDialog from '../dialogs/daynight';

interface TaskResolveDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (taskData: any) => void;
  task: any;
}

const TaskResolveDialog: React.FC<TaskResolveDialogProps> = ({
  open,
  onClose,
  onResolve,
  task
}) => {
  if (!task) return null;

  const renderTaskDialog = () => {
    switch (task.taskType) {
      case 'pad check':
        return (
          <PadCheckDialog
            open={open}
            onClose={onClose}
            onResolve={onResolve}
            task={task}
          />
        );

      case 'meal':
      case 'fluidintake':
        return (
          <MealFluidDialog
            open={open}
            onClose={onClose}
            onResolve={onResolve}
            task={task}
          />
        );

      case 'medication':
        return (
          <MedicationDialog
            open={open}
            onClose={onClose}
            onResolve={onResolve}
            task={task}
          />
        );

      case 'vital signs':
        return (
          <VitalSignsDialog
            open={open}
            onClose={onClose}
            onResolve={onResolve}
            task={task}
          />
        );

      // case 'hygiene':
      //   return (
      //     <HygieneDialog
      //       open={open}
      //       onClose={onClose}
      //       onResolve={onResolve}
      //       task={task}
      //     />
      //   );

      case 'bathing':
        return (
          <BathingDialog
            open={open}
            onClose={onClose}
            onResolve={onResolve}
            task={task}
          />
        );

      case 'positioning':
        return (
          <PositioningDialog
            open={open}
            onClose={onClose}
            onResolve={onResolve}
            task={task}
          />
        );

      case 'exercise':
        return (
          <ExerciseDialog
            open={open}
            onClose={onClose}
            onResolve={onResolve}
            task={task}
          />
        );

      case 'social':
        return (
          <SocialActivityDialog
            open={open}
            onClose={onClose}
            onResolve={onResolve}
            task={task}
          />
        );

      case 'sleep check':
        return (
          <SleepCheckDialog
            open={open}
            onClose={onClose}
            onResolve={onResolve}
            task={task}
          />
        );

      case 'wound care':
        return (
          <WoundCareDialog
            open={open}
            onClose={onClose}
            onResolve={onResolve}
            task={task}
          />
        );

      // case 'assessment':
      //   return (
      //     <AssessmentDialog
      //       open={open}
      //       onClose={onClose}
      //       onResolve={onResolve}
      //       task={task}
      //     />
      //   );

      // case 'notes':
      //   return (
      //     <ProgressNotesDialog
      //       open={open}
      //       onClose={onClose}
      //       onResolve={onResolve}
      //       task={task}
      //     />
      //   );

      case 'wellbeing':
        return (
          <DayNightCheckDialog
            open={open}
            onClose={onClose}
            onResolve={onResolve}
            task={task}
          />
        );

      default:
        // Fallback generic task dialog for any new task types
        return (
          <PadCheckDialog
            open={open}
            onClose={onClose}
            onResolve={onResolve}
            task={task}
          />
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 2
        }
      }}
    >
      {renderTaskDialog()}
    </Dialog>
  );
};

export default TaskResolveDialog;

// Type for the dialog props that all task dialogs should implement
export interface BaseTaskDialogProps {
  open: boolean;
  onClose: () => void;
  onResolve: (taskData: any) => void;
  task: {
    taskType: string;
    taskName: string;
    description?: string;
    [key: string]: any;
  };
}
