// src/services/staffApi.ts
import { baseApi } from './base';

interface IFavoriteStaff {
  _id: string;
  user: {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    avatarUrl?: string;
  };
  role: string;
  organization: string;
  notes?: {
    staffId: string;
    note: string;
    createdAt: string;
  }[];
}

interface IFavoriteStaffResponse {
  success: boolean;
  favoriteStaff: IFavoriteStaff[];
  notes: {
    staffId: string;
    note: string;
    createdAt: string;
  }[];
}

interface AddFavoriteStaffRequest {
  staffId: string;
}

interface AddFavoriteNoteRequest {
  staffId: string;
  note: string;
}

// Types
interface IStaffAvailability {
  _id: string;
  user: {
    _id: string;
    firstName: string;
    lastName: string;
  };
  role: string;
  organization: string;
  availability: {
    isAvailable: boolean;
    reason?: string;
    periods?: {
      morning: boolean;
      afternoon: boolean;
      evening: boolean;
      night: boolean;
    };
  };
}

interface IAvailableStaffResponse {
  success: boolean;
  data: IStaffAvailability[];
  meta: {
    total: number;
    availableCount: number;
  };
}

interface IStaffAvailabilityMap {
  success: boolean;
  data: Record<string, IStaffAvailability[]>;
  meta: {
    dateRange: {
      startDate: string;
      endDate: string;
      totalDays: number;
    };
  };
}

interface GetAvailableStaffParams {
  shiftPatternId: string;
  shiftDate: string;
  careHomeId: string;
}

interface GetStaffAvailabilityParams {
  startDate: string;
  endDate: string;
}

export const staffApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    removeStaff: builder.mutation<any, string>({
      query: (staffId: string) => ({
        url: `/staffs/${staffId}`,
        method: 'DELETE'
      })
    }),
    getCareStaffs: builder.query<any[], void>({
      query: () => '/staffs/care',
      providesTags: ['StaffsForChat', 'Staffs']
    }),
    getAgencyCareStaffs: builder.query({
      query: (organizationId: string) =>
        `/staffs/agency/${organizationId}/care`,
      providesTags: ['StaffsForChat', 'Staffs']
    }),
    getAdminStaffs: builder.query<any[], void>({
      query: () => '/staffs/admin',
      providesTags: ['Staffs', 'StaffsForChat']
    }),
    getStaffsForAssign: builder.query<any[], { date: string }>({
      query: ({ date }) => `auth/staffs?date=${date}`
    }),
    getCarerResume: builder.query<any, string>({
      query: (carerId) => `auth/${carerId}/carer-resume`
    }),
    searchUsers: builder.query<any[], { query: string; accountType: string }>({
      query: ({ query, accountType }) =>
        `auth/search-users/${accountType}?companyName=${query}`
    }),
    getThirdPartyUsers: builder.query<any[], void>({
      query: () => `auth/third-party`
    }),
    uploadProfilePicture: builder.mutation<any, { userId: string; file: File }>(
      {
        query: ({ userId, file }) => ({
          url: `/picture/${userId}/upload`,
          method: 'POST',
          body: file,
          formData: true
        })
      }
    ),

    getAvailableStaff: builder.query<
      IAvailableStaffResponse,
      GetAvailableStaffParams
    >({
      query: ({ shiftPatternId, shiftDate, careHomeId }) => ({
        url: '/staffs/available-staff',
        method: 'GET',
        params: {
          shiftPatternId,
          shiftDate,
          careHomeId
        }
      }),
      providesTags: ['StaffAvailability']
    }),

    getStaffAvailability: builder.query<
      IStaffAvailabilityMap,
      GetStaffAvailabilityParams
    >({
      query: ({ startDate, endDate }) => ({
        url: '/staffs/staff-availability',
        method: 'GET',
        params: {
          startDate,
          endDate
        }
      }),
      providesTags: ['StaffAvailability']
    }),
    getFavoriteStaff: builder.query<IFavoriteStaffResponse, void>({
      query: () => ({
        url: '/staffs/favorites',
        method: 'GET'
      }),
      providesTags: ['FavoriteStaffs']
    }),

    addFavoriteStaff: builder.mutation<
      { success: boolean },
      AddFavoriteStaffRequest
    >({
      query: (body) => ({
        url: '/staffs/favorites',
        method: 'POST',
        body
      }),
      invalidatesTags: ['FavoriteStaffs']
    }),

    removeFavoriteStaff: builder.mutation<{ success: boolean }, string>({
      query: (staffId) => ({
        url: `/staffs/favorites/${staffId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['FavoriteStaffs']
    }),

    addFavoriteNote: builder.mutation<
      { success: boolean },
      AddFavoriteNoteRequest
    >({
      query: (body) => ({
        url: '/staffs/favorites/note',
        method: 'POST',
        body
      }),
      invalidatesTags: ['FavoriteStaffs']
    }),

    checkIsFavorite: builder.query<
      { success: boolean; isFavorite: boolean },
      string
    >({
      query: (staffId) => ({
        url: `/staffs/favorites/${staffId}/check`,
        method: 'GET'
      }),
      providesTags: (result, error, staffId) => [
        { type: 'FavoriteStatus', id: staffId }
      ]
    })
  })
});

export const {
  useRemoveStaffMutation,
  useGetCareStaffsQuery,
  useGetAgencyCareStaffsQuery,
  useGetAdminStaffsQuery,
  useGetStaffsForAssignQuery,
  useGetCarerResumeQuery,
  useSearchUsersQuery,
  useGetThirdPartyUsersQuery,
  useUploadProfilePictureMutation,
  useGetAvailableStaffQuery,
  useGetStaffAvailabilityQuery,

  useGetFavoriteStaffQuery,
  useAddFavoriteStaffMutation,
  useRemoveFavoriteStaffMutation,
  useAddFavoriteNoteMutation,
  useCheckIsFavoriteQuery
} = staffApi;
