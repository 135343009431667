// taskConfigurations.ts

interface TaskSuggestion {
  name: string;
  description?: string;
  defaultTimes?: string[];
  template?: any;
  requirements?: string[];
}

interface TaskTypeConfig {
  [key: string]: {
    suggestions: TaskSuggestion[];
    defaultFrequency: 'frequent' | 'daily' | 'weekly' | 'monthly' | 'one-time';
    requiresPhotos?: boolean;
    requiresNotes?: boolean;
    requiredFields?: string[];
    template?: any;
  };
}

export const TASK_TYPE_CONFIGURATIONS: TaskTypeConfig = {
  'pad check': {
    suggestions: [
      {
        name: 'Morning Pad Check',
        description: 'Early morning continence assessment',
        defaultTimes: ['06:00', '07:00', '08:00'],
        template: {
          stoolPresent: false,
          stoolType: '',
          stoolColor: '',
          stoolAmount: '',
          urinePresent: false,
          urineColor: '',
          urineAmount: '',
          skinCondition: [],
          padType: '',
          actionTaken: []
        }
      },
      {
        name: 'Night-time Pad Check',
        description: 'Evening continence care',
        defaultTimes: ['20:00', '21:00', '22:00'],
        template: {
          stoolPresent: false,
          stoolType: '',
          stoolColor: '',
          stoolAmount: '',
          urinePresent: false,
          urineColor: '',
          urineAmount: '',
          skinCondition: [],
          padType: '',
          actionTaken: []
        }
      }
    ],
    defaultFrequency: 'frequent',
    requiresPhotos: false
  },

  medication: {
    suggestions: [
      {
        name: 'Morning Medications',
        description: 'Early morning medication round',
        defaultTimes: ['08:00'],
        requirements: ['Double check required', 'Check MAR sheet']
      },
      {
        name: 'Lunchtime Medications',
        description: 'Midday medication round',
        defaultTimes: ['12:00'],
        requirements: ['Double check required', 'Check MAR sheet']
      },
      {
        name: 'Evening Medications',
        description: 'Evening medication round',
        defaultTimes: ['18:00'],
        requirements: ['Double check required', 'Check MAR sheet']
      }
    ],
    defaultFrequency: 'daily',
    requiresNotes: true,
    requiredFields: ['medicationChecks', 'administered', 'route']
  },

  wellbeing: {
    suggestions: [
      {
        name: 'Morning Wellness Check',
        description: 'Morning wellbeing assessment',
        defaultTimes: ['09:00'],
        template: {
          mood: '',
          appetite: '',
          mobility: '',
          orientation: '',
          concerns: []
        }
      },
      {
        name: 'Evening Wellbeing Assessment',
        description: 'Evening wellness check',
        defaultTimes: ['19:00'],
        template: {
          mood: '',
          appetite: '',
          mobility: '',
          orientation: '',
          concerns: []
        }
      }
    ],
    defaultFrequency: 'daily',
    requiresNotes: true,
    requiredFields: ['mood', 'mobility', 'concerns']
  },

  'vital signs': {
    suggestions: [
      {
        name: 'Morning Vitals',
        description: 'Morning vital signs check',
        defaultTimes: ['07:00'],
        template: {
          temperature: '',
          bloodPressure: '',
          heartRate: '',
          respiratoryRate: '',
          oxygenSaturation: ''
        }
      },
      {
        name: 'Evening Vitals',
        description: 'Evening vital signs assessment',
        defaultTimes: ['19:00'],
        template: {
          temperature: '',
          bloodPressure: '',
          heartRate: '',
          respiratoryRate: '',
          oxygenSaturation: ''
        }
      }
    ],
    defaultFrequency: 'daily',
    requiresNotes: true,
    requiredFields: ['temperature', 'bloodPressure', 'heartRate']
  },

  meal: {
    suggestions: [
      {
        name: 'Breakfast Service',
        description: 'Morning meal assistance',
        defaultTimes: ['08:30'],
        template: {
          mealType: 'breakfast',
          dietType: '',
          assistance: '',
          foodConsumed: '',
          fluidIntake: ''
        }
      },
      {
        name: 'Lunch Service',
        description: 'Midday meal assistance',
        defaultTimes: ['12:30'],
        template: {
          mealType: 'lunch',
          dietType: '',
          assistance: '',
          foodConsumed: '',
          fluidIntake: ''
        }
      },
      {
        name: 'Dinner Service',
        description: 'Evening meal assistance',
        defaultTimes: ['17:30'],
        template: {
          mealType: 'dinner',
          dietType: '',
          assistance: '',
          foodConsumed: '',
          fluidIntake: ''
        }
      }
    ],
    defaultFrequency: 'daily',
    requiresNotes: true,
    requiredFields: ['assistance', 'foodConsumed']
  },

  'fluid intake': {
    suggestions: [
      {
        name: 'Morning Hydration',
        description: 'Morning fluid intake monitoring',
        defaultTimes: ['09:00', '10:00', '11:00'],
        template: {
          fluidType: '',
          amount: '',
          assistance: '',
          thickener: false
        }
      },
      {
        name: 'Afternoon Drink',
        description: 'Afternoon hydration check',
        defaultTimes: ['14:00', '15:00', '16:00'],
        template: {
          fluidType: '',
          amount: '',
          assistance: '',
          thickener: false
        }
      }
    ],
    defaultFrequency: 'frequent',
    requiresNotes: true,
    requiredFields: ['amount', 'fluidType']
  },

  hygiene: {
    suggestions: [
      {
        name: 'Morning Hygiene Routine',
        description: 'Morning personal care',
        defaultTimes: ['07:30'],
        template: {
          teethBrushing: false,
          facialCare: false,
          hairCare: false,
          assistance: '',
          products: []
        }
      },
      {
        name: 'Evening Hygiene Assistance',
        description: 'Evening personal care',
        defaultTimes: ['19:30'],
        template: {
          teethBrushing: false,
          facialCare: false,
          hairCare: false,
          assistance: '',
          products: []
        }
      }
    ],
    defaultFrequency: 'daily',
    requiresPhotos: false,
    requiresNotes: true,
    requiredFields: ['assistance']
  },

  positioning: {
    suggestions: [
      {
        name: '2-Hour Turn',
        description: 'Regular repositioning',
        defaultTimes: [
          '08:00',
          '10:00',
          '12:00',
          '14:00',
          '16:00',
          '18:00',
          '20:00'
        ],
        template: {
          position: '',
          pressureAreas: [],
          skinCondition: '',
          assistance: ''
        }
      }
    ],
    defaultFrequency: 'frequent',
    requiresNotes: true,
    requiredFields: ['position', 'pressureAreas']
  },

  'wound care': {
    suggestions: [
      {
        name: 'Dressing Change',
        description: 'Wound dressing change and assessment',
        defaultTimes: ['10:00'],
        template: {
          woundLocation: '',
          woundType: '',
          woundSize: '',
          exudate: '',
          dressingType: '',
          nextChange: ''
        }
      }
    ],
    defaultFrequency: 'daily',
    requiresPhotos: true,
    requiresNotes: true,
    requiredFields: ['woundLocation', 'dressingType']
  },

  assessment: {
    suggestions: [
      {
        name: 'Weekly Health Review',
        description: 'Comprehensive health assessment',
        template: {
          generalHealth: '',
          mobility: '',
          nutrition: '',
          hydration: '',
          skinIntegrity: '',
          continence: '',
          medication: '',
          mentalHealth: '',
          socialEngagement: ''
        }
      }
    ],
    defaultFrequency: 'weekly',
    requiresNotes: true,
    requiredFields: ['generalHealth', 'mobility', 'nutrition']
  }
};

export const TASK_FREQUENCIES = [
  {
    type: 'frequent',
    label: 'Frequent',
    description: 'Multiple times per day at regular intervals'
  },
  {
    type: 'daily',
    label: 'Daily',
    description: 'Once per day at specified times'
  },
  {
    type: 'weekly',
    label: 'Weekly',
    description: 'Once per week'
  },
  {
    type: 'monthly',
    label: 'Monthly',
    description: 'Once per month'
  },
  {
    type: 'one-time',
    label: 'One Time',
    description: 'Single occurrence'
  }
];

export const COMMON_TASK_FIELDS = {
  notes: {
    label: 'Notes',
    required: true,
    multiline: true,
    rows: 3,
    placeholder: 'Enter any additional observations or notes...'
  },
  photos: {
    label: 'Photos',
    required: false,
    maxFiles: 4,
    acceptedTypes: ['image/jpeg', 'image/png']
  },
  escalation: {
    label: 'Escalation Required',
    required: false,
    options: ['None', 'Nurse', 'Doctor', 'Emergency']
  }
};

export const TIME_INTERVALS = [
  { value: 1, label: '1 hour' },
  { value: 2, label: '2 hours' },
  { value: 3, label: '3 hours' },
  { value: 4, label: '4 hours' },
  { value: 6, label: '6 hours' },
  { value: 8, label: '8 hours' },
  { value: 12, label: '12 hours' }
];
