import React, { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement
} from '@stripe/react-stripe-js';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Snackbar,
  TextField,
  Grid,
  Autocomplete
} from '@mui/material';
import { useCreateSubscriptionMutation } from 'src/redux/@api/stripe';
import { countries } from './countries';

const CheckoutForm = ({ selectedPlan, organizationType, planTier }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [createSubscription] = useCreateSubscriptionMutation();

  const [billingDetails, setBillingDetails] = useState({
    name: '',
    email: '',
    phone: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    }
  });

  const handleBillingDetailsChange = (event) => {
    const { name, value } = event.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setBillingDetails((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setBillingDetails((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setError(null);

    if (!stripe || !elements) {
      setError("Stripe hasn't loaded yet. Please try again.");
      setProcessing(false);
      return;
    }

    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        throw new Error('Card element not found');
      }

      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: billingDetails.name,
            email: billingDetails.email,
            phone: billingDetails.phone,
            address: {
              line1: billingDetails.address.street,
              city: billingDetails.address.city,
              state: billingDetails.address.state,
              postal_code: billingDetails.address.zipCode,
              country: billingDetails.address.country
            }
          }
        });

      if (paymentMethodError) {
        throw new Error(paymentMethodError.message);
      }

      if (!paymentMethod) {
        throw new Error('Failed to create payment method');
      }

      const subscriptionResponse = await createSubscription({
        planId: selectedPlan,
        organizationType,
        planTier,
        paymentMethodId: paymentMethod.id,
        billingDetails
      });

      const { subscription, clientSecret } = subscriptionResponse.data;

      if (
        subscription.status === 'active' ||
        subscription.status === 'trialing'
      ) {
        setSucceeded(true);
        setSnackbarOpen(true);
      } else if (clientSecret) {
        const { error: confirmationError } = await stripe.confirmCardPayment(
          clientSecret
        );
        if (confirmationError) {
          throw new Error(confirmationError.message);
        } else {
          setSucceeded(true);
          setSnackbarOpen(true);
        }
      } else {
        throw new Error('Unexpected subscription status');
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred');
    } finally {
      setProcessing(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}
    >
      <Typography variant="h6" gutterBottom>
        Billing Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Name"
            name="name"
            value={billingDetails.name}
            onChange={handleBillingDetailsChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={billingDetails.email}
            onChange={handleBillingDetailsChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Phone"
            name="phone"
            value={billingDetails.phone}
            onChange={handleBillingDetailsChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Street Address"
            name="address.street"
            value={billingDetails.address.street}
            onChange={handleBillingDetailsChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="City"
            name="address.city"
            value={billingDetails.address.city}
            onChange={handleBillingDetailsChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="State/Province"
            name="address.state"
            value={billingDetails.address.state}
            onChange={handleBillingDetailsChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="ZIP/Postal Code"
            name="address.zipCode"
            value={billingDetails.address.zipCode}
            onChange={handleBillingDetailsChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="country-select"
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.label}
            value={
              countries.find(
                (country) => country.code === billingDetails.address.country
              ) || null
            }
            onChange={(event, newValue) => {
              handleBillingDetailsChange({
                target: {
                  name: 'address.country',
                  value: newValue ? newValue.code : ''
                }
              });
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.label} ({option.code})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password' // disable autocomplete and autofill
                }}
              />
            )}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.23)'
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.87)'
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main'
                }
              }
            }}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Payment Information
      </Typography>
      <CardElement />

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!stripe || processing || succeeded}
        sx={{ mt: 3 }}
      >
        {processing ? <CircularProgress size={24} /> : 'Subscribe'}
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false);
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          Your subscription is active!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CheckoutForm;
