import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  useTheme,
  IconButton,
  TextField,
  SelectChangeEvent,
  useMediaQuery,
  Typography,
  Autocomplete,
  Tabs,
  Tab
} from '@mui/material';
import {
  FilterList as FilterIcon,
  Close as CloseIcon,
  FilterList
} from '@mui/icons-material';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  useApproveTimesheetMutation,
  useLazyGetTimesheetsQuery
} from 'src/redux/@api/timesheet';
import { useAppSelector } from 'src/redux/hook';
import { ITimesheetResponse } from 'src/interfaces/timesheet';
import { useGetLinkedOrganizationsQuery } from 'src/redux/@api/organisation';
import {
  useGetOtherShiftPatternsQuery,
  useGetYourShiftPatternsQuery
} from 'src/redux/@api/shift';
import TimesheetList from './timesheet-components/timesheet-list';
import { useGetCareStaffsQuery } from 'src/redux/@api/staff';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import RateAndReviewDialog from './timesheet-components/review';
import { useTimesheetWebSocket } from 'src/components/core/ws/timesheet-hook';
import { wsHostname } from 'src/api/api';

interface FilterOptions {
  organizationId: string;
  careUserId: string;
  startDate: Date;
  endDate: Date;
  shiftPatternId: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`timesheet-tabpanel-${index}`}
      aria-labelledby={`timesheet-tab-${index}`}
      {...other}
      style={{ height: '100%', overflow: 'auto' }}
    >
      {value === index && <Box sx={{ height: '100%' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `timesheet-tab-${index}`,
    'aria-controls': `timesheet-tabpanel-${index}`
  };
}

export function HomeTimesheets() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [currentTimesheet, setCurrentTimesheet] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [paginatedTimesheets, setPaginatedTimesheets] = useState<
    ITimesheetResponse[]
  >([]);

  const userState = useAppSelector((state) => state.userState);
  const currentOrganization = userState.currentOrganization;

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    organizationId: 'all',
    careUserId: 'all',
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    shiftPatternId: ''
  });

  const [getTimesheets, { data: timesheetResponse, isLoading }] =
    useLazyGetTimesheetsQuery();
  const [approveTimesheet] = useApproveTimesheetMutation();

  const getStatusFromTabIndex = (index: number): string => {
    switch (index) {
      case 1:
        return 'pending';
      case 2:
        return 'approved';
      case 3:
        return 'rejected';
      default:
        return 'all';
    }
  };

  const loadTimesheets = useCallback(
    async (pageNumber: number, isInitial: boolean = false) => {
      if (isLoadingMore) return;

      setIsLoadingMore(true);
      try {
        const result = await getTimesheets({
          status: getStatusFromTabIndex(tabValue),
          page: pageNumber,
          limit: 20,
          ...filterOptions
        }).unwrap();

        const filteredData = filterTimesheets(result.data);

        if (isInitial) {
          setPaginatedTimesheets(filteredData || []);
        } else {
          setPaginatedTimesheets((prev) => [...prev, ...(filteredData || [])]);
        }

        setHasMore((result.pagination?.totalPages || 0) > pageNumber);
        setPage(pageNumber);
      } catch (error) {
        console.error('Error loading timesheets:', error);
      } finally {
        setIsLoadingMore(false);
      }
    },
    [filterOptions, tabValue, getTimesheets]
  );

  useEffect(() => {
    loadTimesheets(1, true);
  }, [filterOptions, tabValue]);

  const handleScroll = useCallback(
    (event: Event) => {
      const target = event.target as HTMLDivElement;
      if (!target) return;

      const reachedBottom =
        Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <
        1;

      if (reachedBottom && hasMore && !isLoadingMore) {
        loadTimesheets(page + 1);
      }
    },
    [hasMore, isLoadingMore, page, loadTimesheets]
  );

  useEffect(() => {
    const currentScrollRef = scrollRef.current;
    if (currentScrollRef) {
      currentScrollRef.addEventListener('scroll', handleScroll);
      return () => currentScrollRef.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const filterTimesheets = (timesheets: ITimesheetResponse[] = []) => {
    let filtered = timesheets;

    if (filterOptions.organizationId !== 'all') {
      filtered = filtered.filter((timesheet) => {
        if (filterOptions.organizationId === currentOrganization._id) {
          return !timesheet.agency;
        } else {
          return (
            timesheet.agency?._id === filterOptions.organizationId ||
            timesheet.shift_?.homeId === filterOptions.organizationId
          );
        }
      });
    }

    filtered = filtered.filter((timesheet) => {
      const shiftDate = new Date(timesheet.shift_?.date || '');
      return (
        shiftDate >= filterOptions.startDate &&
        shiftDate <= filterOptions.endDate
      );
    });

    if (filterOptions.shiftPatternId) {
      filtered = filtered.filter(
        (timesheet) =>
          timesheet.shift_?.shiftPattern._id === filterOptions.shiftPatternId
      );
    }

    return filtered;
  };

  const handleFilterChange = (field: keyof FilterOptions, value: any) => {
    setFilterOptions((prev) => ({
      ...prev,
      [field]: value
    }));
    setPage(1);
    setPaginatedTimesheets([]);
    setHasMore(true);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPage(1);
    setPaginatedTimesheets([]);
    setHasMore(true);
  };

  // Queries for filter options
  const { data: linkedOrganizations = [] } =
    useGetLinkedOrganizationsQuery('agency');
  const { data: otherShiftPatterns = [] } = useGetOtherShiftPatternsQuery(
    filterOptions.organizationId,
    {
      skip:
        !filterOptions.organizationId ||
        !filterDialogOpen ||
        filterOptions.organizationId === currentOrganization._id
    }
  );

  const { data: careStaffs = [] } = useGetCareStaffsQuery();

  const { data: yourShiftPatterns = [] } = useGetYourShiftPatternsQuery(
    currentOrganization._id,
    {
      skip: !filterDialogOpen
    }
  );

  const [rateAndReviewDialogOpen, setRateAndReviewDialogOpen] = useState(false);

  // Timesheet approval handlers
  const handleApproveTimesheet = (timesheet) => {
    setCurrentTimesheet(timesheet);
    setRateAndReviewDialogOpen(true);
  };

  const handleRateAndReview = async (rating, review) => {
    try {
      await approveTimesheet({
        timesheetId: currentTimesheet?._id,
        rating,
        review
      }).unwrap();
      setPaginatedTimesheets((prevTimesheets) =>
        prevTimesheets.filter((t) => t._id !== currentTimesheet?._id)
      );
      setRateAndReviewDialogOpen(false);
      dispatch(
        showSnack({
          message: 'Timesheet approved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      dispatch(
        showSnack({ message: 'Error approving timesheet', color: 'error' })
      );
    }
  };

  const handleApproveWithoutReview = async (timesheetId) => {
    try {
      await approveTimesheet({
        timesheetId: timesheetId,
        rating: null,
        review: null
      }).unwrap();
      setPaginatedTimesheets((prevTimesheets) =>
        prevTimesheets.map((t) =>
          t._id === timesheetId ? { ...t, status: 'approved' } : t
        )
      );
      dispatch(
        showSnack({
          message: 'Timesheet approved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      dispatch(
        showSnack({
          message: error.data.message || 'Error approving timesheets',
          color: 'error'
        })
      );
    }
  };

  const handleResetFilters = () => {
    setFilterOptions({
      organizationId: 'all',
      careUserId: 'all',
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      shiftPatternId: ''
    });
  };

  const renderTimesheetList = () => (
    <Box
      p={1}
      ref={scrollRef}
      sx={{
        height: `calc(100vh - ${theme.header.height} - 40px - 60px)`,
        overflow: 'auto'
      }}
    >
      <Grid container spacing={1} alignItems="flex-start">
        <TimesheetList
          timesheets={paginatedTimesheets}
          userState={userState}
          currentOrganization={currentOrganization}
          onApproveTimesheet={handleApproveTimesheet}
          onApproveWithoutReview={handleApproveWithoutReview}
        />
        {isLoadingMore && (
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center', p: 2 }}>
              <Typography>Loading more timesheets...</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${theme.header.height})`
      }}
      p={1}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', height: '50px' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="timesheet tabs"
          variant={isMobile ? 'fullWidth' : 'standard'}
        >
          <Tab label="All Timesheets" {...a11yProps(0)} />
          <Tab label="Pending" {...a11yProps(1)} />
          <Tab label="Approved" {...a11yProps(2)} />
          <Tab label="Rejected" {...a11yProps(3)} />
        </Tabs>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          px: 2,
          height: '50px'
        }}
      >
        <IconButton onClick={() => setFilterDialogOpen(true)} color="primary">
          <FilterList />
        </IconButton>
        {timesheetResponse && (
          <Typography variant="body2" color="textSecondary">
            Showing {paginatedTimesheets.length} of{' '}
            {timesheetResponse?.pagination?.total} timesheets
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          overflow: 'hidden'
        }}
      >
        <TabPanel value={tabValue} index={0}>
          {renderTimesheetList()}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {renderTimesheetList()}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          {renderTimesheetList()}
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          {renderTimesheetList()}
        </TabPanel>
      </Box>

      {/* Filter Dialog */}
      <Dialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Filter Timesheets
          <IconButton
            onClick={() => setFilterDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Organization</InputLabel>
                <Select
                  value={filterOptions.organizationId}
                  onChange={(e) =>
                    handleFilterChange('organizationId', e.target.value)
                  }
                  label="Organization"
                >
                  <MenuItem value="all">All Organizations</MenuItem>
                  <MenuItem value={currentOrganization._id}>
                    {currentOrganization.name} (Current)
                  </MenuItem>
                  {linkedOrganizations.map((org) => (
                    <MenuItem key={org._id} value={org._id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {filterOptions.organizationId === currentOrganization._id && (
              <Grid item xs={12}>
                <Autocomplete
                  options={careStaffs}
                  getOptionLabel={(option) =>
                    `${option.user.firstName} ${option.user.lastName}`
                  }
                  onChange={(event, newValue) => {
                    handleFilterChange(
                      'careUserId',
                      newValue ? newValue.user._id : 'all'
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Carer" fullWidth />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={filterOptions.startDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      handleFilterChange('startDate', newValue);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date"
                  value={filterOptions.endDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      handleFilterChange('endDate', newValue);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  minDate={filterOptions.startDate}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Shift Pattern</InputLabel>
                <Select
                  value={filterOptions.shiftPatternId}
                  onChange={(e) =>
                    handleFilterChange('shiftPatternId', e.target.value)
                  }
                  label="Shift Pattern"
                >
                  <MenuItem value="">All Patterns</MenuItem>
                  {filterOptions.organizationId === currentOrganization._id
                    ? yourShiftPatterns.map((pattern) => (
                        <MenuItem key={pattern._id} value={pattern._id}>
                          {pattern.name}
                        </MenuItem>
                      ))
                    : otherShiftPatterns.map((pattern) => (
                        <MenuItem key={pattern._id} value={pattern._id}>
                          {pattern.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleResetFilters}>
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={() => setFilterDialogOpen(false)}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      {/* Rate and Review Dialog */}
      <RateAndReviewDialog
        open={rateAndReviewDialogOpen}
        onClose={() => setRateAndReviewDialogOpen(false)}
        timesheet={currentTimesheet}
        onSubmit={handleRateAndReview}
      />

      {/* Loading State */}
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Typography>Loading timesheets...</Typography>
        </Box>
      )}
    </Box>
  );
}

export default HomeTimesheets;
