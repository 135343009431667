import React, { useState, useCallback, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Fab,
  Box,
  Avatar,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  LinearProgress
} from '@mui/material';
import { Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import AddResidentDialog from 'src/components/core/dialogs/add-residents';
import { useAppSelector } from 'src/redux/hook';
import TaskManagementComponent from '../home/views/task-management';
import { useGetResidentsQuery } from 'src/redux/@api/resident';
import TaskManagementDialog from '../home/views/task-management';
import { wsHostname } from 'src/api/api';
import { useDispatch } from 'react-redux';
import { tasksApi } from 'src/redux/@api/task';
import { updateTaskStatus } from 'src/redux/reducers/residents';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

export interface ITaskSummary {
  upcoming: number;
  pending: number;
  overdue: number;
  total: number;
  urgency: 'white' | 'green' | 'amber' | 'red';
}

export interface IResident {
  _id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  roomNumber: string;
  admissionDate: Date;
  medicalConditions: string[];
  allergies: string[];
  group: string | null;
  emergencyContact: {
    name: string;
    relationship: string;
    phone: string;
  };
  tasks: string[];
  taskSummary: ITaskSummary;
  profilePictureUrl?: string;
  taskUrgency: 'white' | 'green' | 'amber' | 'red';
  carePlan: any;
}

const POLLING_INTERVAL = 10000; // 10 seconds

const ResidentPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const homeId = useAppSelector(
    (state) => state.userState.currentOrganization._id
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedResident, setSelectedResident] = useState<IResident | null>(
    null
  );

  const {
    data: residents,
    isLoading,
    refetch
  } = useGetResidentsQuery(undefined, {
    pollingInterval: POLLING_INTERVAL
  });
  const [taskManagementDialogOpen, setTaskManagementDialogOpen] =
    useState(false);

  const getResidentStatus = useCallback(
    (resident: IResident) => {
      const { taskUrgency, taskSummary } = resident;
      let color: string;
      let progress: number;

      switch (taskUrgency) {
        case 'red':
          color = theme.palette.error.main;
          progress = 100;
          break;
        case 'amber':
          color = theme.palette.warning.main;
          progress = 66;
          break;
        case 'green':
          color = theme.palette.success.main;
          progress = 33;
          break;
        default:
          color = theme.palette.grey[300];
          progress = 0;
      }

      let text = 'No tasks';
      if (taskSummary.overdue > 0) {
        text = `${taskSummary.overdue} overdue task${
          taskSummary.overdue > 1 ? 's' : ''
        }`;
      } else if (taskSummary.pending > 0) {
        text = `${taskSummary.pending} pending task${
          taskSummary.pending > 1 ? 's' : ''
        }`;
      } else if (taskSummary.upcoming > 0) {
        text = `${taskSummary.upcoming} upcoming task${
          taskSummary.upcoming > 1 ? 's' : ''
        }`;
      }

      return { color, progress, text };
    },
    [theme]
  );

  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connected, setConnected] = useState(false);
  const wsRef = React.useRef<WebSocket | null>(null);
  const reconnectTimeout = React.useRef<NodeJS.Timeout | null>(null);
  const currentUser = useAppSelector((state) => state.userState.user);
  const currentOrganization = useAppSelector(
    (state) => state.userState.currentOrganization
  );

  const hapticsVibrate = async () => {
    await Haptics.vibrate();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const connectWebSocket = () => {
      console.log('Initializing WebSocket connection for timesheet scanning');
      // Clean up existing connection
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }

      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      console.log('Initializing WebSocket connection for timesheet scanning');

      const ws = new WebSocket(
        `${wsHostname}/ws-tasks?userId=${currentUser._id}&orgId=${currentOrganization?._id}`
      );

      ws.onopen = () => {
        console.log('WebSocket connected for timesheet scanning');
        setConnected(true);
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          handleWebSocketMessage(data);
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      ws.onclose = (event) => {
        console.log('WebSocket disconnected:', event);
        setConnected(false);

        // Only reconnect if we're still scanning and it wasn't a clean closure
        if (!event.wasClean) {
          console.log('Scheduling reconnection...');
          reconnectTimeout.current = setTimeout(() => {
            console.log('Attempting to reconnect...');
            connectWebSocket();
          }, 3000);
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      wsRef.current = ws;
      setSocket(ws);
    };

    connectWebSocket();

    // Cleanup function
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      setSocket(null);
      setConnected(false);
    };
  }, [currentUser._id, currentOrganization._id]);

  const handleWebSocketMessage = async (data: any) => {
    if (data.type === 'taskUpdate') {
      const taskUpdate = data.data;

      console.log('Received task update:', taskUpdate);

      dispatch(tasksApi.util.invalidateTags(['tasks']));

      dispatch(
        updateTaskStatus({
          residentId: taskUpdate.residentId,
          oldStatus: taskUpdate.oldStatus,
          newStatus: taskUpdate.status
        })
      );
      await hapticsVibrate();
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={4}
      >
        <Typography variant="h4" component="h1">
          Residents
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={() => refetch()}
        >
          Refresh
        </Button>
      </Box>

      <Box
        sx={{
          height: `calc(100vh - ${theme.spacing(20)})`,
          overflowY: 'auto',
          [theme.breakpoints.down('sm')]: {
            height: 'auto',
            overflowY: 'visible'
          }
        }}
      >
        <Grid container spacing={2}>
          {residents?.map((resident: IResident) => {
            const status = getResidentStatus(resident);
            return (
              <Grid
                item
                xs={6}
                sm={4}
                md={2}
                key={resident._id}
                sx={{
                  height: isMobile ? '180px' : '200px',
                  mb: isMobile ? 2 : 0
                }}
              >
                <Card
                  onClick={() => {
                    setSelectedResident(resident);
                    setTaskManagementDialogOpen(true);
                  }}
                  sx={{
                    padding: 0,
                    position: 'relative',
                    cursor: 'pointer',
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.05)'
                    },
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%'
                  }}
                >
                  <CardContent
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      padding: '3px !important',
                      zIndex: 1,
                      height: '100%'
                    }}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 0
                      }}
                    >
                      <Avatar
                        src={resident.profilePictureUrl}
                        alt={`${resident.firstName} ${resident.lastName}`}
                        sx={{
                          width: '100%',
                          height: '70%',
                          margin: '0 auto',
                          borderRadius: '10px !important'
                        }}
                      />
                      <LinearProgress
                        variant="determinate"
                        value={status.progress}
                        sx={{
                          width: '100%',
                          height: 4,
                          borderRadius: 2,
                          my: 0.5,
                          backgroundColor: theme.palette.grey[300],
                          '& .MuiLinearProgress-bar': {
                            borderRadius: 2,
                            backgroundColor: status.color
                          }
                        }}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '30%'
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          textAlign="center"
                          sx={{
                            zIndex: 1000,
                            lineHeight: 1,
                            fontSize: isMobile ? '0.7rem' : '0.8rem'
                          }}
                        >
                          {`${resident.firstName} ${resident.lastName}`}
                        </Typography>
                        <Typography
                          sx={{
                            height: 'fit-content !important',
                            fontSize: isMobile ? '0.6rem' : '0.7rem'
                          }}
                        >
                          {resident.roomNumber}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      {selectedResident && (
        <TaskManagementDialog
          open={taskManagementDialogOpen}
          onClose={() => {
            setTaskManagementDialogOpen(false);
            setSelectedResident(null);
          }}
          resident={selectedResident}
        />
      )}
    </Container>
  );
};

export default ResidentPage;
