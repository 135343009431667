import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';
import moment from 'moment';
import { Close, Download } from '@mui/icons-material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useGetLinkedOranizationByIdQuery } from 'src/redux/@api/organisation';
import { useCreateInvoiceMutation } from 'src/redux/@api/invoices';
import SaveWarningDialog from './warning-invoice';

// Styled Components
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-root': {
    padding: theme.spacing(1.5),
    '@media print': {
      padding: theme.spacing(1),
      fontSize: '10pt'
    }
  }
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  height: 80,
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  '@media print': {
    height: 60
  }
}));

// Interfaces
interface Timesheet {
  _id: string;
  shiftDate: string;
  shiftType: string;
  carerName: string;
  homeName: string;
  hourlyRate: number;
  hours: number;
  amount: number;
  isHoliday?: boolean;
}

interface ShiftSummaryData {
  count: number;
  totalHours: number;
  weekdayHours: number;
  weekendHours: number;
  holidayHours: number;
  weekdayRate: number;
  weekendRate: number;
  holidayRate: number;
  totalAmount: number;
}

interface Address {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

type ShiftSummary = Record<string, ShiftSummaryData>;

interface InvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  timesheets: any[];
  totalAmount: number;
  home: {
    homeId: string;
    homeName: string;
    homeAddress: string;
    homeEmail: string;
    homePhone: string;
  };
  selectedStartDate: string;
  selectedEndDate: string;
}

const InvoiceDialog: React.FC<InvoiceDialogProps> = ({
  open,
  onClose,
  timesheets,
  totalAmount,
  home,
  selectedStartDate,
  selectedEndDate
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const invoiceRef = useRef<HTMLDivElement>(null);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSaveWarning, setShowSaveWarning] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState<string>(
    `INV-${moment().format('YYYYMMDD-HHmmss')}`
  );

  // Queries
  const { data: linkedOrganization, isError: isOrgError } =
    useGetLinkedOranizationByIdQuery(home.homeId, { skip: !open });

  const [createInvoice] = useCreateInvoiceMutation();

  // Calculate shift summary
  const shiftSummary: ShiftSummary = timesheets.reduce((acc, timesheet) => {
    const shiftType = timesheet.shiftType;
    const isWeekend = moment(timesheet.shiftDate).isoWeekday() > 5;
    const isHoliday = timesheet.isHoliday;

    if (!acc[shiftType]) {
      acc[shiftType] = {
        count: 0,
        totalHours: 0,
        weekdayHours: 0,
        weekendHours: 0,
        holidayHours: 0,
        weekdayRate: 0,
        weekendRate: 0,
        holidayRate: timesheet.hourlyRate * 1.5,
        totalAmount: 0
      };
    }

    acc[shiftType].count += 1;
    acc[shiftType].totalHours += timesheet.hours;
    acc[shiftType].totalAmount += timesheet.amount;

    if (isHoliday) {
      acc[shiftType].holidayHours += timesheet.hours;
    } else if (isWeekend) {
      acc[shiftType].weekendHours += timesheet.hours;
      acc[shiftType].weekendRate = timesheet.hourlyRate;
    } else {
      acc[shiftType].weekdayHours += timesheet.hours;
      acc[shiftType].weekdayRate = timesheet.hourlyRate;
    }

    return acc;
  }, {} as ShiftSummary);

  const handleSave = async (downloadAfterSave: boolean = false) => {
    try {
      setIsSaving(true);

      console.log(timesheets);

      const response = await createInvoice({
        homeId: home.homeId,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        timesheets: timesheets.map((timesheet) => timesheet._id),
        totalAmount,
        shiftSummary
      }).unwrap();

      setInvoiceNumber(
        response.invoiceNumber || `INV-${moment().format('YYYYMMDD-HHmmss')}`
      );

      if (downloadAfterSave) {
        await generatePDF();
      }

      // Show success message
      // ... (using your snackbar or notification system)

      if (!downloadAfterSave) {
        onClose();
      }
    } catch (error) {
      console.error('Failed to save invoice:', error);
      // Show error message
      // ... (using your snackbar or notification system)
    } finally {
      setIsSaving(false);
      setShowSaveWarning(false);
    }
  };

  const generatePDF = async () => {
    if (!invoiceRef.current) return;

    try {
      setIsPdfGenerating(true);

      // Wait for elements to be fully rendered
      await new Promise((resolve) => setTimeout(resolve, 500));

      const element = invoiceRef.current;
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        backgroundColor: '#FFFFFF',
        imageTimeout: 30000,
        logging: true,
        onclone: (clonedDoc) => {
          const container = clonedDoc.querySelector(
            '.pdf-content'
          ) as HTMLElement;
          if (container) {
            container.style.width = '800px';
            container.style.backgroundColor = '#FFFFFF';
            container.style.padding = '20px';
          }

          // Update logo in cloned document if exists
          const logoImg = clonedDoc.querySelector(
            '.company-logo'
          ) as HTMLImageElement;
          if (logoImg && linkedOrganization?.logoUrl) {
            logoImg.src = linkedOrganization.logoUrl;
            logoImg.crossOrigin = 'anonymous';
            logoImg.style.display = 'block';
            logoImg.style.visibility = 'visible';
            logoImg.style.opacity = '1';
            logoImg.style.maxWidth = '200px';
            logoImg.style.maxHeight = '60px';
            logoImg.style.objectFit = 'contain';
          }
        }
      });

      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        compress: true
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const margins = 10;

      const imgWidth = pdfWidth - 2 * margins;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = margins;

      // First page
      pdf.addImage(
        imgData,
        'JPEG',
        margins,
        position,
        imgWidth,
        imgHeight,
        undefined,
        'FAST'
      );

      heightLeft -= pdfHeight - 2 * margins;

      // Add additional pages if content overflows
      while (heightLeft >= 0) {
        pdf.addPage();
        pdf.addImage(
          imgData,
          'JPEG',
          margins,
          position - imgHeight + margins * 2,
          imgWidth,
          imgHeight,
          undefined,
          'FAST'
        );
        heightLeft -= pdfHeight - 2 * margins;
      }

      pdf.save(
        `invoice_${linkedOrganization?.name || home.homeName}_${moment().format(
          'YYYYMMDD'
        )}.pdf`
      );
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsPdfGenerating(false);
    }
  };

  const handleDownload = async () => {
    setShowSaveWarning(true);
  };

  const handleSaveAndDownload = async () => {
    await handleSave(true);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { minHeight: '80vh' }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>

      <DialogContent>
        <Box ref={invoiceRef}>
          {/* Company & Invoice Details */}
          <Box sx={{ mb: 4 }}>
            <LogoContainer>
              {linkedOrganization?.logoUrl && (
                <img
                  src={require('src/assets/logos/logo_white_md.png')}
                  alt="Company Logo"
                  style={{
                    maxHeight: '60px',
                    maxWidth: '200px',
                    objectFit: 'contain'
                  }}
                />
              )}
            </LogoContainer>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h4">
                  {linkedOrganization?.name || home.homeName}
                </Typography>
                {linkedOrganization?.address && (
                  <>
                    <Typography variant="body2">
                      {linkedOrganization.address.street}
                    </Typography>
                    <Typography variant="body2">
                      {linkedOrganization.address.city},{' '}
                      {linkedOrganization.address.state}
                    </Typography>
                    <Typography variant="body2">
                      {linkedOrganization.address.zipCode},{' '}
                      {linkedOrganization.address.country}
                    </Typography>
                  </>
                )}
                <Typography variant="body2">
                  {linkedOrganization?.email || home.homeEmail}
                </Typography>
                <Typography variant="body2">{home.homePhone}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ textAlign: { xs: 'left', md: 'right' } }}>
                  <Typography variant="h6">Invoice #{invoiceNumber}</Typography>
                  <Typography variant="body2">
                    Date: {moment().format('MMMM D, YYYY')}
                  </Typography>
                  <Typography variant="body2">
                    Period: {moment(selectedStartDate).format('MMM D')} -{' '}
                    {moment(selectedEndDate).format('MMM D, YYYY')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Total Amount Display */}
          <Box
            sx={{
              mb: 3,
              p: 2,
              borderRadius: 1
            }}
          >
            <Typography variant="h5">
              Total Amount: £{totalAmount.toFixed(2)}
            </Typography>
            <Typography variant="subtitle2">
              Period: {moment(selectedStartDate).format('MMM D')} -{' '}
              {moment(selectedEndDate).format('MMM D, YYYY')}
            </Typography>
          </Box>

          {/* Shift Summary */}
          <Typography variant="h6" gutterBottom>
            Shift Summary
          </Typography>
          <StyledTableContainer sx={{ mb: 4 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Shift Type</TableCell>
                  <TableCell align="right">Count</TableCell>
                  <TableCell align="right">Weekday Hours</TableCell>
                  <TableCell align="right">Weekend Hours</TableCell>
                  <TableCell align="right">Holiday Hours</TableCell>
                  <TableCell align="right">Total Hours</TableCell>
                  <TableCell align="right">Amount (£)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(shiftSummary).map(([shiftType, data]) => (
                  <TableRow key={shiftType} hover>
                    <TableCell>{shiftType}</TableCell>
                    <TableCell align="right">{data.count}</TableCell>
                    <TableCell align="right">
                      {data.weekdayHours.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {data.weekendHours.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {data.holidayHours.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {data.totalHours.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {data.totalAmount.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ '& td': { fontWeight: 'bold' } }}>
                  <TableCell colSpan={6} align="right">
                    Total Amount
                  </TableCell>
                  <TableCell align="right">£{totalAmount.toFixed(2)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledTableContainer>

          {/* Detailed Allocation */}
          <Typography variant="h6" gutterBottom>
            Detailed Allocation
          </Typography>
          <StyledTableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Shift Type</TableCell>
                  <TableCell>Carer Name</TableCell>
                  <TableCell align="right">Hours</TableCell>
                  <TableCell align="right">Rate (£)</TableCell>
                  <TableCell align="right">Amount (£)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timesheets.map((timesheet, index) => (
                  <TableRow key={index} hover>
                    <TableCell>
                      {moment(timesheet.shiftDate).format('MMM D, YYYY')}
                    </TableCell>
                    <TableCell>{timesheet.shiftType}</TableCell>
                    <TableCell>{timesheet.carerName}</TableCell>
                    <TableCell align="right">
                      {timesheet.hours.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {timesheet.hourlyRate.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {timesheet.amount.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
        <Button
          variant="outlined"
          startIcon={
            isPdfGenerating ? <CircularProgress size={20} /> : <Download />
          }
          onClick={handleDownload}
          disabled={isPdfGenerating || isSaving}
        >
          {isPdfGenerating ? 'Generating...' : 'Download PDF'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave(false)}
          disabled={isSaving || isPdfGenerating}
          startIcon={isSaving ? <CircularProgress size={20} /> : null}
        >
          {isSaving ? 'Saving...' : 'Save Invoice'}
        </Button>
      </DialogActions>
      <SaveWarningDialog
        open={showSaveWarning}
        onClose={() => setShowSaveWarning(false)}
        onSave={async () => {
          await handleSave(false);
          setShowSaveWarning(false);
        }}
        onSaveAndDownload={async () => {
          await handleSave(true);
          setShowSaveWarning(false);
        }}
        isSaving={isSaving}
      />
    </Dialog>
  );
};

export default InvoiceDialog;
