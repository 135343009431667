import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Chip,
  Divider,
  Alert,
  styled,
  LinearProgress,
  Tooltip
} from '@mui/material';
import {
  Bathtub,
  Warning,
  CheckCircle,
  AccessibilityNew,
  Timer,
  Person,
  Info,
  Water,
  RecordVoiceOver
} from '@mui/icons-material';
import { ITask } from 'src/interfaces/new/tasks';

interface BathingData {
  // Basic Information
  bathingType: 'full_bath' | 'shower' | 'bed_bath' | 'strip_wash';
  bathingPreference: string;
  waterTemperature: 'warm' | 'hot' | 'cool';
  temperatureChecked: boolean;

  // Assistance Details
  assistanceLevel: 'independent' | 'minimal' | 'moderate' | 'full';
  caregiverCount: number;
  equipmentUsed: string[];
  timeSpent: number;

  // Care Details
  bodyPartsWashed: string[];
  hairWashed: boolean;
  hairCareProducts: string[];
  skinCareProducts: string[];
  oralCare: boolean;
  nailCare: boolean;
  shavingCompleted: boolean;

  // Skin Assessment
  skinAssessment: {
    condition: string;
    concerns: string[];
    pressureAreas: string[];
    bruising: boolean;
    rashes: boolean;
  };

  // Mobility & Safety
  mobilityLevel: string;
  transferMethod: string;
  fallRisk: boolean;
  safetyMeasures: string[];

  // Resident Response
  residentMood: string;
  cooperation: 'cooperative' | 'resistant' | 'agitated' | 'calm';
  painReported: boolean;
  painLocation?: string;
  painLevel?: number;

  // Completion Details
  clothingChanged: boolean;
  roomCleaned: boolean;
  linensChanged: boolean;
  completionTime: string;
  concerns: boolean;
  notes: string;
}

interface BathingReportProps {
  data: BathingData | any;
  task?: Partial<ITask>;
}

const ReportSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& + &': {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const BathingReport: React.FC<BathingReportProps> = ({ data, task }) => {
  const getBathTypeIcon = () => {
    switch (data.bathingType) {
      case 'full_bath':
        return '🛁';
      case 'shower':
        return '🚿';
      case 'bed_bath':
        return '🛏️';
      case 'strip_wash':
        return '🧴';
      default:
        return '🛁';
    }
  };

  const formatTimeSpent = (minutes: number) => {
    if (minutes < 60) return `${minutes} minutes`;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      {/* Header */}
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography fontSize="2rem">{getBathTypeIcon()}</Typography>
          <Box>
            <Typography variant="h6">
              {data.bathingType
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {new Date(task.completedDate).toLocaleString()}
            </Typography>
            {/* completed by */}
            <Box display="flex" alignItems="center" gap={1} mt={1}>
              <RecordVoiceOver />
              <Typography variant="body2">
                Completed by: {task.completedBy?.firstName}{' '}
                {task.completedBy?.lastName}
              </Typography>

              {/* role */}
              <Tooltip title={task.completedBy?.role}>
                <Chip
                  label={task.completedBy?.role}
                  size="small"
                  color="primary"
                />
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box flexGrow={1} />
        {data.concerns && (
          <Chip
            icon={<Warning />}
            label="Requires Review"
            color="error"
            size="small"
          />
        )}
      </Box>

      <Divider />

      {/* Basic Information */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Care Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" gutterBottom>
              Assistance & Time
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              <Chip
                icon={<Person />}
                label={`${data.caregiverCount} Caregiver${
                  data.caregiverCount > 1 ? 's' : ''
                }`}
                size="small"
              />
              <Chip
                icon={<Timer />}
                label={formatTimeSpent(data.timeSpent)}
                size="small"
              />
              <Chip
                icon={<Water />}
                label={`Water: ${data.waterTemperature}`}
                color={data.temperatureChecked ? 'success' : 'default'}
                size="small"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" gutterBottom>
              Equipment Used
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {data.equipmentUsed.map((equipment, index) => (
                <Chip
                  key={index}
                  label={equipment}
                  size="small"
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </ReportSection>

      {/* Care Provided */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Care Provided
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Areas Washed
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {data.bodyPartsWashed.map((part, index) => (
                <Chip key={index} label={part} size="small" color="success" />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap" gap={2} mt={1}>
              {data.hairWashed && (
                <Chip label="Hair Washed" size="small" icon={<CheckCircle />} />
              )}
              {data.oralCare && (
                <Chip label="Oral Care" size="small" icon={<CheckCircle />} />
              )}
              {data.nailCare && (
                <Chip label="Nail Care" size="small" icon={<CheckCircle />} />
              )}
              {data.shavingCompleted && (
                <Chip label="Shaving" size="small" icon={<CheckCircle />} />
              )}
            </Box>
          </Grid>

          {data.skinCareProducts.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Products Used
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {data.skinCareProducts.map((product, index) => (
                  <Chip
                    key={index}
                    label={product}
                    size="small"
                    variant="outlined"
                  />
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Skin Assessment */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Skin Assessment
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              Overall Condition: {data.skinAssessment.condition}
            </Typography>
          </Grid>

          {data.skinAssessment.concerns.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="error" gutterBottom>
                Concerns Identified
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {data.skinAssessment.concerns.map((concern, index) => (
                  <Chip
                    key={index}
                    label={concern}
                    size="small"
                    color="error"
                  />
                ))}
              </Box>
            </Grid>
          )}

          {data.skinAssessment.pressureAreas.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="warning" gutterBottom>
                Pressure Areas
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {data.skinAssessment.pressureAreas.map((area, index) => (
                  <Chip key={index} label={area} size="small" color="warning" />
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Mobility & Safety */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Mobility & Safety
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body2">
              Mobility Level: {data.mobilityLevel}
            </Typography>
            <Typography variant="body2">
              Transfer Method: {data.transferMethod}
            </Typography>
          </Grid>

          {data.fallRisk && (
            <Grid item xs={12}>
              <Alert severity="warning">
                Fall Risk Identified - Safety Measures:
                <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                  {data.safetyMeasures.map((measure, index) => (
                    <Chip
                      key={index}
                      label={measure}
                      size="small"
                      color="warning"
                    />
                  ))}
                </Box>
              </Alert>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Resident Response */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Resident Response
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body2">Mood: {data.residentMood}</Typography>
            <Typography variant="body2">
              Cooperation: {data.cooperation}
            </Typography>
          </Grid>

          {data.painReported && (
            <Grid item xs={12}>
              <Alert severity="warning">
                Pain Reported
                <Typography variant="body2">
                  Location: {data.painLocation}
                </Typography>
                {data.painLevel !== undefined && (
                  <Typography variant="body2">
                    Level: {data.painLevel}/10
                  </Typography>
                )}
              </Alert>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Completion Details */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Completion Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {data.clothingChanged && (
                <Chip
                  label="Clothing Changed"
                  size="small"
                  icon={<CheckCircle />}
                />
              )}
              {data.roomCleaned && (
                <Chip
                  label="Room Cleaned"
                  size="small"
                  icon={<CheckCircle />}
                />
              )}
              {data.linensChanged && (
                <Chip
                  label="Linens Changed"
                  size="small"
                  icon={<CheckCircle />}
                />
              )}
            </Box>
          </Grid>

          {data.notes && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Notes
              </Typography>
              <Typography variant="body2">{data.notes}</Typography>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Timestamp */}
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Typography variant="caption" color="textSecondary">
          Completed: {new Date(data.completionTime).toLocaleString()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default BathingReport;
