import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  CircularProgress,
  styled,
  Alert,
  Step,
  Stepper,
  StepLabel,
  Paper,
  Divider,
  Chip,
  Avatar,
  useTheme
} from '@mui/material';
import {
  Email,
  Business,
  Home,
  ArrowForward,
  CheckCircleOutline,
  ErrorOutline
} from '@mui/icons-material';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useCreateOrganizationInvitationMutation } from 'src/redux/@api/organisation';

interface InviteOrganizationDialogProps {
  open: boolean;
  onClose: () => void;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`
}));

const OrganizationCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1]
}));

const InviteOrganizationDialog: React.FC<InviteOrganizationDialogProps> = ({
  open,
  onClose
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userState = useAppSelector((state) => state.userState);

  const [createinvitation] = useCreateOrganizationInvitationMutation();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [invitationStatus, setInvitationStatus] = useState<
    'idle' | 'success' | 'error'
  >('idle');

  const steps = ['Enter Details', 'Preview', 'Send Invitation'];

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleNext = () => {
    if (activeStep === 0 && !validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    setError('');
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleSendInvitation = async () => {
    setIsLoading(true);
    setError('');

    try {
      // TODO: Replace with your actual API call
      await createinvitation({
        organizationId: userState.currentOrganization._id,
        email,
        metadata: {
          invitingOrgName: userState.currentOrganization.name,
          invitingOrgType: userState.currentOrganization.type
        }
      }).unwrap();

      setInvitationStatus('success');
      dispatch(
        showSnack({
          message: 'Invitation sent successfully',
          color: 'success'
        })
      );
      setActiveStep(2);
    } catch (error) {
      setInvitationStatus('error');
      setError('Failed to send invitation. Please try again.');
      dispatch(
        showSnack({
          message: 'Error sending invitation',
          color: 'error'
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setEmail('');
    setError('');
    setActiveStep(0);
    setInvitationStatus('idle');
    onClose();
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
              Enter the email address of the organization you'd like to connect
              with.
            </Typography>

            <StyledTextField
              autoFocus
              label="Organization Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!error}
              disabled={isLoading}
              placeholder="Enter organization's email address"
              helperText={
                error || 'The invitation will be sent to this email address'
              }
            />
          </>
        );

      case 1:
        return (
          <>
            <Typography variant="subtitle1" gutterBottom>
              Preview Invitation
            </Typography>

            <PreviewContainer>
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" color="textSecondary">
                  From Organization
                </Typography>
                <OrganizationCard>
                  <Avatar
                    src={userState.currentOrganization.logoUrl}
                    sx={{ width: 48, height: 48, mr: 2 }}
                  />
                  <Box>
                    <Typography variant="subtitle1">
                      {userState.currentOrganization.name}
                    </Typography>
                    <Chip
                      icon={
                        userState.currentOrganization.type === 'home' ? (
                          <Home />
                        ) : (
                          <Business />
                        )
                      }
                      label={userState.currentOrganization.type}
                      size="small"
                      color={
                        userState.currentOrganization.type === 'home'
                          ? 'primary'
                          : 'secondary'
                      }
                      sx={{ mt: 1 }}
                    />
                  </Box>
                </OrganizationCard>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                <Divider sx={{ flex: 1 }} />
                <ArrowForward sx={{ mx: 2 }} />
                <Divider sx={{ flex: 1 }} />
              </Box>

              <Box>
                <Typography variant="subtitle2" color="textSecondary">
                  To
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  {email}
                </Typography>
              </Box>
            </PreviewContainer>
          </>
        );

      case 2:
        return (
          <Box sx={{ textAlign: 'center', py: 3 }}>
            {invitationStatus === 'success' ? (
              <>
                <CheckCircleOutline
                  sx={{ fontSize: 64, color: 'success.main', mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  Invitation Sent Successfully!
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  We'll notify you when they accept the invitation.
                </Typography>
              </>
            ) : invitationStatus === 'error' ? (
              <>
                <ErrorOutline
                  sx={{ fontSize: 64, color: 'error.main', mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  Failed to Send Invitation
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {error}
                </Typography>
              </>
            ) : (
              <CircularProgress />
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Invite Organization</Typography>
        <Stepper activeStep={activeStep} sx={{ mt: 2 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>

      <DialogContent>
        {error && activeStep !== 2 && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {renderStepContent(activeStep)}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          {activeStep === 2 ? 'Done' : 'Cancel'}
        </Button>
        {activeStep < 2 && (
          <>
            {activeStep > 0 && <Button onClick={handleBack}>Back</Button>}
            <Button
              variant="contained"
              onClick={activeStep === 1 ? handleSendInvitation : handleNext}
              disabled={isLoading || !email}
              startIcon={isLoading ? <CircularProgress size={20} /> : <Email />}
            >
              {activeStep === 1 ? 'Send Invitation' : 'Next'}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InviteOrganizationDialog;
