import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Paper,
  Box,
  Typography,
  Chip,
  useTheme,
  useMediaQuery,
  styled,
  IconButton
} from '@mui/material';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import { taskTypes } from './task-util';
import {
  useDeleteTaskMutation,
  useGetPendingTasksQuery,
  useGetTasksForResidentQuery
} from 'src/redux/@api/task';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useDispatch } from 'react-redux';
import { IResident } from 'src/interfaces/resident';
import { useGetHistoricTasksQuery } from 'src/redux/@api/resident';

const MobileCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  aspectRatio: '1',
  width: '100%',
  height: '100%'
}));

interface TaskGridProps {
  tab: string;
  resident: any;
  isHistorical?: boolean;
  startDate?: Date;
  endDate?: Date;
  onTaskClick: (task: any) => void;
}

const taskTypeEmojis: Record<string, string> = {
  'pad check': '🩲',
  wellbeing: '😊',
  medication: '💊',
  'vital signs': '❤️',
  meal: '🍽️',
  'fluid intake': '💧',
  hygiene: '🧼',
  bathing: '🛁',
  positioning: '🔄',
  exercise: '🏃',
  social: '👥',
  'sleep check': '😴',
  'wound care': '🏥'
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  backfaceVisibility: 'hidden',
  transformStyle: 'preserve-3d',
  willChange: 'transform',
  transition: 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  width: '300px' // Fixed width for cards
}));

const TaskStackGrid: React.FC<TaskGridProps> = ({
  tab,
  resident,
  isHistorical = false,
  // start sdate should be the start of the month
  startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  endDate = new Date(),
  onTaskClick
}) => {
  const theme = useTheme();
  const [tasks, setTasks] = useState<any[]>([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [deleteTask] = useDeleteTaskMutation();

  const dispatch = useDispatch();

  const { data: allTasks, refetch: refetchTasks } = useGetTasksForResidentQuery(
    resident._id,
    {
      skip: !open || tab !== 'all'
    }
  );
  const { data: pendingTasks, refetch: refetchPendingTasks } =
    useGetPendingTasksQuery(resident._id, {
      skip: !open || tab !== 'pending'
    });

  const { data: historicTasksData, refetch } = useGetHistoricTasksQuery({
    residentId: resident._id,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString()
  });

  useEffect(() => {
    if (allTasks && tab === 'all') {
      setTasks(allTasks.data || []);
    }

    if (pendingTasks && tab === 'pending') {
      setTasks(pendingTasks.data || []);
    }

    if (historicTasksData && tab === 'historical') {
      setTasks(historicTasksData.data || []);
    }

    return () => {
      setTasks([]);
    };
  }, [allTasks, pendingTasks, historicTasksData]);

  const groupedTasks = useMemo(() => {
    const groups = tasks?.reduce((acc, task) => {
      const type = task.taskType;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(task);
      return acc;
    }, {} as Record<string, any[]>);

    Object.keys(groups).forEach((type) => {
      groups[type].sort((a, b) => {
        const dateA = isHistorical
          ? new Date(a.completedDate)
          : (new Date(a.dueDate) as any);
        const dateB = isHistorical
          ? new Date(b.completedDate)
          : (new Date(b.dueDate) as any);
        return dateA - dateB;
      });
    });

    return groups;
  }, [tasks, isHistorical]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'upcoming':
        return theme.palette.info.main;
      case 'pending':
        return theme.palette.warning.main;
      case 'completed':
        return theme.palette.success.main;
      case 'overdue':
        return theme.palette.error.main;
      default:
        return theme.palette.grey[500];
    }
  };

  const getTaskIcon = (type: string) => {
    const taskType = taskTypes.find((t) => t.type === type);
    return taskType ? taskType.icon : <Icon icon="mdi:account" />;
  };
  if (isMobile) {
    return (
      <Box sx={{ width: '100%' }}>
        {Object.entries(groupedTasks).map(([type, taskList]: any) => (
          <Box
            key={`${type}-${isHistorical ? 'hist' : 'active'}`}
            sx={{ mb: 3 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  color: theme.palette.text.primary,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <span style={{ fontSize: '1.2em' }}>
                  {taskTypeEmojis[type] || '📋'}
                </span>
                {type}
              </Typography>
            </Box>

            <Grid container spacing={1.5}>
              {taskList.map((task) => (
                <Grid
                  item
                  xs={6}
                  key={`${task._id}-${isHistorical ? 'hist' : 'active'}`}
                >
                  <MobileCard
                    elevation={2}
                    sx={{
                      cursor: isHistorical ? 'default' : 'pointer',
                      '&:active': {
                        transform: 'scale(0.98)'
                      },
                      position: 'relative',
                      minHeight: '140px'
                    }}
                    onClick={() => !isHistorical && onTaskClick(task)}
                  >
                    {!isHistorical && (
                      <IconButton
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: 4,
                          right: 4,
                          padding: '4px'
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteTask(task._id);
                          dispatch(
                            showSnack({
                              message: 'Task deleted',
                              color: 'success'
                            })
                          );
                        }}
                      >
                        <Icon icon="fluent:delete-32-filled" width={16} />
                      </IconButton>
                    )}

                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        padding: '0 8px'
                      }}
                    >
                      <span style={{ fontSize: '5rem', marginBottom: '8px' }}>
                        {taskTypeEmojis[type] || '📋'}
                      </span>
                    </Box>

                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 8,
                        left: 8,
                        right: 8
                      }}
                    >
                      <Chip
                        size="small"
                        label={task.status}
                        sx={{
                          backgroundColor: getStatusColor(task.status),
                          color: 'white',
                          fontSize: '0.7rem',
                          height: 20,
                          width: '100%',
                          mb: 1
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                          color: theme.palette.text.secondary,
                          justifyContent: 'center'
                        }}
                      >
                        <Icon icon="ph:clock-duotone" width={14} />
                        {dayjs(
                          isHistorical ? task.completedDate : task.dueDate
                        ).format('HH:mm')}
                      </Typography>
                    </Box>
                  </MobileCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      {Object.entries(groupedTasks).map(([type, taskList]: any) => (
        <Box key={type} sx={{ mb: 0 }}>
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontWeight: 600,
              color: theme.palette.text.primary
            }}
          >
            {type}
          </Typography>

          <Box
            sx={{
              position: 'relative',
              width: '100%',
              overflowX: 'auto',
              overflowY: 'hidden',
              WebkitOverflowScrolling: 'touch',
              '::-webkit-scrollbar': {
                height: 6
              },
              '::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.grey[100],
                borderRadius: 3
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[400],
                borderRadius: 3,
                '&:hover': {
                  backgroundColor: theme.palette.grey[500]
                }
              }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: '4px 0 20px 0',
                minWidth: 'min-content'
              }}
            >
              {taskList.map((task, index) => (
                <Box
                  key={task._id}
                  sx={{
                    position: 'relative',
                    marginLeft: index === 0 ? 0 : '-200px',
                    transition: 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      zIndex: taskList.length + 1
                    },
                    '&:hover ~ div': {
                      transform: 'translateX(200px)'
                    },
                    zIndex: taskList.length - index
                  }}
                >
                  <StyledPaper
                    elevation={2}
                    sx={{
                      cursor: isHistorical ? 'default' : 'pointer'
                    }}
                    onClick={() => onTaskClick(task)}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Box sx={{ mr: 1 }}>{getTaskIcon(task.taskType)}</Box>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 500,
                          fontSize: isMobile ? '0.875rem' : '1rem',
                          flexGrow: 1,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {task.taskName}
                      </Typography>
                      <Box>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteTask(task._id);
                            dispatch(
                              showSnack({
                                message: 'Task deleted',
                                color: 'success'
                              })
                            );
                          }}
                        >
                          <Icon icon="fluent:delete-32-filled" />
                        </IconButton>
                      </Box>
                    </Box>

                    <Box sx={{ my: 1 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          lineHeight: 1.5
                        }}
                      >
                        {isHistorical ? task.notes : task.description}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        gap: 1,
                        mt: 'auto'
                      }}
                    >
                      <Chip
                        size="small"
                        label={task.status}
                        sx={{
                          backgroundColor: getStatusColor(task.status),
                          color: 'white',
                          fontSize: '0.75rem',
                          height: 24
                        }}
                      />
                      <Chip
                        icon={<Icon icon="ph:clock-duotone" />}
                        label={dayjs(
                          isHistorical ? task.completedDate : task.dueDate
                        ).format('DD/MM/YYYY HH:mm')}
                        size="small"
                        sx={{
                          fontSize: '0.75rem',
                          height: 24
                        }}
                      />
                    </Box>
                  </StyledPaper>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default TaskStackGrid;
