import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/redux/hook';
import {
  Snackbar,
  Slide,
  SlideProps,
  Box,
  Typography,
  IconButton
} from '@mui/material';
import {
  CheckCircle as SuccessIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { hideSnack } from 'src/redux/reducers/snack/snack-slice';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const getSnackbarConfig = (color: string) => {
  switch (color) {
    case 'success':
      return {
        icon: <SuccessIcon />,
        backgroundColor: 'success.main',
        color: 'success.contrastText'
      };
    case 'error':
    case 'danger':
      return {
        icon: <ErrorIcon />,
        backgroundColor: 'error.main',
        color: 'error.contrastText'
      };
    case 'warning':
      return {
        icon: <WarningIcon />,
        backgroundColor: 'warning.main',
        color: 'warning.contrastText'
      };
    case 'info':
    default:
      return {
        icon: <InfoIcon />,
        backgroundColor: 'info.main',
        color: 'info.contrastText'
      };
  }
};

export function CustomSnackBar() {
  const snackState = useAppSelector((state) => state.snack);
  const dispatch = useDispatch();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideSnack());
  };

  const snackbarConfig = getSnackbarConfig(snackState.color);

  return (
    <Snackbar
      open={snackState.open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      {snackState.open && (
        <Box
          sx={{
            minWidth: { xs: '90vw', sm: 'auto' },
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            bgcolor: snackbarConfig.backgroundColor,
            color: snackbarConfig.color,
            px: 2,
            py: 1.5,
            borderRadius: 1,
            boxShadow: 3,
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {snackbarConfig.icon}
          </Box>

          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: 'whitesmoke' }}
          >
            {snackState.message}
          </Typography>

          <IconButton
            size="small"
            onClick={handleClose}
            sx={{
              color: 'inherit',
              ml: 1,
              p: 0.5,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>

          {/* Progress bar */}
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '2px',
              bgcolor: 'rgba(255, 255, 255, 0.3)',
              '&::after': {
                content: '""',
                position: 'absolute',
                left: 0,
                bottom: 0,
                height: '100%',
                width: '100%',
                bgcolor: 'rgba(255, 255, 255, 0.7)',
                animation: 'progress 5.5s linear',
                '@keyframes progress': {
                  '0%': {
                    width: '100%'
                  },
                  '100%': {
                    width: '0%'
                  }
                }
              }
            }}
          />
        </Box>
      )}
    </Snackbar>
  );
}

export default CustomSnackBar;
