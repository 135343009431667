import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Chip,
  Divider,
  Alert,
  styled,
  Tooltip
} from '@mui/material';
import {
  LocalHospital,
  Warning,
  PhotoCamera,
  Assignment,
  TrendingUp,
  TrendingDown,
  CheckCircle,
  Water,
  RecordVoiceOver
} from '@mui/icons-material';
import { ITask } from 'src/interfaces/new/tasks';

interface WoundCareData {
  // Wound Details
  woundLocation: string;
  woundType: string;
  woundStage?: string;
  woundSize: {
    length: number;
    width: number;
    depth: number;
  };

  // Wound Assessment
  appearance: string[];
  exudate: {
    amount: 'none' | 'light' | 'moderate' | 'heavy';
    type: string;
    odor: boolean;
  };
  surroundingSkin: string[];
  painLevel: number;

  // Treatment
  cleansingMethod: string;
  dressingType: string[];
  medicationsApplied: string[];

  // Progress
  healing: 'improving' | 'static' | 'deteriorating';
  comparedToPrevious: string;
  photographTaken: boolean;

  // Next Care
  nextDressing: string;
  specialInstructions: string;
  nursesReview: boolean;

  concerns: boolean;
  notes: string;
  timestamp: string;
}

interface WoundCareReportProps {
  data: WoundCareData | any;
  task: Partial<ITask>;
}

const ReportSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& + &': {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const WoundCareReport: React.FC<WoundCareReportProps> = ({ data, task }) => {
  const getHealingIcon = (status: string) => {
    switch (status) {
      case 'improving':
        return <TrendingUp color="success" />;
      case 'deteriorating':
        return <TrendingDown color="error" />;
      default:
        return null;
    }
  };

  const getWoundSize = () => {
    return `${data.woundSize.length}cm x ${data.woundSize.width}cm x ${data.woundSize.depth}cm`;
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      {/* Header */}
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <LocalHospital color="primary" fontSize="large" />
        <Box>
          <Typography variant="h6">
            Wound Care Record - {data.woundType}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Location: {data.woundLocation}
          </Typography>
          {data.woundStage && (
            <Typography variant="caption" color="error">
              Stage: {data.woundStage}
            </Typography>
          )}
        </Box>
        <Box flexGrow={1} />
        {data.concerns && (
          <Chip
            icon={<Warning />}
            label="Requires Review"
            color="error"
            size="small"
          />
        )}
        <Typography variant="caption" color="textSecondary">
          {new Date(task.completedDate).toLocaleString()}
        </Typography>
        {/* completed by */}
        <Box display="flex" alignItems="center" gap={1} mt={1}>
          <RecordVoiceOver />
          <Typography variant="body2">
            Completed by: {task.completedBy?.firstName}{' '}
            {task.completedBy?.lastName}
          </Typography>

          {/* role */}
          <Tooltip title={task.completedBy?.role}>
            <Chip label={task.completedBy?.role} size="small" color="primary" />
          </Tooltip>
        </Box>
      </Box>

      <Divider />

      {/* Wound Assessment */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Wound Assessment
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Size
            </Typography>
            <Typography variant="body2">{getWoundSize()}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Appearance
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {data.appearance.map((app, index) => (
                <Chip
                  key={index}
                  label={app}
                  size="small"
                  color={
                    ['granulating', 'epithelializing'].includes(
                      app.toLowerCase()
                    )
                      ? 'success'
                      : ['sloughy', 'necrotic'].includes(app.toLowerCase())
                      ? 'error'
                      : 'default'
                  }
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Exudate
            </Typography>
            <Box display="flex" gap={1} flexWrap="wrap">
              <Chip
                icon={<Water />}
                label={`Amount: ${data.exudate.amount}`}
                color={
                  data.exudate.amount === 'heavy'
                    ? 'error'
                    : data.exudate.amount === 'moderate'
                    ? 'warning'
                    : 'default'
                }
                size="small"
              />
              {data.exudate.type && (
                <Chip label={`Type: ${data.exudate.type}`} size="small" />
              )}
              {data.exudate.odor && (
                <Chip label="Odor Present" color="warning" size="small" />
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Surrounding Skin
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {data.surroundingSkin.map((condition, index) => (
                <Chip
                  key={index}
                  label={condition}
                  size="small"
                  color={
                    ['healthy', 'intact'].includes(condition.toLowerCase())
                      ? 'success'
                      : ['macerated', 'inflamed'].includes(
                          condition.toLowerCase()
                        )
                      ? 'warning'
                      : 'default'
                  }
                />
              ))}
            </Box>
          </Grid>

          {data.painLevel > 0 && (
            <Grid item xs={12}>
              <Alert severity="warning">Pain Level: {data.painLevel}/10</Alert>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Treatment */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Treatment Applied
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Cleansing
            </Typography>
            <Chip label={data.cleansingMethod} size="small" color="primary" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Dressing
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {data.dressingType.map((dressing, index) => (
                <Chip
                  key={index}
                  label={dressing}
                  size="small"
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Box>
          </Grid>

          {data.medicationsApplied.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Medications Applied
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {data.medicationsApplied.map((med, index) => (
                  <Chip key={index} label={med} size="small" color="info" />
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Progress */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Progress Assessment
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" gap={1}>
              {getHealingIcon(data.healing)}
              <Typography>Healing Status: {data.healing}</Typography>
            </Box>
            {data.comparedToPrevious && (
              <Typography variant="body2" color="textSecondary" mt={1}>
                Changes: {data.comparedToPrevious}
              </Typography>
            )}
          </Grid>

          {data.photographTaken && (
            <Grid item xs={12}>
              <Chip
                icon={<PhotoCamera />}
                label="Photograph Taken"
                size="small"
              />
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Next Care */}
      <ReportSection>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Next Care Plan
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Next Dressing Change
            </Typography>
            <Typography variant="body2">
              {new Date(data.nextDressing).toLocaleString()}
            </Typography>
          </Grid>

          {data.specialInstructions && (
            <Grid item xs={12}>
              <Alert severity="info">
                <Typography variant="subtitle2">
                  Special Instructions:
                </Typography>
                {data.specialInstructions}
              </Alert>
            </Grid>
          )}

          {data.nursesReview && (
            <Grid item xs={12}>
              <Alert severity="warning">Requires nurse review</Alert>
            </Grid>
          )}
        </Grid>
      </ReportSection>

      {/* Notes */}
      {data.notes && (
        <ReportSection>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Additional Notes
          </Typography>
          <Typography variant="body2">{data.notes}</Typography>
        </ReportSection>
      )}

      {/* Timestamp */}
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Typography variant="caption" color="textSecondary">
          Care Provided: {new Date(data.timestamp).toLocaleString()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default WoundCareReport;
