import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Paper,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Stack,
  Avatar,
  Chip
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  ExpandMore,
  ExpandLess,
  AccessTime as TimeIcon,
  Home as HomeIcon,
  EventAvailable as ShiftIcon,
  Assignment as TimesheetIcon,
  EventAvailable,
  Close,
  CheckBoxSharp,
  CheckSharp,
  CheckCircle
} from '@mui/icons-material';
import moment from 'moment';
import QRCode from 'react-qr-code';

import {
  CalendarContainer,
  DayCell,
  ShiftAvatar,
  ShiftIndicator
} from './calendar-views/styles';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { useGetAssignmentsForUserQuery } from 'src/redux/@api/shift';
import {
  useCheckTimesheetQrcodeStatusQuery,
  useCreateTimesheetAndGenerateQRCodeMutation,
  useCreateTimesheetRequestMutation,
  useValidateQRCodeMutation
} from 'src/redux/@api/timesheet';
import { QrCodeIcon } from 'lucide-react';
import { useAppSelector } from 'src/redux/hook';
import { useTimesheetWebSocket } from '../ws/timesheet/TimesheetWebSocket';
import { TimesheetWebSocketProvider } from '../ws/timesheet/TimesheetWebSocketProvider';
import LocationVerificationDialog from './utils/check-location';

interface ShiftAssignment {
  _id: string;
  status: string;
  shift: {
    _id: string;
    date: string;
    shiftPattern: {
      _id: string;
      name: string;
      timings: {
        careHomeId: string;
        startTime: string;
        endTime: string;
      }[];
    };
    homeId: {
      _id: string;
      name: string;
      address?: any;
    };
  };
  timesheet?: {
    status: string;
  };
}

interface CareStaffShiftCalendarProps {
  onMonthChange: (month: number, year: number) => void;
}

const CareStaffShiftCalendar: React.FC<CareStaffShiftCalendarProps> = ({
  onMonthChange
}) => {
  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment());
  const [assignments, setAssignments] = useState<
    Record<string, ShiftAssignment[]>
  >({});
  const [showFullCalendar, setShowFullCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [isViewShiftOpen, setIsViewShiftOpen] = useState(false);
  const [selectedShifts, setSelectedShifts] = useState<ShiftAssignment[]>([]);
  const [qrCodeData, setQRCodeData] = useState<string | null>(null);
  const [qrDialogOpen, setQrDialogOpen] = useState(false);
  const [currentQrCode, setCurrentQrCode] = useState<string | null>(null);

  const [isLocationVerificationOpen, setIsLocationVerificationOpen] =
    useState(false);
  const [postcode, setPostcode] = useState<string | null>(null);

  const [selectedAssignment, setSelectedAssignment] =
    useState<ShiftAssignment | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const userState = useAppSelector((state) => state.userState);

  useEffect(() => {
    // Call onMonthChange when the component mounts
    onMonthChange(currentDate.month() + 1, currentDate.year());
  }, []);

  const {
    data: userAssignments,
    isLoading,
    isError,
    refetch
  } = useGetAssignmentsForUserQuery(undefined);

  const [requestTimeSheet, requestTimeSheetState] =
    useCreateTimesheetAndGenerateQRCodeMutation();
  const [
    createTimesheetAndGenerateQRCode,
    createTimesheetAndGenerateQRCodeState
  ] = useCreateTimesheetAndGenerateQRCodeMutation();
  const [validateQRCode] = useValidateQRCodeMutation();

  const { data: qrCodeStatus, refetch: refetchQrCodeStatus } =
    useCheckTimesheetQrcodeStatusQuery(currentQrCode);

  useEffect(() => {
    if (userAssignments) {
      const groupedAssignments = groupAssignmentsByDate(userAssignments);
      setAssignments(groupedAssignments);
    }
  }, [userAssignments]);

  // WebSocket related state and refs
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connected, setConnected] = useState(false);
  const wsRef = useRef<WebSocket | null>(null);
  const reconnectTimeout = useRef<NodeJS.Timeout>();

  // Updated WebSocket connection logic
  useEffect(() => {
    const connectWebSocket = () => {
      // Clean up existing connection if any
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }

      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }

      // Only connect if we have a QR code
      if (!currentQrCode) {
        return;
      }

      console.log(
        'Initializing WebSocket connection for QR code:',
        currentQrCode
      );

      const ws = new WebSocket(
        `${process.env.REACT_APP_WS_HOSTNAME}/timesheet-ws?userId=${userState.user._id}&orgId=${selectedAssignment?.shift?.homeId?._id}`
      );

      ws.onopen = () => {
        console.log('WebSocket connected for QR code:', currentQrCode);
        setConnected(true);
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received WebSocket message:', data);

          if (
            data?.type === 'TIMESHEET_PROCESSED' &&
            data.payload.barcode === currentQrCode
          ) {
            if (data.payload.status === 'success') {
              setQrDialogOpen(false);
              setSelectedShifts((prev) => {
                return prev.map((s) =>
                  s.shift?._id === selectedAssignment?.shift?._id
                    ? {
                        ...s,
                        timesheet: { status: 'approved' }
                      }
                    : s
                );
              });
              dispatch(
                showSnack({
                  message: 'Timesheet approved successfully',
                  color: 'success'
                })
              );
              refetch(); // Refresh the assignments

              // Close the connection as we're done with this QR code
              ws.close();
            } else if (data.payload.status === 'error') {
              dispatch(
                showSnack({
                  message: `Error processing timesheet: ${data.payload.error}`,
                  color: 'error'
                })
              );
            }
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      ws.onclose = (event) => {
        console.log('WebSocket disconnected:', event);
        setConnected(false);

        // Only reconnect if we still have the same QR code and it wasn't a clean closure
        if (!event.wasClean && currentQrCode) {
          console.log('Scheduling reconnection...');
          reconnectTimeout.current = setTimeout(() => {
            console.log('Attempting to reconnect...');
            connectWebSocket();
          }, 3000);
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      wsRef.current = ws;
      setSocket(ws);
    };

    if (selectedAssignment?.shift?.homeId?._id) {
      connectWebSocket();
    } else {
      console.log('Home ID not found');
      wsRef.current = null;
      setSocket(null);
    }

    // Cleanup function
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      setSocket(null);
      setConnected(false);
    };
  }, [selectedAssignment]); // Only reconnect when QR code changes

  const handleLocationVerificationComplete = async (isOk) => {
    console.log('Location verification complete:', isOk);
    if (isOk) {
      setIsLocationVerificationOpen(false);
      const shift = selectedAssignment;
      const response = await createTimesheetAndGenerateQRCode({
        shiftId: shift.shift?._id,
        shiftPattern: shift.shift?.shiftPattern._id,
        homeId: shift.shift?.homeId._id
      }).unwrap();
      setSelectedShifts((prev) =>
        prev.map((s) =>
          s.shift?._id === shift.shift?._id
            ? {
                ...s,
                timesheet: { status: 'pending' }
              }
            : s
        )
      );
      if (response) {
        setCurrentQrCode(response);
        setSelectedAssignment(shift);
        setQrDialogOpen(true);
        refetchQrCodeStatus();
        // Notify through WebSocket that QR code is generated
        socket?.send(
          JSON.stringify({
            type: 'TIMESHEET_QR_GENERATED',
            payload: {
              barcode: response,
              carerId: userState.user._id,
              orgId: userState.currentOrganization._id,
              shiftId: shift.shift?._id,
              timestamp: new Date()
            }
          })
        );
      }
      dispatch(
        showSnack({
          message: 'QR Code generated successfully. Awaiting scan.',
          color: 'success'
        })
      );
    }

    // Close the dialog
    setIsLocationVerificationOpen(false);
  };

  const handleRequestTimesheet = async (shift: ShiftAssignment) => {
    try {
      setPostcode(shift.shift?.homeId?.address?.zipCode);
      setIsLocationVerificationOpen(true);
      setSelectedAssignment(shift);
    } catch (error) {
      dispatch(
        showSnack({
          message:
            error?.message || 'Error generating QR code. Try again later',
          color: 'error'
        })
      );
    }
  };

  const getTimesheetStatusColor = (status?: string) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'approved':
        return 'success';
      case 'rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  const groupAssignmentsByDate = (
    assignmentsData: ShiftAssignment[]
  ): Record<string, ShiftAssignment[]> => {
    return assignmentsData?.reduce((acc, assignment) => {
      const dateKey = moment(assignment.shift?.date).format('YYYY-MM-DD');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(assignment);
      return acc;
    }, {} as Record<string, ShiftAssignment[]>);
  };

  const handlePrevMonth = (): void => {
    const newDate = moment(currentDate).subtract(1, 'month');
    setCurrentDate(newDate);
    onMonthChange(newDate.month() + 1, newDate.year());
  };

  const handleNextMonth = (): void => {
    const newDate = moment(currentDate).add(1, 'month');
    setCurrentDate(newDate);
    onMonthChange(newDate.month() + 1, newDate.year());
  };

  const handleDayClick = (day: moment.Moment) => {
    const dateKey = day.format('YYYY-MM-DD');
    const dayAssignments = assignments[dateKey] || [];
    setSelectedDate(day);
    setSelectedShifts(dayAssignments);
    setIsViewShiftOpen(true);
    setQRCodeData(null); // Reset QR code when opening a new day
  };

  useEffect(() => {
    if (qrCodeStatus) {
      if (qrCodeStatus.status === 'approved') {
        setQrDialogOpen(false);
        setSelectedShifts((prev) => {
          return prev.map((s) =>
            s.shift?._id === selectedAssignment?.shift?._id
              ? {
                  ...s,
                  timesheet: qrCodeStatus
                }
              : s
          );
        });
        refetch();
        dispatch(
          showSnack({
            message: 'Timesheet approved successfully',
            color: 'success'
          })
        );
      }
    }
  }, [qrCodeStatus]);

  const getShiftColor = (shiftName: string): string => {
    let hash = 0;
    for (let i = 0; i < shiftName?.length; i++) {
      hash = shiftName?.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 20;
    return `hsl(${hue}, 100%, 70%)`;
  };

  const renderCalendarDay = (
    day: moment.Moment,
    isCurrentMonth: boolean
  ): JSX.Element => {
    const dateKey = day.format('YYYY-MM-DD');
    const dayAssignments = assignments[dateKey] || [];
    const isToday = day.isSame(moment(), 'day');

    let completedShifts = 0;

    dayAssignments.forEach((assignment) => {
      if (assignment.status === 'signed') {
        completedShifts++;
      }
    });

    const renderShiftAvatars = () => {
      return dayAssignments.slice(0, 2).map((assignment, index) => (
        <ShiftAvatar
          key={assignment._id}
          style={{
            backgroundColor: getShiftColor(assignment.shift?.shiftPattern.name),
            boxShadow: `0 0 0 2px 3px gray`,
            zIndex: index,
            fontSize: '0.65rem'
          }}
        >
          {assignment.shift?.shiftPattern?.name.substring(0, 2).toUpperCase()}
        </ShiftAvatar>
      ));
    };

    if (isMobile) {
      return (
        <Grid item xs={12 / 7} key={day.format('YYYY-MM-DD')}>
          <Badge
            badgeContent={dayAssignments.length}
            color="primary"
            invisible={dayAssignments.length === 0}
            sx={{
              '& .MuiBadge-badge': {
                right: 5,
                top: 5,
                padding: '0 4px',
                color: 'white',
                boxShadow: '0.5px 0px 0px 0.1px rgba(0,0,0,0.2)'
              }
            }}
          >
            <Paper
              elevation={1}
              sx={{
                height: '2.5rem',
                width: '2.5rem',
                margin: 'auto',
                borderRadius: '30%',
                border: isToday
                  ? `1px solid ${theme.palette.error.main}`
                  : 'none',
                backgroundColor: isCurrentMonth
                  ? 'white'
                  : theme.palette.action.hover,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={() => handleDayClick(day)}
            >
              <Typography variant="caption">{day?.date()}</Typography>
            </Paper>
          </Badge>
        </Grid>
      );
    }

    return (
      <Grid item xs={12 / 7} key={day.format('YYYY-MM-DD')}>
        <DayCell
          isCurrentMonth={isCurrentMonth}
          isToday={isToday}
          onClick={() => handleDayClick(day)}
        >
          <Typography variant="subtitle2">{day.date()}</Typography>

          <Box display="flex" mt={1}>
            {renderShiftAvatars()}
          </Box>
          {dayAssignments.length > 0 && (
            <Typography variant="caption">
              {dayAssignments.length}{' '}
              {dayAssignments.length === 1 ? 'shift' : 'shifts'}
            </Typography>
          )}
          {completedShifts > 0 && <CheckCircle color="success" />}
        </DayCell>
      </Grid>
    );
  };

  const renderCalendarDays = (): JSX.Element[] => {
    const days: JSX.Element[] = [];
    const startDate = moment(currentDate).startOf('month');
    const endDate = moment(currentDate).endOf('month');

    const firstDayOfMonth = startDate.day();
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(
        <Grid item xs={12 / 7} key={`empty-start-${i}`}>
          <Box />
        </Grid>
      );
    }

    let day = startDate.clone();
    while (day.isSameOrBefore(endDate)) {
      days.push(renderCalendarDay(day.clone(), true));
      day.add(1, 'day');
    }

    const lastDayOfMonth = endDate.day();
    for (let i = lastDayOfMonth; i < 6; i++) {
      days.push(
        <Grid item xs={12 / 7} key={`empty-end-${i}`}>
          <Box />
        </Grid>
      );
    }

    return days;
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography color="error">
        Error loading shifts. Please try again later.
      </Typography>
    );
  }

  return (
    <CalendarContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">{currentDate.format('MMMM YYYY')}</Typography>
        <Box>
          <IconButton onClick={handlePrevMonth}>
            <ChevronLeft />
          </IconButton>
          <IconButton onClick={handleNextMonth}>
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={1}>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <Grid item xs={12 / 7} key={day}>
            <Typography variant="subtitle1" align="center">
              {day}
            </Typography>
          </Grid>
        ))}
        {isMobile && !showFullCalendar
          ? renderCalendarDays().slice(0, 7)
          : renderCalendarDays()}
      </Grid>
      {isMobile && (
        <Box
          onClick={() => setShowFullCalendar(!showFullCalendar)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
            cursor: 'pointer',
            p: 0
          }}
        >
          <Typography variant="button" sx={{ mr: 1 }}>
            {showFullCalendar ? 'Show Current Week' : 'View Full Month'}
          </Typography>
          {showFullCalendar ? <ExpandLess /> : <ExpandMore />}
        </Box>
      )}

      <Dialog
        open={isViewShiftOpen}
        onClose={() => setIsViewShiftOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: 'hidden'
          }
        }}
      >
        <DialogTitle>
          <Stack direction="row" alignItems="center" spacing={2}>
            <EventAvailable />
            <Typography variant="h6">
              Shifts for {selectedDate?.format('MMMM D, YYYY')}
            </Typography>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ p: 3 }}>
          <Grid container spacing={3}>
            {selectedShifts.map((assignment) => (
              <Grid item xs={12} key={assignment._id}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 4
                    }
                  }}
                >
                  <Grid container spacing={3}>
                    {/* Shift Information */}
                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        mb={2}
                      >
                        <Avatar
                          sx={{
                            bgcolor: getShiftColor(
                              assignment.shift?.shiftPattern.name
                            ),
                            width: 56,
                            height: 56
                          }}
                        >
                          {assignment.shift?.shiftPattern?.name
                            .substring(0, 2)
                            .toUpperCase()}
                        </Avatar>
                        <Box>
                          <Typography variant="h6" gutterBottom>
                            {assignment.shift?.shiftPattern.name}
                          </Typography>
                          <Chip
                            size="small"
                            label={assignment.status}
                            color={
                              getTimesheetStatusColor(assignment.status) as any
                            }
                            sx={{ textTransform: 'capitalize' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>

                    {/* Shift Details */}
                    <Grid item xs={12} md={6}>
                      <Stack spacing={2}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <HomeIcon color="action" />
                          <Typography>
                            {assignment.shift?.homeId.name}
                          </Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center" spacing={1}>
                          <TimeIcon color="action" />
                          <Typography>
                            {
                              assignment.shift?.shiftPattern?.timings?.find(
                                (timing) =>
                                  timing.careHomeId ===
                                  assignment.shift?.homeId?._id
                              )?.startTime
                            }{' '}
                            -{' '}
                            {
                              assignment.shift?.shiftPattern?.timings?.find(
                                (timing) =>
                                  timing.careHomeId ===
                                  assignment.shift?.homeId?._id
                              )?.endTime
                            }
                          </Typography>
                        </Stack>

                        {assignment?.timesheet && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <TimesheetIcon color="action" />
                            <Typography>
                              Timesheet Status:{' '}
                              <Chip
                                size="small"
                                label={assignment.timesheet.status}
                                color={
                                  getTimesheetStatusColor(
                                    assignment.timesheet.status
                                  ) as any
                                }
                                sx={{ textTransform: 'capitalize', ml: 1 }}
                              />
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </Grid>

                    {/* Action Buttons */}
                    <Grid item xs={12} md={6}>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        height="100%"
                      >
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          disabled={
                            assignment?.timesheet?.status === 'approved'
                          }
                          onClick={() => handleRequestTimesheet(assignment)}
                          loading={requestTimeSheetState.isLoading}
                          startIcon={<QrCodeIcon />}
                          sx={{ minWidth: 200 }}
                        >
                          {assignment?.timesheet?.status === 'approved'
                            ? 'Timesheet Approved'
                            : assignment?.timesheet?.status === 'pending'
                            ? 'Generate New QR Code'
                            : 'Request Timesheet'}
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ px: 3, py: 2, borderTop: 1, borderColor: 'divider' }}
        >
          <Button
            onClick={() => setIsViewShiftOpen(false)}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* QR Code Dialog */}
      <Dialog
        open={qrDialogOpen}
        onClose={() => setQrDialogOpen(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: 'hidden'
          }
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            py: 2
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <QrCodeIcon />
            <Typography variant="h6">Timesheet QR Code</Typography>
          </Stack>
          <IconButton
            onClick={() => setQrDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'inherit'
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  {selectedAssignment?.shift?.shiftPattern.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {selectedAssignment?.shift?.homeId.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {moment(selectedAssignment?.shift?.date).format(
                    'MMMM D, YYYY'
                  )}
                </Typography>
              </Box>

              <Box
                sx={{
                  bgcolor: 'background.paper',
                  p: 3,
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {currentQrCode && (
                  <Box sx={{ width: '100%', maxWidth: 200, margin: '0 auto' }}>
                    <QRCode
                      size={256}
                      style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%'
                      }}
                      value={`${currentQrCode}:${userState.user?._id}`}
                      viewBox={`0 0 256 256`}
                    />
                  </Box>
                )}
              </Box>

              <Typography
                variant="body2"
                align="center"
                color="textSecondary"
                sx={{
                  bgcolor: 'action.hover',
                  p: 2,
                  borderRadius: 1
                }}
              >
                Timesheet is pending, find your senior to scan this or wait for
                the home admins to approve this later.
              </Typography>
            </Stack>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{ px: 3, py: 2, borderTop: 1, borderColor: 'divider' }}
        >
          <Button
            onClick={() => setQrDialogOpen(false)}
            variant="outlined"
            color="primary"
            fullWidth
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LocationVerificationDialog
        open={isLocationVerificationOpen}
        postcode={postcode} // Your postcode here
        onVerificationComplete={handleLocationVerificationComplete}
        onClose={() => setIsLocationVerificationOpen(false)}
      />
    </CalendarContainer>
  );
};

export default CareStaffShiftCalendar;
