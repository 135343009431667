import React, { useState, useMemo } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Chip,
  Stack,
  alpha,
  Grid,
  Menu,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import {
  Search,
  MoreVert,
  Business,
  CheckCircle,
  Cancel,
  Description,
  Visibility
} from '@mui/icons-material';
import moment from 'moment';
import ViewInvoiceDialog from './view-invoices';
import {
  useGetInvoiceByIdQuery,
  useGetInvoicesQuery,
  useLazyGetInvoiceByIdQuery
} from 'src/redux/@api/invoices';
import InvoiceDialog from 'src/components/core/dialogs/shifts/invoice-dialog';

// Mock data for demonstration
const MOCK_INVOICE = {
  invoiceNumber: 'INV-20240101',
  createdAt: '2024-01-01',
  dueDate: '2024-02-01',
  totalAmount: 1500.0,
  status: 'pending',
  shiftSummary: [
    {
      shiftType: 'Morning',
      count: 10,
      totalHours: 80,
      weekdayHours: 60,
      weekendHours: 20,
      holidayHours: 0,
      weekdayRate: 15,
      weekendRate: 20,
      holidayRate: 25,
      totalAmount: 1500
    }
  ],
  startDate: '2024-01-01',
  endDate: '2024-01-15',
  homeId: {
    name: 'Sample Care Home',
    address: '123 Care Street',
    email: 'care@example.com',
    phone: '1234567890'
  },
  agencyId: {
    name: 'Sample Agency',
    address: '456 Agency Road',
    email: 'agency@example.com',
    phone: '0987654321'
  }
};

const InvoiceTable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [filter, setFilter] = useState('all');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const {
    data: invoices,
    isSuccess,
    isLoading,
    isError,
    error
  } = useGetInvoicesQuery(undefined);

  // Mock data
  // const invoices = [
  //   {
  //     _id: '1',
  //     invoiceNumber: 'INV-001',
  //     createdAt: '2024-01-01',
  //     agencyId: { name: 'Agency 1' },
  //     homeId: { name: 'Home 1' },
  //     totalAmount: 1500,
  //     status: 'pending'
  //   },
  //   {
  //     _id: '2',
  //     invoiceNumber: 'INV-002',
  //     createdAt: '2024-01-02',
  //     agencyId: { name: 'Agency 2' },
  //     homeId: { name: 'Home 2' },
  //     totalAmount: 2000,
  //     status: 'paid'
  //   },
  //   {
  //     _id: '3',
  //     invoiceNumber: 'INV-003',
  //     createdAt: '2024-01-03',
  //     agencyId: { name: 'Agency 3' },
  //     homeId: { name: 'Home 3' },
  //     totalAmount: 1000,
  //     status: 'overdue'
  //   },

  //   // Add more data here

  //   {
  //     _id: '4',
  //     invoiceNumber: 'INV-004',
  //     createdAt: '2024-01-04',
  //     agencyId: { name: 'Agency 4' },
  //     homeId: { name: 'Home 4' },
  //     totalAmount: 2500,
  //     status: 'paid'
  //   },
  //   {
  //     _id: '5',
  //     invoiceNumber: 'INV-005',
  //     createdAt: '2024-01-05',
  //     agencyId: { name: 'Agency 5' },
  //     homeId: { name: 'Home 5' },
  //     totalAmount: 1800,
  //     status
  //   }
  // ];

  const handleView = (invoice) => {
    setSelectedInvoice(MOCK_INVOICE);
    setViewDialogOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredInvoices = useMemo(() => {
    return invoices?.data?.filter((invoice) => {
      const matchesFilter = filter === 'all' || invoice.status === filter;
      const matchesSearch =
        search === '' ||
        invoice.invoiceNumber.toLowerCase().includes(search.toLowerCase()) ||
        invoice.agencyId.name.toLowerCase().includes(search.toLowerCase()) ||
        invoice.homeId.name.toLowerCase().includes(search.toLowerCase());

      return matchesFilter && matchesSearch;
    });
  }, [isSuccess, filter, search]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'paid':
        return {
          bgcolor: alpha(theme.palette.success.main, 0.1),
          color: theme.palette.success.main
        };
      case 'pending':
        return {
          bgcolor: alpha(theme.palette.warning.main, 0.1),
          color: theme.palette.warning.main
        };
      default:
        return {
          bgcolor: alpha(theme.palette.error.main, 0.1),
          color: theme.palette.error.main
        };
    }
  };

  // Mobile Card View
  const MobileView = () => (
    <Stack spacing={2}>
      {filteredInvoices?.map((invoice) => (
        <Card key={invoice._id}>
          <CardContent>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                {invoice.invoiceNumber}
              </Typography>
              <Chip
                label={
                  invoice.status.charAt(0).toUpperCase() +
                  invoice.status.slice(1)
                }
                size="small"
                sx={getStatusColor(invoice.status)}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">
                  Date
                </Typography>
                <Typography variant="body2">
                  {moment(invoice.createdAt).format('MMM D, YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">
                  Amount
                </Typography>
                <Typography variant="body2">
                  £{invoice.totalAmount.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">
                  Agency
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                >
                  <Business fontSize="small" />
                  {invoice.agencyId.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">
                  Home
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                >
                  <Business fontSize="small" />
                  {invoice.homeId.name}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => handleView(invoice)}>
                <MoreVert />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );

  // Desktop Table View

  const DesktopView = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedInvoice, setSelectedInvoice] = useState<any>(null);

    const [isViewDialogOpen, setViewDialogOpen] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState<any>(null);

    const [getInvoiceById] = useLazyGetInvoiceByIdQuery();

    const handleMenuOpen = (
      event: React.MouseEvent<HTMLElement>,
      invoice: any
    ) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setSelectedInvoice(invoice);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
      setSelectedInvoice(null);
    };

    const handleViewClick = async () => {
      try {
        const invoice = await getInvoiceById(selectedInvoice._id).unwrap();
        console.log('Invoice:', invoice);
        setInvoiceDetails(invoice.data);
        setViewDialogOpen(true);
      } catch (error) {
        setInvoiceDetails(null);
        console.log('Error viewing invoice:', error);
      }
      handleMenuClose();
    };

    const handleAcceptClick = async () => {
      try {
        // Call your accept invoice API/function here
        // await handleAcceptInvoice(selectedInvoice._id);
        handleMenuClose();
      } catch (error) {
        console.error('Error accepting invoice:', error);
        // Handle error (show notification, etc.)
      }
    };

    const handleRejectClick = async () => {
      try {
        // Call your reject invoice API/function here
        // await handleRejectInvoice(selectedInvoice._id);
        handleMenuClose();
      } catch (error) {
        console.error('Error rejecting invoice:', error);
        // Handle error (show notification, etc.)
      }
    };

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Agency</TableCell>
              <TableCell>Home</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInvoices?.map((invoice) => (
              <TableRow key={invoice._id}>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell>
                  {moment(invoice.createdAt).format('MMM D, YYYY')}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Business fontSize="small" />
                    {invoice.agencyId.name}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Business fontSize="small" />
                    {invoice.homeId.name}
                  </Box>
                </TableCell>
                <TableCell>£{invoice.totalAmount.toFixed(2)}</TableCell>
                <TableCell>
                  <Chip
                    label={
                      invoice.status.charAt(0).toUpperCase() +
                      invoice.status.slice(1)
                    }
                    size="small"
                    sx={getStatusColor(invoice.status)}
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={(e) => handleMenuOpen(e, invoice)}
                    aria-label="more options"
                  >
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItem onClick={handleViewClick}>
            <ListItemIcon>
              <Visibility fontSize="small" />
            </ListItemIcon>
            <ListItemText>View Invoice</ListItemText>
          </MenuItem>

          {selectedInvoice?.status === 'pending' && (
            <>
              <MenuItem
                onClick={handleAcceptClick}
                sx={{ color: 'success.main' }}
              >
                <ListItemIcon>
                  <CheckCircle fontSize="small" color="success" />
                </ListItemIcon>
                <ListItemText>Accept Invoice</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={handleRejectClick}
                sx={{ color: 'error.main' }}
              >
                <ListItemIcon>
                  <Cancel fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText>Reject Invoice</ListItemText>
              </MenuItem>
            </>
          )}

          {selectedInvoice?.status === 'paid' && (
            <MenuItem onClick={handleViewClick}>
              <ListItemIcon>
                <Description fontSize="small" />
              </ListItemIcon>
              <ListItemText>Download PDF</ListItemText>
            </MenuItem>
          )}
        </Menu>

        {isViewDialogOpen && invoiceDetails && (
          <InvoiceDialog
            open={isViewDialogOpen}
            onClose={() => {
              setViewDialogOpen(false);
              setInvoiceDetails(null);
            }}
            timesheets={invoiceDetails.timesheets}
            totalAmount={invoiceDetails.totalAmount}
            home={{
              homeId: invoiceDetails.home.homeId,
              homeName: invoiceDetails.home.homeName,
              homeAddress: invoiceDetails.home.homeAddress,
              homeEmail: invoiceDetails.home.homeEmail,
              homePhone: invoiceDetails.home.homePhone
            }}
            selectedStartDate={invoiceDetails.period.startDate}
            selectedEndDate={invoiceDetails.period.endDate}
          />
        )}
      </TableContainer>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Filters */}
      <Box
        sx={{
          mb: 3,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: 2
        }}
      >
        <TextField
          placeholder="Search invoices?.data?..."
          variant="outlined"
          fullWidth={isMobile}
          size="small"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: <Search color="action" sx={{ mr: 1 }} />
          }}
          sx={{
            maxWidth: isMobile ? '100%' : '300px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '28px'
            }
          }}
        />
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Filter</InputLabel>
          <Select
            value={filter}
            label="Filter"
            onChange={(e) => setFilter(e.target.value)}
          >
            <MenuItem value="all">All Status</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="overdue">Overdue</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Responsive View */}
      {isMobile ? <MobileView /> : <DesktopView />}

      {/* Pagination */}
      <TablePagination
        component="div"
        count={filteredInvoices?.data?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* View Dialog */}
      {viewDialogOpen && selectedInvoice && (
        <ViewInvoiceDialog
          open={viewDialogOpen}
          onClose={() => {
            setViewDialogOpen(false);
            setSelectedInvoice(null);
          }}
          invoice={selectedInvoice}
          home={{
            homeName: selectedInvoice.homeId?.name,
            homeAddress: selectedInvoice.homeId?.address,
            homeEmail: selectedInvoice.homeId?.email,
            homePhone: selectedInvoice.homeId?.phone
          }}
          agency={{
            agencyName: selectedInvoice.agencyId?.name,
            agencyAddress: selectedInvoice.agencyId?.address,
            agencyEmail: selectedInvoice.agencyId?.email,
            agencyPhone: selectedInvoice.agencyId?.phone
          }}
        />
      )}
    </Box>
  );
};

export default InvoiceTable;
